import React from 'react';
import { useEffect } from 'react';
import { ASSETS_API } from '../../utils/apiRoutes';

const ScrollerComponent = () => {
  const imagesUrl = ASSETS_API().resources.images;

  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', 'true');

        const scrollerInner = scroller.querySelector('.scroller__inner') as HTMLElement;
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true) as HTMLElement;
          duplicatedItem.setAttribute('aria-hidden', 'true');
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className="scroller w-full" data-direction="right" data-speed="slow">
      <div className="scroller__inner">
        <img src={`${imagesUrl}/homepage/boracay.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/puerto.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/siargao.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/bhl.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/baguio.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/vigan.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/cebu.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/manila.png`} alt="" loading="lazy" />
        <img src={`${imagesUrl}/homepage/palawan.png`} alt="" loading="lazy" />
      </div>
    </div>
  );
};

export default ScrollerComponent;
