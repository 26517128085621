import React from 'react';
import { SvgProps } from './SvgProps';

/**
 *
 * @param param _width: number, _height: number, colors: { primary: string; secondary: string; tertiary?: string; quaternary?: string }
 * @returns React Component
 */
export const WaveUp = ({ _width = 1401, _height = 628, colors = { primary: '#2EAE4E', secondary: '#BEE6C8', tertiary: '#D2FCDB', quaternary: '#EAF7ED' } }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 1401 628" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={0.2}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1317.18 -545.215L1242.94 -502.355C1168.71 -459.496 1020.24 -373.777 892.601 -251.974C764.965 -130.171 658.162 27.7158 509.692 113.435C361.223 199.153 171.088 212.704 6.9936 271.359C-157.101 330.015 -295.153 433.776 -364.179 485.656L-433.206 537.537L-526.955 375.158L-452.721 332.298C-378.486 289.439 -230.017 203.72 -81.5475 118.002C66.9218 32.2829 215.391 -53.4358 363.86 -139.155C512.329 -224.873 660.799 -310.592 809.268 -396.311C957.737 -482.03 1106.21 -567.748 1180.44 -610.608L1254.68 -653.467L1317.18 -545.215Z"
          fill={colors.primary}
          fillOpacity={0.5}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1295.01 -583.596L1220.78 -540.737C1146.54 -497.878 998.073 -412.159 870.437 -290.356C742.8 -168.553 635.998 -10.6661 487.528 75.0527C339.059 160.771 148.924 174.322 -15.1705 232.977C-179.265 291.633 -317.317 395.394 -386.344 447.274L-455.37 499.155L-549.119 336.776L-474.885 293.917C-400.65 251.057 -252.181 165.339 -103.712 79.6198C44.7576 -6.09891 193.227 -91.8177 341.696 -177.536C490.165 -263.255 638.634 -348.974 787.104 -434.693C935.573 -520.411 1084.04 -606.13 1158.28 -648.989L1232.51 -691.849L1295.01 -583.596Z"
          fill={colors.secondary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1272.84 -621.987L1198.61 -579.128C1124.37 -536.268 975.905 -450.55 848.269 -328.747C720.632 -206.944 613.83 -49.0567 465.36 36.662C316.891 122.381 126.756 135.931 -37.3384 194.587C-201.433 253.242 -339.485 357.003 -408.512 408.884L-477.538 460.764L-571.287 298.385L-497.053 255.526C-422.818 212.667 -274.349 126.948 -125.88 41.2292C22.5896 -44.4895 171.059 -130.208 319.528 -215.927C467.997 -301.646 616.467 -387.365 764.936 -473.083C913.405 -558.802 1061.87 -644.521 1136.11 -687.38L1210.34 -730.239L1272.84 -621.987Z"
          fill={colors.tertiary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1250.68 -660.373L1176.44 -617.513C1102.21 -574.654 953.737 -488.935 826.101 -367.132C698.465 -245.329 591.662 -87.4424 443.192 -1.72369C294.723 83.9951 104.588 97.5455 -59.5064 156.201C-223.601 214.857 -361.653 318.618 -430.679 370.498L-499.706 422.378L-593.455 260L-519.221 217.14C-444.986 174.281 -296.517 88.5622 -148.047 2.84341C0.42165 -82.8753 148.891 -168.594 297.36 -254.313C445.829 -340.032 594.299 -425.75 742.768 -511.469C891.237 -597.188 1039.71 -682.906 1113.94 -725.766L1188.18 -768.625L1250.68 -660.373Z"
          fill={colors.quaternary}
        />
      </g>
    </svg>
  );
};

export const WaveDown = ({ _width = 1440, _height = 260, colors = { primary: '#2EAE4E', secondary: '#BEE6C8', tertiary: '#D2FCDB', quaternary: '#EAF7ED' } }: SvgProps) => {
  // set the width of the viewport
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <svg width={_width} height={_height} viewBox={`0 0 ${width} 260`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity={0.2}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-105.667 176.241L-15.3275 181.134C75.0121 186.026 255.691 195.812 438.749 161.684C621.806 127.557 807.242 49.518 987.921 59.3032C1168.6 69.0885 1344.52 166.698 1523.42 209.418C1702.31 252.137 1884.18 239.966 1975.12 233.881L2066.05 227.795L2055.35 425.401L1965.01 420.508C1874.67 415.616 1693.99 405.83 1513.31 396.045C1332.63 386.26 1151.95 376.475 971.274 366.689C790.594 356.904 609.915 347.119 429.236 337.334C248.557 327.549 67.8775 317.763 -22.4621 312.871L-112.802 307.978L-105.667 176.241Z"
          fill={colors.primary}
          fillOpacity={0.5}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-108.193 222.952L-17.8529 227.845C72.4867 232.737 253.166 242.522 436.223 208.395C619.281 174.268 804.716 96.2289 985.396 106.014C1166.07 115.799 1342 213.409 1520.89 256.129C1699.79 298.848 1881.66 286.677 1972.59 280.592L2063.53 274.506L2052.82 472.112L1962.48 467.219C1872.14 462.327 1691.47 452.541 1510.79 442.756C1330.11 432.971 1149.43 423.186 968.748 413.4C788.069 403.615 607.39 393.83 426.711 384.045C246.031 374.259 65.3521 364.474 -24.9875 359.582L-115.327 354.689L-108.193 222.952Z"
          fill={colors.secondary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-110.72 269.673L-20.3803 274.565C69.9594 279.458 250.639 289.243 433.696 255.116C616.753 220.989 802.189 142.95 982.868 152.735C1163.55 162.52 1339.47 260.13 1518.37 302.849C1697.26 345.569 1879.13 333.398 1970.06 327.313L2061 321.227L2050.3 518.833L1959.96 513.94C1869.62 509.047 1688.94 499.262 1508.26 489.477C1327.58 479.692 1146.9 469.906 966.221 460.121C785.542 450.336 604.862 440.551 424.183 430.765C243.504 420.98 62.8247 411.195 -27.5149 406.302L-117.854 401.41L-110.72 269.673Z"
          fill={colors.tertiary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-113.245 316.39L-22.9056 321.282C67.434 326.175 248.113 335.96 431.171 301.833C614.228 267.706 799.664 189.666 980.343 199.452C1161.02 209.237 1336.94 306.847 1515.84 349.566C1694.74 392.286 1876.6 380.115 1967.54 374.029L2058.47 367.944L2047.77 565.549L1957.43 560.657C1867.09 555.764 1686.41 545.979 1505.73 536.194C1325.05 526.408 1144.37 516.623 963.695 506.838C783.016 497.053 602.337 487.267 421.658 477.482C240.979 467.697 60.2993 457.912 -30.0403 453.019L-120.38 448.126L-113.245 316.39Z"
          fill={colors.quaternary}
        />
      </g>
    </svg>
  );
};
