import { SvgProps } from './SvgProps';

export const QuestionCircleSvg = ({ _width = 16, _height = 16, _color = '#016E7F' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_177_30778)">
        <path
          d="M8 0.500006C5.94573 0.437961 3.95056 1.19232 2.45121 2.59796C0.951859 4.0036 0.0704796 5.94601 0 8.00001C0.0704796 10.054 0.951859 11.9964 2.45121 13.4021C3.95056 14.8077 5.94573 15.5621 8 15.5C10.0543 15.5621 12.0494 14.8077 13.5488 13.4021C15.0481 11.9964 15.9295 10.054 16 8.00001C15.9295 5.94601 15.0481 4.0036 13.5488 2.59796C12.0494 1.19232 10.0543 0.437961 8 0.500006ZM8 14.25C6.27717 14.3123 4.59981 13.6896 3.33484 12.5184C2.06987 11.3471 1.32027 9.72253 1.25 8.00001C1.32027 6.27749 2.06987 4.6529 3.33484 3.48163C4.59981 2.31037 6.27717 1.68776 8 1.75001C9.72283 1.68776 11.4002 2.31037 12.6652 3.48163C13.9301 4.6529 14.6797 6.27749 14.75 8.00001C14.6797 9.72253 13.9301 11.3471 12.6652 12.5184C11.4002 13.6896 9.72283 14.3123 8 14.25Z"
          fill={_color}
        />
        <path
          d="M7.97997 11.71C8.39971 11.71 8.73997 11.3697 8.73997 10.95C8.73997 10.5303 8.39971 10.19 7.97997 10.19C7.56023 10.19 7.21997 10.5303 7.21997 10.95C7.21997 11.3697 7.56023 11.71 7.97997 11.71Z"
          fill={_color}
        />
        <path
          d="M9.72997 4.74999C9.23462 4.37039 8.6238 4.17267 7.99997 4.18999C7.69884 4.17247 7.39724 4.21487 7.11262 4.31473C6.828 4.4146 6.56602 4.56995 6.34187 4.77178C6.11771 4.97361 5.93583 5.21792 5.80676 5.49054C5.6777 5.76317 5.60401 6.05869 5.58997 6.35999V6.46999H6.82997V6.36999C6.85054 6.07818 6.98446 5.806 7.20311 5.61165C7.42175 5.4173 7.70776 5.31621 7.99997 5.32999C8.141 5.31294 8.28405 5.32614 8.41959 5.3687C8.55513 5.41126 8.68004 5.48221 8.78601 5.57683C8.89198 5.67144 8.97657 5.78755 9.03416 5.91742C9.09174 6.04729 9.12099 6.18794 9.11997 6.32999C9.11997 6.67999 8.87997 7.05999 8.33997 7.43999C8.07245 7.59033 7.84311 7.8002 7.6697 8.05337C7.4963 8.30655 7.38347 8.59623 7.33997 8.89999V9.25999H8.57997V8.99999C8.589 8.80693 8.67124 8.62456 8.80997 8.48999C8.96834 8.31148 9.14236 8.14749 9.32997 7.99999L9.49997 7.85999C9.77835 7.67904 10.0073 7.43163 10.1662 7.14008C10.325 6.84853 10.4088 6.52202 10.41 6.18999C10.411 5.91448 10.3505 5.64222 10.2328 5.39308C10.1152 5.14395 9.94338 4.92424 9.72997 4.74999Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_177_30778">
          <rect width={_width} height={_height} fill={_color} />
        </clipPath>
      </defs>
    </svg>
  );
};
