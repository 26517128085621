import React from 'react';
import { AlertBox } from '../../components/AlertBox';
import { ConfirmEmailSvg } from '../../resources/svg/ConfirmEmailSvg';
import { GalagoButton } from '../../components/Button';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import EmailHook from '../../hooks/EmailHook';

const EmailConfirmationDialog = () => {
  const { resendEmailApi } = EmailHook();
  const { userEmail, setShowEmailConfirmationDialog, showEmailConfirmationDialog } = EmailDialogStore();

  const [disableResend, setDisableResend] = React.useState(true);
  const [timeLeft, setTimeLeft] = React.useState(0);

  React.useEffect(() => {
    if (showEmailConfirmationDialog === false) {
      setTimeLeft(60);
    }
  }, [showEmailConfirmationDialog]);

  React.useEffect(() => {
    // prevent running when the dialog is not shown
    setDisableResend(true);
    if (showEmailConfirmationDialog === false) return;
    // if (disableResend === false) return;
    // console.log('triggered');
    // exit early when we reach 0
    if (!timeLeft) {
      setDisableResend(false);
      return;
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, showEmailConfirmationDialog]);

  const handleResendEmail = async () => {
    setDisableResend(true);
    const response = await resendEmailApi(userEmail as string);
    // console.log(response);
    const { statusCode } = response.data;
    if (statusCode === 200) {
      setDisableResend(false);
      setTimeLeft(60);
      // setShowEmailConfirmationDialog(false);
    }
  };

  return (
    <AlertBox
      size={'lg'}
      title={' '}
      show={showEmailConfirmationDialog}
      onHide={(): void => {
        setShowEmailConfirmationDialog(false);
      }}
    >
      <div id="confirm-email-alert">
        <div>
          <ConfirmEmailSvg _color="#016e7f" _width={132} />
          {/* <EmailSendSvg _color="#016e7f" _width={80} /> */}
        </div>
        <div>
          <h2>Confirm your email</h2>
          <p>
            You've got mail at <span className="text-primary-primary-100">{userEmail}</span>! Check your inbox (or spam) <br /> and confirm your email address to continue your signup.
          </p>
        </div>
        <div className="d-flex">
          <div className="relative w-100 d-flex flex-col gap-3">
            <span className="absolute top-[-24px] right-0 muted text-secondary" style={{ fontSize: '14px', display: timeLeft === 0 ? 'none' : 'block' }}>
              Resend in {timeLeft}s
            </span>
            <GalagoButton
              label={'Resend Email'}
              btn="primary"
              className="w-100"
              onClick={() => {
                handleResendEmail();
                // setConfirmEmailModal(false); // close this modal
                // setDisableResend(true);
              }}
              disabled={disableResend}
            />
            <button
              className="text-primary-primary-100 text-decoration-none align-self-center"
              style={{ cursor: 'pointer', fontWeight: '500' }}
              onClick={(e) => {
                setShowEmailConfirmationDialog(false);
              }}
            >
              Close
            </button>
          </div>
          {/* Hidden This is currently not functional */}
          {/* <GalagoButton label={'Resend Email'} btn="light" /> */}
        </div>
      </div>
    </AlertBox>
  );
};

export default EmailConfirmationDialog;
