import React from 'react';
import { useSearchParams } from 'react-router-dom';
import HotelBookingConfirmedPage from '../../../components/Hotels/HotelBookingConfirmedPage';
import BookingConfirmationPage from '../flights/BookingConfirmationPage';

export const ConfirmationPage = () => {
  const [searchParams] = useSearchParams();
  const paymentReference: string | null = searchParams.get('paymentReference');
  const product: string | null = searchParams.get('product');
  const platform: string | null = searchParams.get('platform');

  if (product === 'hotels') {
    return <HotelBookingConfirmedPage platform={platform} paymentReference={paymentReference} />;
  } else {
    return <BookingConfirmationPage />;
  }
};
