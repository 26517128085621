import { SvgProps } from './SvgProps';

export const LinkedInSvg = ({ _width = 12, _height = 13, _color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 21 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.36271 7.47254H11.4577V9.01421C11.9035 8.12754 13.0469 7.33087 14.7644 7.33087C18.0569 7.33087 18.8385 9.09587 18.8385 12.3342V18.3317H15.5052V13.0717C15.5052 11.2275 15.0594 10.1875 13.9244 10.1875C12.3502 10.1875 11.696 11.3084 11.696 13.0709V18.3317H8.36271V7.47254ZM2.64687 18.19H5.98021V7.33087H2.64687V18.19ZM6.45771 3.79004C6.45783 4.06943 6.40242 4.34607 6.2947 4.60386C6.18697 4.86165 6.02908 5.09547 5.83021 5.29171C5.42721 5.69223 4.88172 5.91642 4.31354 5.91504C3.74637 5.91466 3.20213 5.69104 2.79854 5.29254C2.60038 5.09563 2.44303 4.86156 2.33549 4.60373C2.22796 4.34591 2.17236 4.06939 2.17188 3.79004C2.17188 3.22587 2.39688 2.68587 2.79937 2.28754C3.20261 1.8885 3.74708 1.66479 4.31437 1.66504C4.88271 1.66504 5.42771 1.88921 5.83021 2.28754C6.23187 2.68587 6.45771 3.22587 6.45771 3.79004Z"
        fill={_color}
      />
    </svg>
  );
};
