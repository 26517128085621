import React from 'react';
import { getTransactionDetails } from '../utils/apiRoutes';
import axios, { AxiosResponse } from 'axios';

const FlightHistoryInformationHooks = (id: string) => {
  const [flightDetails, setFlightDetails] = React.useState<{ [index: string]: any }>([]);

  React.useEffect(() => {
    getFlightDetails(id);
    // eslint-disable-next-line
  }, []);

  const getFlightDetails = async (id: string) => {
    try {
      const response: AxiosResponse = await axios.get(getTransactionDetails(id));
      setFlightDetails(response.data.data);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      setFlightDetails(response.data);
      return response;
    }
  };

  return { flightDetails };
};

export default FlightHistoryInformationHooks;
