import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { bookingFlightsApiRoute, bookingFlightsV2ApiRoute, X_API_KEY } from '../utils/apiRoutes';
import { MystiflyFlightSegmentHook } from './MystiflyFlightSegmentHook';

// type FlightBookingModalHooksType = {
//   Flight: { [index: string]: any };
//   TransactionId: string;
//   Contacts: { [index: string]: any };
//   Passengers: { [index: string]: any };
// };

export const FlightBookingModalHooks = () => {
  // const { flightSegments } = FlightResultsHook();

  const { segments } = MystiflyFlightSegmentHook();

  // eslint-disable-next-line
  const [bookFlightResponse, setBookFlightResponse] = useState<{ [index: string]: any }>();

  useEffect(() => {
    if (axios.defaults.headers.common['Authorization'] === undefined) {
      axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    }
  }, []);

  const galagoBookFlight = async (dataParam: {
    paymentIntentId: number | string;
    referenceId: number | string;
    theTotalAmount: number;
    TransactionId: string;
    Flight: { [index: string]: any };
    Contacts: { [index: string]: any };
    Passengers: { [index: string]: any };
  }) => {
    try {
      const flightDetails = dataParam.Flight;
      const transactionId = dataParam.TransactionId;

      const cabinClass = dataParam.Flight.cabin;
      const adults = flightDetails.passengers.adults;
      const children = flightDetails.passengers.children;
      const infants = flightDetails.passengers.infants;

      const flight = flightDetails.flight;
      // const apiProvider = flight.Provider;
      const apiProvider = 'MYSTIFLY';
      // console.log(adults);
      const flightType = flightDetails.type;

      // console.log(flightDetails.flight);
      // return;
      const { departureFlightSegmentDetails, returnFlightSegmentDetails } = segments({ FlightType: flightType, FlightDetails: flightDetails.flight });

      // console.log(departureFlightSegmentDetails);
      // return;

      const ProviderDetailsPayload = {
        FareSourceCode: flight.FareSourceCode,
        FlightFares: flight.FlightFares,
        OriginDestinations: flight.OriginDestinations,
        PenaltiesInfo: flight.PenaltiesInfo,
        ValidatingCarrier: flight.ValidatingCarrier,
      };

      const urlParam = flightType === 'one-way' ? 'one-way' : 'roundtrip';

      const contactDetails = dataParam.Contacts[0];
      const customerName = `${contactDetails.firstName} ${contactDetails?.middleName} ${contactDetails.lastName}`;
      // console.log();
      let rawPayload = {
        transactionId: transactionId,
        origin: departureFlightSegmentDetails?.departureCode,
        destination: returnFlightSegmentDetails ? returnFlightSegmentDetails?.arrivalCode : departureFlightSegmentDetails?.arrivalCode,
        departureDate: departureFlightSegmentDetails?.departureDateTime,
        returnDate: returnFlightSegmentDetails ? returnFlightSegmentDetails?.arrivalDateTime : null,
        customerDetails: {
          name: customerName,
          email: contactDetails.email,
          mobileNumber: `${contactDetails.idd.replace('+', '')}${contactDetails.mobile}`,
        },
        adults: adults,
        children: children,
        infants: infants,
        cabinClass: cabinClass,
        flightType: dataParam.Flight.type === 'round-trip' ? 'roundtrip' : 'oneWay',
        provider: apiProvider,
        providerDetails: ProviderDetailsPayload,
        paymentFlightDetails: {
          name: customerName,
          amount: +(dataParam.theTotalAmount * 0.01),
          referenceNumber: dataParam.referenceId,
          paymentIntentId: dataParam.paymentIntentId,
          promoCodes: [],
        },
        passengerDetails: dataParam.Passengers,
      };

      let _url = urlParam === 'one-way' ? bookingFlightsApiRoute('one-way') : bookingFlightsV2ApiRoute('roundtrip');

      const response: AxiosResponse = await axios.post(_url, rawPayload);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  // console.log(providerDetails);

  return { bookFlightResponse, galagoBookFlight };
};

// eslint-disable-next-line
// const lowerCaseKeys = (obj: any): any => {
//   if (typeof obj !== 'object') {
//     return obj;
//   }
//   if (Array.isArray(obj)) {
//     return obj.map(lowerCaseKeys);
//   }
//   if (obj === null) {
//     return null;
//   }
//   const entries = Object.entries(obj);
//   const mappedEntries = entries.map(([k, v]) => [`${k.substr(0, 1).toLowerCase()}${k.substr(1)}`, lowerCaseKeys(v)] as const);
//   return Object.fromEntries(mappedEntries);
// };
