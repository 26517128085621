/**
 *
 * @param seconds accepts seconds in number type
 * @returns readable time 00h 00min for humans
 */
export const timeForHumans = (seconds: number) => {
  let hours = seconds / 3600; // seconds to hours formula

  // split hour and minutes
  const _hours = hours.toString().split('.');

  // if _hours has only one item, then it means it only has Hours
  if (_hours.length === 1) {
    return `${_hours[0]}h`;
  }

  // else compute the total minutes and concat it to the Hours
  let minutes = _hours[1] !== undefined ? Number('.' + _hours[1]) : 0;

  minutes = minutes * 60;
  // const rawMinutes = minutes;
  const roundedMinutes = Math.round(minutes); // round the minutes to prevent the missing 1 minute difference

  return `${_hours[0]}h ${roundedMinutes}min`;
};
