/**
 *
 * @param string string to be truncated
 * @param maxLength maximum length of the string
 * @returns truncated string returns the string with ... if it exceeds the maxLength
 */
export const truncate = (string: string, maxLength: number) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + '...';
  }
  return string;
};
