import { create } from 'zustand';

export const FlightFilterTypeStore = create((set: any) => ({
  flightFilterType: 'departure' as 'departure' | 'return',
  setFlightFilterType: (type: 'departure' | 'return') => {
    set((state: { flightFilterType: 'departure' | 'return' }) => ({
      flightFilterType: type,
    }));
  },
}));
