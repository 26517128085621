import { SvgProps } from './SvgProps';

export const CheckFilledSvg = ({ _width = 15, _height = 15, _color = '#016E7F' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M10 0.498047C4.486 0.498047 0 4.98405 0 10.498C0 16.012 4.486 20.498 10 20.498C15.514 20.498 20 16.012 20 10.498C20 4.98405 15.514 0.498047 10 0.498047ZM8.001 14.911L4.288 11.206L5.7 9.79005L7.999 12.085L13.293 6.79105L14.707 8.20505L8.001 14.911Z"
        fill={_color}
      />
    </svg>
  );
};

export const CheckCircleOutlinedSvg = ({ _width = 15, _height = 15, _color = '#016E7F' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M4.6 6.5L6.12081 7.86873C6.37971 8.10174 6.78195 8.06606 6.9958 7.79112L9.4 4.7M7 12.5C10.3137 12.5 13 9.81371 13 6.5C13 3.18629 10.3137 0.5 7 0.5C3.68629 0.5 1 3.18629 1 6.5C1 9.81371 3.68629 12.5 7 12.5Z"
        stroke={_color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckSvg = ({ _width = 15, _height = 15, _color = '#2EAE4E' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 24 25" fill="none">
      <path
        width={_width}
        height={_height}
        d="M21.7988 8.11726L9.79883 20.1173C9.69431 20.2221 9.57012 20.3054 9.43337 20.3621C9.29663 20.4189 9.15002 20.4482 9.00195 20.4482C8.85389 20.4482 8.70728 20.4189 8.57053 20.3621C8.43378 20.3054 8.30959 20.2221 8.20508 20.1173L2.95508 14.8673C2.85043 14.7626 2.76742 14.6384 2.71078 14.5017C2.65415 14.3649 2.625 14.2184 2.625 14.0704C2.625 13.9224 2.65415 13.7759 2.71078 13.6391C2.76742 13.5024 2.85043 13.3782 2.95508 13.2735C3.05972 13.1689 3.18396 13.0859 3.32069 13.0292C3.45741 12.9726 3.60396 12.9434 3.75195 12.9434C3.89994 12.9434 4.04649 12.9726 4.18322 13.0292C4.31995 13.0859 4.44418 13.1689 4.54883 13.2735L9.00289 17.7276L20.207 6.52539C20.4183 6.31404 20.7049 6.19531 21.0038 6.19531C21.3027 6.19531 21.5894 6.31404 21.8007 6.52539C22.012 6.73673 22.1308 7.02338 22.1308 7.32226C22.1308 7.62115 22.012 7.90779 21.8007 8.11914L21.7988 8.11726Z"
        fill={_color}
      />
    </svg>
  );
};

export const CheckProgressBar = ({ _width = 15, _height = 15, _color = '#2EAE4E' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        width={_width}
        height={_height}
        d="M5.70708 7.87889C5.31655 8.26942 4.68339 8.26942 4.29286 7.87889L2.41397 6C2.0235 5.60953 1.39043 5.60953 0.999969 6C0.609503 6.39046 0.609503 7.02353 0.999969 7.414L4.29286 10.7069C4.68339 11.0974 5.31655 11.0974 5.70708 10.7069L14 2.414C14.3904 2.02353 14.3904 1.39046 14 0.999999C13.6095 0.609534 12.9764 0.609534 12.586 0.999999L5.70708 7.87889Z"
        fill="#016E7F"
      />
    </svg>
  );
};
