import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import bookingVerificationAnimation from '../../../resources/lottie/airplane_flight_verification.json';
// import { render } from '@testing-library/react';

const BookingVerification = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: bookingVerificationAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    renderer: 'svg',
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animatedLabels = [
    <p className="generalsans-medium">Please be patient, this may take a moment.</p>,
    <p className="generalsans-medium">Counting down the minutes to your adventure...</p>,
    <p className="generalsans-medium">Our booking fairies are hard at work waving their wands..</p>,
    <p className="generalsans-medium">Patience is a virtue, but finding your flight shouldn't be. Hang tight!</p>,
    <p className="generalsans-medium">We're sending out search parties for your flight. Stay tuned!</p>,
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % animatedLabels.length);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [animatedLabels]);

  return (
    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] flex justify-center items-center bg-[#00000099]">
      <div className="booking-verification-container flex flex-col gap-4 w-[700px] h-[400px]  ">
        <Lottie options={defaultOptions} height={200} width={200} />

        <div className=" flex flex-col justify-center items-center">
          <p className="satoshi-semibold text-xl mb-2">Verifying your chosen flight's availability.</p>
          <div className="booking-verification-label-animation flex flex-col justify-center items-center">{animatedLabels[currentIndex]}</div>
        </div>
      </div>
    </div>
  );
};

export default BookingVerification;
