import React, { useState } from 'react';

interface AllHotelPhotosModalSlideShowProps {
  images: string[];
}

const AllHotelPhotosModalSlideShow = ({ images }: AllHotelPhotosModalSlideShowProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <section className="absolute top-[5.69rem] left-[2.5rem] h-[41.88rem] overflow-hidden flex flex-col items-start justify-start gap-[0.63rem] text-color-shades-white-900">
      <div className="self-stretch relative h-[28.56rem] mb-[1.88rem]">
        <img
          className="absolute top-[0rem] left-[0rem] rounded-xl w-[54.88rem] h-[30.56rem] object-cover border-[2px] border-solid border-black-black-300"
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />

        {/* btngrp */}
        <div className="absolute top-[calc(50%_-_18.5px)] left-[calc(50%_-_440px)] w-[54.88rem] flex items-center justify-between py-0 px-8 box-border">
          <button
            onClick={prevSlide}
            className="flex flex-row items-start justify-start relative gap-[0.63rem] disabled:bg-black-black-300 rounded-[50%] bg-color-shades-white-900 box-border w-[2.5rem] h-[2.5rem] z-[0] border-[2px] border-solid border-border-colors-border-primary [transform:_rotate(180deg)] "
          >
            <div className="my-0 mx-[!important] absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-[1rem] h-[1rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border z-[1]">
              <img className="relative w-[0.43rem] h-[0.72rem]" alt="" src="/svg/slideshowArrowRight.svg" loading="lazy" />
            </div>
          </button>

          <button
            onClick={nextSlide}
            className="flex flex-row items-start justify-start relative gap-[0.63rem] disabled:bg-black-black-300 rounded-[50%] bg-color-shades-white-900 box-border w-[2.5rem] h-[2.5rem] z-[0] border-[2px] border-solid border-border-colors-border-primary"
          >
            <div className="my-0 mx-[!important] absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-[1rem] h-[1rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border z-[1]">
              <img className="relative w-[0.43rem] h-[0.72rem]" alt="" src="/svg/slideshowArrowRight.svg" loading="lazy" />
            </div>
          </button>
        </div>

        <div className="absolute top-[calc(50%_+_191px)] left-[calc(50%_-_439px)] rounded-t-none rounded-b-xl bg-[#333]/80 w-[54.88rem] flex flex-row items-center justify-between py-4 px-8 box-border">
          <div className="relative leading-[1.31rem] font-medium">Name of room area</div>
          <div className="relative leading-[1.31rem] font-medium">
            Image {currentIndex + 1} of {images.length}
          </div>
        </div>
      </div>

      <div className="w-[54.88rem] flex flex-row items-start justify-start gap-[0.63rem]">
        <img
          className="flex-1 relative rounded-xl max-w-full overflow-hidden h-[10rem] object-cover border-[2px] border-solid border-black-black-300"
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <img
          onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}
          className="cursor-pointer relative rounded-xl w-[17.63rem] h-[10rem] object-cover filter opacity-[50%] border-[2px] border-solid border-black-black-300"
          src={images[(currentIndex + 1) % images.length]}
          alt={`Slide ${currentIndex + 2}`}
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <img
          onClick={() => setCurrentIndex((currentIndex + 2) % images.length)}
          className="cursor-pointer relative rounded-xl w-[17.63rem] h-[10rem] object-cover filter opacity-[50%] border-[2px] border-solid border-black-black-300"
          src={images[(currentIndex + 2) % images.length]}
          alt={`Slide ${currentIndex + 3}`}
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
      </div>
    </section>
  );
};

export default AllHotelPhotosModalSlideShow;
