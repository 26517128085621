import React from 'react';
import { SadGallySvg } from '../../resources/svg/GallySvg';
import { GalagoButton } from '../../components/Button';
import { WaveDown, WaveUp } from '../../resources/svg/WaveSvg';

export const ExpiredEmailLinkWithExistingUser = () => {
  return (
    <div className="relative">
      <div className="absolute top-0 left-0 right-0 w-100">
        <WaveUp _width={'100%'} colors={{ primary: '#C8C0BF', secondary: '#E1D8D7', tertiary: '#FAF0EF', quaternary: '#FDFAFA' }} />
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-100">
        <WaveDown _width={'100%'} colors={{ primary: '#C8C0BF', secondary: '#E1D8D7', tertiary: '#FAF0EF', quaternary: '#FDFAFA' }} />
      </div>
      <div className="relative !z-10 d-flex flex-column justify-center align-items-center" style={{ height: '100vh' }}>
        <SadGallySvg />
        <div className="d-flex flex-column align-items-center">
          <p className="mb-2 mt-2 font-medium fs-2">Whoops! Looks like that link expired</p>
          <p className="mb-3" style={{ width: '85%', textAlign: 'center', fontSize: '20px', color: '#ADADAD' }}>
            Hey there! Looks like that link might have expired on us. Don't sweat it, happens to the best of us! Just pop back to the GalaGO! homepage and we'll get you started.
          </p>
          <GalagoButton label="Visit GalaGO! PH" onClick={() => (window.location.href = '/')} size="lg" className="!w-[300px] p-3" />
        </div>
      </div>
    </div>
  );
};
