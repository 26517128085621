import { SvgProps } from './SvgProps';

export const LongArrowSvg = ({ _width = 102, _height = 16, _color = '#ADADAD' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 102 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M101.207 8.70711C101.598 8.31658 101.598 7.68342 101.207 7.29289L94.8431 0.928932C94.4526 0.538408 93.8195 0.538408 93.4289 0.928932C93.0384 1.31946 93.0384 1.95262 93.4289 2.34315L99.0858 8L93.4289 13.6569C93.0384 14.0474 93.0384 14.6805 93.4289 15.0711C93.8195 15.4616 94.4526 15.4616 94.8431 15.0711L101.207 8.70711ZM0.5 9H100.5V7H0.5V9Z"
        fill="#ADADAD"
      />
    </svg>
  );
};
