import { useState } from 'react';
import { FloatingLabelFormControl } from '../../components/FormControl';
import { Container, Col, Row, Button, Modal } from 'react-bootstrap';
import { EmailSvg } from '../../resources/svg/EmailSvg';
// import { ChangePasswordHooks } from '../../hooks/ChangePasswordHooks';

type profileDataProps = {
  title: string;
  content: string;
  show: boolean;
  setShow: any;
  onDataUpdate: () => void;
};

// Profile Informations
function ProfileData({ title, content, show, setShow, onDataUpdate }: profileDataProps) {
  // const { refreshToken } = AuthenticationHooks();
  // const { validateOldPassword, validateNewPassword } = ChangePasswordHooks();
  const [showForm, setShowForm] = useState(false);
  const [cancelEdit, setCancelEdit] = useState('Edit');
  // eslint-disable-next-line
  // const [textContent, setTextContent] = useState(content);
  // eslint-disable-next-line
  // const [showFormEditName, setShowFormEditName] = useState(false);
  // eslint-disable-next-line
  // const [firstName, setFirstName] = useState('');
  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // console.log(textContent);

  // password validations
  // const [oldPasswordError, setOldPasswordError] = useState(false);
  // const [newPasswordError, setNewPasswordError] = useState(false);
  // const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  // const [oldPasswordSuccess, setOldPasswordSuccess] = useState(false);
  // const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
  // const [confirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);

  // Email Modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
  };

  // console.log(content, 'name');

  //Password Modal
  // const [showConfirmation, setShowConfirmation] = useState(false);
  // const handleConfirmationClose = () => {};
  // const handleConfirmationShow = () => {
  //   if (!oldPasswordSuccess) {
  //     return;
  //   }
  //   if (password !== confirmPassword) {
  //     return;
  //   }
  //   validateNewPassword(password, confirmPassword).then((response: any) => {
  //     // console.log("updated password", response)
  //     if (response.status === 200) {
  //       setShowConfirmation(true);
  //       onDataUpdate();
  //       setShowForm(false);
  //       setCancelEdit('Edit');
  //       // setShowFormEditName(false);
  //       // setTextContent(content);
  //       setTimeout(() => {
  //         setShowConfirmation(false);
  //       }, 3000);
  //     } else {
  //       alert(response.response.data.message);
  //     }
  //   });
  // };

  // const enableChangePasswordButton = (): boolean => {
  //   if (confirmPasswordSuccess && newPasswordSuccess && oldPasswordSuccess) {
  //     return false;
  //   }
  //   return true;
  // };

  // const handleValidateOldPassword = async (password: string) => {
  //   const response = await validateOldPassword(password);

  //   if (response.status !== 200) {
  //     setOldPasswordError(true);
  //     return;
  //   }
  //   setOldPasswordError(false);
  //   if (response.status === 200 && response.data.message === 'Password is verified') {
  //     setOldPasswordSuccess(true);
  //     return;
  //   }
  //   setOldPasswordSuccess(false);
  // };

  // Changable labels
  // const subLabels: any = {
  //   Name: 'GalaGO! utilizes this information to autofill fields and speed up your booking process.',
  //   EmailAddress: 'Enter an email address to which you always have access.',
  //   ContactNumber: 'Enter your active phone number so that GalaGO! can easily get in touch with you.',
  //   Nationality: '',
  //   TravelDocument: 'Ensure that your ID remains valid for at least six (6) months from the current date.',
  //   Password: 'Password must be at least 8 alpha numeric characters with at least 1 upper case and 1 special character.',
  // };

  return (
    <>
      <Container fluid className="row-info">
        <Row>
          <div className="profile-data">
            <div className="d-flex align-items-center gap-4">
              <p className="text title">{title}</p>
              <p className="text content">{content}</p>
            </div>
            <p
              className="cursor-pointer text-primary text-sm edit"
              onClick={() => {
                setShowForm((prev) => !prev);
                setCancelEdit((prev: string) => (prev === 'Cancel' ? 'Edit' : 'Cancel'));
                // setTextContent((prev) => (prev === content ? subLabels[title.replace(' ', '')] : content));
              }}
            >
              {cancelEdit}
            </p>
          </div>
        </Row>

        {/* Name */}
        {showForm && title === 'Legal Name' && (
          <>
            <Row className="d-flex">
              <Col lg={4} className="ps-0 pe-2.5 pb-3">
                <FloatingLabelFormControl type={'text'} label="First Name" placeholder={''} />
              </Col>
              <Col lg={4} className="ps-0 pe-2.5 pb-3">
                <FloatingLabelFormControl type={'text'} label="Middle Initial( optional )" placeholder="" />
              </Col>
              <Col lg={4} className="ps-0 pt-0 pe-0 pb-3">
                <FloatingLabelFormControl type={'text'} label="Last Name" placeholder="" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end p-0">
                <Button
                  className="Button"
                  onClick={() => {
                    setShowForm(false);
                    setCancelEdit('Edit');
                    // setShowFormEditName(false);
                    // setTextContent(content);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {showForm && title === 'Birthday' && (
          <>
            <Row className="d-flex">
              <Col lg={12} className="pb-3 px-0">
                <FloatingLabelFormControl type={'date'} label="Birthday" placeholder="Birthday" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button className="Button" onClick={handleShow}>
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {showForm && title === 'Email Address' && (
          <>
            <Row className="d-flex">
              <Col lg={12} className="pb-3 px-0">
                <FloatingLabelFormControl type={'email'} label="Email Address" placeholder="" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button className="Button" onClick={handleShow}>
                  Send Link
                </Button>
              </Col>
            </Row>
          </>
        )}

        {true && title === 'Email Address' && (
          <Modal show={showModal} onHide={handleClose} animation={false} size="lg" centered>
            <Modal.Body>
              <Container className="text-center">
                <Row className="p-5">
                  <EmailSvg _width={150} _height={150} />
                </Row>
                <Row>
                  <h2>Confirm your email</h2>
                  <p className="p-0 m-0">We’ve sent a confirmation email to {content}</p>
                  <p>Confirm your email address to continue your sign up</p>
                </Row>

                <Button
                  variant="primary"
                  onClick={() => {
                    setShowForm(false);
                    setCancelEdit('Edit');
                    // setShowFormEditName(false);
                    // setTextContent(content);
                    handleClose();
                  }}
                  className="my-3 px-5"
                  id="email"
                >
                  Resend Email
                </Button>
              </Container>
            </Modal.Body>
          </Modal>
        )}

        {showForm && title === 'Contact Number' && (
          <>
            <Row className="d-flex">
              <Col lg={12} className="mb-3 px-0">
                <FloatingLabelFormControl type={'number'} label="Mobile Number " placeholder="" />
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button
                  className="Button"
                  onClick={() => {
                    setShowForm(false);
                    setCancelEdit('Edit');
                    // setShowFormEditName(false);
                    // setTextContent(content);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {showForm && title === 'Nationality' && (
          <>
            <Row className="d-flex">
              <Col lg={12} className="mb-3 px-0">
                <FloatingLabelFormControl type={'text'} label="Nationality" placeholder="" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button
                  className="Button"
                  onClick={() => {
                    setShowForm(false);
                    setCancelEdit('Edit');
                    // setShowFormEditName(false);
                    // setTextContent(content);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {/* Travel Documents */}
        {showForm && title === 'Travel Document' && (
          <>
            <Row className="d-flex">
              <Col lg={6} className="mb-3 px-0 pe-4">
                <FloatingLabelFormControl type={'text'} label="Travel Document" placeholder="" />
              </Col>
              <Col lg={6} className="mb-3 px-0">
                <FloatingLabelFormControl type={'date'} label="Travel Document Expiration Date" placeholder="" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button
                  className="Button"
                  onClick={() => {
                    setShowForm(false);
                    setCancelEdit('Edit');
                    // setShowFormEditName(false);
                    // setTextContent(content);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}

        {/* Password */}
        {/* {showForm && title === 'Password' && (
          <>
            <Row>
              <Col className="d-flex p-0 text-secondary text-sm pb-2">
                <span>Password must be at least 8 alpha numeric characters with at least 1 uppercase and 1 special character.</span>
              </Col>
            </Row>
            <Row className="">
              <Col lg={12} className="mb-3 px-0">
                <FloatingLabelFormControl
                  onChange={(e) => {
                    // handleValidateOldPassword(e.target.value);
                    debounce(() => handleValidateOldPassword(e.target.value), 2000);

                    // refreshToken().then((response) => {
                    //   validateOldPassword(e.target.value).then((response: any) => {
                    //     if (response.status === 200 && response.data.message === 'Password is verified') {
                    //       setOldPasswordSuccess(true);
                    //     } else {
                    //       setOldPasswordSuccess(false);
                    //     }

                    //     if (response?.response?.data?.statusCode === 400) {
                    //       setOldPasswordError(true);
                    //     } else {
                    //       setOldPasswordError(false);
                    //     }
                    //   });
                    // });
                    enableChangePasswordButton();
                  }}
                  success={oldPasswordSuccess}
                  error={oldPasswordError}
                  type={'password'}
                  label="Old Password *"
                  placeholder=""
                />
              </Col>
              {oldPasswordError ? (
                <div className="d-flex align-item-center gap-1 ">
                  <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                  <span className="mb-3 red">Incorrect Password!</span>
                </div>
              ) : null}
              {oldPasswordSuccess ? (
                <div className="d-flex align-item-center gap-1">
                  <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                  <span className="mb-3 lime">Correct Password!</span>
                </div>
              ) : null}
              <Col lg={12} className="mb-3 px-0">
                <FloatingLabelFormControl
                  onChange={(e) => {
                    setPassword(e.target.value);
                    const password = checkPasswordStrength(e.target.value);
                    setNewPasswordError(!password);
                    setNewPasswordSuccess(password);
                    enableChangePasswordButton();
                  }}
                  success={newPasswordSuccess}
                  error={newPasswordError}
                  type={'password'}
                  label="New Password *"
                  placeholder=""
                />
              </Col>

              {newPasswordError ? (
                <div className="d-flex align-item-center gap-1">
                  <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                  <span className="mb-3 red">Password strength: Weak</span>
                </div>
              ) : null}

              {newPasswordSuccess ? (
                <div className="d-flex align-item-center gap-1">
                  <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                  <span className="mb-3 lime">Password strength: Good</span>
                </div>
              ) : null}

              <Col lg={12} className="mb-3 px-0">
                <FloatingLabelFormControl
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordSuccess(e.target.value === password ? true : false);
                    setConfirmPasswordError(e.target.value !== password ? true : false);
                    enableChangePasswordButton();
                  }}
                  success={confirmPasswordSuccess}
                  error={confirmPasswordError}
                  type={'password'}
                  label="Confirm Password *"
                  placeholder=""
                />
              </Col>

              {confirmPasswordSuccess ? (
                <div className="d-flex align-item-center gap-1">
                  <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                  <span className="mb-3 lime">Password Match!</span>
                </div>
              ) : null}

              {confirmPasswordError ? (
                <div className="d-flex align-item-center gap-1">
                  <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                  <span className="mb-3 red">Password doesn’t matched!</span>
                </div>
              ) : null}
            </Row>
            <Row>
              <Col className="d-flex justify-content-end px-0">
                <Button disabled={enableChangePasswordButton()} className="Button" onClick={handleConfirmationShow}>
                  Save
                </Button>
              </Col>
            </Row>
          </>
        )}
        {/* Password Modal */}
        {/* <Modal show={showConfirmation} onHide={handleConfirmationClose} size="lg">
          <Modal.Body>
            <Container className="text-center py-5">
              <Row className="py-5 m-0">
                <CheckCircleOutlinedSvg _width={100} _height={100} _color="limegreen" />
                <p className="p-0 m-0 fw-bold fs-5 py-3">Password Updated Successfully</p>
              </Row>
            </Container>
          </Modal.Body>
        </Modal> */}
      </Container>

      {/* Alerts for password  */}
    </>
  );
}

export default ProfileData;
