import { bookingHotelGalagoApiRoute } from '../utils/apiRoutes';
import axios, { AxiosResponse } from 'axios';

export const HotelBookingHooks = () => {
  const hotelBookingApi = bookingHotelGalagoApiRoute();
  // const [bookHotelResponse, setBookHotelResponse] = React.useState<{ [index: string]: any }>();

  const bookHotel = async (payload: { [index: string]: any }) => {
    try {
      const response: AxiosResponse = await axios.post(hotelBookingApi, payload);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  return { bookHotel };
};
