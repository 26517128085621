import React from 'react';

export const RoomInclusion = ({ inclusion }: { inclusion: string }) => {
  const inclusions = inclusion.split(',');
  return (
    <React.Fragment>
      {inclusions.map((inclusion, index) => (
        <span className="text-xs mr-2 mt-2 px-2 py-1 bg-[#C6EDEA] rounded-[10px] inline-block" key={index}>
          {inclusion}
        </span>
      ))}
    </React.Fragment>
  );
};
