// import parse from 'html-react-parser';
import React, { useState } from 'react';
import '../../../resources/css/hotel-facilities.scss';
import { useDisclosure } from '@nextui-org/react';
// import { hotelStore } from '../../../store/HotelStore';
import ModalTemplate from '../../../components/ui/ModalTemplate';
import AboutAccommodationModal from './AboutAccommodationModal';
import HotelFacilitiesRcol from './HotelFacilitiesRcol';
// import moment from 'moment';
import { GalagoButton } from '../../../components/Button';

type HotelFacilitiesProps = {
  id?: string;
  facilitiesData: {
    description: any;
    propertyName: string;
    hotelFacilities: string[];
    bookingDetails: {
      keyword: string;
      checkInDate: string;
      checkOutDate: string;
      rooms: number;
      adults: number;
      longitude: number;
      latitude: number;
    };
    address: string;
    attractions: string[];
  };
};

const HotelFacilities = ({ id, facilitiesData }: HotelFacilitiesProps) => {
  const { isOpen, onOpenChange, onClose } = useDisclosure();
  // const openAboutAccommodationModal = () => onOpen();
  // const openCancellationPolicyModal = () => onOpen();

  // const { openAboutAccommodation, closeAboutAccommodation } = hotelStore();

  const closeAboutAccommodationModal = () => {
    onClose();
    // closeAboutAccommodation();
  };

  const { description, propertyName, hotelFacilities, bookingDetails } = facilitiesData;
  const aboutAccommodationModalData = {
    description,
    propertyName,
  };
  // const splitDescription = description ? description.toString().split(/<\/\D>/g) : '';
  // const minDescription = `
  //   ${splitDescription[0] !== undefined ? splitDescription[0] : ''}
  //   ${splitDescription[1] !== undefined ? splitDescription[1] : ''}
  //   ${splitDescription[2] !== undefined ? splitDescription[2] : ''}
  // `;

  const bookingDates = `${bookingDetails.checkInDate} - ${bookingDetails.checkOutDate}`;
  // const totalNights = moment(bookingDetails.checkOutDate).diff(moment(bookingDetails.checkInDate), 'days');
  const roomsGuests = ` ${bookingDetails.adults}`;
  const roomNum = `${bookingDetails.rooms}`;
  const keyword = `${bookingDetails.keyword}`;
  // const location = `${facilitiesData.address}`
  // const roomsText = description.toString().match(/Rooms:(.*)/s)?.[1].trim();
  const descriptionString = JSON.stringify(description);
  let finalDescription = descriptionString.match(/"Rooms":"(.*?)"/)?.[1];
  // console.log(facilitiesData);

  const [showMore, setShowMore] = useState(false);
  // console.log(bookingDetails);

  return (
    <>
      <section id={id} className="container  mt-14">
        <div className="row justify-content-center">
          {/* left col */}
          <div>
            <div className="relative mb-2.5 font-semibold text-xl">About your stay</div>
            <div className="flex flex-row gap-2 text-base font-medium mb-6">
              <span>{bookingDates}</span>
              <span>|</span>
              <span>
                {roomNum} {parseInt(roomNum) === 1 ? 'room,' : 'rooms,'}
              </span>
              {roomsGuests} {parseInt(roomsGuests) === 1 ? 'adult' : 'adults'}
              <span>|</span>
              <span>{keyword}</span>
            </div>

            <div className="flex flex-column gap-4 border-bottom pb-4">
              {/* <div className="">{parse(minDescription.replaceAll('<p>', '<p className="[margin-block-start:0] [margin-bottom:8px] font-footnote fs-6 font-regular">'))}</div> */}
              <p className="[margin-block-start:0] [margin-bottom:8px] font-footnote fs-6 font-regular">{finalDescription}</p>
              {showMore && <p className="[margin-block-start:0] font-footnote fs-6 font-regular">{description.Dining}</p>}
              {description.Dining && <GalagoButton label={showMore ? 'See Less' : 'See More'} btn="link" className="w-max !p-0 " onClick={() => setShowMore(!showMore)} />}

              {/* <a
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  openAboutAccommodationModal();
                  openAboutAccommodation();
                }}
                href="/#"
                className="font-medium text-base"
              >
                See More
              </a> */}
            </div>

            {/* rcol */}
            <HotelFacilitiesRcol hotelFacilities={hotelFacilities} />
          </div>
        </div>

        <div className="row justify-content-center"></div>
      </section>

      <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[741px] w-[900px]">
        <AboutAccommodationModal onClose={closeAboutAccommodationModal} aboutAccommodationModalData={aboutAccommodationModalData} />
      </ModalTemplate>
    </>
  );
};

export default HotelFacilities;
