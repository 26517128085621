import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';


function BoholTour(){
 const [fbHover, setFbHover] = React.useState('#ADADAD');
 const [liHover, setLiHover] = React.useState('#ADADAD');
 const [twtHover, setTwtHover] = React.useState('#ADADAD');
 const [linkHover, setLinkHover] = React.useState('#ADADAD');

 return (
   <>
     <NavBarTransparent />

     <div className="hero_sec">
       <img
         src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/00+cover.jpeg"
         alt="Your Ultimate Guide to El Nido, Palawan"
         loading="lazy"
         onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
       />
       <div className="gradient_overlay">
         <h1>Top Destination</h1>

         <div className="bread_crumbs">
           <a href="/#/blogs">Blogs</a>
           <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
             Discover the Best Things to Do in Bohol All in One Tour!
           </a>
         </div>
       </div>
     </div>

     <div className="articleBody">
       <div className="articleHeader">
         <h1>Discover the Best Things to Do in Bohol All in One Tour!</h1>

         <div className="info">
           <p className="authorSec">
             By <u>Zoe Alcazaren</u>
           </p>
           <p className="separator">•</p>
           <p className="articleDate">Nov 7, 2023</p>
           <p className="separator">•</p>
           <p className="article-read">6 min read</p>
         </div>

         <div className="shareBtnContainer">
           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
             <FacebookSvg _color={fbHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
             <LinkedInSvg _color={liHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
             <TwitterSvg _color={twtHover} _width={24} _height={24} />
           </a>

           <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
             <LinkSvg _color={linkHover} _width={24} _height={24} />
           </a>
         </div>
       </div>

       <div className="content">
         <div className="articleDescription">
           <p>
             Known for its natural and cultural wonders, Bohol is a must-visit destination in the Philippines! Whether you’re a local or tourist, Bohol is definitely a place to make breathtaking
             memories. We won’t be surprised if it’ll leave you wanting to return for seconds.
           </p>
           <p>
             It may seem impossible to see all of Bohol on a limited budget and itinerary – what with travelers recommending countless sights across its multiple islands. Fortunately, a Bohol
             Countryside Tour is the ideal adventure for first-timers who want to make the most of their holiday.
           </p>
           <p>Ready to go? Here’s what you can expect from a typical Bohol Countryside Tour.</p>
         </div>

         <div className="img_container">
           <img
             src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/01+roland+hechanova+-+unsplash.png"
             alt="Bohol Countryside Tour"
             loading="lazy"
             onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
           />
           <p>Photo by Roland Hechanova via Unsplash</p>
         </div>

         <div className="extensive_story">
           <h2>When is the Best Time to Go on a Bohol Countryside Tour?</h2>
           <p>
             No two Bohol Countryside Tours are the same, and going during different times of the year makes for vastly different experiences. So we recommend visiting Bohol during the dry months –
             usually December to May – as most tours are outdoors.
           </p>

           <div className="galaGuide">
             <h3>GalaGuide!</h3>
             <p>The dry season is also the best time to visit the Chocolate Hills, so you can see them in their signature color.</p>
           </div>
         </div>

         <div className="storieswithTitleImageParagraphs">
           <div className="head">
             <h2>Things to do in Bohol: What to See and Expect on the Bohol Choco Countryside Tour</h2>

             <div className="head_desc">
               <p>Most Bohol Countryside Tours occur inland and take between six to eight hours. Here are a few stops on the Bohol Countryside Tour, which you can experience with GalaGO!</p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Albur Church and Museum</h2>
               <p>Tagbilaran East Road, East Poblacion, Albuquerque</p>
             </div>

             <div className="info-grp">
               <p>
                 Also known as the Santa Monica Parish Church, the Albur Church and Museum is a historical landmark in Bohol’s Municipality of Albuquerque. The church falls under the Diocese of
                 Tagbilaran and became an Important Cultural Property in 2013, as named by the National Museum of the Philippines.
               </p>
               <p>
                 The Albur Church and Museum was established in 1869 after separating from the Baclayon Parish. This grand and impeccable site is responsible for much spiritual growth in the region
                 when people from neighboring barangays settled in Segundo to be closer to the chapel.
               </p>
               <p>
                 From the late 1860s to 1880s, the church underwent significant extensions, donning new coral stone to replace its once tabique walls. The people built a grotto between the church and
                 convent, adding three bells inscribed with Sta. Monica, San Augustine, and Calipay (meaning joy).
               </p>
               <p>
                 Like many religious sites in Bohol, the Albur Church has endured dozens of historical events. In 2013 the church fell victim to the 7.2 magnitude earthquake and, fortunately, was
                 restored to its original splendor and expressive halls.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Tarsier Conservation Area</h2>
               <p>Tarsier Conservation Road, Corella</p>
             </div>

             <div className="head">
               <div className="img_container">
                 <img
                   className="image"
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/02+Tarsier.png"
                   alt="Tarsier"
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Hitoshi Namura via Unsplash</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 Also known as the Santa Monica Parish Church, the Albur Church and Museum is a historical landmark in Bohol’s Municipality of Albuquerque. The church falls under the Diocese of
                 Tagbilaran and became an Important Cultural Property in 2013, as named by the National Museum of the Philippines.
               </p>
               <p>
                 The Albur Church and Museum was established in 1869 after separating from the Baclayon Parish. This grand and impeccable site is responsible for much spiritual growth in the region
                 when people from neighboring barangays settled in Segundo to be closer to the chapel.
               </p>
               <p>
                 From the late 1860s to 1880s, the church underwent significant extensions, donning new coral stone to replace its once tabique walls. The people built a grotto between the church and
                 convent, adding three bells inscribed with Sta. Monica, San Augustine, and Calipay (meaning joy).
               </p>
               <p>
                 Like many religious sites in Bohol, the Albur Church has endured dozens of historical events. In 2013 the church fell victim to the 7.2 magnitude earthquake and, fortunately, was
                 restored to its original splendor and expressive halls.
               </p>
             </div>

             <div className="galaGuide">
               <h3>GalaGuide!</h3>
               <p>When visiting the tarsiers, please stay quiet and respect their space, as these magnificently little creatures are prone to stress.</p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Blood Compact Site</h2>
               <p>EK Inting St, Tagbiliran</p>
             </div>

             <div className="info-grp">
               <p>
                 Bohol’s Blood Compact Monument honors the first international treaty between the Philippines and Spain. National Artist Napoleon Abueva erected the monument featuring Miguel Lopez de
                 Legaspi and Rajah Sikatuna performing the historic blood compact on March 16, 1565.
               </p>
               <p>The sculpture rests on a raised dais, providing breathtaking views of the Bohol Sea. But did you know that it doesn’t mark the actual location where the treaty occurred?</p>
               <p>
                 The blood compact was historically referred to as sandugo, a Visayan word directly translating to “one blood.” The sandugo is also present in the region’s provincial flag and Bohol’s
                 seal of government.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Man-Made Forest</h2>
               <p>Loay Int Road, Bilar</p>
             </div>

             <div className="head">
               <div className="img_container">
                 <img
                   className="image"
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/03+roland+hechanova+on+unsplash.png"
                   alt="Loay Int Road, Bilar"
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Roland Hechanova via Unsplash</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 The Bilar Man-Made Forest is a 2-kilometer stretch of towering mahogany trees embracing where the road from Loboc meets Bilar. Each tree was part of a 50-year-old reforestation
                 project that is now one of Bohol’s most sought-after, picture-perfect locations.
               </p>
               <p>
                 The views are awe-inspiring, and you may recognize this venue in several local films. You’ll notice a rapid temperature change when you enter the forest, as the trees completely block
                 the sunlight. Every branch grows into another, and young trees grow straight toward the sky!
               </p>
             </div>

             <div className="galaGuide">
               <h3>GalaGuide!</h3>
               <p>If you’re busy taking photographs, have a buddy keep an eye out, as it’s easy to forget you’re traipsing an open highway.</p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Chocolate Hills</h2>
               <p>Spanning Carmen, Batuan, and Sagbayan</p>
             </div>

             <div className="head">
               <div className="img_container">
                 <img
                   className="image"
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/07+Shing.png"
                   alt="Chocolate Hills"
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Shing via Unsplash</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 When it comes to enumerating the top tourist attractions in Bohol, the Chocolate Hills nab the number one spot. You’ll see a thousand symmetrical and conical brown hills over 50
                 square kilometers here.
               </p>
               <p>As their name suggests, these hills turn a delicious—sadly inedible—chocolate brown during the dry season.</p>
               <p>
                 Not only are the Chocolate Hills a visual feast, but they’re also the subject of a long-standing Philippine legend. According to myth, two giants once fought, hurling stones and sand
                 at each other to form the Chocolate Hills.
               </p>
               <p>
                 Of course, there’s no scientific basis to this, as science credits their formation to uplifting coral deposits. Whatever you believe, the Chocolate Hills are still a sight to behold!
               </p>
             </div>

             <div className="galaGuide">
               <h3>GalaGuide!</h3>
               <p>You’ll have to trek up a few hundred steps to reach the viewing deck, so you’ll want to be physically prepared. Trust us, the view will be worth a little exercise.</p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Baclayon Church</h2>
               <p>A. Realista, Baclayon</p>
             </div>

             <div className="info-grp">
               <p>
                 Bohol is home to dozens of the Philippines’ oldest churches—the Baclayon Church is among the older ones, dating way back to 1727! The Baclayon Church stands out because it’s
                 structurally original, maintaining most of its authentic materials despite the earthquake in 2013. The simple cross-shaped building is made from coral stone and egg whites mixed into
                 plaster.
               </p>
               <p>Jesuits founded the actual parish in 1596 following Spanish conquistador settlements. Today, local parishioners have taken over.</p>
               <p>
                 Because of its age and rich history, the Baclayon Church became a UNESCO World Heritage Site. Visiting the church is a captivating experience as visitors behold its blended
                 architectural styles and grand visual interest.
               </p>
               <p>The church boasts two facades—the interior draws inspiration from classical architectural styles, while the exterior displays touches from Augustinian Recollects.</p>
               <p>Like the Albuquerque Church, the Baclayon Church is also a Natural Cultural Treasure.</p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Loboc River Cruise</h2>
               <p>Spanning Carmen across the Loay Bridge</p>
             </div>

             <div className="head">
               <div className="img_container">
                 <img
                   className="image"
                   src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/05+aldrino.png"
                   alt="Loboc River"
                   loading="lazy"
                   onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                 />
                 <p>Photo by Aldrino via Unsplash</p>
               </div>
             </div>

             <div className="info-grp">
               <p>
                 Among the top activities to experience in Bohol is the Loboc River Cruise, which begins at the Loay Bridge neighboring the Church of San Pedro. Thanks to conservation efforts, the
                 river’s surface is a milky jade and spectacularly clean.
               </p>
               <p>The tour lasts between 45 minutes and an hour, and our GalaGO! tour inclusions cover your buffet lunch.</p>
               <p>
                 This famed floating restaurant also houses a group of onboard singers who serenade guests as they take in the river sights. Mid-trip, the cruise stops near Busay Falls. Surrounding
                 the area are hundreds of overhanging palm trees, and your raft may pass a fisherman or two.
               </p>
             </div>
           </div>

           <div className="story">
             <div className="title">
               <h2>Aproniana Gift Shop</h2>
               <p>Bohol Circumferential Road, Baclayon</p>
             </div>

             <div className="info-grp">
               <p>
                 If you’re looking for Bohol’s ultimate souvenir destination, look no further than the Aproniana Gift Shop. You’ll find everything from stuffed tarsiers, shirts, hats, magnets,
                 keychains, necklaces, wooden products, and lanterns to Boholano delicacies.
               </p>
             </div>

             <div className="galaGuide">
               <h3>GalaGuide!</h3>
               <p>If you’re lucky, you might even get a 10% discount, which they give out randomly.</p>
             </div>
           </div>
         </div>

         <div className="extensive_story">
           <h2>Tips for Making Your Countryside Tour More Enjoyable</h2>
           <p>
             Depending on your group and the weather conditions, the Bohol Countryside Tour takes six to eight hours. Best be prepared for hot weather and some walking. Here are a few tips for making
             your tour experience comfortable and enjoyable.
           </p>

           <div className="bullet_grp">
             <div className="bullet_list">
               <p className="title">Pack appropriately!</p>
               <ul>
                 <li>Extra shirt or towel</li>
                 <li>Water bottle</li>
                 <li>Sun protection</li>
                 <li>Cap</li>
                 <li>First aid kit</li>
               </ul>
             </div>

             <div className="bullet_list">
               <p>If you’re new to packing a first aid kit, here are a few essentials:</p>
               <ul>
                 <li>Adhesive bandages</li>
                 <li>Antiseptic wipes</li>
                 <li>Aspirin and painkillers</li>
                 <li>Insect repellent</li>
                 <li>Antihistamines</li>
                 <li>Sunscreen</li>
               </ul>
               <p>
                 While preparation is essential, you’ll want to avoid packing too much. In addition, wear light and breathable clothing meant for hot weather. In case of rain, pack an umbrella,
                 windbreaker, or light raincoat.
               </p>
             </div>
           </div>

           <div className="tip">
             <h3>Get enough rest</h3>
             <p>
               Most tours begin in the morning, so you’ll want ample rest beforehand. Sleep early and have a hearty breakfast -it’s free if you book a Bohol package with us!—before taking in the
               sights and sounds.
             </p>
           </div>

           <div className="tip">
             <h3>Be safe!</h3>
             <p>It should go without saying, and while Bohol’s tourist attractions are generally safe, you can never be too careful. Be aware of your surroundings and buddy up.</p>
             <p>
               Be cautious when interacting with others who aren’t part of your tour. Most importantly, know where the nearest emergency services are. Your GalaGO! guides are trained to look after
               you, but being prepared has its perks.
             </p>
           </div>

           <div className="tip">
             <h3>Plan for the right time</h3>
             <p>
               While our GalaGO! tours are available year-round, the Philippine rainy season is hardly forgiving. The best time to travel to Bohol is during the dry season or the summer when there’ll
               be little to no chance of cancellations.
             </p>
           </div>
         </div>

         <div className="footnote">
           <div className="title_container">
             <h2>Enjoy the best tourist spots in Bohol with GalaGO!</h2>
             <div className="img_container">
               <img
                 src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Bohol+Countryside+Tour+-+Best+things+to+do+in+Bohol/06+Austin+Nicomedez.png"
                 alt="Bohol"
                 loading="lazy"
                 onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
               />
               <p>Photo by Austin Nicomedez via Unsplash</p>
             </div>
           </div>

           <div className="info">
             <p>Every turn and corner in Bohol is a spectacle you won’t want to miss. If you want to maximize the best things to do in Bohol, GalaGO! has you covered.</p>
           </div>
         </div>
       </div>

       <hr />

       <div className="featured_blogs">
         <h2>Featured Blogs</h2>
         <div className="card_container">
           <div className="blog_card col-md-6 col-12">
             <div className="img_container">
               <img
                 src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                 alt="Discover the Best Things to Do in Bohol All in One Tour!"
                 loading="lazy"
                 onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
               />

               <div className="badge">
                 <h2>Travel Tip</h2>
               </div>
             </div>

             <div className="info_grp">
               <div className="detail">
                 <p className="date"> September 25, 2023</p>
                 <p className="separator">·</p>
                 <p className="duration">6 min read</p>
               </div>

               <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

               <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                 <div className="read_btn">
                   <p>Read Article</p>
                   <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                 </div>
               </a>
             </div>
           </div>

           <div className="blog_card col-md-6 col-12">
             <div className="img_container">
               <img
                 src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                 alt="Guide to Travel Requirements in the Philippines"
                 loading="lazy"
                 onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
               />

               <div className="badge">
                 <h2>Travel Tip</h2>
               </div>
             </div>

             <div className="info_grp">
               <div className="detail">
                 <p className="date"> September 25, 2023</p>
                 <p className="separator">·</p>
                 <p className="duration">6 min read</p>
               </div>

               <h3> Guide to Travel Requirements in the Philippines</h3>

               <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                 <div className="read_btn">
                   <p>Read Article</p>
                   <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                 </div>
               </a>
             </div>
           </div>
         </div>
       </div>
     </div>
   </>
 );
    
}
export default BoholTour