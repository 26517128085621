import React from 'react';
import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

const ThingsToDoInElNido = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/00+Cover+-+Ehmir+Bautista+via+Unsplash.png"
          alt="What to Do in Cebu: Top 10 Best Cebu Tours"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Things to Do in El Nido for an Adventure-Filled Trip
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Things to Do in El Nido for an Adventure-Filled Trip</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Oct 18, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">7 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>Visiting El Nido, Palawan soon? Get ready for not one, not two, but a series of breathtaking adventures.</p>
            <p>From pristine beaches to hidden lagoons and stunning caves, this tropical paradise is home to countless natural sites that will leave you in awe.</p>
            <p>
              Whether you're an easygoing beach lover, an ever-curious nature enthusiast, or an adventurous soul in general, El Nido offers a plethora of activities that will give you the chill or the
              thrill you’re looking for
            </p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/01+Robert+Mikoleit+on+Unsplash.png"
              alt="El Nido Tours"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Robert Mikoleit via Unsplash</p>
          </div>

          <div className="textual_story">
            <h2>Things to do in El Nido that you cannot miss</h2>
            <p>
              With its diverse collection of natural wonders and discoveries, you will never run out of things to do in El Nido. This is a destination you visit to live out your best island girl (or
              guy) dream life — sunburn and coral reef scratches included.
            </p>
            <p>There are diving spots where you can swim into the colorful depths of the sea, hiking trails where you can climb to scenic peaks, and more wondrous experiences.</p>
            <p>You can go through enchanting underwater tunnels and explore hidden lagoons.</p>
            <p>You can bask in the tranquility of secluded islands and relax on powdery white-sand shores.</p>
            <p>You can behold uniquely shaped rock formations while snorkeling amidst vibrant marine life.</p>
            <p>One of the best ways to experience as many of these wonders as possible is by finding the right El Nido tour package for you.</p>
            <p>
              A good tour package will guide you on how to go to El Nido and how to get around the municipality like a local. Most importantly, it should give you an all-inclusive, far-reaching, and
              thrilling island-hopping tour.
            </p>
            <p>
              There are a lot of freelance tour guides and small-scale travel agencies present in El Nido. Each of them will offer their own combination, variation, or adjustments of the four main
              tour packages allowed by the municipality.
            </p>
            <p>
              The four main tour packages of El Nido are aptly labeled Tour A, B, C, and D. They cover all of the islands, lagoons, beaches, and other attractions that travelers are allowed to visit
              while in the area.
            </p>
            <p>As you mull over which is the right itinerary for you, here’s a quick list of the top attractions you should keep in mind.</p>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Snake Island</h2>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/02+Jules+Bassoleil+unsplash.png"
                  alt="Snake Island"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Jules Bassoleil via Unsplash</p>
              </div>
            </div>

            <div className="info_grp">
              <p>
                Snake Island, also officially known as Vigan Island, is a unique El Nido destination that gets its name from its s-shaped landform that looks like it snakes its way across the
                crystal-clear aquamarine waters.
              </p>

              <p>This standout tourist attraction is one of the few tourist spots in the country that transforms with the changing of the tides.</p>

              <p>
                During low tide, it’s a small island connected to El Nido’s mainland by a stretch of white sand shoal that you can cross by foot. During high tide, it’s a lone sandbar submerged two
                feet underwater.
              </p>

              <p>As you walk along and around the island, you can take in the panoramic views of the surrounding islands and endless lush greenery.</p>

              <p>It will give you that deserted paradise island vibe that’s great for couples looking for a romantic getaway or introverted travelers looking for a quiet breather.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Popolkan Island</h2>
            </div>

            <div className="info_grp">
              <p>Next up is Popolkan Island, a popular and fantastic snorkeling spot with a few stunning on-land sceneries to offer.</p>

              <p>The island itself features a beautiful stretch of white sand shores, lush greenery, and towering limestone karst formations on one side.</p>

              <p>
                In the surrounding azure waters, you can swim and snorkel to your heart’s content. Brave a dive and witness the local vibrant marine life. Be mesmerized by a collection of colorful
                fishes, oddly shaped corals, and other amusing sea creatures.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Pinagbuyutan Island</h2>
            </div>

            <div className="info_grp">
              <p>
                Pinagbuyutan Island beckons with towering cliffs and the most darling beach. Situated not too far from El Nido’s mainland, this uninhabited island offers a quiet and relaxing haven for
                beach lovers and nature enthusiasts alike.
              </p>

              <p>
                The only crowd you’ll find here is the gathering of coconut trees all across the island. Along its edges is a pristine shoreline with powdery white sand, and there’s the tiniest of
                coves where you can take a dip in inviting waters.
              </p>

              <p>
                With nothing more on the island but a small shack that sells local coconuts, this destination offers a quiet retreat where you can take pictures, enjoy a fresh coconut, and simply bask
                in the sun while letting the worries of the world slip away.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Entulala Island</h2>
            </div>

            <div className="info_grp">
              <p>Unwind on Entulala Island, where tranquility meets beauty.</p>

              <p>This idyllic destination in Bacuit Bay is famed for two things — its beautiful beachscape and its distinct towering rock formation.</p>

              <p>
                Here, you can immerse yourself in the raw charm of an untouched tropical paradise. Its laid-back atmosphere creates the perfect ambiance for lounging on the beach or taking a leisurely
                swim.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Cudugnon Cave</h2>

              <div className="img_container">
                <img
                  className="image"
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/02+Cathy+and+Brea+on+the+Go+via+CBonthego.com.png"
                  alt="Cudugnon Cave"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Cathy and Brea on the Go via CBonthego.com</p>
              </div>
            </div>

            <div className="info_grp">
              <p>Delve into intriguing nature-bound histories by exploring Cudugnon Cave at Lagen Island.</p>
              <p>
                This enormous cavern sitting at the foot of Mt. Maateg, tucked away behind a limestone wall on a scenic beach, holds a bounty of cultural significance for the municipality of El Nido
                and the country.
              </p>
              <p>
                Once an archaeological site, this cave is believed to be a Neolithic burial site. There were even artifacts found in the cave dating back to the Chinese Sung Dynasty (960-1279)! Aside
                from providing visitors a glimpse into the lives of its ancient inhabitants, the cave itself is also a marvel.
              </p>
              <p>Its rock formations form narrow openings leading to the sky, filling the cave’s wide chambers with colorful natural light during specific times of the day.</p>
              <p>It’s truly a sight to behold, and a great picture to share with your friends.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Hidden Beach</h2>
            </div>

            <div className="info_grp">
              <p>Hidden Beach is a secluded beach situated in a small cove along the northeastern shores of Matinloc Island.</p>

              <p>
                True to its name, this hidden gem is accessible through a small crevice in the towering limestone cliffs that surround it. Swimming under this tunnel to get to the beach is part of the
                fun!
              </p>

              <p>Once at the beach itself, you can wade through calm, crystal-clear, shallow waters. Swim a little further and you can go snorkeling to see an abundant marine life.</p>

              <p>
                Although the Hidden Beach is one of the more popular stops for island hopping tours in El Nido, access to this destination depends on the state of the sea. If the waves are too strong,
                there’s a chance that tour guides will skip it for everyone’s safety.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Secret Beach</h2>
            </div>

            <div className="info_grp">
              <p>Another hidden wonder, Secret Beach can also only be accessed through a narrow underwater passage.</p>

              <p>Once again, you’d have to swim your way to this destination, but it’s worth it! The sense of discovery and challenge definitely adds to the allure of this hidden treasure.</p>

              <p>
                The waters at this beach are more emerald than aquamarine thanks to the gray karst rock formations surrounding it. These walls of limestone may look imposing, but they also give off a
                sense of privacy and exclusivity making the beach feel like your personal piece of paradise.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Matinloc Beach</h2>
            </div>

            <div className="info_grp">
              <p>Matinloc Beach or Matinloc Resort captivates visitors with its striking scenery and tranquil ambiance.</p>
              <p>Its azure waters and limestone cliffs make for a picture-perfect backdrop as you adventure around the island.</p>
              <p>On the beach, you can relax on fine white sand shores, snorkel in the biodiverse waters, or enjoy fresh seafood for lunch.</p>
              <p>
                If you’re religious or simply looking for more scenery, you can also explore the nearby Matinloc Shrine also known as the Shrine of the Blessed Virgin. This additional site is popular
                for its fantastic views from the cliffs, where you get an awesome bird’s eye view of the rest of Matinloc Island as well as the surrounding islands.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Helicopter Island</h2>
            </div>

            <div className="info_grp">
              <p>Helicopter Island, also officially known as Dilumacad Island, is popular for its unique shape resembling a helicopter from a distance.</p>
              <p>
                Though a spectacle from afar, this island is a charming destination up close. It has a long stretch of sandy beach ideal for long walks and leisurely sunbathing. The surrounding waters
                are calm and pristine, perfect for a quick and relaxing swim.
              </p>
              <p>Here, you can unwind and completely immerse yourself in the beauty of nature.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Cadlao Lagoon</h2>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/03+Adam+Navarro+via+Unsplash.png"
                  alt="Cadlao Lagoon"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Adam Navarro via Unsplash</p>
              </div>
            </div>

            <div className="info_grp">
              <p>Cadlao Lagoon is an incredible blue lagoon that you can visit on Cadlao Island, the largest island in El Nido.</p>
              <p>Here, you can go kayaking to explore the serene waters while listening to the wildlife hiding within the lush vegetation on top of the limestone cliffs.</p>
              <p>There’s a tiny white sand beach hidden in the lagoon where you can go down and take a short hike to a rocky lookout with the most astounding views.</p>
              <p>This island hopping stop is perfect for unwinding and connecting with nature.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Small Lagoon</h2>
            </div>

            <div className="info_grp">
              <p>
                The Small Lagoon, accessible through a small entrance, is a kayaker's dream. You can paddle your way through the calm waters of this beautiful little inlet and marvel at the towering
                rock formations that enclose its area.
              </p>
              <p>There are caves that you can pass through (even while on your kayak!) and cliff diving spots that you can explore.</p>
              <p>With a bit of curiosity and a touch of daring, this lagoon offers a fun aquatic experience all around.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Tapiutan Island</h2>
            </div>

            <div className="info_grp">
              <p>
                Tapiutan Island is an unassuming beauty located in the mini archipelago of Bacuit Bay. One of the lesser-known and most remote islands of El Nido, this destination is usually a
                stopover for a refreshing seaside lunch.
              </p>
              <p>But the island has so much more to offer.</p>
              <p>
                It’s home to both Star Beach and Talisay Beach. There are coral reefs near its shores where the water is so clear you can observe marine life without having to swim or snorkel. Its
                surrounding waters are a favored area for divers.
              </p>
              <p>If you want a less crowded exploration with an unobstructed view of the West Philippine Sea, this is a great stop.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Paradise Beach</h2>
            </div>

            <div className="info_grp">
              <p>As the name suggests, Paradise Beach is a tropical haven with a dream-like white sand coast lined with coconut trees and pristine turquoise waters.</p>
              <p>
                With a relaxing middle-of-nowhere ambiance, this beach is an ideal place for resting under the shade, going on a lazy swim, or embracing your inner childhood by building a sand castle.
              </p>
              <p>It's a favorite place for picnics by the locals and a true piece of paradise that's hard to leave behind for tourists.</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Bukal Beach</h2>
            </div>

            <div className="info_grp">
              <p>
                A visit to Bukal Beach brings you an intimate and charming beachscape experience. Located in Cadlao Island, this small piece of heaven offers a marvelous place to sink your toes into
                soft sand and relish the unspoiled beauty of open skies and sloping island peaks.
              </p>
              <p>From swimming to beachcombing, Bukal Beach is a serene setting to top off your El Nido island-hopping adventure.</p>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Things+to+do+in+El+nido/04+Jules+Bassoleil.png"
              alt="El Nido"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Jules Bassoleil via Unsplash</p>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Experience El Nido with GalaGO!</h2>
              <p>Whether you're in search of adventure, relaxation, or simply a connection with nature, El Nido has something to offer.</p>
            </div>

            <div className="info">
              <div className="bullet_grp">
                <div className="bullet_list">
                  <p>
                    Embrace the beauty, the adventure, and the tranquility that El Nido graciously provides. Do it by booking your island tour package with GalaGO! and have all the essentials taken
                    care of for you. Our island hopping packages already include:
                  </p>

                  <ul>
                    <li>air-conditioned van for transport,</li>
                    <li>tour guide,</li>
                    <li>boat fees, and</li>
                    <li>permit fees</li>
                  </ul>

                  <p>All you’ll have to cover are the lagoon fees and possible environmental fees (which is usually around PHP 200).</p>
                </div>

                <p>Each island, beach, and lagoon in El Nido tells a unique story. Experience them all to enrich your vacation with memories that will last for a lifetime!</p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThingsToDoInElNido;
