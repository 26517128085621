import React from 'react';
import { getFlightTransactions, getHotelTransactions } from '../utils/apiRoutes';
import axios, { AxiosResponse } from 'axios';

const BookHistoryHooks = () => {
  const [flightHistory, setFlightHistory] = React.useState<{ [index: string]: any }>([]);
  const [hotelHistory, setHotelHistory] = React.useState<{ [index: string]: any }>([]);

  React.useEffect(() => {
    getFlightHistory();
    getHotelHistory();
  }, []);

  const getFlightHistory = async () => {
    // fetch flight history
    try {
      const response: AxiosResponse = await axios.get(getFlightTransactions());
      setFlightHistory(response.data.data);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  const getHotelHistory = async () => {
    // fetch hotel history
    try {
      const response = await axios.get(getHotelTransactions());
      setHotelHistory(response.data.data);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  return { getFlightHistory, flightHistory, getHotelHistory, hotelHistory };
};

export default BookHistoryHooks;
