import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  // value: string;
  // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // checked: boolean;
  description?: string;
  props?: any;
}

const FilterCheckbox = ({ id, label, description, ...props }: CheckboxProps) => (
  <div className="form-check flex align-items-center">
    {/* <input className="form-check-input mr-2 mt-1.5 custom-checkbox" type="checkbox" value={value} id={id} onChange={onChange} checked={checked} /> */}
    <input className="form-check-input mr-2 mt-1.5 custom-checkbox" type="checkbox" id={id} {...props} />
    <div className="form-check-labels cursor-pointer">
      <label className="form-check-label cursor-pointer flex flex-col pt-2" htmlFor={id}>
        {label}
        {description && <span className="text-black-black-200 text-xs">{description}</span>}
      </label>
    </div>
  </div>
);

export default FilterCheckbox;
