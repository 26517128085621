import React, { useState } from 'react';
import HotelFormInformation from '../../../components/Hotels/HotelFormInformation';
import HotelBookingSummary from '../../../components/Hotels/HotelBookingSummary';
import { NavigateFunction } from 'react-router';
import StickyBox from 'react-sticky-box';
import { HotelGuestStore } from '../../../store/HotelGuestsStore';
import { checkEmail } from '../../../utils/data.utils';
import { HotelCartStore } from '../../../store/HotelCartStore';
import { HotelPreBookingHooks } from '../../../hooks/HotelPreBookingHooks';
import HotelCancellationPolicy from '../../../components/Hotels/HotelCancellationPolicy';
import { GalagoButton } from '../../../components/Button';
import { AlertBox } from '../../../components/AlertBox';
import { HurtGallySvg } from '../../../resources/svg/GallySvg';
import '../../../resources/css/button.scss';
type HotelBookingFormProps = {
  selectedRooms: { [index: string]: any };
  hotelInfo: { [index: string]: any };
  transactionId: string;
  facilitiesData: { [index: string]: any };
  searchCriteria: { [index: string]: any };
  navigate: NavigateFunction;
  hotel: { [index: string]: any };
};

const HotelBookingForm = ({ hotel, selectedRooms, hotelInfo, transactionId, facilitiesData, searchCriteria, navigate }: HotelBookingFormProps) => {
  const { setSelectedHotelRooms } = HotelCartStore();
  const { preBookingTBO, preBookingTBOResponse, preBookCancellationPolicy, error } = HotelPreBookingHooks();
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  // eslint-disable-next-line
  const { guest, setTitleError, setFirstNameError, setLastNameError, setEmailError, setMobileError } = HotelGuestStore();
  const nights = selectedRooms[0].DayRates[0].length;
  const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [continueBtn, setContinueBtn] = useState(false);

  let bookingCodeArray: string[] = [];
  // eslint-disable-next-line
  selectedRooms.map((value: { [index: string]: any }, key: number) => {
    bookingCodeArray[key] = value.BookingCode;
  });

  const guestValidation = (): boolean => {
    let status = true;
    if (guest.title.length === 0 || guest.firstName.length === 0 || guest.lastName.length === 0 || guest.email.length === 0 || !checkEmail(guest.email)) {
      status = false;
    }
    if (bookingCodeArray.length <= 0) {
      status = false;
      alert(`Something went wrong; can't find any selected hotels`);
    }
    if (guest.title.length === 0) {
      setTitleError(true);
    }
    if (guest.firstName.length === 0) {
      setFirstNameError(true);
    }
    if (guest.lastName.length === 0) {
      setLastNameError(true);
    }
    if (guest.email.length === 0 || !checkEmail(guest.email)) {
      setEmailError(true);
    }
    return status;
  };

  React.useEffect(() => {
    preBookingTBO(bookingCodeArray, setDisableContinueButton);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (error !== null && error !== undefined) {
      setShowErrorModal(true);
    }
    // if (error === 'Session Expired') {
    //   if (window.confirm('Booking session is expired') === true) {
    //     navigate('/');
    //   }
    // }
    // if (error === 'Given rate is not available for this booking anymore') {
    //   if (window.confirm('Given rate is not available for this booking anymore') === true) {
    //     navigate('/');
    //   }
    // }
    // eslint-disable-next-line
  }, [error]);

  // console.log(continueBtn);
  console.log({ selectedRooms });
  console.log({ preBookCancellationPolicy });
  return (
    <section className="row gap-5 justify-content-md-center mb-10">
      <div className="col-5">
        <HotelFormInformation setContinueBtn={setContinueBtn} />
        {/* <SpecialRequests /> */}
        <HotelCancellationPolicy CancellationData={preBookCancellationPolicy} selectedRooms={selectedRooms} />
        <div className="form-check m-0 p-0 mb-4">
          <input
            className="form-check-input m-2 !ml-0"
            type="checkbox"
            value=""
            onChange={(e) => setAgreedToTermsAndConditions(e.target.checked)}
            id="flight-agreement"
            style={{ width: 20, height: 20 }}
          />
          <label className="form-check-label textColor" style={{ display: 'unset' }} htmlFor="flight-agreement">
            <p className="generalsans-regular text-base">
              I have read and agreed to the booking
              <a href="/#/terms-and-conditions" className="no-underline hover:text-[#4FA2B4]" style={{ color: '#016e7f' }}>
                Terms and Conditions
              </a>
              of GalaGO!, and
              {/* <a href="" className="no-underline hover:text-[#4FA2B4] text-text-colors-text-brand" > Hotel Bookings Policy </a> */}
              <a href="/#/privacy-policy" className="no-underline hover:text-[#4FA2B4]" style={{ color: '#016e7f' }}>
                Privacy Statement.
              </a>
            </p>
          </label>
        </div>
        <GalagoButton
          label="Continue"
          disabled={disableContinueButton || !continueBtn || !agreedToTermsAndConditions}
          onClick={() => {
            const isValidated = guestValidation();
            if (!isValidated) {
              return;
            }
            navigate('/hotel/payment/page', {
              state: { ...{ tboDataResponse: preBookingTBOResponse }, ...{ hotelInfo, selectedRooms, facilitiesData, nights, guest, searchCriteria, transactionId, hotel } },
            });
          }}
          btn="primary"
          className="generalsans-regular continue-btn"
        />
      </div>
      <div className="col-5">
        <StickyBox offsetTop={130} offsetBottom={20}>
          <HotelBookingSummary
            preBookingTBOResponse={preBookingTBOResponse}
            setSelectedHotelRooms={setSelectedHotelRooms}
            navigate={navigate}
            searchCriteria={searchCriteria}
            hotelInfo={hotelInfo}
            selectedRooms={selectedRooms}
            nights={nights}
          />
        </StickyBox>
      </div>

      <AlertBox
        size={'sm'}
        show={showErrorModal}
        children={
          <div className="d-flex flex-column align-items-center py-10">
            <HurtGallySvg _width={150} _height={150} />
            <p className="px-6">{error}</p>
            <div className="mt-4 w-75">
              <GalagoButton
                btn="primary"
                size="sm"
                label={'Search another hotel'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  navigate('/');
                }}
              />
            </div>
          </div>
        }
      />
    </section>
  );
};

export default HotelBookingForm;
