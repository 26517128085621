import { create } from "zustand";
import { combine } from "zustand/middleware";

export const hotelStore = create(
  combine(
    {
      seeAllPhotos: false,
      slideShow: false,
      accommodation: false,
      cancellation: false,
      isHotelSearchBtnClicked: false,
      propertyFilters: true,
      facilitiesAndServicesFilters: true,
      propertyFeaturesFilters: true,
      roomAmenitiesFilters: true,
      mealPlansFilters: true,
      propertyAccessibilityFilters: true,
      bedPreferencesFilters: true,
    },
    (set, get) => ({
      // togglers
      toggleHotels: () =>
        set(() => ({
          seeAllPhotos: !get().seeAllPhotos,
        })),
      toggleGallery: () =>
        set(() => ({
          slideShow: !get().slideShow,
        })),
      toggleSearchBtn: () =>
        set(() => ({
          isHotelSearchBtnClicked: !get().isHotelSearchBtnClicked,
        })),

      // filter togglers
      toggleProperties: () =>
        set(() => ({
          propertyFilters: !get().propertyFilters,
        })),
      toggleFacilities: () =>
        set(() => ({
          facilitiesAndServicesFilters: !get().facilitiesAndServicesFilters,
        })),
      togglePropertyFeatures: () =>
        set(() => ({
          propertyFeaturesFilters: !get().propertyFeaturesFilters,
        })),
      toggleRoomAmenities: () =>
        set(() => ({
          roomAmenitiesFilters: !get().roomAmenitiesFilters,
        })),
      toggleMealPlans: () =>
        set(() => ({
          mealPlansFilters: !get().mealPlansFilters,
        })),
      togglePropertyAccessibility: () =>
        set(() => ({
          propertyAccessibilityFilters: !get().propertyAccessibilityFilters,
        })),
      toggleBedPreferences: () =>
        set(() => ({
          bedPreferencesFilters: !get().bedPreferencesFilters,
        })),

      // setters
      openAllHotelPhotosModal: () => set(() => ({ seeAllPhotos: true })),
      openAboutAccommodation: () => set(() => ({ accommodation: true })),
      closeAboutAccommodation: () => set(() => ({ accommodation: false })),
      openCancellationPolicy: () => set(() => ({ cancellation: true })),
      closeCancellationPolicy: () => set(() => ({ cancellation: false })),
    })
  )
);
