import { create } from 'zustand';

export const AsyncTypestore = create((set: any) => ({
  flyingFrom: '',
  setFlyingFrom: (text: string) => {
    set((state: { textDisplay: string }) => ({
        flyingFrom: text,
    }));
  },
  flyingTo: '',
  setFlyingTo: (text: string) => {
    set((state: { textDisplay: string }) => ({
      flyingTo: text,
    }));
  },
  fromDetails:  '',
  setFromDetails: (text: string) => {
    set((state: { textDisplay: string }) => ({
        fromDetails: text,
    }));
  },
  toDetails:  '',
  setToDetails: (text: string) => {
    set((state: { textDisplay: string }) => ({
      toDetails: text,
    }));
  },
  isFromActive: false,
  setIsFromActive: (isShow: boolean) => {
    set((state: { showReturnFlights: boolean }) => ({
        isFromActive: isShow,
    }));
  }, 
  isToActive: false,
  setIsToActive: (isShow: boolean) => {
    set((state: { showReturnFlights: boolean }) => ({
      isToActive: isShow,
    }));
  }, 
}));
