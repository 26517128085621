import moment from 'moment';
import { timeForHumans } from '../utils/time.utils';

export const RoundTripFlightHook = () => {
  // const [firstItemDestination, setFirstItemDestination] = React.useState<{ [index: string]: any }>();
  // const [lastItemDestination, setLastItemDestination] = React.useState<{ [index: string]: any }>();

  /**
   *
   * @param OriginDestination object key
   * @param legIndicator leg indicator of origin destination this indicates the flights group, eg. departure or return
   * @param fromAirportCode code of the airport
   * @param toAirportCode code of the airport
   * @returns object
   */
  const roundTripFlightSegment = (OriginDestination: { [index: string]: any }, legIndicator: number | string, fromAirportCode?: string, toAirportCode?: string) => {
    if (!OriginDestination) return {};
    let flightDeparture: { [index: string]: any } = {};
    let flightArrival: { [index: string]: any } = {};

    const flights = OriginDestination?.filter((element: { LegIndicator: string }) => {
      return element.LegIndicator === legIndicator;
    });

    flightDeparture = flights?.[0];
    flightArrival = flights?.slice(-1)[0];

    const carrierCode = flightDeparture?.FlightSegment?.OperatingCarrierCode;
    const flightNumber = flightDeparture?.FlightSegment?.OperatingFlightNumber;

    const MarketingCarriercode = flightDeparture?.FlightSegment?.MarketingCarriercode;
    const MarketingFlightNumber = flightDeparture?.FlightSegment?.MarketingFlightNumber;

    // departure location
    const departureCode = flightDeparture?.FlightSegment?.DepartureAirportLocationCode;
    const arrivalCode = flightArrival?.FlightSegment?.ArrivalAirportLocationCode;
    const departure = flightDeparture?.FlightSegment?.DepartureLocationDetails;
    const arrival = flightArrival?.FlightSegment?.ArrivalLocationDetails;

    const departureDateTime = flightDeparture?.FlightSegment?.DepartureDateTime;
    const arrivalDateTime = flightArrival?.FlightSegment?.ArrivalDateTime;

    // departure and arrival time
    const departureTime = moment(flightDeparture?.FlightSegment?.DepartureDateTime).format('HH:mm');
    const arrivalTime = moment(flightArrival?.FlightSegment?.ArrivalDateTime).format('HH:mm');
    // departure and arrival date
    const departureDate = moment(flightDeparture?.FlightSegment?.DepartureDateTime).format('ddd, MMM, DD');
    const arrivalDate = moment(flightArrival?.FlightSegment?.ArrivalDateTime).format('ddd, MMM, DD');
    // const cabinClass = flightDeparture?.ItineraryReference.CabinClassType;
    const CabinClassType = flightDeparture?.ItineraryReference?.CabinClassType;
    const cabinClass = CabinClassType ? CabinClassType : flightDeparture?.ItineraryReference?.FareFamily;

    const equipment = flightDeparture?.FlightSegment?.Equipment;

    // airline details
    const airlineCode = '';

    // direct or stops count
    const _stops = flights?.length - 1;
    let stops = 'Direct';
    if (_stops > 0) {
      stops = _stops + ' Stop' + (_stops > 1 ? 's' : '');
    }

    // const flightDuration = calcTotalTimeDuration(flightArrival?.FlightSegment?.ArrivalDateTime, flightDeparture?.FlightSegment?.DepartureDateTime);
    let totalJourneyDuration = 0;
    let totalStopsDuration = 0;
    // eslint-disable-next-line
    flights?.map((flight: { [index: string]: any }, index: number) => {
      // get the arrival date and time, this will be used to calculate the total time duration of the flight
      const arrivalDateTime = flight.FlightSegment.ArrivalDateTime;
      // get the departure date and time of the next stop, this will be used to calculate the total time duration of the flight
      const departureNextDateTime = flights?.[index + 1]?.FlightSegment.DepartureDateTime;
      // get the total time duration of arrivalDateTime and departureNextDateTime
      const stopDuration = moment(departureNextDateTime).diff(moment(arrivalDateTime), 'minutes');
      // sum all the time duration of the stops
      if (stopDuration > 0) {
        totalStopsDuration += stopDuration;
      }
      totalJourneyDuration += flight.FlightSegment.JourneyDuration;
    });
    const minutesDuration = totalJourneyDuration + totalStopsDuration;
    const flightDuration = timeForHumans(minutesDuration * 60);

    const isDomesticFlight = departure?.countryCode === arrival?.countryCode;

    return {
      departureTime,
      arrivalTime,
      departureDate,
      arrivalDate,
      departureDateTime,
      arrivalDateTime,
      stops,
      departureCode,
      arrivalCode,
      carrierCode,
      flightNumber,
      MarketingCarriercode,
      MarketingFlightNumber,
      airlineCode,
      departure,
      arrival,
      flightDuration,
      cabinClass,
      equipment,
      isDomesticFlight,
    };
  };

  /**
   * This function will calculate the total time duration of two datetime using moment and manual computation
   * @param arrivalDate Arrival date
   * @param departureDate Departure date
   * @returns string
   */
  // eslint-disable-next-line
  const calcTotalTimeDuration = (arrivalDate: string, departureDate: string) => {
    var firstDate = moment(arrivalDate);
    var secondDate = moment(departureDate);
    let seconds = firstDate.diff(secondDate, 'seconds');
    return timeForHumans(seconds);
  };

  return { roundTripFlightSegment };
};
