import React from 'react';

const HotelConfirmationProgress3 = () => {
  return (
    <section className="relative w-100 bg-background-colors-surface-secondary h-[7.31rem] flex flex-row py-0 items-center justify-center text-background-colors-surface-secondary">
      <div className="flex flex-row items-start justify-start gap-[0.5rem]">
        <div className="flex flex-row items-center justify-center gap-[0.5rem] ">
          <div className="rounded-81xl bg-color-shades-white-800 w-[1.75rem] h-[1.75rem] flex flex-col p-2.5 box-border items-center justify-center ">
            <b className="relative leading-[1.38rem]">1</b>
          </div>
          <span className="relative text-[0.94rem] leading-[1.25rem] font-normal text-color-shades-white-800 font-footnote">Enter Details</span>
          <div className="relative bg-color-shades-white-800 w-[4.06rem] h-[0.13rem]" />
        </div>

        <div className="flex flex-row items-center justify-center gap-[0.5rem] ">
          <div className="rounded-81xl bg-color-shades-white-800 w-[1.75rem] h-[1.75rem] flex flex-col p-2.5 box-border items-center justify-center ">
            <b className="relative leading-[1.38rem]">2</b>
          </div>
          <span className="relative text-[0.94rem] leading-[1.25rem] font-normal text-color-shades-white-800 font-footnote">Payment</span>
          <div className="relative bg-color-shades-white-800 w-[4.06rem] h-[0.13rem]" />
        </div>

        <div className="flex flex-row items-center justify-center gap-[0.5rem] ">
          <div className="rounded-81xl bg-color-shades-white-800 w-[1.75rem] h-[1.75rem] flex flex-col p-2.5 box-border items-center justify-center ">
            <b className="relative leading-[1.38rem]">3</b>
          </div>
          <span className="relative text-[0.94rem] leading-[1.25rem] font-semibold text-color-shades-white-800 font-footnote">Booking is confirmed</span>
        </div>
      </div>
    </section>
  );
};

export default HotelConfirmationProgress3;
