 import React, { useEffect, useRef, useState } from 'react';
import '../../../resources/css/search-filter-flights.scss';
import { GalagoButton } from '../../../components/Button';
import SliderFilter from '../../../components/ui/slider-filter';
import { FlightFiltersStoreOneWay } from '../../../store/FlightFiltersStoreOneWay';
import { useNavigate } from 'react-router';
import { formatPriceNoRound } from '../../../utils/data.utils';
import FlightFiltersOneWay from './FlightFiltersOneWay';
import { FlightFilterStoreRoundTrip } from '../../../store/FlightFiltersStoreRoundTrip';
import FlightFiltersRoundTrip from './FlightFiltersRoundTrip';
import { FlightFilterTypeStore } from '../../../store/FlightFilterTypeStore';
import { FlightSortingStore } from '../../../store/FlightSortingStore';

type FlightFiltersType = {
  FlightType: 'one-way' | 'round-trip' | 'multi-city';
  SearchParams: { [index: string]: any };
  // resetRoundTripFlightSelection?: (param: boolean) => void;
};

export default function FlightFilters({ FlightType, SearchParams }: FlightFiltersType) {
  const navigate = useNavigate();

  const {
    filterKey,
    priceRange,
    selectedPriceRange,
    setSelectedPriceRange,
    selectedDepartureTimeRange,
    setSelectedDepartureTimeRange,
    selectedArrivalTimeRange,
    setSelectedArrivalTimeRange,
    selectedStops,
    setSelectedStops,
    selectedCabinAndCheckInBaggage,
    setSelectCabinAndCheckInBaggage,
    setSelectedAirlines,
    selectedAirlines,
    airlineList,
    setSelectedPriceRangeLabel,
    selectedPriceRangeLabel,
  } = FlightFiltersStoreOneWay();
  const {
    selectedReturnDepartureTimeRange,
    setSelectedReturnDepartureTimeRange,
    selectedReturnArrivalTimeRange,
    setSelectedReturnArrivalTimeRange,
    selectedReturnStops,
    setSelectedReturnStops,
    selectedReturnCabinAndCheckInBaggage,
    setSelectedReturnCabinAndCheckInBaggage,
    selectedReturnAirlines,
    setSelectedReturnAirlines,
    returnAirlineList,
  } = FlightFilterStoreRoundTrip();

  const { setFlightFilterType } = FlightFilterTypeStore();
  const { setOneWaySort, setSelectedFilter } = FlightSortingStore();

  const getOriginCode = SearchParams.get('originCode');
  const getDestinationCode = SearchParams.get('destinationCode');
  const getOriginCity = SearchParams.get('originCity');
  const getDestinationCity = SearchParams.get('destinationCity');
  const getDepartureDate = SearchParams.get('departureDate');
  const getReturnDate = SearchParams.get('returnDate');
  const getAdults = SearchParams.get('adults') ?? 1;
  const getMinors = SearchParams.get('minors') ?? 0;
  const getChildren = SearchParams.get('children') ?? 0;
  const getInfants = SearchParams.get('infants') ?? 0;
  const getCabinClass = SearchParams.get('class');

  const getSortedBy = SearchParams.get('sortedBy') ?? 'LowestPrice';
  const getReturnSortedBy = SearchParams.get('returnSortedBy') ?? 'LowestPrice';

  const [showAllAirlines, setShowAllAirlines] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  // For Gradient on Bottom Scroll and Tooltip
  const [isGradientVisible, setIsGradientVisible] = useState(false);
  const searchFilterContainerRef = useRef<HTMLDivElement | null>(null);
  const checkboxDirectRef = useRef<HTMLInputElement | null>(null);
  const checkboxOneStopRef = useRef<HTMLInputElement | null>(null);
  const checkboxManyRef = useRef<HTMLInputElement | null>(null);
  const checkboxCabinAndCheckInBaggageRef = useRef<HTMLInputElement | null>(null);
  const airlinesDivHolderRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasShownTooltip, setHasShownTooltip] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState<'departure' | 'return'>('departure'); // departure or return
  // const [returnSort, setReturnSort] = useState<'Lowest Price First' | 'CheapestPrice'>('Lowest Price First');

  const handleRangeChange = (newValue: number[]): void => {
    setSelectedPriceRange({ start: newValue[0], end: newValue[1] });
    setSelectedPriceRangeLabel({ start: newValue[0], end: newValue[1] });
    setIsFilterChanged(true);
  };

  const handleShowAirlines = () => {
    setShowAllAirlines((prevShowAll) => !prevShowAll);
  };

  // For the tooltip on apply button
  useEffect(() => {
    if (isFilterChanged && !hasShownTooltip) {
      setShowTooltip(true);
      setHasShownTooltip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterChanged]);

  // Scroll and Gradient State
  useEffect(() => {
    const handleScroll = () => {
      const container = searchFilterContainerRef.current;
      const scrollPosition = container!.scrollTop + container!.offsetHeight;
      const containerHeight = container!.scrollHeight;

      if (scrollPosition >= containerHeight) {
        setIsGradientVisible(true);
      } else {
        setIsGradientVisible(false);
      }
    };

    const container = searchFilterContainerRef.current;
    if (container) {
      container!.addEventListener('scroll', handleScroll);
    }

    if (showAllAirlines) {
      container!.scrollTop = container!.scrollHeight;
    }

    // Clean up the event listener
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showAllAirlines]);

  let urlGetParams = '';
  urlGetParams += `?originCode=${getOriginCode}`;
  urlGetParams += `&destinationCode=${getDestinationCode}`;
  urlGetParams += `&originCity=${getOriginCity}`;
  urlGetParams += `&destinationCity=${getDestinationCity}`;
  urlGetParams += `&departureDate=${getDepartureDate}`;
  urlGetParams += `&returnDate=${getReturnDate}`;
  urlGetParams += `&adults=${getAdults}`;
  urlGetParams += `&minors=${getMinors}`;
  urlGetParams += `&children=${getChildren}`;
  urlGetParams += `&infants=${getInfants}`;
  urlGetParams += `&class=${getCabinClass}`;
  // urlGetParams += `&sortedBy=${getSortedBy}`;
  // filters

  useEffect(() => {
    const priceRangeUrl = SearchParams.get('priceRange');
    const airlinesUrl = SearchParams.get('airlines');
    const departureTimeRangeUrl = SearchParams.get('departureTimeRange');
    const arrivalTimeRangeUrl = SearchParams.get('arrivalTimeRange');
    const stopsUrl = SearchParams.get('stops');
    const withCheckInBaggageUrl = SearchParams.get('withCheckInBaggage');
    if (priceRangeUrl) {
      const priceRange = priceRangeUrl.split('-');
      setSelectedPriceRange({ start: parseInt(priceRange[0]), end: parseInt(priceRange[1]) });
      setSelectedPriceRangeLabel({ start: parseInt(priceRange[0]), end: parseInt(priceRange[1]) });
    }
    if (airlinesUrl) {
      setSelectedAirlines(airlinesUrl.split('|'));
    }
    if (departureTimeRangeUrl) {
      const departureTimeRange = departureTimeRangeUrl.split('-');
      setSelectedDepartureTimeRange({ start: departureTimeRange[0], end: departureTimeRange[1] });
    }
    if (arrivalTimeRangeUrl) {
      const arrivalTimeRange = arrivalTimeRangeUrl.split('-');
      setSelectedArrivalTimeRange({ start: arrivalTimeRange[0], end: arrivalTimeRange[1] });
    }
    if (stopsUrl) {
      setSelectedStops(stopsUrl.split('|'));
    }
    if (withCheckInBaggageUrl) {
      const isTrue: boolean = withCheckInBaggageUrl === 'true';
      setSelectCabinAndCheckInBaggage(isTrue);
    }

    if (getSortedBy) {
      setSelectedFilter(getSortedBy);
    }

    if (getReturnSortedBy) {
      setSelectedFilter(getReturnSortedBy);
    }

    // (async () => {
    //   await resetFilters();
    // })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  const resetFilters = () => {
    setSelectedPriceRange({ start: priceRange.start, end: priceRange.end });
    setSelectedPriceRangeLabel({ start: priceRange.start, end: priceRange.end });

    setSelectedDepartureTimeRange({ start: '', end: '' }); // departure
    setSelectedReturnDepartureTimeRange({ start: '', end: '' }); // return
    setSelectedArrivalTimeRange({ start: '', end: '' }); // departure
    setSelectedReturnArrivalTimeRange({ start: '', end: '' }); // return
    setSelectCabinAndCheckInBaggage(false); // departure
    setSelectedReturnCabinAndCheckInBaggage(false); // return
    // remove all selected stops
    selectedStops.splice(selectedStops.indexOf('direct'), 1); // departure
    selectedStops.splice(selectedStops.indexOf('1'), 1); // departure
    selectedStops.splice(selectedStops.indexOf('many'), 1); // departure
    selectedReturnStops.splice(selectedReturnStops.indexOf('direct'), 1); // return
    selectedReturnStops.splice(selectedReturnStops.indexOf('1'), 1); // return
    selectedReturnStops.splice(selectedReturnStops.indexOf('many'), 1); // return

    // remove all selected airlines
    // eslint-disable-next-line array-callback-return
    airlineList.map((airline: { [index: string]: any }, index: React.Key) => {
      if (selectedAirlines.includes(airline.airlineCode)) {
        selectedAirlines.splice(selectedAirlines.indexOf(airline.airlineCode), 1);
      }
    }); // departure
    // eslint-disable-next-line array-callback-return
    returnAirlineList.map((airline: { [index: string]: any }, index: React.Key) => {
      if (selectedReturnAirlines.includes(airline.airlineCode)) {
        selectedReturnAirlines.splice(selectedReturnAirlines.indexOf(airline.airlineCode), 1);
      }
    }); // return

    setIsFilterChanged(false);
    // reset airline checkboxes
    airlinesDivHolderRef.current!.querySelectorAll('input').forEach((input: HTMLInputElement) => {
      // reset the checked attribute of the input element
      input.checked = false;
    });

    resetSliders();

    checkboxDirectRef.current!.checked = false;
    checkboxOneStopRef.current!.checked = false;
    checkboxManyRef.current!.checked = false;
    checkboxCabinAndCheckInBaggageRef.current!.checked = false;

    let url = `/flights/${FlightType}${urlGetParams}`;
    navigate(url);
  };

  const resetSliders = () => {
    // search div with data-key attribute inside searchFilterContainerRef
    const allRangeInputsDivs = searchFilterContainerRef.current!.querySelectorAll('.rs-slider-handle');
    // loop through allRangeInputsDiv and reset the values of the sliders
    allRangeInputsDivs.forEach((div) => {
      // get data-key attribute value
      const key = div.getAttribute('data-key');
      // get the input element inside the div
      const input = div.querySelector('input');
      // get the aria-valuemax and aria-valuemin attribute of the input element
      const min = input!.getAttribute('aria-valuemin');
      const max = input!.getAttribute('aria-valuemax');
      // get the sliderbars (the teal colored bars) to reset position when clear all button is pressed
      const sliderBars = document.querySelectorAll('.rs-slider-progress-bar');

      // reset the rs-slider-handle style to 0% if the key is start, 100% if the key is end
      if (div instanceof HTMLDivElement) {
        if (key === 'start') {
          div.style.left = '0%';

          //loop the sliderBars to access each style to leftmost.
          sliderBars.forEach((sliderBar) => {
            (sliderBar as HTMLElement).style.left = '0%';
          });
        }
        if (key === 'end') {
          div.style.left = '100%';
        }
      }
      // set the aria-valuenow and value of the input element to the default value
      input!.setAttribute('aria-valuenow', priceRange[key as 'start' | 'end'].toString());
      input!.setAttribute('value', priceRange[key as 'start' | 'end'].toString());

      // reset the rs-slider-handle data-range-key attribute to the default value
      div.setAttribute('data-range', `${min},${max}`);

      // get the upper sibling of this div
      const upperSibling = div.previousElementSibling as HTMLDivElement;
      // if the upper sibling has class rs-slider-bar then continue then reset the teal color the slider bar
      if (upperSibling.classList.contains('rs-slider-bar')) {
        // get the child div of rs-slider-bar
        const childDiv = upperSibling.querySelector('div');
        // change the style width to 100% of childDiv
        childDiv!.style.width = '100%';
      }
    });
  };

  // listen to changes in selected Journey if changed reset filters
  // useEffect(() => {
  // resetFilters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedJourney]);

  const handleApplyFilterButton = () => {
    // let url: string = `/flights/${FlightType}?`;
    urlGetParams += `&filterKey=${filterKey}`;

    if (selectedPriceRange.start !== 0 && selectedPriceRange.end !== 0) {
      urlGetParams += `&priceRange=${selectedPriceRange.start}-${selectedPriceRange.end}`;
    }

    switch (selectedJourney) {
      case 'departure': // One-way or departure leg of round-trip
        if (selectedDepartureTimeRange.start !== '' && selectedDepartureTimeRange.end !== '') {
          urlGetParams += `&departureTimeRange=${selectedDepartureTimeRange.start}-${selectedDepartureTimeRange.end}`;
        }
        if (selectedArrivalTimeRange.start !== '' && selectedArrivalTimeRange.end !== '') {
          urlGetParams += `&arrivalTimeRange=${selectedArrivalTimeRange.start}-${selectedArrivalTimeRange.end}`;
        }
        if (selectedStops.length) {
          urlGetParams += `&stops=${selectedStops.join('|')}`;
        }
        if (selectedCabinAndCheckInBaggage) {
          urlGetParams += `&withCheckInBaggage=${selectedCabinAndCheckInBaggage}`;
        }
        if (selectedAirlines.length !== 0) {
          urlGetParams += `&airlines=${selectedAirlines.join('|')}`;
        }
        if (getSortedBy) {
          urlGetParams += `&sortedBy=${getSortedBy}`;
        }
        if (!SearchParams.get('sortedBy')) {
          setOneWaySort('Lowest Price First');
        }
        break;

      case 'return': // Return leg of round-trip
        if (selectedReturnDepartureTimeRange.start !== '' && selectedReturnDepartureTimeRange.end !== '') {
          urlGetParams += `&returnDepartureTimeRange=${selectedReturnDepartureTimeRange.start}-${selectedReturnDepartureTimeRange.end}`;
        }
        if (selectedReturnArrivalTimeRange.start !== '' && selectedReturnArrivalTimeRange.end !== '') {
          urlGetParams += `&returnArrivalTimeRange=${selectedReturnArrivalTimeRange.start}-${selectedReturnArrivalTimeRange.end}`;
        }
        if (selectedReturnStops.length) {
          urlGetParams += `&returnStops=${selectedReturnStops.join('|')}`;
        }
        if (selectedReturnCabinAndCheckInBaggage) {
          urlGetParams += `&returnWithCheckInBaggage=${selectedReturnCabinAndCheckInBaggage}`;
        }
        if (selectedReturnAirlines.length !== 0) {
          urlGetParams += `&returnAirlines=${selectedReturnAirlines.join('|')}`;
        }
        if (getReturnSortedBy) {
          urlGetParams += `&returnSortedBy=${getReturnSortedBy}`;
        }
        // if (!SearchParams.get('returnSortedBy')) {
        //   setReturnSort('Lowest Price First'); // Use a different state setter for round-trip sorting
        // }
        break;

      default:
        break;
    }

    // Return the final URL and navigate
    navigate(`/flights/${FlightType}${urlGetParams}`);
  };

  return (
    <div className="search-filter-container-flights relative" style={{ zIndex: 2 }}>
      <div className="search-header">
        <p style={{ fontSize: '17px', marginRight: '28px' }}>Filters</p>
        <div className="flex gap-1">
          {isFilterChanged && (
            <GalagoButton
              onClick={() => {
                resetFilters();
                setShowTooltip(false);
              }}
              className="clear-btn"
              label="Clear All"
              btn="primary"
            />
          )}
          <GalagoButton
            label="Apply"
            className="apply-btn font-bold"
            onClick={() => {
              setShowTooltip(false);
              handleApplyFilterButton();
            }}
            disabled={!isFilterChanged}
          />
        </div>
      </div>

      <div ref={searchFilterContainerRef} className="filter-scroll">
        <div className="search-content">
          <div className="price-range-container">
            <p className="satoshi-semibold text-xl text-[#333333] ">Price Range</p>
            <p className="text-grey generalsans-medium">Set price range per passenger</p>
            <div className="slider-container my-4 ms-1 me-1.5">
              {priceRange.start !== 0 && priceRange.end !== 0 && (
                <SliderFilter
                  min={priceRange.start}
                  max={priceRange.end}
                  defaultValue={[selectedPriceRange.start === 0 ? priceRange.start : selectedPriceRange.start, selectedPriceRange.end === 0 ? priceRange.end : selectedPriceRange.end]}
                  tooltip={false}
                  step={500}
                  constraint={([start, end]) => {
                    // const minSteps = 6000;
                    const minSteps = priceRange.end * 0.1;
                    return start < end && end - start >= minSteps;
                  }}
                  onChange={handleRangeChange}
                />
              )}
            </div>

            <div className="price-range-btns d-flex justify-content-between generalsans-medium">
              <div className="price-range-btn">
                <p className="text-secondary-secondary-100">Minimum</p>
                <p className="text-black-black-100">PHP {selectedPriceRangeLabel.start !== 0 ? formatPriceNoRound(selectedPriceRangeLabel.start) : formatPriceNoRound(priceRange.start)}</p>
              </div>
              <div className="price-range-btn">
                <p className="text-secondary-secondary-100">Maximum</p>
                <p className="text-black-black-100">PHP {selectedPriceRangeLabel.end !== 0 ? formatPriceNoRound(selectedPriceRangeLabel.end) : formatPriceNoRound(priceRange.end)}</p>
              </div>
            </div>
          </div>

          {FlightType === 'round-trip' && (
            <div id="flight-leg-indicator-option" className="flex gap-2 text-center border rounded-md p-2">
              <div
                onClick={() => {
                  setSelectedJourney('departure');
                  setFlightFilterType('departure');
                  setShowAllAirlines(false);
                }}
                className={`px-4 py-[.8em] w-1/2 rounded-md fw-medium ${selectedJourney === 'departure' ? 'selected' : ''} `}
              >
                Departure
              </div>
              <div
                onClick={() => {
                  setSelectedJourney('return');
                  setFlightFilterType('return');
                  setShowAllAirlines(false);
                }}
                className={`px-4 py-[.8em] w-1/2 rounded-md fw-medium ${selectedJourney === 'return' ? 'selected' : ''}`}
              >
                Return
              </div>
            </div>
          )}

          {selectedJourney === 'departure' ? (
            <FlightFiltersOneWay
              selectedDepartureTimeRange={selectedDepartureTimeRange}
              priceRange={priceRange}
              setSelectedDepartureTimeRange={setSelectedDepartureTimeRange}
              setIsFilterChanged={setIsFilterChanged}
              selectedArrivalTimeRange={selectedArrivalTimeRange}
              setSelectedArrivalTimeRange={setSelectedArrivalTimeRange}
              checkboxOneStopRef={checkboxOneStopRef}
              checkboxDirectRef={checkboxDirectRef}
              checkboxManyRef={checkboxManyRef}
              checkboxCabinAndCheckInBaggageRef={checkboxCabinAndCheckInBaggageRef}
              airlinesDivHolderRef={airlinesDivHolderRef}
              selectedStops={selectedStops}
              setSelectedStops={setSelectedStops}
              setSelectCabinAndCheckInBaggage={setSelectCabinAndCheckInBaggage}
              selectedCabinAndCheckInBaggage={selectedCabinAndCheckInBaggage}
              airlineList={airlineList}
              showAllAirlines={showAllAirlines}
              selectedAirlines={selectedAirlines}
              setSelectedAirlines={setSelectedAirlines}
              handleShowAirlines={handleShowAirlines}
            />
          ) : (
            <FlightFiltersRoundTrip
              selectedReturnDepartureTimeRange={selectedReturnDepartureTimeRange}
              setSelectedReturnDepartureTimeRange={setSelectedReturnDepartureTimeRange}
              setIsFilterChanged={setIsFilterChanged}
              selectedReturnArrivalTimeRange={selectedReturnArrivalTimeRange}
              setSelectedReturnArrivalTimeRange={setSelectedReturnArrivalTimeRange}
              checkboxOneStopRef={checkboxOneStopRef}
              checkboxDirectRef={checkboxDirectRef}
              checkboxManyRef={checkboxManyRef}
              checkboxCabinAndCheckInBaggageRef={checkboxCabinAndCheckInBaggageRef}
              airlinesDivHolderRef={airlinesDivHolderRef}
              selectedReturnStops={selectedReturnStops}
              setSelectedReturnStops={setSelectedReturnStops}
              setSelectedReturnCabinAndCheckInBaggage={setSelectedReturnCabinAndCheckInBaggage}
              selectedReturnCabinAndCheckInBaggage={selectedReturnCabinAndCheckInBaggage}
              returnAirlineList={airlineList} // used the airlineList for the return flight because it's the same as the departure
              showAllAirlines={showAllAirlines}
              selectedReturnAirlines={selectedReturnAirlines}
              setSelectedReturnAirlines={setSelectedReturnAirlines}
              // handleCheckboxChange={handleCheckboxChange}
              handleShowAirlines={handleShowAirlines}
            />
          )}

          <div className={`bottom-gradient ${isGradientVisible ? 'show' : ''}`}></div>
          <div className={`big-tooltip absolute top-0 right-[-199px] flex flex-col z-30 ${showTooltip ? 'show' : ''}`}>
            <p>
              Click here to apply your <br /> flight search filters!
            </p>
            <GalagoButton label="Got it!" className="btn-tooltip" onClick={(): void => setShowTooltip(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}
