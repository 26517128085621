import { SvgProps } from './SvgProps';

export const FacebookSvg = ({ _width = 12, _height = 13, _color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 21 20" fill="none">
      <path
        d="M12.1615 11.2484H14.2448L15.0781 7.91504H12.1615V6.24837C12.1615 5.39004 12.1615 4.58171 13.8281 4.58171H15.0781V1.78171C14.8065 1.74587 13.7806 1.66504 12.6973 1.66504C10.4348 1.66504 8.82812 3.04587 8.82812 5.58171V7.91504H6.32812V11.2484H8.82812V18.3317H12.1615V11.2484Z"
        fill={_color}
      />
    </svg>
  );
};
