import React, { useEffect, useRef, useState } from 'react';
import '../../../resources/css/hotel-available-rooms-card.scss';
import { CheckSvg } from '../../../resources/svg/CheckSvg';
import { PersonSvg } from '../../../resources/svg/PersonSvg';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { InfoCircle } from '../../../resources/svg/InfoSvg';
import CancellationPolicyHooks from '../../../hooks/CancellationPolicyHooks';
import { GalagoButton } from '../../../components/Button';
import FilterCheckbox from '../../../components/ui/checkbox-filter';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HotelRoomsPrebookingHooks from '../../../hooks/HotelRoomsPrebookingHooks';
import { HotelRoomFiltersStore } from '../../../store/HotelRoomFiltersStore';
import HotelAmenitiesModal from './HotelAmenitiesModal';
import { ErrorGalagoHotel } from '../../../components/ErrorGalagoPage';
import { AnimatedGalagoHotelLoading } from '../../../components/AnimatedGalagoLoading';
import { CancellationPolicy } from '../../../components/CancellationPolicy';

type AvailableRoomsCardGroupProps = {
  rooms: Array<{
    BookingCode: string;
    Name: string[];
    TotalFare: number;
    TotalFareWithMarkUp: number;
    IsRefundable: boolean;
    CancelPolicies: { [index: string]: any }[];
    DayRates: { [index: string]: any }[];
    Adults: number;
    Children: number;
  }>;
  nights: number;
  hotelInfo: { [index: string]: any };
  facilitiesData: { [index: string]: any };
  Currency?: string;
  setSelectedHotelRooms: (arg: { [index: string]: any }) => void;
  selectedHotelRooms: { [index: string]: any };
  roomCount: number;
  maxRoomNeeded: number;
};

const AvailableRoomsCardGroup = ({ Currency, rooms, maxRoomNeeded, nights, hotelInfo, facilitiesData, setSelectedHotelRooms, selectedHotelRooms, roomCount }: AvailableRoomsCardGroupProps) => {
  const navigate = useNavigate();
  const urlData = useLocation();
  const [searchParams] = useSearchParams();
  const { selectedBedPreferences, setSelectedBedPreferences, selectedFacilities, setSelectedFacilities, selectedMealType, setSelectedMealType } = HotelRoomFiltersStore();
  const { cancellationPolicy2 } = CancellationPolicyHooks();
  const [showAmenitiesModal, setShowAmenitiesModal] = useState(false);
  const [selectedShowAmenities, setSelectedShowAmenities] = useState([]);

  //HotelRoomsPreebookingHook
  const { roomsPrebooking, roomsPrebookingResult } = HotelRoomsPrebookingHooks();
  // let { images } = hotelInfo;
  // const roomImage = images[0];
  const [isBedPreferencesOpen, setIsBedPreferencesOpen] = useState(false);
  const [isMealTypeOpen, setIsMealTypeOpen] = useState(false);
  const [isFacilitiesOpen, setIsFacilitiesOpen] = useState(false);
  const cache = roomsPrebookingResult?.cacheKey;
  const bookingCodes = searchParams.get('roomCodes');
  let bookingCodesArray = bookingCodes?.split(',');
  let getCacheKey = searchParams.get('filterKey');
  let getBedPreferences = searchParams.get('bedPreferences');
  let getMealInclusions = searchParams.get('mealInclusions');
  let getRoomFacilities = searchParams.get('roomFacilities');
  console.log(roomsPrebookingResult?.data.tbo);
  const roomsData = roomsPrebookingResult?.data?.tbo ? roomsPrebookingResult.data.tbo.map((prebookRoom: { BookingCode: string; }) => {
    const matchingRoom = rooms.find((room) => room.BookingCode === prebookRoom.BookingCode);
    if (matchingRoom) {
      return { ...prebookRoom, Adults: matchingRoom.Adults, Children: matchingRoom.Children };
    }
    return prebookRoom;
  }) : [];

  React.useEffect(() => {
    if (getBedPreferences) setSelectedBedPreferences(getBedPreferences?.split(',') as string[]);
    if (getRoomFacilities) setSelectedFacilities(getRoomFacilities?.split(',') as string[]);
    if (getMealInclusions) setSelectedMealType(getMealInclusions?.split(',') as string[]);
    // eslint-disable-next-line
  }, []);

  let urlGetParams = '';
  urlGetParams += `?roomCodes=${bookingCodes}`;
  const filters = roomsPrebookingResult?.filters;

  useEffect(() => {
    let payload: {
      filterKey: string | null;
      bookingCodes: string[];
      bedPreferences?: string[];
      mealInclusions?: string[];
      roomFacilities?: string[];
    } = {
      filterKey: getCacheKey,
      bookingCodes: bookingCodesArray as string[],
    };

    if (getBedPreferences) payload.bedPreferences = getBedPreferences?.split(',');
    if (getMealInclusions) payload.mealInclusions = getMealInclusions?.split(',');
    if (getRoomFacilities) payload.roomFacilities = getRoomFacilities?.split(',');

    roomsPrebooking(payload);
    // eslint-disable-next-line
  }, [searchParams]);

  const handleBedPreferencesClick = () => {
    setIsBedPreferencesOpen(!isBedPreferencesOpen);
  };

  const handleMealTypeClick = () => {
    setIsMealTypeOpen(!isMealTypeOpen);
  };

  const handleFacilitiesClick = () => {
    setIsFacilitiesOpen(!isFacilitiesOpen);
  };

  const bedDropdownRef = useRef<HTMLDivElement>(null);
  const mealDropdownRef = useRef<HTMLDivElement>(null);
  const roomDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bedDropdownRef.current && !bedDropdownRef.current.contains(event.target as Node)) {
        setIsBedPreferencesOpen(false);
      }
      if (mealDropdownRef.current && !mealDropdownRef.current.contains(event.target as Node)) {
        setIsMealTypeOpen(false);
      }
      if (roomDropdownRef.current && !roomDropdownRef.current.contains(event.target as Node)) {
        setIsFacilitiesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [bedDropdownRef, mealDropdownRef, roomDropdownRef]);

  // Room Price ToolTip
  // const [IsRoomPriceTooltipVisible, setIsRoomPriceTooltipVisible] = useState(false);

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const handleRoomPriceEnter = (index: number) => {
    setHoveredRow(index);
  };

  const handleRoomPriceLeave = () => {
    setHoveredRow(null);
  };

  console.log('roomsData', roomsData);
  console.log('rooms', rooms);
  return (
    <div>
      <div className="flex flex-column mb-10 gap-1">
        <h3 className="p-0 text-2xl satoshi-medium font-semibold textColor m-0">Available Rooms</h3>
        <p className="p-0 text-secondary text-base generalsans-medium m-0">Prices might change. You'll see the final price on the next page.</p>
      </div>

      <div className="room-filters">
        <div className={isBedPreferencesOpen ? 'bed-preferences-filters active' : 'bed-preferences-filters'} onClick={handleBedPreferencesClick}>
          <p>Bed Preferences</p>
        </div>

        {isBedPreferencesOpen ? (
          <section ref={bedDropdownRef} className="bed-preferences-dropdown text-[#333333]">
            {filters?.bedPreferences.map((bedPreferences: { [index: string]: any }, index: React.Key) => (
              <FilterCheckbox
                key={index}
                id={`${bedPreferences}`}
                label={`${bedPreferences}`}
                value={`${bedPreferences}`}
                defaultChecked={selectedBedPreferences.includes(bedPreferences.toString())}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const isChecked = event.target.checked;
                  const bedPreferenceValue = event.target.value;
                  if (!isChecked) {
                    setSelectedBedPreferences([bedPreferenceValue], true);
                  }
                  if (!selectedBedPreferences.includes(bedPreferenceValue) && isChecked) {
                    setSelectedBedPreferences([...selectedBedPreferences, bedPreferenceValue]);
                  }
                }}
              />
            ))}
          </section>
        ) : null}

        <div className={isMealTypeOpen ? 'meal-type-filters active' : 'meal-type-filters'} onClick={handleMealTypeClick}>
          Meal Type
        </div>

        {isMealTypeOpen ? (
          <section ref={mealDropdownRef} className="meal-type-dropdown text-[#333333]">
            {filters?.mealInclusions.map((item: string, key: React.Key) => (
              <FilterCheckbox
                key={key}
                label={item}
                id={item}
                defaultValue={item}
                defaultChecked={selectedMealType.includes(item.toString())}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const isChecked = e.target.checked;
                  const value = e.target.value;
                  // if checked add to selectedMealType if not remove from selectedMealType
                  if (!isChecked) {
                    setSelectedMealType([value], true);
                  }
                  if (!selectedMealType.includes(value) && isChecked) {
                    setSelectedMealType([...selectedMealType, value]);
                  }
                }}
              />
            ))}
          </section>
        ) : null}

        <div className={isFacilitiesOpen ? 'facilities-filters active' : 'facilities-filters'} onClick={handleFacilitiesClick}>
          Room Facilities & Services
        </div>

        {isFacilitiesOpen ? (
          <section ref={roomDropdownRef} className="facilities-dropdown text-[#333333]">
            {filters?.roomFacilities.map((item: string, key: React.Key) => (
              <FilterCheckbox
                key={key}
                label={item}
                id={item}
                defaultValue={item}
                defaultChecked={selectedFacilities.includes(item.toString())}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const isChecked = e.target.checked;
                  const value = e.target.value;
                  // if checked add to selectedFacilities if not remove from selectedFacilities
                  if (!isChecked) {
                    setSelectedFacilities([value], true);
                  }
                  if (!selectedFacilities.includes(value) && isChecked) {
                    setSelectedFacilities([...selectedFacilities, value]);
                  }
                }}
              />
            ))}
          </section>
        ) : null}

        <div>
          <GalagoButton
            className="text-xs"
            label="Apply Filter"
            onClick={() => {
              urlGetParams += `&filterKey=${cache}`;
              if (selectedBedPreferences) {
                urlGetParams += `&bedPreferences=${selectedBedPreferences.join(',')}`;
              }
              if (selectedMealType) {
                urlGetParams += `&mealInclusions=${selectedMealType.join(',')}`;
              }
              if (selectedFacilities) {
                urlGetParams += `&roomFacilities=${selectedFacilities.join(',')}`;
              }

              let url = `/hotel/profile${urlGetParams}`;

              const { hotel, nights, searchCriteria } = urlData.state.payload;

              const navigateData = {
                state: {
                  payload: {
                    hotel,
                    nights,
                    bookingDetails: searchCriteria,
                    searchCriteria: searchCriteria,
                  },
                },
              };

              navigate(url, navigateData);
            }}
          />
        </div>
      </div>

      <table className="w-100 rounded-tl-xl table-room-card">
        <tr className="available-rooms-title">
          <td className="w-[25%] rounded-tl-xl">Room Type</td>
          <td className="w-[20%]">Guests</td>
          <td className="w-25">Your choices</td>
          <td className="w-30 rounded-tr-xl">Price for 1 Night</td>
        </tr>
      </table>

      {/* if no room is available show this table */}
      {roomsData?.length === 0 && (
        <table className="w-100 bg-[#F9F9F9] table-room-card">
          <tr>
            <td colSpan={4} className="text-center">
              <ErrorGalagoHotel title={'No rooms available, please check another hotel'} content={''} show={true} />
            </td>
          </tr>
        </table>
      )}
      <HotelAmenitiesModal show={showAmenitiesModal} onHide={() => setShowAmenitiesModal(false)} amenities={selectedShowAmenities} />
      {roomsData ? (
        roomsData?.map((room: { [index: string]: any }, index: React.Key) => {
          let rowClass = Number(index) % 2 === 0 ? 'even-row' : 'odd-row';
          // let cancellationPolicies = room.CancelPolicies;

          // TODO: double check this function where we are computing the cancellation policy
          // const cancellationPolicyResult = cancellationPolicy2({ cancellationObject: cancellationPolicies, selectedRooms: rooms });
          const inclusionsArray = room.Inclusion.toLowerCase()
            .replace(/\b\w/g, (match: string) => match.toUpperCase())
            .split(',');

          // search the word smoking the ne room.Name and remove it
          let roomName = room.Name.toString().replace(/nonsmoking/i, '');
          // split roomName with , and remove empty strings
          let newRoomName = roomName.split(',').filter(Boolean).toString().replace(', ', ' - ');

          let basePrice = room.DayRates[0][0].BasePrice;

          return (
            <>
              <table className={`w-100 ${rowClass} table-room-card`} key={index}>
                <tr className="available-rooms-content">
                  <td className="rooms w-25">
                    <p>{newRoomName}</p>

                    <ul className="pl-0" data-id={room.BookingCode}>
                      {room.Amenities.map((amenity: string[], amenityIndex: React.Key) => {
                        if (Number(amenityIndex) > 4) return null;
                        return (
                          <>
                            <li key={amenityIndex}>
                              <CheckSvg width={16} _height={16} _color="#016e7f" /> {amenity}
                            </li>
                          </>
                        );
                      })}
                    </ul>

                    <GalagoButton
                      label="More"
                      btn="link"
                      className="w-max !p-0 no-underline mt-1"
                      onClick={() => {
                        setShowAmenitiesModal(true);
                        // To prevent the modal from looping, add the selected room amenities to state and pass it to the modal.
                        setSelectedShowAmenities(room.Amenities);
                      }}
                    />
                  </td>

                  <td className="w-[20%]">
                    <div className="sleeps">
                      <PersonSvg />
                      <span className="text-base font-medium">{room.Adults + room.Children > 1 && <span> x {room.Adults + room.Children}</span>}</span>
                    </div>
                  </td>

                  <td className="w-25">
                    <div className="choice">
                      <ul className="pl-[17px]">
                        {inclusionsArray.map((inclusion: string, inclusionIndex: React.Key) => (
                          <>
                            <li className="text-primary-primary-100 mb-2 list-disc " key={inclusionIndex}>
                              {inclusion}
                            </li>
                          </>
                        ))}
                        <li className=" list-disc">
                          <CancellationPolicy room={room} />
                        </li>
                      </ul>
                      {/* {cancellationPolicyResult} */}
                    </div>
                  </td>

                  <td className="w-30 position-relative">
                    <div className="price ">
                      <div className="fees ">
                        <p className="font-semibold text-base flex items-center justify-end ">
                          {Currency}&nbsp;<span>{formatPriceNoRound(basePrice)}</span>
                          <div className="ml-1 cursor-pointer" onMouseEnter={() => handleRoomPriceEnter(+index)} onMouseLeave={handleRoomPriceLeave}>
                            <InfoCircle _height={20} _width={20} style={{ marginBottom: '10px' }} />
                          </div>
                        </p>

                        {hoveredRow === index && (
                          <div className="room-price-tooltip">
                            <div className="room-price-row">
                              <div className="text-left">
                                <p>Total Room Price</p>
                                <p id="night-small">
                                  {' '}
                                  ({` for ${nights} `} {nights === 1 ? 'night ' : 'nights '})
                                </p>
                              </div>

                              <div>
                                <span>
                                  {Currency} {formatPriceNoRound(room.TotalFare - room.TotalTax)}
                                </span>
                              </div>
                            </div>
                            <div>
                              {room.DayRates[0].map((value: { BasePrice: number }, key: React.Key) => {
                                const nightNumber = Number(key) + 1;
                                return (
                                  <div className="room-price-row">
                                    <p>Night {nightNumber}</p>
                                    <p>
                                      <span>PHP</span> {formatPriceNoRound(value.BasePrice)}
                                    </p>
                                  </div>
                                );
                              })}
                              <div></div>
                            </div>

                            <div className="room-price-row text-text-colors-text-secondary">
                              <div>
                                <p>Taxes and charges</p>
                              </div>
                              <div>
                                <span>
                                  {Currency} {formatPriceNoRound(room.TotalTax)}
                                </span>
                              </div>
                            </div>

                            <div className="total-price">
                              <p className="font-semibold">Total</p>
                              <p>
                                {Currency} {formatPriceNoRound(room.TotalFare)}
                              </p>
                            </div>
                          </div>
                        )}
                        <p className="taxes-and-fees">
                          {Currency} <span className=""> {formatPriceNoRound(room.TotalFare)} </span>with Taxes and Fees
                        </p>
                      </div>

                      <GalagoButton
                        size="sm"
                        className="!w-[100px] reserve-btn"
                        label={'Reserve'}
                        // Only disable other buttons when the selected count is equal to the room count
                        disabled={roomCount === selectedHotelRooms.length && !selectedHotelRooms.find((xRoom: { BookingCode: string }) => xRoom.BookingCode === room.BookingCode)}
                        onClick={() => {
                          if (roomCount === selectedHotelRooms.length) return;
                          //  Prevent the user from clicking the selected button again
                          if (!selectedHotelRooms.includes(room)) {
                            setSelectedHotelRooms(room);
                          }
                        }}
                        // Set the button color when the button is clicked
                        style={selectedHotelRooms.find((xRoom: { BookingCode: string }) => xRoom.BookingCode === room.BookingCode) && { backgroundColor: '#016372' }}
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </>
          );
        })
      ) : (
        <table className="w-100 bg-[#F9F9F9] table-room-card">
          {' '}
          <tr>
            <td colSpan={4} className="text-center">
              <AnimatedGalagoHotelLoading loadingText="Checking room's availability..." />{' '}
            </td>{' '}
          </tr>
        </table>
      )}
      <br />

      {/* {rooms
        ? rooms.map((room: { [index: string]: any }, index) => {
            const foundInSelection = selectedHotelRooms.find((xRoom: { BookingCode: string }) => xRoom.BookingCode === room.BookingCode);
            const isSelected = foundInSelection ? true : false;
            const disableButton = selectedHotelRooms.length >= roomCount ? true : false;
            return (
              <HotelCardContainer
                setSelectedHotelRooms={setSelectedHotelRooms}
                isSelected={isSelected}
                Currency={Currency}
                key={index}
                hotelInfo={hotelInfo}
                facilitiesData={facilitiesData}
                roomImage={roomImage}
                room={room}
                nights={nights}
                disableButton={disableButton}
              />
            );
          })
        : null} */}
    </div>
  );
};

export default AvailableRoomsCardGroup;
