import { SvgProps } from './SvgProps';

export const UploadPlaceholderSvg = ({ _width = 64, _height = 64, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 64 64" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.33398 13.3333C5.33398 11.2115 6.17684 9.17669 7.67713 7.6764C9.17742 6.17611 11.2123 5.33325 13.334 5.33325H50.6673C52.789 5.33325 54.8239 6.17611 56.3242 7.6764C57.8245 9.17669 58.6673 11.2115 58.6673 13.3333V30.6666C58.6671 30.8915 58.6384 31.1155 58.582 31.3333C58.6384 31.551 58.6671 31.775 58.6673 31.9999V50.6666C58.6673 52.7883 57.8245 54.8231 56.3242 56.3234C54.8239 57.8237 52.789 58.6666 50.6673 58.6666H13.334C11.2123 58.6666 9.17742 57.8237 7.67713 56.3234C6.17684 54.8231 5.33398 52.7883 5.33398 50.6666V42.6666C5.3342 42.4417 5.36287 42.2177 5.41932 41.9999C5.36293 41.7822 5.33426 41.5582 5.33398 41.3332V13.3333ZM13.318 39.5466C12.39 39.5626 11.5047 39.6053 10.6673 39.6693V13.3333C10.6673 12.626 10.9483 11.9477 11.4484 11.4476C11.9485 10.9475 12.6267 10.6666 13.334 10.6666H50.6673C51.3746 10.6666 52.0528 10.9475 52.5529 11.4476C53.053 11.9477 53.334 12.626 53.334 13.3333V29.3759C41.8753 29.7466 33.6006 32.5066 27.7873 36.3706C29.99 37.1519 32.454 38.2639 34.87 39.7199C38.6007 41.9599 42.3927 45.1066 44.9446 49.2746C45.1274 49.5733 45.2496 49.9051 45.3042 50.251C45.3588 50.597 45.3447 50.9502 45.2627 51.2907C45.1807 51.6312 45.0325 51.9522 44.8265 52.2354C44.6205 52.5186 44.3607 52.7584 44.062 52.9412C43.7633 53.1241 43.4315 53.2462 43.0855 53.3008C42.7396 53.3554 42.3863 53.3413 42.0458 53.2593C41.7054 53.1773 41.3844 53.0291 41.1012 52.8231C40.818 52.6171 40.5781 52.3573 40.3953 52.0586C38.4326 48.8506 35.3873 46.2506 32.1233 44.2906C28.8646 42.3332 25.55 41.1119 23.2513 40.5786C20.0132 39.8391 16.6976 39.4935 13.3766 39.5493H13.318V39.5466ZM18.6087 17.3013C19.7972 16.4638 21.2134 16.0098 22.6673 15.9999C23.7607 15.9999 25.342 16.3066 26.726 17.3013C28.246 18.3946 29.334 20.1973 29.334 22.6666C29.334 25.1359 28.246 26.9359 26.726 28.0292C25.5377 28.8676 24.1215 29.3226 22.6673 29.3333C21.574 29.3333 19.9927 29.0266 18.6087 28.0292C17.0887 26.9359 16.0007 25.1386 16.0007 22.6666C16.0007 20.1973 17.0887 18.3973 18.6087 17.3013Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
