import { create } from 'zustand';

export const AnimatedLogoLoadingStore = create((set: any) => ({
  showAnimatedLogoLoading: false,
  setShowAnimatedLogoLoading: (isShow: boolean) => {
    set((state: { showAnimatedLogoLoading: boolean }) => ({
      showAnimatedLogoLoading: isShow,
    }));
  },
  animatedLogoText: '',
  setAnimatedLogoText: (text: string) => {
    set((state: { animatedLogoText: string }) => ({
      animatedLogoText: text,
    }));
  },
}));
