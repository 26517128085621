import React, { Key } from 'react';
import { SelectedFlightInfo } from './SelectedFlightInfo';
import { AnimatedGalagoLoading } from '../../../components/AnimatedGalagoLoading';
import Skeleton from 'react-loading-skeleton';
import { OneWayFlightList } from './OneWayFlightList';
import '../../../resources/css/search-filter-flights.scss';
import SelectedFlightLabel from './SelectedFlightLabel';
import { ErrorGalagoPage } from '../../../components/ErrorGalagoPage';
import FlightFilters from './FlightFilters';

type OneWayFlightListType = {
  fromCityName: string;
  fromAirportCode: string;
  toCityName: string;
  toAirportCode: string;
  selectedFlight: { [index: string]: any } | null | undefined;
  setSelectedFlight: any;
  flights: { [index: string]: any } | null | undefined;
  // viewFlightInfo?: () => void;
  searchParams: { [index: string]: any };
  // setSortingFilter?: (value: string) => void;
};

export const OneWayFlight = ({
  fromCityName,
  fromAirportCode,
  toCityName,
  toAirportCode,
  selectedFlight,
  setSelectedFlight,
  flights,
  // viewFlightInfo,
  // setSortingFilter,
  searchParams,
}: OneWayFlightListType) => {
  // const title = 'Uh-oh! No results found';
  // const content = "Unfortunately, we couldn't find any flights that fit your preferences. You may want to try modifying your search.";

  try {
    return (
      <>
        <div className="container mt-[4rem] mb-2  p-0 flex">
          {/* arrival flights will show if the flight type is one way and round trip */}
          {/* {selectedFlight && (
            <div>
              <SelectedFlightLabel
                selectedFlight={selectedFlight}
                fromCityName={fromCityName}
                fromAirportCode={fromAirportCode}
                toCityName={toCityName}
                toAirportCode={toAirportCode}
                searchParams={searchParams}
              />
              <SelectedFlightInfo id="selected-departure-info" flightInfo={selectedFlight} changeFlight={() => setSelectedFlight(undefined)} />
            </div>
          )} */}
          {!selectedFlight && (
            <div className="search-container position-sticky z-10">
              <FlightFilters FlightType="one-way" SearchParams={searchParams} />
            </div>
          )}
          <div className="container pt-3 mb-[4rem] flex gap-4">
            <div className="flight-title flex flex-col w-100">
              <SelectedFlightLabel
                selectedFlight={selectedFlight}
                fromCityName={fromCityName}
                fromAirportCode={fromAirportCode}
                toCityName={toCityName}
                toAirportCode={toAirportCode}
                resultsCount={flights?.length}
                searchParams={searchParams}
              />

              {selectedFlight && (
                <>
                  <SelectedFlightInfo FlightType="one-way" id="selected-departure-info" flightInfo={selectedFlight} changeFlight={() => setSelectedFlight(undefined)} />
                </>
              )}

              {selectedFlight ? null : (
                <div className="row flight-result-list">
                  {!flights || flights === null ? (
                    <>
                      <div className="mt-2">
                        <AnimatedGalagoLoading show={true} loadingText="Searching Flights. Please Wait..." />
                      </div>
                      <Skeleton count={10} height={100} />
                    </>
                  ) : (
                    // <Container className="flight-filter-result-container flex gap-4 w-100 my-[4rem]">
                    <>
                      <div className="w-100 mt-3">
                        <div className="flight-title">
                          <div className="flight-result-list ">
                            {flights?.map((info: { [index: string]: any }, key: Key) => {
                              return <OneWayFlightList info={info} key={info.FareSourceCode} />;
                            })}
                          </div>
                        </div>
                      </div>
                      <ErrorGalagoPage show={!flights?.length} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    return <></>;
  }
};
