import React, { useState } from 'react';
import Offers from './Offers';
import { CheckSvg } from '../../../resources/svg/CheckSvg';
import { InfoCircle } from '../../../resources/svg/InfoSvg';
import HotelAmenitiesModal from './HotelAmenitiesModal';
import { GalagoButton } from '../../../components/Button';

type HotelFacilitiesRcolProps = { hotelFacilities: string[] };

const HotelFacilitiesRcol = ({ hotelFacilities }: HotelFacilitiesRcolProps) => {
  let hotelFacilityHolder: string[] = [];
  const offers: React.ReactElement[] = [];
  const [showAmenitiesModal, setShowAmenitiesModal] = useState(false);

  if (hotelFacilities) {
    for (let [index, hotelFacility] of hotelFacilities.entries()) {
      if (index > 14) break;
      hotelFacilityHolder.push(hotelFacility);
      if ((index + 1) % 5 === 0) {
        offers.push(<Offers key={index} hotelFacilityHolder={hotelFacilityHolder} />);
        hotelFacilityHolder = [];
      }
    }
  }

  return (
    <>
      <div className="py-[40px]">
        <div className="relative font-semibold flex items-center w-[28.38rem] mb-[20px]">This property offers</div>

        <div className="flex bg-[#D0F0EF] w-max items-center justify-center gap-1 py-[6px] px-[4px] rounded-xl">
          <InfoCircle _color="#016E7F" />
          <p className="font-semibold" style={{ color: '#016E7F', fontSize: '13px' }}>
            Facilities and Amenities are subject to availability and may be chargeable as per the hotel policy
          </p>
        </div>
        {/* <div className="self-stretch flex flex-col items-start justify-start gap-[2rem] text-[0.81rem]"> */}
        {/* <div className="flex flex-row items-start self-stretch justify-between">{offers}</div> */}
        <div className="align-items-center w-100 mt-3" style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', justifyContent: ' space-between' }}>
          <HotelAmenitiesModal show={showAmenitiesModal} onHide={() => setShowAmenitiesModal(false)} amenities={hotelFacilities} />
          {hotelFacilities.map((facility, index) => {
            if (index > 7) return null;
            return (
              <div className="d-flex flex-row align-items-center py-[5px]" key={index}>
                <div className="p-2">
                  <CheckSvg _color="#016E7F" _width={24} _height={24} />
                </div>
                <div className="text-medium font-medium">{facility}</div>
              </div>
            );
          })}

          {hotelFacilities.length > 8 && <GalagoButton label="See More" btn="link" className="w-max !p-0  mt-3" onClick={() => setShowAmenitiesModal(true)} />}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default HotelFacilitiesRcol;
