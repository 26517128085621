import moment from 'moment';
import React from 'react';
import { ChevronRightSvg, ChevronRoundTrip } from '../../resources/svg/ChevronSvg';
import { titleCase } from '../../utils/data.utils';

const FlightListHistory = ({ data }: { [index: string]: any }) => {
  let flightType = data.flightType as string;
  flightType = flightType.toLowerCase();
  const url = `#/history/flight?id=${data.transactionId}`;
  return (
    <a href={url} className="history">
      <div>
        <h6>
          {data.originCity} ({data.originCode}) {flightType === 'roundtrip' ? <ChevronRoundTrip /> : <ChevronRightSvg />} {data.destinationCity} ({data.destinationCode})
        </h6>
        <span>
          Departure: {moment(data.departureDate).format('MMMM D')}
          {flightType === 'roundtrip' ? ` - Return ${moment(data.returnDate).format('MMMM D')}` : ''}
        </span>
      </div>
      <div>
        <span className={`${data.type} fw-bold`}>{titleCase(data.type)}</span>
        <span className={`${flightType} fw-bold`}>{flightType === 'oneway' ? 'One Way Flight' : 'Round Trip Flight'}</span>
        <span className={`passengers fw-bold`}>
          {data.passengersTotal} Passenger{data.passengersTotal > 1 ? 's' : ''}
        </span>
      </div>
    </a>
  );
};

export default FlightListHistory;
