import React from 'react';
// import { New } from '../../resources/svg/NewSvg';
// import { TrendFlame } from '../../resources/svg/TrendFlameSvg';
import { Link } from 'react-router-dom';
import { ArrowLongRight } from '../../resources/svg/ArrowLongRightSvg';






// import '../../resources/css/blog.scss';
// import plane from '../../resources/images/plane.png';
// import plane2 from '../../resources/images/plane2.png';
// import bohol from '../../resources/images/bohol.png';
// import { ASSETS_API } from '../../utils/apiRoutes';

// const blogs = [
//   {
//     title: 'asdas',
//     tags: [
//       {
//         title: 'bohol',
//         color: '#016e7f',
//         icon: '',
//       },
//     ],
//   },
// ];

// {blogs.map((blog: any)=>{
//   return(
//     <p>{blog.tags.map((tag: any)=>{
//       {
//         tag.icon ? <span> <img src={tag.icon} alt="broken wala kang jowa" />
//         </span> : null
//       }
//       {tag.title}

//     })}</p>
//   )
// })}

const FeaturedBlogs = () => {
  // const imagesUrl = ASSETS_API().resources.blogs;
  // `${imagesUrl}/plane2.png`
  return (
    <>
      {/* First Blog */}
      <Link to="/a-guide-to-ethical-and-sustainable-tourism-in-the-philippines" style={{ textDecoration: 'none', color: '#3333333' }}>
        <div className="featured-blog-cards">
          <img
            src={'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/A+guide+to+Ethical.jpg'}
            alt="Avatar"
            style={{ width: '100%', borderRadius: '20px' }}
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />

          <div className="container">
            <p className="text-secondary p-0 m-0">December 6, 2023 · 7 min read</p>
            <p className="blog-title general">A Guide to Ethical and Sustainable Tourism in the Philippines</p>
            <p className="readArticle">
              <span>Read article</span><ArrowLongRight _color="#016e7f" />
            </p>
          </div>
        </div>
      </Link>


      {/* Second Blog */}
      <Link to="/travelers-crash-course-on-filipino-customs,-traditions,-and-cultural-etiquette" style={{ textDecoration: 'none', color: '#000000' }}>
        <div className="featured-blog-cards">
          <img
            src={'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Mindful+Traveler%E2%80%99s+Guide+to+Filipino+Customs+and+Traditions.jpg'}
            alt="Avatar"
            style={{ width: '100%', borderRadius: '20px' }}
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          
          <div className="container">
            <p className="text-secondary p-0 m-0">December 6, 2023 · 6 min read</p>
            <p className="blog-title general">Traveler's Crash Course on Filipino Customs, Traditions, and Cultural Etiquette</p>
            <p className="readArticle">
              <span>Read article</span><ArrowLongRight _color="#016e7f" />
            </p>
          </div>
        </div>
      </Link>


      {/* Third Blog */}
      <Link to="/gadgets-and-travel-apps-every-taveler-should-have" style={{ textDecoration: 'none', color: '#000000' }}>
        <div className="featured-blog-cards">
          <img
            src={'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Gadgets+and+Smartphone+Apps+Every+Traveler+Should+Have.jpg'}
            alt="Avatar"
            style={{ width: '100%', borderRadius: '20px' }}
          />
          
          <div className="container">
            <p className="text-secondary p-0 m-0">November 16, 2023 · 6 min read</p>
            <p className="blog-title general">Gadgets and Travel Apps Every Traveler Should Have</p>
            <p className="readArticle">
              <span>Read article</span><ArrowLongRight _color="#016e7f" />
            </p>
          </div>
        </div>
      </Link>

      {/* <Link to="/philippines-road-trip-guide:-destinations-you-can-reach-by-car" style={{ textDecoration: 'none', color: '#000000' }}>
        <div className="">
          <img
            src={'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Philippines+road+trip+guide_+Destinations+you+can+reach+by+car.jpg'}
            alt="Avatar"
            style={{ width: '100%' }}
          />
          <div className="tags container py-3 d-flex gap-2 ">
            <div className="tags travel_guide">Travel Guide</div>
            <div className="trend tags d-flex align-items-center gap-1">
              <TrendFlame _width={20} _height={20} />
              Trend
            </div>
          </div>
          <div className="container">
            <p className="text-secondary p-0 m-0">November 14, 2023 · 7 min read</p>
            <p className="blog-title general">Philippines Road Trip Guide: Destinations You Can Reach by Car</p>
            <p className="readArticle">
              Read article <ArrowRightSvg _color="#016e7f" />
            </p>
          </div>
        </div>
      </Link>

      <Link to="/beat-the-heat!-explore-the-coldest-places-in-the-philippines" style={{ textDecoration: 'none', color: '#000000' }}>
        <div className="">
          <img
            src={'https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Beat+the+heat_+Cool+climate+destinations+in+the+Philippines.jpg'}
            alt="Avatar"
            style={{ width: '100%' }}
          />
          <div className="tags container py-3 d-flex gap-2 ">
            <div className="tags travel_guide">Travel Guide</div>
          </div>
          <div className="container">
            <p className="text-secondary p-0 m-0">November 14, 2023 · 6 min read</p>
            <p className="blog-title general">Beat the Heat! Explore the Coldest Places in the Philippines</p>
            <p className="readArticle">
              Read article <ArrowRightSvg _color="#016e7f" />
            </p>
          </div>
        </div>
      </Link> */}
    </>
  );
};

export default FeaturedBlogs;
