import axios from 'axios';
import moment from 'moment';
import { timeForHumans } from '../utils/time.utils';
import { numberDecimal } from '../utils/data.utils';

export const FlightResultsHook = () => {
  // const flightType = () => {};

  const foundFlights = async (_url: string, payload: { [index: string]: any }) => {
    axios.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    try {
      const response = await axios.request({
        method: 'get',
        url: _url,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const flightCabinType = (cabin: string, flights: { [index: string]: any }) => {
    let _flights = [];
    let _cabin = cabin.toLowerCase();
    // console.log(_cabin);
    switch (_cabin) {
      case 'economy':
        _flights = flights.economy;
        break;
      case 'business':
        _flights = flights.business;
        break;
      case 'first':
      case 'first class':
        _flights = flights.first;
        break;
      case 'premium economy':
      case 'premiumeconomy':
        _flights = flights.premiumEconomy;
        break;
      default:
        _flights = [];
        break;
    }
    return _flights;
  };

  /**
   *
   * @param OriginDestinations object
   * @returns object
   */
  const flightSegments = (OriginDestinations: { [index: string]: any }) => {
    if (OriginDestinations === undefined) return {};
    // console.log('ljasdflkj');
    const firstItemDestination = OriginDestinations?.[0];
    const lastItemDestination = OriginDestinations?.slice(-1)[0];

    const carrierCode = firstItemDestination?.FlightSegment?.OperatingCarrierCode;
    const flightNumber = firstItemDestination?.FlightSegment?.OperatingFlightNumber;

    const MarketingCarriercode = firstItemDestination?.FlightSegment?.MarketingCarriercode;
    const MarketingFlightNumber = firstItemDestination?.FlightSegment?.MarketingFlightNumber;

    // departure location
    const departureCode = firstItemDestination?.FlightSegment.DepartureAirportLocationCode;
    const arrivalCode = lastItemDestination?.FlightSegment.ArrivalAirportLocationCode;
    const departure = firstItemDestination?.FlightSegment.DepartureLocationDetails;
    const arrival = lastItemDestination?.FlightSegment.ArrivalLocationDetails;

    const departureDateTime = firstItemDestination?.FlightSegment.DepartureDateTime;
    const arrivalDateTime = lastItemDestination?.FlightSegment.ArrivalDateTime;

    // departure and arrival time
    const departureTime = moment(firstItemDestination?.FlightSegment.DepartureDateTime).format('HH:mm');
    const arrivalTime = moment(lastItemDestination?.FlightSegment.ArrivalDateTime).format('HH:mm');
    // departure and arrival date
    const departureDate = moment(firstItemDestination?.FlightSegment.DepartureDateTime).format('ddd, MMM, DD');
    const arrivalDate = moment(lastItemDestination?.FlightSegment.ArrivalDateTime).format('ddd, MMM, DD');
    // const cabinClass = firstItemDestination?.ItineraryReference.CabinClassType;
    const CabinClassType = firstItemDestination?.ItineraryReference.CabinClassType;
    const cabinClass = CabinClassType ? CabinClassType : firstItemDestination?.ItineraryReference.FareFamily;
    const CabinClassCode = firstItemDestination?.ItineraryReference.CabinClassCode;

    const equipment = firstItemDestination?.FlightSegment.Equipment;

    // airline details
    const airlineCode = '';

    // direct or stops count
    const _stops = OriginDestinations?.length - 1;
    let stops = 'Direct';
    if (_stops > 0) {
      stops = _stops + ' Stop' + (_stops > 1 ? 's' : '');
    }

    // const flightDuration = calcTotalTimeDuration(lastItemDestination?.FlightSegment.ArrivalDateTime, firstItemDestination?.FlightSegment.DepartureDateTime);
    let totalJourneyDuration = 0;
    let totalStopsDuration = 0;
    // eslint-disable-next-line
    OriginDestinations.map((flight: { [index: string]: any }, index: number) => {
      // get the arrival date and time, this will be used to calculate the total time duration of the flight
      const arrivalDateTime = flight.FlightSegment.ArrivalDateTime;
      // get the departure date and time of the next stop, this will be used to calculate the total time duration of the flight
      const departureNextDateTime = OriginDestinations[index + 1]?.FlightSegment.DepartureDateTime;
      // get the total time duration of arrivalDateTime and departureNextDateTime
      const stopDuration = moment(departureNextDateTime).diff(moment(arrivalDateTime), 'minutes');
      // sum all the time duration of the stops
      if (stopDuration > 0) {
        totalStopsDuration += stopDuration;
      }
      totalJourneyDuration += flight.FlightSegment.JourneyDuration;
    });

    const minutesDuration = totalJourneyDuration + totalStopsDuration;
    const flightDuration = timeForHumans(minutesDuration * 60);

    const isDomesticFlight = departure?.countryCode === arrival?.countryCode ? true : false;

    return {
      departureTime,
      arrivalTime,
      departureDate,
      arrivalDate,
      departureDateTime,
      arrivalDateTime,
      stops,
      departureCode,
      arrivalCode,
      carrierCode,
      flightNumber,
      MarketingCarriercode,
      MarketingFlightNumber,
      airlineCode,
      departure,
      arrival,
      flightDuration,
      cabinClass,
      CabinClassCode,
      equipment,
      isDomesticFlight,
    };
  };

  /**
   *
   * @param FlightFares object from mystifly api
   * @returns object
   */
  const flightFares = (FlightFares: { [index: string]: any }) => {
    let adultPrice: number = 0;
    let adultBaseFare: number = 0;
    let adultTax: number = 0;
    let adultTotalPrice: number = 0;
    // let adultTotalPriceWithMarkedUp: number = 0;
    let adultMarkedPrice: number = 0;
    let adultTotalTaxAmount: number = 0;
    let adultQuantity: number = 0;

    let childPrice: number = 0;
    let childBaseFare: number = 0;
    let childTax: number = 0;
    let childTotalPrice: number = 0;
    // let childTotalPriceWithMarkedUp: number = 0;
    let childMarkedPrice: number = 0;
    let childTotalTaxAmount: number = 0;
    let childQuantity: number = 0;

    let infantPrice: number = 0;
    let infantBaseFare: number = 0;
    let infantTax: number = 0;
    let infantTotalPrice: number = 0;
    // let infantTotalPriceWithMarkedUp: number = 0;
    let infantMarkedPrice: number = 0;
    let infantTotalTaxAmount: number = 0;
    let infantQuantity: number = 0;

    // let totalPrice: number = 0;
    // let markedUpPrice: number = 0;
    // let markedPrice: number = 0;
    // let totalTax: number = 0;

    const currency = FlightFares?.Currency;
    const fareType = FlightFares?.FareType;

    // eslint-disable-next-line
    FlightFares?.PassengerFare.map((item: any) => {
      if (item.PaxType === 'ADT') {
        adultQuantity = numberDecimal(item.Quantity);
        adultPrice = numberDecimal(item.TotalFare);
        adultBaseFare = numberDecimal(item.BaseFare);
        // adultTax = numberDecimal(item.TotalTax / item.Quantity);
        adultTax = item.TaxBreakUp.reduce((prev: any, curr: any) => prev + Number(curr.Amount), 0);
        adultTotalPrice = numberDecimal(item.TotalFare);
        // adultTotalPriceWithMarkedUp = Number(parseFloat(item.TotalFareWithMarkUp));
        // adultMarkedPrice = Number(item.TotalFareWithMarkUp - item.TotalFare);
        adultTotalTaxAmount = numberDecimal(item.TotalFare) - numberDecimal(item.BaseFare);
      }

      if (item.PaxType === 'CHD') {
        childQuantity = numberDecimal(item.Quantity);
        childPrice = numberDecimal(item.TotalFare);
        childBaseFare = numberDecimal(item.BaseFare);
        childTax = item.TaxBreakUp.reduce((prev: any, curr: any) => prev + Number(curr.Amount), 0);
        childTotalPrice = numberDecimal(item.TotalFare);
        // childTotalPriceWithMarkedUp = Number(parseFloat(item.TotalFareWithMarkUp));
        // childMarkedPrice = Number(item.TotalFareWithMarkUp - item.TotalFare);
        childTotalTaxAmount = numberDecimal(item.TotalFare) - numberDecimal(item.BaseFare);
      }

      if (item.PaxType === 'INF') {
        infantQuantity = numberDecimal(item.Quantity);
        infantPrice = numberDecimal(item.TotalFare);
        infantBaseFare = numberDecimal(item.BaseFare);
        infantTax = item.TaxBreakUp.reduce((prev: any, curr: any) => prev + Number(curr.Amount), 0);
        infantTotalPrice = numberDecimal(item.TotalFare);
        // infantTotalPriceWithMarkedUp = Number(parseFloat(item.TotalFareWithMarkUp));
        // infantMarkedPrice = Number(item.TotalFareWithMarkUp - item.TotalFare);
        infantTotalTaxAmount = numberDecimal(item.TotalFare) - numberDecimal(item.BaseFare);
      }
    });

    // base fare
    const adultBaseFareGrandTotal = adultQuantity * adultBaseFare;
    const childBaseFareGrandTotal = childQuantity * childBaseFare;
    const infantBaseFareGrandTotal = infantQuantity * infantBaseFare;
    const baseFareGrandTotal = adultBaseFareGrandTotal + childBaseFareGrandTotal + infantBaseFareGrandTotal;
    // taxes
    const adultTaxesGrandTotal = adultQuantity * adultTotalTaxAmount;
    const childTaxesGrandTotal = childQuantity * childTotalTaxAmount;
    const infantTaxesGrandTotal = infantQuantity * infantTotalTaxAmount;
    const taxesGrandTotal = adultTaxesGrandTotal + childTaxesGrandTotal + infantTaxesGrandTotal;
    // marked price
    const adultMarkedPriceGrandTotal = adultQuantity * adultMarkedPrice;
    const childMarkedPriceGrandTotal = childQuantity * childMarkedPrice;
    const infantMarkedPriceGrandTotal = infantQuantity * infantMarkedPrice;
    const markedPriceGrandTotal = adultMarkedPriceGrandTotal + childMarkedPriceGrandTotal + infantMarkedPriceGrandTotal;
    // total flight fare
    const adultFlightFareGrandTotal = adultQuantity * adultTotalPrice;
    const childFlightFareGrandTotal = childQuantity * childTotalPrice;
    const infantFlightFareGrandTotal = infantQuantity * infantTotalPrice;
    const flightFareGrandTotal = adultFlightFareGrandTotal + childFlightFareGrandTotal + infantFlightFareGrandTotal;
    // total passenger count
    const passengerCount = adultQuantity + childQuantity + infantQuantity;

    return {
      fareType: fareType,
      currency: currency,
      baseFareGrandTotal: baseFareGrandTotal,
      adultTax: adultTotalTaxAmount,
      childTax: childTotalTaxAmount,
      infantTax: infantTotalTaxAmount,
      taxesGrandTotal: taxesGrandTotal,
      markedPriceGrandTotal: markedPriceGrandTotal,
      flightFareGrandTotal: flightFareGrandTotal,
      passengerCount: passengerCount,
      passengers: { adultQuantity, childQuantity, infantQuantity },
      adult: { adultPrice, adultBaseFare, adultTax, adultTotalPrice, adultMarkedPrice, adultTotalTaxAmount },
      totalAdultPrice: adultMarkedPriceGrandTotal + adultBaseFareGrandTotal,
      child: { childPrice, childBaseFare, childTax, childTotalPrice, childMarkedPrice, childTotalTaxAmount },
      totalChildPrice: childMarkedPriceGrandTotal + childBaseFareGrandTotal,
      infant: { infantPrice, infantBaseFare, infantTax, infantTotalPrice, infantMarkedPrice, infantTotalTaxAmount },
      totalInfantPrice: infantMarkedPriceGrandTotal + infantBaseFareGrandTotal,
    };
  };

  /**
   *
   * @param passengers object {adult: 0, child: 0, infant: 0}
   * @param flightFares object this.flightFares()
   */
  const flightSummary = (passengers: { [index: string]: any }, flightFares: { [index: string]: any }) => {
    // total base fare
    const totalAdultBaseFare = passengers.adult * flightFares.adult.adultBaseFare;
    const totalChildBaseFare = passengers.child * flightFares.child.childBaseFare;
    const totalInfantBaseFare = passengers.infant * flightFares.infant.infantBaseFare;
    // total taxes
    const totalAdultTax = passengers.adult * flightFares.adult.adultTotalTaxAmount;
    const totalChildTax = passengers.child * flightFares.child.childTotalTaxAmount;
    const totalInfantTax = passengers.infant * flightFares.infant.infantTotalTaxAmount;
    // total marked prices
    const totalAdultMarkedPrice = passengers.adult * flightFares.adult.adultMarkedPrice;
    const totalChildMarkedPrice = passengers.child * flightFares.child.childMarkedPrice;
    const totalInfantMarkedPrice = passengers.infant * flightFares.infant.infantMarkedPrice;
    // total price
    const totalAdultPrice = passengers.adult * flightFares.adult.adultTotalPriceWithMarkedUp;
    const totalChildPrice = passengers.child * flightFares.child.childTotalPriceWithMarkedUp;
    const totalInfantPrice = passengers.infant * flightFares.infant.infantTotalPriceWithMarkedUp;

    return {
      totalBaseFare: totalAdultBaseFare + totalChildBaseFare + totalInfantBaseFare,
      totalFlightTax: totalAdultTax + totalChildTax + totalInfantTax,
      totalFlightMarkedPrice: totalAdultMarkedPrice + totalChildMarkedPrice + totalInfantMarkedPrice,
      totalFlightFare: totalAdultPrice + totalChildPrice + totalInfantPrice,
    };
  };

  // this fnFlightDuration function is not used anymore and can be remove in the future
  // eslint-disable-next-line
  // const fnFlightDuration = (OriginDestinations: { [index: string]: any }) => {
  //   let time = 0;
  //   OriginDestinations.map((segment: { FlightSegment: { JourneyDuration: number } }) => {
  //     time += segment.FlightSegment.JourneyDuration;
  //   });
  //   time = time / 60;

  //   const [hours, _minutes] = time.toString().split('.');

  //   let minutes = _minutes !== undefined ? Number('.' + _minutes.substring(0, 1)) : 0;
  //   minutes = Number(minutes) * 60;

  //   if (minutes == 0) return `${hours}h`;
  //   return `${hours}h ${minutes}m`;
  // };

  /**
   * This function will calculate the total time duration of two datetime using moment and manual computation
   * @param arrivalDate Arrival date
   * @param departureDate Departure date
   * @returns string
   */
  const calcTotalTimeDuration = (arrivalDate: string, departureDate: string) => {
    var firstDate = moment(arrivalDate);
    var secondDate = moment(departureDate);
    let seconds = firstDate.diff(secondDate, 'seconds');
    return timeForHumans(seconds);
  };

  return { foundFlights, flightCabinType, flightSegments, flightFares, flightSummary, calcTotalTimeDuration };
};
