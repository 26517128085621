import React from 'react';
import '../../resources/css/blog.scss';
import { ListSvg } from '../../resources/svg/ListSvg';
import { Container } from 'react-bootstrap';
import FeaturedBlogs from '../../components/Blogs/FeaturedBlogs';
import NavBarTransparent from '../../components/NavBarTransparent';
import { GridSvg } from '../../resources/svg/GridSvg';
import BlogList from '../../components/Blogs/BlogList';
import BlogsHooks from '../../hooks/BlogsHooks';

const BlogMainPage = () => {
  const { changeViewHandler, view } = BlogsHooks();

  return (
    <>
      <NavBarTransparent />
      <div className="blog-hero-section">
        <p className="head-text general">Travel blogs</p>
        <p className="tagline satoshi ">GO! read and witness the world of destinations</p>

        {/* HIDDDEN FOR FUTURE FUNCTIONALITY */}
        {/* <div className="search-container">
          <input type='text' className='search-input' placeholder='Search blogs'></input>
          
          <div className='position-absolute search-icon z-2'>
            <SearchSvg _color="#333333" _width={22} _height={22}/>
          </div>
        </div> */}
      </div>
      <Container className="px-5">{/* <BlogNavBar /> */}</Container>
      <Container className="featured-blogs px-5">
        <p className="feature-header-text general">Featured Blogs</p>

        <div className="blog-cards text-start">
          <FeaturedBlogs />
        </div>
        <hr style={{ width: '100%', margin: '100px auto auto auto', borderTop: '1px solid #aeaeae' }} />
      </Container>

      <Container className="px-5 list-grid-wrapper">
        <div className="list-grid-view pt-5">
          <span onClick={() => changeViewHandler('list')}>
            <ListSvg _color={view === 'list' ? '#016e7f' : 'gray'} />
          </span>
          <span onClick={() => changeViewHandler('grid')}>
            <GridSvg _color={view === 'grid' ? '#016e7f' : 'gray'} />
          </span>
        </div>

        <BlogList BlogView={view} />
      </Container>
    </>
  );
};

export default BlogMainPage;
