import moment from 'moment';
import React from 'react';
import { titleCase } from '../../utils/data.utils';
import pluralize from 'pluralize';

const HotelListHistory = ({ data }: { [index: string]: any }) => {
  const url = `#/history/hotel?id=${data.transactionId}`;
  return (
    <a href={url} className="history">
      <div>
        <h6>
          {data?.nights} {pluralize('Night', data?.nights)} at {data?.hotelName}
        </h6>
        <span>
          Check-in: {moment(data?.checkInTimestamp).format('MMMM D')} - Check-out: {moment(data?.checkOutTimestamp).format('MMMM D')}
        </span>
      </div>
      <div>
        <span className={`${data?.type} fw-bold`}>{data?.type === 'ongoing' ? 'On-going' : titleCase(data?.type)}</span>
        <span className={`oneway fw-bold`}>
          {data?.rooms} {pluralize('Room', data?.rooms)}
        </span>
        <span className={`passengers fw-bold`}>
          {data?.guests} {pluralize('Guest', data?.guests)}
        </span>
      </div>
    </a>
  );
};

export default HotelListHistory;
