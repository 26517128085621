import React from 'react';
import { FlightTabIndex } from './FlightTabIndex';
import { HotelTabIndex } from './HotelTabIndex';
import { HotelSvg } from '../../resources/svg/HotelSvg';
import { AirplaneUpRightSvg } from '../../resources/svg/AirplanesSvg';
import Lottie from 'react-lottie';
import animationData from '../../resources/lottie/Animation - 1724147820281.json';
import cta from '../../resources/lottie/CTA.json';

export const SearchForm = () => {
  const [activeTab, setActiveTab] = React.useState('flights');

  const arrow = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const ctaText = {
    loop: true,
    autoplay: true,
    animationData: cta,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
      <div id="hero-section">
        <video src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/--GALAGO+WEB+ASSETS/homepage/gogogo.mp4" autoPlay loop muted playsInline/>
        <div className='overlay'/>
        <div className="get-ready-to-go">
         <div className='mx-auto text-center flex flex-col gap-3'>
          <p className="" style={{lineHeight: '1.5rem', fontWeight:'700', fontSize: 40, fontFamily: 'Satoshi'}}>Get ready to GO!</p>
            <p className="p-0 m-0" style={{lineHeight: '1.5rem', fontWeight:'500', fontSize: 20}}>
              Trot the globe or discover the beauty of the Philippines islands. <br/> Your next adventure starts here!
            </p>
         </div>
         <div className='search-form-container'>
          <div id="search-form-main">
          
          <ul className="nav nav-tabs active" id="search-tabs" role="tablist">
          
            <li className="nav-item">
              <button
                className="nav-link active satoshi-regular d-flex gap-2 align-items-center p-3"
                id="flight-tab"
                data-bs-toggle="tab"
                data-bs-target="#flight-tab-pane"
                type="button"
                role="tab"
                aria-controls="flight-tab-pane"
                aria-selected="true"
                onClick={() => {
                  setActiveTab('flights');
                }}
              >
                <AirplaneUpRightSvg _color={activeTab === 'flights' ? '#016E7F' : '#333'} />
                <span style={{color: activeTab === 'flights' ? '#016E7F' : '#333', fontWeight: 'bold'}}>Flights</span>
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link satoshi-regular d-flex gap-2 align-items-center p-3"
                id="hotel-tab"
                data-bs-toggle="tab"
                data-bs-target="#hotel-tab-pane"
                type="button"
                role="tab"
                aria-controls="hotel-tab-pane"
                aria-selected="false"
                onClick={() => setActiveTab('hotels')}
              >
                <HotelSvg _color={activeTab === 'hotels' ? '#016E7F' : '#333'} />
                <span style={{color: activeTab === 'hotels' ? '#016E7F' : '#333', fontWeight: 'bold'}}>Hotels</span>
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <FlightTabIndex />
            <HotelTabIndex reset={true} />
          </div>
          </div>
        </div> 


        <div className='p-0 m-0 w-full flex justify-center align-center'> 
        <Lottie
          ariaLabel='lottie'
          options={ctaText}
          height={20}
          width={150}
          isClickToPauseDisabled={true}
          />
        </div>
        <div className="">
          
          <Lottie
          ariaLabel='lottie'
          options={arrow}
          height={110}
          width={110}
          isClickToPauseDisabled={true}
          />
        </div>
        </div>
        
      </div>  
    </>
  );
};
