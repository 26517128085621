import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal, Row } from 'react-bootstrap';
import { NavBar } from '../../components/NavBar';
import '../../resources/css/profile.scss';
import { ProfileHooks } from '../../hooks/ProfileHooks';
import ProfileData from './ProfileData';
import Footer from '../../components/Footer';
import { CameraSvg } from '../../resources/svg/CameraSvg';
import AvatarEditor from 'react-avatar-editor';
import { AlertBox } from '../../components/AlertBox';
import Dropzone from 'react-dropzone';
import { UploadPlaceholderSvg } from '../../resources/svg/UploadPlaceholderSvg';
import { RotateRightSvg } from '../../resources/svg/RotateRightSvg';
import { RotateLeftSvg } from '../../resources/svg/RotateLeftSvg';
import Slider from '@mui/material/Slider/Slider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { InfoCircle } from '../../resources/svg/InfoSvg';

// import Cookies from 'universal-cookie';
import { AuthenticationHooks } from '../../hooks/AuthenticationHooks';
import { ChevronRight, Trash } from 'lucide-react';
import { CheckCircleOutlinedSvg } from '../../resources/svg/CheckSvg';
import AccountSecurity from './AccountSecurity';
import moment from 'moment';
// import NavBarTransparent from '../../components/NavBarTransparent';
// import { createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#ADADAD',
//       // light: will be calculated from palette.primary.main,
//       // dark: will be calculated from palette.primary.main,
//       // contrastText: will be calculated to contrast with palette.primary.main
//     },
// }});

const PrettoSlider = styled(Slider)({
  color: '#ADADAD',
});

// interface User {
//   firstName: string;
// }

const UserProfile = () => {
  // const cookie = new Cookies();
  // console.log("RENDERING THE PAGE")
  const { getProfile, uploadProfilePhoto } = ProfileHooks();
  const { checkLoggedUser } = AuthenticationHooks();
  // const [profile, setProfile] = useState();

  const editor: any = useRef(null);
  const dropzoneRef: any = useRef(null);
  const hiddenFileInputRef: any = useRef(null);

  const initialUser = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    birthDate: '',
    mobileNumber: '',
    profilePic: '',
    passwordUpdatedAt: '',
  };

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [image, setImage] = useState('');
  const [imageScale, setImageScale] = useState(1);
  const [imageRotate, setImageRotate] = useState(0);

  const [showName, setShowName] = useState(false);
  // const [showEmail, setShowEmail] = useState(false);
  // const [showNumber, setShowNumber] = useState(false);
  // const [showNationality, setShowNationality] = useState(false);
  const [uploadisLoading, setUploadisLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  // const [showDocNumExp, setShowDocNumExp] = useState(false);
  const [user, setUser] = useState(initialUser);
  const [profileData, setProfileData] = useState<Array<{ title: string; content: string; show: boolean; setShow: any }>>([]);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadedErrorText, setUploadedErrorText] = useState('');

  // Tabs
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  // let uploadErrorMessage = '';

  const fetchUser = async () => {
    const userDetails = await checkLoggedUser();
    // console.log(userDetails);
    if (userDetails.status === 200) {
      const { data } = userDetails.data;
      // console.log('saved user', savedUser);
      const appendToPreventCache = Math.random();
      setUser({ ...data, profilePic: data.profilePic ? data.profilePic + `?id=${appendToPreventCache}` : '' });
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * Ram this method is called "Template String" other way to concat variables using two Grave Accent (``)
     */

    const fullName = `${user?.firstName} ${user?.middleName ? user?.middleName : ''} ${user?.lastName}`; // BASTA MAS MAGANDA SA CONCAT
    const profileInfo = [
      { title: 'Legal Name', content: fullName ? fullName : '--------', show: showName, setShow: setShowName },
      { title: 'Birthday', content: user?.birthDate ? moment(user?.birthDate).format('MMMM DD, YYYY') : '--------', show: false, setShow: () => {} },
      { title: 'Email Address', content: user?.email ? user?.email : '--------', show: false, setShow: () => {} },
      { title: 'Contact Number', content: user.mobileNumber ? user.mobileNumber : '--------', show: false, setShow: () => {} },
      // single if else
      // { title: 'Nationality', content: '--------', show: false, setShow: () => {} }, // needs to update
      // { title: 'Travel Document', content: '--------', show: false, setShow: () => {} },
      // { title: 'Password', content: `Updated ${moment(user?.passwordUpdatedAt).fromNow()}`, show: false, setShow: () => {} },
    ];
    setProfileData(profileInfo);
    // eslint-disable-next-line
    // console.log(profileData, 'profileData');
    // eslint-disable-next-line
  }, [user]);
  // console.log(profileData, 'profileData');
  const dataUpdate = () => {
    getProfile().then(() => {
      fetchUser();
    });
  };

  const profileDataElements = profileData.map((profileDatum, index) => (
    <ProfileData onDataUpdate={dataUpdate} key={index} title={profileDatum.title} content={profileDatum.content} show={profileDatum.show} setShow={profileDatum.setShow} />
  ));

  const handleScale = (e: any) => {
    const value = e.target.value;
    setImageScale(value);
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current?.open();
    }
  };

  const handleFileType = (fileLoaded: any, eventTriggered: 'onDrop' | 'onDialogOpen') => {
    const acceptedFiles = ['image/jpeg', 'image/png', 'image/jpg', 'image/bmp'];
    const errorText = 'Unsupported file type. Please attach an image with JPEG, BMP, or PNG file types.';
    if (eventTriggered === 'onDrop') {
      if (!acceptedFiles.includes(fileLoaded.type)) {
        setHasUploadError(true);
        setUploadedErrorText(errorText);
        return;
      } else setHasUploadError(false);
    } else if (eventTriggered === 'onDialogOpen') {
      const string1 = fileLoaded.split(';');
      const string2 = string1[0].split(':');
      const fileLoadedType = string2[1];
      if (!acceptedFiles.includes(fileLoadedType)) {
        setHasUploadError(true);
        setUploadedErrorText(errorText);
        return;
      } else setHasUploadError(false);
    }
  };

  const onImageChange = (event: any) => {
    setImageRotate(0);
    setImageScale(1);

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        setHasUploadError(false);
        setImage(e.target.result);
        handleFileType(e.target.result, 'onDialogOpen');
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleClick = (event: any) => {
    hiddenFileInputRef.current?.click();
  };

  function dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleSubmitPhoto = async () => {
    const canvasScaled = editor.current.getImageScaledToCanvas().toDataURL();
    // console.log('canvasScaled', canvasScaled);
    // const canvasScaled = editor.current.getImage().toDataURL()
    const _file = dataURLtoFile(canvasScaled, 'profile.png');
    const fd = new FormData();
    // console.log(_file);
    fd.append('file', _file);
    const MAX_FILE_SIZE = 2000000; // 2MB

    if (_file.size > MAX_FILE_SIZE) {
      const errorText = 'Image exceeds the file-size limit. Please attach images smaller than 2MB.';
      setHasUploadError(true);
      setUploadedErrorText(errorText);
      return;
    }

    setUploadisLoading(true);

    uploadProfilePhoto(fd).then((res: any) => {
      // console.log(res);
      if (res.status === 200) {
        getProfile().then((response: any) => {
          if (response.status === 200) {
            const userData = response.data.data;
            const appendToPreventCache = Math.random();

            setUser({ ...user, profilePic: userData.profilePic + `?id=${appendToPreventCache}` });

            setUploadisLoading(false);
            setShowUploadModal(false);
            setImage('');
          }
        });
      }
    });
  };

  return (
    <>
      <NavBar photo={user.profilePic} />
      {/* <NavBarTransparent photo={user.profilePic} /> */}
      <Container className="py-5 profile">
        <div className="profile-tabs">
          <div className="tabs-container">
            <div className="tabs" onClick={() => handleTabClick(1)}>
              <p>Account Details</p>
              <ChevronRight />
            </div>
            <div className="tabs" onClick={() => handleTabClick(2)}>
              <p>Traveler Details</p>
              <ChevronRight />
            </div>
            <div className="tabs" onClick={() => handleTabClick(3)}>
              <p>Account & Security</p>
              <ChevronRight />
            </div>
          </div>
        </div>
        <div className="profile-forms">
          {/* Account Details */}
          {activeTab === 1 && (
            <div className="">
              <div className="p-[32px] border-1 border-[#D9D9D9] rounded-xl w-100">
                <div className="textColor d-flex justify-content-between align-items-center">
                  <div className="info-title">
                    <p style={{ fontSize: '22px', margin: '0', paddingBottom: '8px', fontWeight: '600' }}>Personal Info</p>
                    <p style={{ fontSize: '14px', fontWeight: '500' }}>
                      GalaGO! uses this information to autofill forms, <br /> speeding up your booking process!
                    </p>
                  </div>

                  <div>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="image">
                        <div
                          className="cameraIcon "
                          onClick={() => {
                            setShowUploadModal(true);
                          }}
                        >
                          <CameraSvg _width={24} _height={24} />
                        </div>
                        {!!user.profilePic.length ? <img src={user.profilePic} loading="lazy" alt="avatar" key={user.profilePic} /> : <span className="initial">{user?.firstName?.charAt(0)}</span>}
                      </div>
                      {/* <div className="profileDetails px-3">
                        <p className="profile-name satoshi-medium">
                          {user?.firstName} {user?.lastName}
                        </p>
                        <p className="profile-info">{user?.email}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
                <hr style={{ border: 'none', borderTop: '2px solid #E7EAEB', marginTop: '2rem', marginBottom: '1rem' }} />
                {profileDataElements}
                {/* Hidden for awhile */}
                <div style={{ marginTop: '2rem', display: 'none', justifyContent: 'end' }}>
                  <Button className="delete-info-btn" onClick={() => setShowModal(true)}>
                    <Trash className="icon" width={20} height={20} />
                    Delete my personal info
                  </Button>
                </div>
              </div>
            </div>
          )}

          {/* Traveler Details */}
          {activeTab === 2 && <div> Reserve page for traveler details </div>}

          {/* Account & Security */}
          {activeTab === 3 && <AccountSecurity />}

          <AlertBox size={'lg'} title="Change profile picture" show={showUploadModal} onHide={() => setShowUploadModal(false)}>
            <Container>
              {image ? (
                <div style={{ padding: '34px', height: '226px', margin: 'auto' }}>
                  <div className="image-prev-container">
                    <div style={{ borderRadius: '14px', overflow: 'hidden', width: 'fit-content', border: '1px solid rgba(51, 51, 51, 0.40)' }}>
                      <AvatarEditor
                        ref={editor}
                        width={170}
                        height={170}
                        borderRadius={100}
                        border={[140, 40]}
                        scale={imageScale}
                        rotate={imageRotate}
                        // color={[1,110,127,0.9]}
                        image={image}
                      />
                    </div>
                    {hasUploadError ? (
                      <div className="mt-4 error-div">
                        <div className="mt-1 mr-1">
                          <InfoCircle _width={16} _height={16} />
                        </div>
                        <p className="errorText">{uploadedErrorText}</p>
                      </div>
                    ) : (
                      <div className="controller-div my-6">
                        <button className="mr-5" onClick={() => setImageRotate((prev) => prev + 90)}>
                          <RotateRightSvg />
                        </button>

                        <button className="mr-4" onClick={() => setImageRotate((prev) => prev - 90)}>
                          <RotateLeftSvg />
                        </button>

                        <div className="mt-1">
                          <Box sx={{ width: 360 }}>
                            <PrettoSlider defaultValue={1.1} min={1.1} max={1.9} step={0.1} onChange={handleScale} />
                          </Box>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Dropzone
                  ref={dropzoneRef}
                  onDrop={(dropped: any) => {
                    // console.log('dropeed', dropped);
                    setImage(dropped[0]);
                    handleFileType(dropped[0], 'onDrop');
                  }}
                  noKeyboard
                >
                  {({ getRootProps, getInputProps }) => (
                    <div style={{ padding: '34px', height: '226px', margin: 'auto' }} {...getRootProps()}>
                      <div className="dropFilesZone">
                        <UploadPlaceholderSvg />
                        <span className="textGuide mt-3">2MB max</span>
                        <span className="textGuide mb-2">JPEG, BMP or PNG files only</span>
                        <span className="dropHereText">Click to upload or drag and drop files here!</span>
                      </div>
                      <input {...getInputProps()} />
                    </div>
                  )}
                </Dropzone>
              )}

              <div style={{ margin: '34px' }} className="mt-20">
                {/* <input  /> */}
                {!image ? (
                  <button className="w-full mt-[32px] btn btn-primary" onClick={openDialog}>
                    Upload profile picture
                  </button>
                ) : (
                  <div style={{ display: 'flex', marginTop: '148px' }} className="mt-20">
                    <input type="file" accept=".png,.jpeg,.jpg,.bmp" style={{ display: 'none' }} ref={hiddenFileInputRef} onChange={onImageChange} />
                    <button onClick={handleClick} className={`w-full btn bg-transparent text-primary border border-primary mr-2 ${uploadisLoading ? 'disable-button' : ''}`}>
                      Change Picture{' '}
                    </button>
                    <button className={`w-full btn btn-primary ml-2 ${hasUploadError ? 'disable-button' : ''}`} disabled={hasUploadError} onClick={handleSubmitPhoto}>
                      {uploadisLoading ? ' Uploading Picture ...' : 'Save Profile Picture'}
                    </button>
                  </div>
                )}
              </div>
            </Container>
          </AlertBox>
        </div>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" animation={false}>
        <Modal.Body>
          <Container className="text-center py-5">
            <Row className="py-5 m-0">
              <CheckCircleOutlinedSvg _width={100} _height={100} _color="limegreen" />
              <p style={{ fontSize: 17, fontWeight: 600, marginTop: '2rem' }}>Successfully Sent!</p>
              <p className="p-0 m-0 py-3" style={{ fontSize: 14, fontWeight: 500 }}>
                Your deletion request has been submitted. Please note that it will take [number of days] for the process to be completed.{' '}
              </p>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default UserProfile;
