import { SvgProps } from './SvgProps';

export const ChildSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 5">
        <path
          id="Vector"
          d="M7.00213 2.46161C7.00803 2.72888 6.96175 2.99466 6.866 3.24335C6.77026 3.49205 6.62699 3.71864 6.44459 3.90982C6.26219 4.101 6.04435 4.25293 5.80385 4.35667C5.56336 4.46042 5.30505 4.5139 5.0441 4.51396C4.78315 4.51403 4.52482 4.46069 4.28427 4.35706C4.04373 4.25344 3.82581 4.10163 3.64332 3.91054C3.46083 3.71945 3.31744 3.49293 3.22158 3.24429C3.12571 2.99564 3.0793 2.72988 3.08507 2.46261C3.09639 1.93823 3.30764 1.43922 3.6736 1.07241C4.03956 0.705594 4.53114 0.500131 5.04312 0.5C5.5551 0.499869 6.04678 0.70508 6.41292 1.0717C6.77906 1.43833 6.99055 1.93723 7.00213 2.46161Z"
          fill={_color}
        />
        <path
          id="Vector_2"
          d="M9.73493 7.80034L7.18821 5.16116C6.97723 4.94153 6.72565 4.76718 6.44815 4.64829C6.17065 4.52941 5.87281 4.46837 5.57202 4.46875H4.52297C3.88825 4.46875 3.31328 4.73367 2.89796 5.16116L0.351242 7.80034C0.257479 7.85711 0.177613 7.93507 0.117739 8.02828C0.0578656 8.12149 0.0195666 8.22748 0.0057647 8.33816C-0.0080372 8.44884 0.00302277 8.56129 0.0381008 8.66692C0.0731789 8.77256 0.131348 8.86859 0.208169 8.94768C0.28499 9.02678 0.378433 9.08685 0.481366 9.12332C0.5843 9.15978 0.694005 9.17167 0.802108 9.15809C0.910211 9.1445 1.01386 9.10579 1.10513 9.04492C1.19641 8.98405 1.27291 8.90262 1.32879 8.80684L3.08407 7.00758V13.5002H4.55333V9.48621H5.53284V13.5002H7.00211V7.00758L8.71624 8.81387C8.77749 8.8962 8.85507 8.9643 8.94388 9.01368C9.03268 9.06305 9.13069 9.09259 9.23145 9.10034C9.33221 9.10808 9.43342 9.09386 9.52843 9.05862C9.62343 9.02337 9.71006 8.9679 9.78262 8.89585C9.85517 8.82381 9.91199 8.73683 9.94935 8.64065C9.9867 8.54446 10.0037 8.44126 9.99932 8.33785C9.9949 8.23443 9.96915 8.13315 9.92375 8.04067C9.87834 7.9482 9.81433 7.86663 9.73591 7.80135L9.73493 7.80034Z"
          fill={_color}
        />
      </g>
    </svg>
  );
};
