import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';


const TheUltimateGuide = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/How+to+travel+when+you're+allergic.jpg"
          alt="Your Ultimate Guide to El Nido, Palawan"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              The Ultimate Guide to Traveling With Food Allergies
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>The Ultimate Guide to Traveling With Food Allergies</h1>

          <div className="info">
            <p className="authorSec">
              By <u>Zoe Alcazaren</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Nov 7, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">6 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              In May and September of 2023, I traveled to Thailand twice, taking with me a severe allergy to peanuts, coconut, and shellfish. Fortunately, I didn’t once have to rely on my Epipen, nor
              did I have to pop an antihistamine. Some call it luck—I call it meticulous planning.
            </p>
            <p>
              If you’re wondering, “Is it safe to travel with allergies?” the simple answer is yes! Through careful preparation and with specific items in your hand-carry, you can prevent a costly
              visit to the clinic and even safely enjoy local delicacies.
            </p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+with+food+allergies/01+Cristiano+Pinto+on+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Cristiano Pinto via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Know your allergies</h2>
            <div className="info_grp">
              <p>
                Contrary to popular belief, you aren’t born with your allergies. It’s possible to develop late-stage allergies to foods you’ve enjoyed your entire life—such was the case for my sudden
                inability to consume shellfish.
              </p>
              <p>Thus, undergoing allergy testing can help you develop a manageable treatment and avoidance plan when traveling.</p>
              <p>Most diagnosticians administer skin tests, but getting an in vitro immunoglobulin E antibody test to diagnose food allergies is possible.</p>
            </div>

            <div className="bullet_grp">
              <div className="bullet_list">
                <p>Food allergies typically manifest in the following ways:</p>
                <ul>
                  <li>Skin reactions like hives or itching</li>
                  <li>Gastrointestinal symptoms like nausea and vomiting</li>
                  <li>Swelling of the lips, tongue, and throat</li>
                  <li>Difficulty swallowing</li>
                  <li>Anaphylaxis (severe allergic reactions resulting in a drop in blood pressure, difficulty breathing, and other life-threatening symptoms)</li>
                </ul>
              </div>
            </div>

            <div className="bullet_grp">
              <div className="bullet_list">
                <p>The most common food allergies include the following:</p>
                <ul>
                  <li>Peanuts and tree nuts</li>
                  <li>Milk</li>
                  <li>Eggs</li>
                  <li>Wheat</li>
                  <li>Soy</li>
                  <li>Shellfish</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+with+food+allergies/02+That's+Her+Business+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by That's Her Business via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Research your destination</h2>
            <div className="info_grp">
              <p>
                When I told friends I was traveling to Thailand twice this year, I received identical responses from most people—why? Traveling to a destination rife with food allergies is daring, but
                it isn’t impossible.
              </p>
              <p>
                Ample research can ensure a safe and enjoyable travel experience. Some destinations have an excellent reputation for allergy awareness and food labeling. You can find this information
                by reading reviews on Trip Advisor or Yelp.
              </p>
              <p>If you’re unfamiliar with the area where you’re staying, research the closest emergency hospitals and clinics.</p>
              <p>
                Then, review your international travel insurance. Unfortunately, most policies consider allergies a pre-existing condition. If you don’t have insurance yet, consider one that covers
                food allergy reactions.
              </p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>What are the best places to travel if you have food allergies?</h2>
            <div className="bullet_grp">
              <div className="bullet_list">
                <p>
                  As someone with severe food allergies, it can be challenging to find allergy-friendly destinations. Food allergies can be limiting, but several international destinations make them
                  easier to manage. Some destinations with an impeccable awareness of food allergies include the following:
                </p>
                <ul>
                  <li>
                    <span>Australia and New Zealand:</span> These countries have strict food labeling regulations, making identifying allergens in packaged foods easier. In addition, many restaurants
                    in major cities like Sydney and Melbourne have meticulously labeled menus.
                  </li>
                  <li>
                    <span>Italy:</span> Most Roman dishes are simple and made with fresh, high-quality ingredients. Many staples like pasta are available in vegan and gluten-free options.
                  </li>
                  <li>
                    <span>Spain:</span> While typical Catalan food is shellfish-heavy, Spanish cuisine is so diverse you’ll likely find safe options.
                  </li>
                  <li>
                    <span>Japan:</span> Unlike many Asian countries, Japan seldom uses nuts in its dishes. Of course, Japan is also rife with seafood but equally rich in allergy-safe meats.
                  </li>
                  <li>
                    <span>United States:</span> Considering the number of people who suffer from food allergies in the United States (roughly 3 million), you’ll be pleased by its allergy-aware
                    restaurant culture.
                  </li>
                  <li>
                    <span>Cruise ships:</span> Regardless of your destination, cruise ships have some of the strictest food allergy restrictions. As you would with an airline, contact your cruiser
                    beforehand to inform them of your allergies.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+with+food+allergies/03+Pixelumina+Photography+on+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Pixelumina Photography via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Get an EpiPen</h2>
            <p>Epipens are notoriously expensive and unavailable locally—but what if you suffer from anaphylactic allergies?</p>

            <div className="bullet_grp">
              <div className="numbered_list">
                <p>Here’s how I get my “makeshift” Epipen kit:</p>
                <ul>
                  <li>Consult with your allergologist to get a comprehensive picture of your allergies. Ask for a prescription for epinephrine. Most allergologists prescribe a dose of 1mL.</li>
                  <li>Purchase the epinephrine vials and a disposable 1mL syringe from your closest hospital pharmacy.</li>
                  <li>
                    Keep your makeshift kit secure in a hard-shell pencil case or similar storage container. Always keep your epinephrine at room temperature. If you’re traveling somewhere hot,
                    consider purchasing an insulated bag or case.
                  </li>
                </ul>
                <p>Epinephrine works immediately after injecting it, but it may take between 5 and 10 minutes to feel the full effects.</p>
              </div>
            </div>

            <div className="tip_container">
              <div className="tip">
                <h3>Can you take an EpiPen on an international flight?</h3>
                <p>
                  According to the{' '}
                  <a href="https://www.tsa.gov/travel/security-screening/whatcanibring/items/epipens" target="_blank" rel="noreferrer">
                    TSA website
                  </a>
                  , you can legally take epinephrine on board a flight, given that you declare them upon security inspection.
                </p>
                <p>
                  When I purchase my epinephrine at The Medical City’s pharmacy, they label each vial to identify the medication. While a medical certificate isn’t necessary, I recommend obtaining one
                  just to cover your bases.
                </p>
              </div>

              <div className="tip">
                <h3>Can an EpiPen go through an x-ray?</h3>
                <p>There is no scientific evidence that an X-ray machine will change the effectiveness of epinephrine—your medication will be fine!</p>
              </div>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Prepare an emergency medical kit</h2>
            <div className="bullet_grp">
              <div className="bullet_list">
                <p>Keep multiple allergy emergency medical kits in your carry-on and check-in baggage. Here’s what to include:</p>
                <ul>
                  <li>
                    <span>Antihistamines:</span> Over-the-counter antihistamines can relieve allergy symptoms like hives, itching, and mild swelling. Typical antihistamines include diphenhydramine
                    (Benadryl) and cetirizine (Claritin and Zyrtec).
                  </li>
                  <li>
                    <span>Oral corticosteroids:</span> If your allergy symptoms are severe, your doctor may prescribe oral corticosteroids like prednisone to reduce inflammation.
                  </li>
                  <li>
                    <span>Topical antihistamines:</span> Consider carrying topical antihistamines like hydrocortisone if you tend to rash out after an allergy. Calamine cream can help relieve itching.
                  </li>
                  <li>
                    <span>Pain relievers:</span> Acetaminophen and ibuprofen can relieve headaches and pain related to allergic reactions.
                  </li>
                  <li>
                    <span>Allergy action plan:</span> In the worst-case scenario that you experience an allergic reaction without a travel companion, detailed instructions in your kit (including
                    emergency contact information) can provide bystanders with some context for how to help.
                  </li>
                </ul>
                <p>It should go without saying but don’t forget to check the expiration date on your medications and replace them accordingly.</p>
              </div>
              <p>If you’re traveling with a companion, ask them to carry some antihistamines.</p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Contact your airline before your trip</h2>
            <div className="info_grp">
              <p>Remember, you can encounter food allergies anywhere, including on the plane. Most airlines with pre-ordered meals indicate allergens on their website.</p>
              <p>
                However, it pays to be ultra-vigilant, so consider calling the airline before you fly. If your food allergies are sensitive to acting up just by smelling an allergen, some airlines
                take extreme precautions by refraining from serving items like nuts on flights.
              </p>
              <p>
                If your airline, for one reason or another, can’t accommodate this request, communicate with passengers seated around you so they can react appropriately in an emergency. Don’t forget
                to inform flight attendants of your allergies.
              </p>
              <p>Naturally, the simplest way to avoid a mishap on your plane is to pack your own snacks and food.</p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Travel with a guide or local</h2>
            <div className="info_grp">
              <p>
                Communicating with your server at a restaurant can take time, especially with a language barrier. Instead of struggling with inaccuracies on Google Translate, consider traveling with a
                guide or local.
              </p>
              <p>
                In my case, arranging lunches and dinners with my Thai friend made it easy to avoid allergens. Don’t hesitate to ask your server if someone on their staff can communicate with you in
                English or politely ask a restaurant-goer if they can help translate for you.
              </p>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+with+food+allergies/04+Frederick+Medina+via+unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Frederick Medina via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Find other ways to communicate</h2>
            <div className="bullet_grp">
              <div className="bullet_list">
                <p>
                  If you’re traveling to a location with poor cellular service, you may not have time to translate your food allergies on an app. Thus, you can never underestimate the power of a
                  pocket notebook. Here are a few sentences and phrases worth translating.
                </p>
                <ul>
                  <li>
                    I have a/n <span>x</span> allergy.
                  </li>
                  <li>
                    I have a severe allergy and can get very sick if I eat <span>x</span>.
                  </li>
                  <li>
                    Please make sure the food does not contain any <span>x</span>.
                  </li>
                  <li>Do you have an allergy-free menu?</li>
                  <li>
                    Could you recommend a dish safe for someone with a/n <span>x</span> allergy?
                  </li>
                  <li>In case of an emergency, where is the nearest hospital or clinic?</li>
                  <li>Thank you for understanding and helping me stay safe.</li>
                </ul>
                <p>Consider wearing a medical ID or food allergy bracelet identifying your allergies and who to call in an emergency.</p>
              </div>
              <p>
                Another option is to obtain a translation card in the local language listing your allergies. I was lucky enough to have a friend living in Thailand who thoughtfully translated this for
                me. Many Thai restaurants, including franchises and mom-and-pop corner shops, were highly accommodating of my allergies, and I didn’t run into any problems.
              </p>
              <p>In addition, many restaurants offer allergy-free versions of popular meals like pad thai and tom yum.</p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Carry your own utensils</h2>
            <div className="info_grp">
              <p>
                Don’t worry about feeling “extra.” Carrying your own utensils may save your life. Consider something compact like chopsticks, which have a slim profile. Alternatively, you can purchase
                something multi-use, like a spork.
              </p>
              <p>Drink from your own thermos and avoid sharing a glass with someone else.</p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Pack for protection</h2>
            <div className="info_grp">
              <p>While you can pick what you eat, you can’t who’s been at your seat beforehand. Especially when traveling with children, carrying a pack of antibacterial wipes can go a long way.</p>
              <p>
                Don’t underestimate adults either—did you know we put our hands to our faces about 15 times an hour? Thoroughly wiping down touchpoints can prevent cross-contamination and rid any area
                of allergens.
              </p>
              <p>
                Another way to protect yourself from contamination is to wear long sleeves. Of course, doing so may not be ideal if you’re traveling to a hot country. However, wearing a light jacket
                or sweater can be an effective barrier in airconditioned areas or the airport.
              </p>
              <p>
                Whether you have allergies or not, don’t ditch the face mask. While there’s a low risk of experiencing anaphylaxis due to breathing in an allergen, volatile organic compounds can still
                cause significant discomfort.
              </p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Avoid street food</h2>
            <div className="info_grp">
              <p>
                Unfortunately, the street food tour was the Thailand must-do that didn’t fit my itinerary. There isn’t often a reliable way to check the ingredients in a street food dish. Plus, most
                street vendors cook using a single wok or a limited number of pans.
              </p>
              <p>Unless you’re with a guide or friend who can guarantee a specific type of street food is allergy-free, you’re better off avoiding a possible allergic reaction.</p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Choose a hotel over a hostel</h2>
            <div className="info_grp">
              <p>
                Consider booking a hotel instead of a hostel with a shared kitchen if your budget allows it. While you can easily communicate your allergies to hostel mates, there’s no guarantee
                they’ll be diligent enough to remember them—especially if you have a long list.
              </p>
              <p>Of course, you can always buy individual meals instead of cooking.</p>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Traveling with food allergies shouldn't be difficult</h2>
            </div>

            <div className="info">
              <p>
                Ultimately, traveling with food allergies is a nuisance, but it isn’t impossible. Through careful planning and vetting allergy-friendly destinations, food allergies shouldn’t hinder
                your much-awaited holiday.
              </p>
              <p>Before traveling, prepare the appropriate medical kit, inform your airline or hotel of your allergies, and provide emergency medical contact information to your travel companions.</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheUltimateGuide;
