import React from 'react';
import Footer from '../../../components/Footer';
import HotelConfirmationProgress1 from '../../../components/Hotels/HotelConfirmationProgress1';
import HotelBookingForm from './HotelBookingForm';
import { NavBar } from '../../../components/NavBar';
import ScrollToTop from '../../../components/ScrollToTop';
import { useLocation, useNavigate } from 'react-router';

// type HotelConfirmationPageProps = {};

const HotelConfirmationPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { selectedRooms, hotelInfo, facilitiesData, searchCriteria, transactionId, hotel } = state;

  return (
    <>
      <ScrollToTop />
      <NavBar />
      <HotelConfirmationProgress1 />
      <main className="container pt-5">
        <HotelBookingForm
          hotel={hotel}
          navigate={navigate}
          selectedRooms={selectedRooms}
          hotelInfo={hotelInfo}
          transactionId={transactionId}
          facilitiesData={facilitiesData}
          searchCriteria={searchCriteria}
        />
      </main>
      <Footer />
    </>
  );
};

export default HotelConfirmationPage;
