import React from 'react';
import { useLocation } from 'react-router';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    } as any);
  }, [pathname]);
  return null;
}

export default ScrollToTop;
