import { create } from 'zustand';

export const HotelFormStore = create((set) => ({

  adults: 1,
  children: 0,
  rooms: 1,

  hotels: '',
  setHotels: (text: string) => {
    set((state: { textDisplay: string }) => ({
        hotels: text,
    }));
  },
  selectedHotel: '',
  setSelectedHotel: (text: string) => {
    set((state: { textDisplay: string }) => ({
        selectedHotel: text,
    }));
  },
  checkInDate:  '',
  setCheckInDate: (text: string) => {
    set((state: { textDisplay: string }) => ({
        checkInDate: text,
    }));
  },
  checkOutDate:  '',
  setCheckOutDate: (text: string) => {
    set((state: { textDisplay: string }) => ({
        checkOutDate: text,
    }));
  },
  setAdults: (value: number) => set((state: { adults: number; }) => ({
    adults: value >= 1 ? value : 1,  // Ensure adults doesn't go below 1
  })),
  
  setChildren: (value: number) => set((state: { children: number; }) => ({
    children: value >= 0 && value <= 9 ? value : state.children, // Ensure the children count is within 0-9 range
  })),
  setRooms: (text: number) => set(() => ({ rooms: text })),

  
}));
