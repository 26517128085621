import React from 'react';
import { preBookingHotelGalagoApiRoute, preBookingHotelTBOApiRoute, X_API_KEY } from '../utils/apiRoutes';
import axios from 'axios';
// import { useNavigate } from 'react-router';

export const HotelPreBookingHooks = () => {
  // const navigate = useNavigate();
  const [preBookingTBOResponse, setPreBookingTBOResponse] = React.useState<{ [index: string]: any }>();
  const [error, setError] = React.useState<string | null>();
  const [preBookCancellationPolicy, setPreBookCancellationPolicy] = React.useState<{ [index: string]: any }>({});

  /**
   *
   * @param payload array of string
   * @param setDisableContinueButton function that accepts a boolean parameter
   * @param routeStates object
   * @returns object
   */
  const preBookingTBO = async (payload: string[], setDisableContinueButton: (param: boolean) => void) => {
    const tboPreBookingRoute = preBookingHotelTBOApiRoute();

    if (localStorage.jwt) {
      const { accessToken } = JSON.parse(localStorage.jwt);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    }

    return await axios
      .request({
        method: 'post',
        url: tboPreBookingRoute,
        data: { bookingCodes: payload },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((data) => {
        const tboData = data.data.data.tbo;

        setPreBookingTBOResponse(tboData);
        setError(null); // reset error state

        let cancellationPolicy: { [index: string]: any } = {};
        // eslint-disable-next-line
        tboData.map((hotel: { [index: string]: any }) => {
          // catch tbo response if the pre-booking session is expired or room is sold out
          if (typeof hotel === 'string') {
            if (hotel === 'Given rate is not Available for booking anymore') {
              setError("Apologies! Unfortunately, the room(s) are currently sold out. Let's look for a different hotel that suites your needs.");
              // eslint-disable-next-line
              return;
            }
            setError(hotel);
            // eslint-disable-next-line
            return;
          }
          // eslint-disable-next-line
          hotel.Rooms.map((room: { [index: string]: any }) => {
            cancellationPolicy[room.Name] = room.CancelPolicies;
          });
        });

        setPreBookCancellationPolicy(cancellationPolicy);

        setDisableContinueButton(false);
      })
      .catch((error): void => {
        setDisableContinueButton(false);
        setError(error.message);
      });
  };

  /**
   *
   * @param transactionId string
   * @param provider string
   * @param providerDetails object
   * @returns object
   */
  const preBookingGalago = async (payload: { [index: string]: any }, setDisableButton: (e: boolean) => void) => {
    const galagoPreBookingRoute = preBookingHotelGalagoApiRoute();

    if (localStorage.jwt) {
      const { accessToken } = JSON.parse(localStorage.jwt);
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    }

    return await axios
      .request({
        method: 'post',
        url: galagoPreBookingRoute,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(({ data }) => {
        // console.log(data.data);
        setDisableButton(false);
      })
      .catch((error: { [index: string]: any }): void => {
        // console.log(error.response.data.message);
        setError(error?.message);
        if (error.response.data.message === 'TransactionHotel already pre-booked!') {
          setDisableButton(false);
        }
        // alert('Error encountered while creating transaction id');
      });
  };

  return { preBookingTBO, preBookingTBOResponse, preBookCancellationPolicy, preBookingGalago, error };
};
