import {create} from "zustand"

export const FlightSortingStore = create((set: any) => ({
  oneWaySort: 'Lowest Price First',
  setOneWaySort: (text: string) => {
    set((state: { oneWaySort: string }) => ({
      oneWaySort: text,
    }));
  },

  roundTripSort: 'Lowest Price First',
  setRoundTripSort: (text: string) => {
    set((state: { roundTripSort: string }) => ({
      roundTripSort: text,
    }));
  },

  selectedFilter: '',
  setSelectedFilter: (text: string) => {
    set((state: { selectedFilter: string }) => ({
      selectedFilter: text,
    }));
  },
}));