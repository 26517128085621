import React, { useEffect, useState } from 'react';
import { FloatingLabelFormControl, SelectFloatingLabelFormControl } from '../../../components/FormControl';
import { FlightPassengersStore } from '../../../store/FlightPassengersStore';
import { CountryCodesSelectInput } from '../../../components/CountryCodesSelectInput';
import { InfoCircle } from '../../../resources/svg/InfoSvg';
import '../../../resources/css/hotel-option-dropdown.scss';
interface Passenger {
  firstName: string;
  middleName: string;
  lastName: string;
}
const ContactDetails = () => {
  // const { isSwitchOn, toggleSwitch, isPassengerDetailsCheckboxTicked } = useInternationalBooking();
  // const { selectedPassengers , appendContact, contacts } = FlightPassengersStore();
  const { selectedPassengers, appendContact, contacts } = FlightPassengersStore() as { selectedPassengers: Passenger[]; appendContact: any; contacts: any };
  // const selectedPassengerss: Passenger = selectedPassengers;
  // const [showCountryCodes, setShowCountryCodes] = useState(false);

  const [isSwitchOn, setIsSwitchOn] = useState<Boolean>(false);
  const handleToggleSwitch = (e: any) => {
    setIsSwitchOn(e.target.checked);
  };

  useEffect(() => {
    appendContact({ ...[{ idd: '+63' }] });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isSwitchOn) {
      appendContact({
        ...[
          {
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            mobile: '',
            idd: '+63',
          },
        ],
      });
    }
    // clear the contacts field

    // eslint-disable-next-line
  }, [isSwitchOn]);

  // const contactDetailChange = (e: any) => {
  //   const dataset = e.target.selectedOptions[0].dataset;
  //   appendContact([
  //     {
  //       firstName: dataset.firstname,
  //       lastName: dataset.lastname,
  //       middleName: dataset.middlename,
  //       idd: '+63',
  //     },
  //   ]);
  // };

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showPassengers, setShowPassengers] = useState(false);
  const [passengerValue, setPassengerValue] = useState('');

  function checkPassengerHasValue() {
    return selectedPassengers[0].firstName && selectedPassengers[0].lastName;
  }

  const [inputFocus, setInputFocus] = useState('');

  return (
    <div className="w-[37.38rem] self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-right text-[1rem] text-text-colors-text-primary font-footnote">
      <div className="w-full flex flex-col py-2.5 items-start justify-start gap-2.5 relative">
        <div className="self-stretch relative leading-[2.56rem] text-[2.12rem] align-self-start textColor font-satoshi font-bold">Contact Details</div>

        <p className="text-left textColor font-medium font-footnote">Please provide your contact details so we can reach out regarding any booking or payment updates.</p>
      </div>

      <div className="text-[1rem] font-footnote self-stretch flex flex-col items-start justify-start gap-[1rem]">
        {selectedPassengers.length > 0 && checkPassengerHasValue() && (
          <div className="d-flex align-items-end gap-[12px] py-[10px]">
            <div className="form-check form-switch">
              <input
                onClick={() => {
                  handleToggleSwitch({ target: { checked: !isSwitchOn } });

                  if (selectedPassengers.length === 1) {
                    appendContact([
                      {
                        firstName: selectedPassengers[0].firstName,
                        lastName: selectedPassengers[0].lastName,
                        middleName: selectedPassengers[0]?.middleName ?? '',
                        idd: '+63',
                      },
                    ]);
                    setPassengerValue(selectedPassengers[0].firstName + ' ' + (selectedPassengers[0]?.middleName ?? '') + ' ' + selectedPassengers[0].lastName);
                  } else {
                    setPassengerValue('');
                  }
                }}
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
            </div>
            <div>
              <span className="font-regular text-secondary">Use Passenger Details</span>
            </div>
          </div>
        )}
        {/* use passenger switch state on */}
        {isSwitchOn && (
          <div className=" w-full  position-relative transparent-line">
            {/* <div className="w-100 transparent-line "> */}
            <SelectFloatingLabelFormControl
              type="text"
              placeholder="Select Passenger"
              label="Select Passenger"
              value={passengerValue}
              defaultValue={passengerValue}
              receivedValue={passengerValue ? true : false}
              onClick={() => {
                setShowPassengers(true);
              }}
              style={{ width: '100%', borderWidth: '2px', borderColor: passengerValue ? '#4FA2B4' : '#D9d9d9d9' }}
              usePassengerDetailsStyle={true}
            />
            {/* </div> */}

            <div className="w-full relative">
              {/* <select className="form-select capitalize" aria-label=".form-select-sm example" onChange={contactDetailChange} style={{ border: '2px solid #d9d9d9' }}>
                <option selected disabled>
                  Select Passenger
                </option>
                {selectedPassengers?.map((value: { firstName: string; middleName: string; lastName: string }, key) => {
                  return (
                    <React.Fragment key={key}>
                      <option data-firstName={value.firstName} data-lastName={value.lastName} data-middleName={value.middleName}>
                        {value.firstName} {value.middleName} {value.lastName}
                      </option>
                    </React.Fragment>
                  );
                })}
              </select> */}

              {showPassengers && selectedPassengers.length > 1 && (
                <div
                  className="dropdown-option w-100 text-start mt-2 !bg-white !z-10"
                  onMouseLeave={() => {
                    // passengerValue ? setPassengerError(false) : setPassengerError(true);
                    setShowPassengers(false);
                  }}
                >
                  {selectedPassengers?.map((value: { firstName: string; middleName?: string; lastName: string }, key) => {
                    return (
                      <React.Fragment key={key}>
                        {value.firstName && value.lastName && (
                          <div
                            data-firstName={value.firstName}
                            data-lastName={value.lastName}
                            data-middleName={value?.middleName ?? ''}
                            onClick={() => {
                              setPassengerValue(value.firstName + ' ' + (value.middleName ?? '') + ' ' + value.lastName);
                              setShowPassengers(false);
                              appendContact([
                                {
                                  firstName: value.firstName,
                                  lastName: value.lastName,
                                  middleName: value?.middleName ?? '',
                                  idd: '+63',
                                },
                              ]);
                            }}
                          >
                            {value.firstName + ' ' + (value.middleName ?? '') + ' ' + value.lastName}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {!isSwitchOn && (
          <>
            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ firstName: e.target.value }] });
                  e.target.value ? setFirstNameError(false) : setFirstNameError(true);
                }}
                type={'text'}
                placeholder={'First Name'}
                label={'First Name *'}
                style={{ borderColor: (contacts?.[0] as { firstName: string })?.firstName ? '#4FA2B4' : '', width: '100%' }}
                autoComplete="false"
                onFocus={() => setInputFocus('firstName')}
                onBlur={() => setInputFocus('')}
                error={firstNameError}
                success={(contacts?.[0] as { firstName: string })?.firstName !== '' || inputFocus === 'firstName'}
              />

              {firstNameError && (
                <span className="flex items-center" style={{ color: '#BD0A00' }}>
                  <InfoCircle _color="#BD0A00" />
                  &nbsp;Please enter your first name
                </span>
              )}
            </div>

            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ middleName: e.target.value }] });
                }}
                type={'text'}
                placeholder={'Middle Name (Optional)'}
                label={'Middle Name (Optional)'}
                style={{ borderColor: (contacts?.[0] as { middleName: string })?.middleName ? '#4FA2B4' : ' ', width: '100%' }}
                autoComplete="false"
                onFocus={() => setInputFocus('middleName')}
                onBlur={() => setInputFocus('')}
                success={(contacts?.[0] as { middleName: string })?.middleName !== '' || inputFocus === 'middleName'}
              />
            </div>

            <div className="w-full">
              <FloatingLabelFormControl
                onChange={(e) => {
                  appendContact({ ...[{ lastName: e.target.value }] });
                  e.target.value ? setLastNameError(false) : setLastNameError(true);
                }}
                type={'text'}
                placeholder={'Last Name'}
                label={'Last Name *'}
                style={{ borderColor: (contacts?.[0] as { lastName: string })?.lastName ? '#4FA2B4' : '', width: '100%' }}
                autoComplete="false"
                onFocus={() => setInputFocus('lastName')}
                onBlur={() => setInputFocus('')}
                error={lastNameError}
                success={(contacts?.[0] as { lastName: string })?.lastName !== '' || inputFocus === 'lastName'}
              />

              {lastNameError && (
                <span className="flex items-center" style={{ color: '#BD0A00' }}>
                  <InfoCircle _color="#BD0A00" />
                  &nbsp;Please enter your last name
                </span>
              )}
            </div>
          </>
        )}

        <div className="w-full">
          <FloatingLabelFormControl
            onChange={(e) => {
              appendContact({ ...[{ email: e.target.value }] });
              e.target.value ? setEmailError(false) : setEmailError(true);
            }}
            type={'text'}
            placeholder={'Email Address'}
            label={'Email Address *'}
            style={{ borderColor: (contacts?.[0] as { email: string })?.email ? '#4FA2B4' : '', width: '100%' }}
            autoComplete="false"
            onFocus={() => setInputFocus('email')}
            onBlur={() => setInputFocus('')}
            error={emailError}
            success={(contacts?.[0] as { email: string })?.email !== '' || inputFocus === 'email'}
            value={(contacts?.[0] as { email: string })?.email}
          />
          {emailError && (
            <span className="flex items-center" style={{ color: '#BD0A00' }}>
              <InfoCircle _color="#BD0A00" />
              &nbsp;Please enter a valid email address
            </span>
          )}
        </div>

        <div className="flex flex-row items-end justify-start w-full gap-[0.88rem]">
          {/* <CountryAreaCodeDropdown
            optionalClassName="w-[120px]"
            onChange={(e: any) => {
              appendContact({ ...[{ idd: e.code }] });
            }}
            show={false}
            onHide={function (): void {
              throw new Error('Function not implemented.');
            }}
          /> */}
          <div className="w-[110px]">
            <CountryCodesSelectInput
              onChange={(e) => {
                appendContact({ ...[{ idd: e.target.value }] });
              }}
              style={{ border: '2px solid #4FA2B4' }}
            />
          </div>

          <div className="col">
            <FloatingLabelFormControl
              onChange={(e) => {
                appendContact({ ...[{ mobile: e.target.value }] });
              }}
              type={'text'}
              placeholder={'Mobile Number'}
              label={'Mobile Number (Optional)'}
              autoComplete="false"
              style={{ borderColor: (contacts?.[0] as { mobile: string })?.mobile ? '#4FA2B4' : ' ', width: '100%' }}
              onFocus={() => setInputFocus('mobile')}
              onBlur={() => setInputFocus('')}
              success={(contacts?.[0] as { mobile: string })?.mobile !== '' || inputFocus === 'mobile'}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full">
        <hr className="w-full text-gray-200" />
      </div>
    </div>
  );
};

export default ContactDetails;
