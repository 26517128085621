import { useState } from 'react';

const BlogsHooks = () => {
  const [view, setView] = useState<'list' | 'grid'>('list');
  
  
 
  const changeViewHandler = (viewValue: 'list' | 'grid') => {
    setView(viewValue);
    return;
  };
 
  
  return { changeViewHandler, view };
};

export default BlogsHooks;
