import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL, getCompanions } from '../utils/apiRoutes';
// import { UserStore } from '../../store/UserStore';

export const FlightPassengersHook = () => {
  // const { token } = UserStore();

  const saveFlightPassengers = async (payload: { [index: string]: any }) => {
    // console.log(userStore.token);
    try {
      // const response = await axios.request({
      //   method: 'POST',
      //   url: saveCompanionDetails(),
      //   data: payload,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json',
      //     // Authorization: `Bearer ${token}`,
      //   },
      // });
      const response: AxiosResponse = await axios.post(`${API_BASE_URL}/flights/traveler-details/user-array`, payload);
      return response;
    } catch (error) {
      const response = error as AxiosResponse;
      return response;
    }
  };

  const getAllSavedPassengers = async () => {
    // console.log(token);
    // if (token === undefined) return;
    // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
      const response: AxiosResponse = await axios.get(getCompanions());
      return response;
    } catch (error) {
      // window.location.reload();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  return { saveFlightPassengers, getAllSavedPassengers };
};
