import {create} from "zustand"

export const HotelSortingStore = create((set: any) => ({
  hotelSort: '',
  setHotelSort: (text: string) => {
    set((state: { hotelSort: string }) => ({
      hotelSort: text,
    }));
  },

  selectedFilter: '',
  setSelectedFilter: (text: string) => {
    set((state: { selectedFilter: string }) => ({
      selectedFilter: text,
    }));
  },
}));