import { create } from 'zustand';

export const PendingVerificationStore = create((set: any) => ({
  showPendingDialog: false,
  setShowPendingDialog: (isShow: boolean) => {
    set((state: { showPendingDialog: boolean }) => ({
      showPendingDialog: isShow,
    }));
  },
}));
