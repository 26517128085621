import React, { useEffect, useState } from 'react';
import '../../resources/css/accountsecurity.scss';
import { ChevronDown } from 'lucide-react';
import { FloatingLabelFormControl } from '../../components/FormControl';
import { Button, Container, Modal, Row } from 'react-bootstrap';
import { CheckCircleOutlinedSvg } from '../../resources/svg/CheckSvg';
import { RadioGroup, RadioGroupItem } from '../../components/ui/radiogroup';
import { Label } from '../../components/ui/label';
import { Link } from 'react-router-dom';
import UserHooks from '../../hooks/UserHooks';
import { debounce } from '../../utils/debounce';
import { ChangePasswordHooks } from '../../hooks/ChangePasswordHooks';
import { InfoCircle } from '../../resources/svg/InfoSvg';
import { checkPasswordStrength } from '../../utils/data.utils';
// import { Navigate } from 'react-router-dom';


const AccountSecurity = () => {
  const { validateOldPassword, validateNewPassword } = ChangePasswordHooks();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showSplashModal, setShowSplashModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [customReason, setCustomReason] = useState('');
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const { deleteUserAccount } = UserHooks();

  const handleDeleteUser = async () => {
    try {
      await deleteUserAccount();
    } catch (err) {
    } finally {
      setShowPasswordModal(false);
    }
  };

  const [isPasswordArrowRotated, setRotateArrow] = useState(false);
  const [isDeleteAccountArrowRotated, setArrowRotation] = useState(false);
  const handleCheckboxPress = (option: number) => {
    if (selectedOption === option) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };
  const handleReason = () => {
    switch (selectedOption) {
      case 1:
        return 'I’m not satisfied with my experience on GalaGO!';
      case 2:
        return 'I’m unsure about how GalaGO! handles my privacy';
      case 3:
        return 'I have another account';
      case 4:
        return 'I don’t use GalaGO! enough';
      case 5:
        return customReason;
      default:
        break;
    }
  };
  const passwordClick = () => {
    setShowPassword(!showPassword);
    setShowDelete(false);
    setRotateArrow(!isPasswordArrowRotated);
  };
  const deleteClick = () => {
    setShowPassword(false);
    setShowDelete(!showDelete);
    setArrowRotation(!isDeleteAccountArrowRotated);
    setSelectedOption(null);
  };
  // const modalClick = () => {
  //   setShowPasswordModal(true);
  //   setShowPassword(false);
  // };
  const deleteAccount = () => {
    setShowSplashModal(false);
    setShowDelete(!showDelete);
  };
  const splashModalClick = () => {
    setShowSplashModal(true);
    setShowDeleteAccountModal(false);
  };

  const isButtonDisabled = () => {
    if (!selectedOption) return true;
    if (selectedOption === 5 && !customReason) return true;
    return false;
  };

  // password validations
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [oldPasswordSuccess, setOldPasswordSuccess] = useState(false);
  const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
  const [confirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);
  const enableChangePasswordButton = (): boolean => {
    if (confirmPasswordSuccess && newPasswordSuccess && oldPasswordSuccess) {
      return false;
    }
    return true;
  };
  const handleValidateOldPassword = async (password: string) => {
    const response = await validateOldPassword(password);
    if (response.status !== 200) {
      setOldPasswordError(true);
      return;
    }
    setOldPasswordError(false);
    if (response.status === 200 && response.data.message === 'Password is verified') {
      setOldPasswordSuccess(true);
      return;
    }
    setOldPasswordSuccess(false);
  };
  const handleConfirmationShow = async () => {
    if (!oldPasswordSuccess) {
      return;
    }
    if (password !== confirmPassword) {
      return;
    }
    try {
      const response = (await validateNewPassword(password, confirmPassword)) as { [index: string]: any };
      if (response?.status >= 400) {
        alert('Failed to change password');
      }
    } catch (error) {
      alert('Failed to change password');
    }
  };

  useEffect(() => {
    if (selectedOption !== 5) setCustomReason('');
  }, [selectedOption]);

  return (
    <>
      <div className="account-security">
        <div className="title_container">
          <p className="account-security__title">Account & Security</p>
          <p className="account-security__subtitle">Manage your account’s data and security</p>
        </div>
        <div className="account-security__tabs">
          <div className="account-security__tab_signout">
            <div className="d-flex justify-between align-center">
              <p className="tab-title">Manage Active Session</p>
              <Link to={'/logout'} style={{ textDecoration: 'none' }}>
                <p className="tab-title">Sign Out</p>
              </Link>
            </div>
            <div>
              <p>Clicking 'Sign Out' will log you out from all devices except this one. Please allow a few minutes for the process to complete.</p>
            </div>
          </div>
          <div className="account-security__tab">
            <div className="show-tab">
              <div>
                <p className="tab-title">Update Password</p>
                <p>To secure your account, kindly change your password once in a while.</p>
              </div>
              <div onClick={passwordClick}>
                <ChevronDown width={20} height={20} />
              </div>
            </div>
            {showPassword && (
              <div className="show-tab-password">
                <div className="flex-1">
                  <p className="tab-title">Password</p>
                </div>
                <div className="flex-1">
                  <div className="password-input">
                    <span className="password-input__input">
                      <FloatingLabelFormControl
                        onChange={(e) => {
                          debounce(() => handleValidateOldPassword(e.target.value), 2000);
                          enableChangePasswordButton();
                        }}
                        success={oldPasswordSuccess}
                        error={oldPasswordError}
                        type={'password'}
                        label="Old Password *"
                        placeholder=""
                      />
                      {oldPasswordError ? (
                        <div className="d-flex align-item-center gap-1 ">
                          <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                          <span className="mb-3 red">Incorrect Password!</span>
                        </div>
                      ) : null}
                      {oldPasswordSuccess ? (
                        <div className="d-flex align-item-center gap-1">
                          <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                          <span className="mb-3 lime">Correct Password!</span>
                        </div>
                      ) : null}
                    </span>
                    <span className="password-input__input">
                      <FloatingLabelFormControl
                        onChange={(e) => {
                          setPassword(e.target.value);
                          const password = checkPasswordStrength(e.target.value);
                          setNewPasswordError(!password);
                          setNewPasswordSuccess(password);
                          enableChangePasswordButton();
                        }}
                        success={newPasswordSuccess}
                        error={newPasswordError}
                        type={'password'}
                        label="New Password *"
                        placeholder=""
                      />
                      <p style={{ color: '#ADADAD' }}>At least 8 alpha numeric characters with at least 1 upper case and 1 special character</p>
                      {newPasswordError ? (
                        <div className="d-flex align-item-center gap-1">
                          <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                          <span className="mb-3 red">Password strength: Weak</span>
                        </div>
                      ) : null}

                      {newPasswordSuccess ? (
                        <div className="d-flex align-item-center gap-1">
                          <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                          <span className="mb-3 lime">Password strength: Good</span>
                        </div>
                      ) : null}
                    </span>
                    <span className="password-input__input">
                      <FloatingLabelFormControl
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setConfirmPasswordSuccess(e.target.value === password ? true : false);
                          setConfirmPasswordError(e.target.value !== password ? true : false);
                          enableChangePasswordButton();
                        }}
                        success={confirmPasswordSuccess}
                        error={confirmPasswordError}
                        type={'password'}
                        label="Confirm Password *"
                        placeholder=""
                      />
                      <p style={{ color: '#ADADAD' }}>Re-enter your new password</p>
                      {confirmPasswordSuccess ? (
                        <div className="d-flex align-item-center gap-1">
                          <CheckCircleOutlinedSvg _width={20} _height={20} _color="#217C38" />
                          <span className="mb-3 lime">Password Match!</span>
                        </div>
                      ) : null}

                      {confirmPasswordError ? (
                        <div className="d-flex align-item-center gap-1">
                          <InfoCircle _width={20} _height={20} _color="#BD0A00" />
                          <span className="mb-3 red">Password doesn’t matched!</span>
                        </div>
                      ) : null}
                    </span>
                  </div>

                  <div className="show-tab-buttons">
                    <Button className="btn-cancel">Cancel</Button>
                    <Button disabled={enableChangePasswordButton()} className="btn-save" onClick={handleConfirmationShow}>
                      Save
                    </Button>
                    {/* <Button className="btn-save" onClick={modalClick}>
                      Save
                    </Button> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="account-security__tab">
            <div className="show-tab">
              <div>
                <p className="tab-title">Delete Account</p>
                <p>Permanently delete your account.</p>
              </div>
              <div onClick={deleteClick}>
                <ChevronDown width={20} height={20} />
              </div>
            </div>
            {showDelete && (
              <div className="show-tab-delete">
                <div className="delete-note">
                  <div className="gally-note">
                    <div className="circle-img">
                      <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-mobile-asset/gally+delete.svg" alt="" className="gally-note-img" />
                    </div>
                  </div>
                  <p>Before proceeding with the deletion of your account, keep in mind that:</p>
                  <div className="note-list">
                    <p>1. You will lose access to your account and won't be able to log in anymore.</p>
                    <p>2. You will lose access to your account's data, including past bookings, traveler details, bookmarked hotels, profile, and contact information.</p>
                    <p>3. When you delete your account, all in-app data associated with your upcoming bookings and reservations will be removed.</p>
                  </div>
                </div>
                <div className="reason-delete">
                  <p style={{ fontWeight: 600 }}>What is the reason for deleting your account?</p>
                  <RadioGroup defaultValue="option-one" className="items-center justify-center gap-5 ">
                    <div className="flex flex-row items-center justify-start gap-[0.5rem] pt-[0.2rem]">
                      <RadioGroupItem
                        value="option-one"
                        checked={selectedOption === 1}
                        id="option-one"
                        onClick={() => {
                          handleCheckboxPress(1);
                        }}
                      />
                      <Label htmlFor="option-one" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                        I’m not satisfied with my experience on GalaGO!
                      </Label>
                    </div>

                    <div className="flex flex-col items-start justify-start gap-[0.5rem] pt-[0.2rem]">
                      <div className="flex gap-[0.5rem]">
                        <RadioGroupItem
                          value="option-two"
                          checked={selectedOption === 2}
                          id="option-two"
                          onClick={() => {
                            setShow(!show);
                            handleCheckboxPress(2);
                          }}
                        />
                        <Label htmlFor="option-two" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                          I’m unsure about how GalaGO! handles my privacy
                        </Label>
                      </div>
                      {selectedOption === 2 && (
                        <div className="pt-[0.5rem] pb-[0.5rem] flex gally-warning-container">
                          <div className="gally-warning">
                            <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-mobile-asset/Warning+icon.png" alt="" />
                            <p>
                              Wait, before deleting your account, we encourage you to take a moment to review our
                              <a href="/#/privacy-policy" className="no-underline hover:text-[#4FA2B4]" style={{ color: '#016e7f' }}>
                                {' '}
                                privacy policy.
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex flex-row items-center justify-start gap-[0.5rem] pt-[0.2rem]">
                      <RadioGroupItem
                        value="option-three"
                        checked={selectedOption === 3}
                        id="option-three"
                        onClick={() => {
                          handleCheckboxPress(3);
                        }}
                      />
                      <Label htmlFor="option-three" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                        I have another account
                      </Label>
                    </div>

                    <div className="flex flex-row items-center justify-start gap-[0.5rem] pt-[0.2rem]">
                      <RadioGroupItem
                        value="option-four"
                        checked={selectedOption === 4}
                        id="option-four"
                        onClick={() => {
                          handleCheckboxPress(4);
                        }}
                      />
                      <Label htmlFor="option-four" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                        I don’t use GalaGO! enough
                      </Label>
                    </div>

                    <div className="flex flex-col items-start justify-start gap-[0.5rem] pt-[0.2rem]">
                      <div className="flex gap-[0.5rem]">
                        <RadioGroupItem
                          value="option-five"
                          id="option-five"
                          checked={selectedOption === 5}
                          onClick={() => {
                            setShow(!show);
                            handleCheckboxPress(5);
                          }}
                        />
                        <Label htmlFor="option-five" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                          Other
                        </Label>
                      </div>

                      {selectedOption === 5 && (
                        <div>
                          <FloatingLabelFormControl type="text" placeholder="Other reason" label="Other reason" />
                        </div>
                      )}
                    </div>
                  </RadioGroup>
                  <div className="delete-buttons">
                    <Button className="btn-cancel" onClick={() => deleteClick()}>
                      Cancel
                    </Button>
                    <Button className="btn-delete" onClick={() => setShowDeleteAccountModal(true)}>
                      Delete Account
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal of Password deletion */}
      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} size="lg" animation={false}>
        <Modal.Body>
          <Container className="text-center py-5">
            <Row className="py-5 m-0">
              <CheckCircleOutlinedSvg _width={100} _height={100} _color="limegreen" />
              <p style={{ fontSize: 17, fontWeight: 600, marginTop: '2rem' }}>Password Successfully Updated</p>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* Modal of Password deletion */}

      {/* <div className="account-security__tabs">
        <div className="account-security__tab_signout">
          <div className="d-flex justify-between align-center">
            <p className="tab-title">Manage Active Session</p>
            <Link to={'/logout'} style={{ textDecoration: 'none' }}>
              <p className="tab-title">Sign Out</p>
            </Link>
          </div>
          <div>
            <p>Clicking 'Sign Out' will log you out from all devices except this one. Please allow a few minutes for the process to complete.</p>
          </div>
        </div>

        <div className="account-security__tab">
          <div className="show-tab">
            <div className="tab-head">
              <p className="tab-title">Update Password</p>
              <p>To secure your account, kindly change your password once in a while.</p>
            </div>
            <div onClick={passwordClick}>
              <ChevronDown width={20} height={20} color="#ADADAD" className={`arrow-icon ${isPasswordArrowRotated ? 'rotated' : ''}`} />
            </div>
          </div>
          {showPassword && (
            <div className="show-tab-password">
              <div className="flex-auto w-32">
                <p className="tab-title">Password</p>
              </div>
              <div className="flex-auto w-60">
                <div className="password-input">
                  <span className="password-input__input">
                    {' '}
                    <FloatingLabelFormControl type="password" placeholder="Old Password" label="Old Password" />
                  </span>
                  <span className="password-input__input">
                    <FloatingLabelFormControl type="password" placeholder="Password" label="New Password" />
                    <p style={{ color: '#ADADAD' }}>At least 8 alpha numeric characters with at least 1 upper case and 1 special character</p>
                  </span>
                  <span className="password-input__input">
                    <FloatingLabelFormControl type="password" placeholder="Confirm Password" label="Confirm Password" />
                    <p style={{ color: '#ADADAD' }}>Re-enter your new password</p>
                  </span>
                </div>

                <div className="show-tab-buttons">
                  <Button className="btn-cancel w-32">Cancel</Button>
                  <Button className="btn-save w-32" onClick={modalClick}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="account-security__tab">
          <div className="show-tab">
            <div className="tab-head">
              <p className="tab-title">Delete Account</p>
              <p>Permanently delete your account.</p>
            </div>
            <div onClick={deleteClick}>
              <ChevronDown width={20} height={20} color="#ADADAD" className={`arrow-icon ${isDeleteAccountArrowRotated ? 'rotated' : ''}`} />
            </div>
          </div>
          {showDelete && (
            <div className="show-tab-delete">
              <div className="delete-note">
                <div className="gally-note">
                  <div className="circle-img">
                    <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-mobile-asset/gally+delete.svg" alt="" className="gally-note-img" />
                  </div>
                </div>

                <div className="note-body">
                  <p>Before proceeding with the deletion of your account, keep in mind that:</p>
                  <div className="note-list">
                    <div className="note">
                      <p>1.</p> <p className="item">You will lose access to your account and won't be able to log in anymore.</p>
                    </div>
                    <div className="note">
                      <p>2.</p> <p className="item">You will lose access to your account's data, including past bookings, traveler details, bookmarked hotels, profile, and contact information.</p>
                    </div>

                    <div className="note">
                      <p>3.</p> <p className="item">When you delete your account, all in-app data associated with your upcoming bookings and reservations will be removed.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reason-delete">
                <p style={{ fontWeight: 600, paddingBottom: '14px', width: '100%' }}>What is the reason for deleting your account?</p>
                <RadioGroup defaultValue="option-one" className="items-start justify-start flex flex-col  gap-[12px] ">
                  <div className="flex flex-row items-center justify-start gap-[0.5rem]">
                    <RadioGroupItem
                      value="option-one"
                      checked={selectedOption === 1}
                      id="option-one"
                      onClick={() => {
                        handleCheckboxPress(1);
                      }}
                    />
                    <Label htmlFor="option-one" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                      I’m not satisfied with my experience on GalaGO!
                    </Label>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-[12px] w-full mb-[2px]">
                    <div className="flex gap-[0.5rem]">
                      <RadioGroupItem
                        value="option-two"
                        checked={selectedOption === 2}
                        id="option-two"
                        onClick={() => {
                          setShow(!show);
                          handleCheckboxPress(2);
                        }}
                      />
                      <Label htmlFor="option-two" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                        I’m unsure about how GalaGO! handles my privacy
                      </Label>
                    </div>
                    {selectedOption === 2 && (
                      <div className="gally-warning-container w-full">
                        <div className="gally-warning">
                          <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-mobile-asset/Warning+icon.png" alt="" />
                          <p>Wait, before deleting your account, we encourage you to take a moment to review our privacy policy.</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row items-center justify-start gap-[0.5rem]">
                    <RadioGroupItem
                      value="option-three"
                      checked={selectedOption === 3}
                      id="option-three"
                      onClick={() => {
                        handleCheckboxPress(3);
                      }}
                    />
                    <Label htmlFor="option-three" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                      I have another account
                    </Label>
                  </div>

                  <div className="flex flex-row items-center justify-start gap-[0.5rem]">
                    <RadioGroupItem
                      value="option-four"
                      checked={selectedOption === 4}
                      id="option-four"
                      onClick={() => {
                        handleCheckboxPress(4);
                      }}
                    />
                    <Label htmlFor="option-four" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                      I don’t use GalaGO! enough
                    </Label>
                  </div>

                  <div className="flex flex-col items-start justify-start gap-[12px] w-full">
                    <div className="flex gap-[0.5rem]">
                      <RadioGroupItem
                        value="option-five"
                        id="option-five"
                        checked={selectedOption === 5}
                        onClick={() => {
                          setShow(!show);
                          handleCheckboxPress(5);
                        }}
                      />
                      <Label htmlFor="option-five" className="font-footnote relative flex shrink-0 cursor-pointer items-center text-sm text-text-colors-text-primary font-medium">
                        Other
                      </Label>
                    </div>

                    {selectedOption === 5 && (
                      <div className="w-full">
                        <FloatingLabelFormControl type="text" placeholder="Other reason" label="Other reason" />
                      </div>
                    )}
                  </div>
                </RadioGroup>
                <div className="delete-buttons">
                  <Button className="btn-cancel w-44">Cancel</Button>
                  <Button className="btn-delete w-44" onClick={() => setShowDeleteAccountModal(true)}>
                    Delete Account
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}

      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} size="lg">
        <Modal.Body>
          <Container className="text-center py-5">
            <Row className="py-5 m-0">
              <CheckCircleOutlinedSvg _width={100} _height={100} _color="limegreen" />
              <p style={{ fontSize: 17, fontWeight: 600, marginTop: '2rem' }}>Password Successfully Updated</p>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteAccountModal} onHide={() => setShowDeleteAccountModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ fontSize: 24, fontWeight: 600 }}>Account Deletion</p>
            <p style={{ fontSize: 14, fontWeight: 500 }}>To proceed with account deletion, please enter your password.</p>
            <div style={{ marginTop: '1.5rem' }}>
              {/* don't have a functionality to check password right now */}
              <FloatingLabelFormControl type="password" placeholder="Password" label="Password" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="w-full"
            onClick={() => {
              handleReason();
              handleDeleteUser();
              splashModalClick();
            }}
            style={isButtonDisabled() ? { borderColor: 'none' } : {}}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSplashModal} onHide={() => setShowSplashModal(false)} size="lg">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="m-0 text-center flex flex-col justify-center align-items-center w-100">
            <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/galago-mobile-asset/splash.png" alt="" style={{ width: 130, height: 130 }} />
            <p style={{ fontSize: 20, fontWeight: 700, marginTop: '2rem' }}>We’re sad to see you go.</p>
            <p>
              Looking forward to be a part of your future <br /> adventures again!
            </p>
            <Button className="w-full mt-4" onClick={deleteAccount}>
              Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountSecurity;
