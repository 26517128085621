/**
 * @description Debounce function that will be called after the wait time (self invoking function)
 * @param func Function parameter
 * @param wait Time to wait in milliseconds
 * @param immediate boolean parameter if true the function will be called immediately, by default it is false
 * @example debounce(() => console.log('Hello'), 1000)
 * @returns The function that will be called after the wait time
 */
export const debounce = (func: Function, wait: number, immediate: boolean = false) => {
  (function (this: any) {
    const context = this;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  })();
};
