import React from 'react';
import '../resources/css/hotel-option-dropdown.scss';
interface TitleDropdownProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  important?: false;
  passengerType?: string;
  props?: any;
  setPassengers: (passengers: any) => void;
  setShowTitleDropDown: (titleDropDown: boolean) => void;
  passengers: any;
  index: number;
}

export const TitleDropdown = ({ important, passengerType, setPassengers, setShowTitleDropDown, passengers, index, ...props }: TitleDropdownProps) => {
  const passenger = passengerType?.toLowerCase();
  return (
    <div className=" w-full ">
      {passenger === 'adult' || passenger === 'minor' ? (
        <div className='dropdown-option'>
          <div
            onClick={() => {
              setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ title: 'mister' } } } });
              setShowTitleDropDown(false);
            }}
          >
            Mr.
          </div>
          <div
            onClick={() => {
              setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ title: 'miss' } } } });
              setShowTitleDropDown(false);
            }}
          >
            Ms.
          </div>
        </div>
      ) : (
        ''
      )}
      {passenger === 'child' || passenger === 'infant' ? (
        <div className='dropdown-option'>
          <div
            onClick={() => {
              setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ title: 'mistress' } } } });
              setShowTitleDropDown(false);
            }}
          >
            Mistress
          </div>
          <div
            onClick={() => {
              setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ title: 'master' } } } });
              setShowTitleDropDown(false);
            }}
          >
            Master
          </div>
        </div>
      ) : (
        ''
      )}

      {/* <select className="form-select w-full" aria-label="" {...props}>
        <option value="" selected disabled></option>
        {passenger === 'adult' || passenger === 'minor' ? (
          <>
            <option value="mister">Mr</option>
            <option value="miss">Ms</option>
          </>
        ) : null}

        {passenger === 'child' || passenger === 'infant' ? (
          <>
            <option value="mistress">Mistress</option>
            <option value="master">Master</option>
          </>
        ) : null}
      </select>
      <label htmlFor="floatingSelectGrid">Title{important ? ' *' : ''}</label> */}
    </div>
  );
};
