import { merge } from 'lodash';
import { create } from 'zustand';

export const PassengersStore = create((set: any) => ({
  passengers: { adults: 1, minors: 0, children: 0, infants: 0 },
  setPassengers: (_passengers: { [index: string]: any }) => {
    set((state: { passengers: {} }) => ({
      passengers: merge(state.passengers, _passengers),
    }));
  },
}));
