import React, { ReactElement } from 'react';
import { formatPriceNoRound } from '../utils/data.utils';
import moment from 'moment';

export const CancellationPolicy = ({ room }: { room: { [index: string]: any } }) => {
  // const cancellationPolicy = CancellationData;
  let totalRoomPrice: number = room.TotalFare;

  const { CancelPolicies } = room;

  const policies: ReactElement[] = [];

  // eslint-disable-next-line
  CancelPolicies.map((policy: { [index: string]: any }, key: number) => {
    // reverse the date
    const dateArray = policy.FromDate.split(' ')[0].split('-').reverse();
    // get the partial amount
    let partialAmount: string = `PHP ${formatPriceNoRound(policy.CancellationCharge)}`;
    // if the charge type is percentage get the percentage amount
    if (policy.ChargeType === 'Percentage') {
      const percent = (policy.CancellationCharge / totalRoomPrice) * 100;
      partialAmount = `${formatPriceNoRound(percent)}% `;
    }

    // get the next date
    let nextDateArray: string[] | undefined = [];
    // eslint-disable-next-line
    [CancelPolicies[key + 1]].map((policy: { [index: string]: any }, _key: number) => {
      nextDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
    });

    // get the last date
    let lastDateArray: string[] | undefined = [];
    // eslint-disable-next-line
    [CancelPolicies[CancelPolicies.length - 1]].map((policy: { [index: string]: any }, _key: number) => {
      lastDateArray = policy?.FromDate.split(' ')[0].split('-').reverse();
    });
    // // format the dates
    const date = moment(`${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`).format('MMMM DD, YYYY');
    const nextDate = nextDateArray !== undefined ? moment(`${nextDateArray[0]}-${nextDateArray[1]}-${Number(nextDateArray[2]) - 1}`).format('MMMM DD, YYYY') : undefined;
    const lastDate = lastDateArray !== undefined ? moment(`${lastDateArray[0]}-${lastDateArray[1]}-${lastDateArray[2]}`).format('MMMM DD, YYYY') : undefined;

    // policies.push(<>{` date: ${date} | nextDate : ${nextDate} | lastDate: ${lastDate} `}</>);

    /**
     * Fully Refundable policy
     * if the next date not undefined return UNTIL NEXT DATE (11:59pm)
     * else if last date is undefined return THIS ROOM IS 100% REFUNDABLE
     * else return null
     *
     * Partially Refundable policy
     * if last date is undefined and date is undefined return PARTIAL AMOUNT IS REFUNDABLE WHEN CANCELLED
     * else if last date is undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE STARTING DATE
     * else if last date is not undefined and date is not undefined return PARTIAL AMOUNT IS REFUNDABLE UNTIL DATE
     *
     * Non refundable policy
     * if the date is in the past then return FREE CANCELLATION IS NOT AVAILABLE FOR THIS ROOM
     * else return STARTING DATE (12:00am)
     */

    if (policy.CancellationCharge === 0) {
      policies.push(
        <div className="mb-1" key={key} >
          <span className="d-block text-success font-medium ">Fully Refundable</span>
          <span className='text-success '>
            {nextDate !== undefined ? `Until ${nextDate} (11:59pm)` : null}
            {lastDate === undefined ? `This room is 100% refundable` : null}
          </span>
        </div>
      );
    } else if (policy.CancellationCharge !== 100 && policy.CancellationCharge !== 0) {
      policies.push(
        <div className="mb-1" key={key}>
          <span className="d-block text-warning font-medium">Partially Refundable</span>
          <span className="d-block text-warning">
            {lastDate === undefined && date === undefined ? `${partialAmount} is refundable when cancelled` : null}
            {lastDate === date ? `${partialAmount} is refundable starting ${date}` : null}
            {lastDate !== undefined && lastDate !== date ? `${partialAmount} is refundable until ${moment(lastDate).subtract(1, 'day').format('MMMM DD, YYYY')}` : null}
          </span>
        </div>
      );
    } else if (policy.CancellationCharge === 100) {
      policies.push(
        <div className="mb-1" key={key}>
          <span className="d-block text-danger font-medium">Non-Refundable</span>
          <span className="d-block text-danger">{moment() > moment(date) ? `Free cancellation is not available for this room` : `Starting ${date} (12:00am)`}</span>
        </div>
      );
    }
  });

  return <div>{policies}</div>;
};
