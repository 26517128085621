import { SvgProps } from './SvgProps';
export const HotelSearchSvg = ({ _width = 28, _height = 16, _color = '#333333' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M2.3252 13.1589H13.6744" stroke={_color} strokeWidth="1.66667" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0127 13.1638V10.863" stroke={_color} strokeWidth="0.666667" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.0226 7.42737C11.3933 7.42737 10.8877 7.93293 10.8877 8.5623V9.73331C10.8877 10.3627 11.3933 10.8682 12.0226 10.8682C12.652 10.8682 13.1575 10.3627 13.1575 9.73331V8.5623C13.1575 7.93293 12.652 7.42737 12.0226 7.42737Z"
        stroke={_color}
        strokeWidth="0.666667"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8916 13.1586V4.92013C2.8916 3.88322 3.4075 3.36218 4.43409 3.36218H7.64797C8.67455 3.36218 9.18526 3.88322 9.18526 4.92013V13.1586"
        stroke={_color}
        strokeWidth="1.66667"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.80078 6.06555H7.35436" stroke={_color} stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.80078 8H7.35436" stroke={_color} stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.06445 13.1589V11.2244" stroke={_color} strokeWidth="1.66667" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
