import { create } from 'zustand';

export const PasswordDialogStore = create((set: any) => ({
  showPasswordDialog: false,
  setShowPasswordDialog: (isShow: boolean) => {
    set((state: { showPasswordDialog: boolean }) => ({
      showPasswordDialog: isShow,
    }));
  },
}));
