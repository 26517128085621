import axios, { AxiosResponse } from 'axios';
import { resendEmail } from '../utils/apiRoutes';

const EmailHook = () => {
  const resendEmailApi = async (email: string) => {
    try {
      const response: AxiosResponse = await axios.post(resendEmail(), { email: email });
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  return { resendEmailApi };
};

export default EmailHook;
