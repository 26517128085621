import { SvgProps } from './SvgProps';

export const CameraSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" fill="#333333" stroke="#E8E8E8" />
      <path d="M12 10C10.916 10 10 10.916 10 12C10 13.084 10.916 14 12 14C13.084 14 14 13.084 14 12C14 10.916 13.084 10 12 10Z" fill="white" />
      <path
        d="M17.3359 7.33594H15.6119L13.8073 5.53127C13.7455 5.46924 13.672 5.42005 13.5911 5.38653C13.5102 5.353 13.4235 5.33581 13.3359 5.33594H10.6693C10.5817 5.33581 10.495 5.353 10.4141 5.38653C10.3332 5.42005 10.2597 5.46924 10.1979 5.53127L8.39327 7.33594H6.66927C5.93394 7.33594 5.33594 7.93394 5.33594 8.66927V16.0026C5.33594 16.7379 5.93394 17.3359 6.66927 17.3359H17.3359C18.0713 17.3359 18.6693 16.7379 18.6693 16.0026V8.66927C18.6693 7.93394 18.0713 7.33594 17.3359 7.33594ZM12.0026 15.3359C10.1959 15.3359 8.66927 13.8093 8.66927 12.0026C8.66927 10.1959 10.1959 8.66927 12.0026 8.66927C13.8093 8.66927 15.3359 10.1959 15.3359 12.0026C15.3359 13.8093 13.8093 15.3359 12.0026 15.3359Z"
        fill="white"
      />
    </svg>
  );
};
