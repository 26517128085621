import axios from 'axios';
import React, { useState } from 'react';
import { nationalityApiRoute } from '../utils/apiRoutes';

export const CountriesHook = () => {
  const [countries, setCountries] = useState<{ [index: string]: any }>();
  const [nationalities, setNationalities] = useState<{ [index: string]: any }>();

  React.useEffect(() => {
    (async () => {
      axios
        .get(nationalityApiRoute())
        .then((response) => {
          // console.log(response.data);
          let nation: any[] = [];
          let country: any[] = [];
          if (response.status === 200) {
            // eslint-disable-next-line
            response.data.map((res: { [index: string]: any }): any => {
              // console.log(country.idd.suffixes.length);

              // for (let index = 0; index < res.idd.suffixes.length; index++) {
              // const element = country.idd.suffixes[index];
              nation.push({
                value: res.cca2,
                label: res.demonyms.eng.m,
              });
              country.push({
                label: res.name.common,
                value: res.cca2,
              });
              // }
            });
          }

          nation.sort((a, b) => {
            let text1 = a.value.toUpperCase();
            let text2 = b.value.toUpperCase();
            return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
          });
          country.sort((a, b) => {
            let text1 = a.value.toUpperCase();
            let text2 = b.value.toUpperCase();
            return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
          });
          // console.log(nation);
          setNationalities(nation);
          setCountries(country);
        })
        .catch((error) => {
          // console.log(error);
        });
    })();
  }, []);

  return { countries, nationalities };
};
