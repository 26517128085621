import React from 'react';

interface SvgProps extends React.SVGAttributes<HTMLOrSVGElement> {
  _width?: number;
  _height?: number;
  _color?: string;
  props?: any;
}

export const CloseCircleFilledSvg = ({ _width = 15, _height = 15, _color = '#333', ...props }: SvgProps) => {
  return (
    <svg width={_width} height={_height} {...props} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M6.5 0C3.1916 0 0.5 2.6916 0.5 6C0.5 9.3084 3.1916 12 6.5 12C9.8084 12 12.5 9.3084 12.5 6C12.5 2.6916 9.8084 0 6.5 0ZM9.0242 7.6758L8.1758 8.5242L6.5 6.8484L4.8242 8.5242L3.9758 7.6758L5.6516 6L3.9758 4.3242L4.8242 3.4758L6.5 5.1516L8.1758 3.4758L9.0242 4.3242L7.3484 6L9.0242 7.6758Z"
        fill={_color}
      />
    </svg>
  );
};
