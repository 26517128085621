import React from "react";
import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';


function UltimateGuideToElNido() {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/00+Cover+-+Rommel+Paras+on+Unsplash.jpeg"
          alt="Your Ultimate Guide to El Nido, Palawan"
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Top Destination</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Your Ultimate Guide to El Nido, Palawan
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Your Ultimate Guide to El Nido, Palawan</h1>

          <div className="info">
            <p className="authorSec">
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Sep 29, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">7 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>Traveling to the nature-rich islands of El Nido, Palawan is synonymous to island-hopping and delicious drinks by the beach.</p>
            <p>Mention El Nido, and travelers from all over the world automatically think of stunning limestone cliffs, crystal-clear waters, and pristine white sandy beaches.</p>
            <p>But is that all?</p>
            <p>
              This article will guide you on your journey from getting to El Nido, to where to stay, what to eat, and what extra explorations you can do to make your trip a true escape — from work,
              from stress, and from the bustling city life.
            </p>
          </div>

          <div className="textual_story">
            <h2>When to go to El Nido, Palawan</h2>
            <p>Your window of opportunity is any time between January to April.</p>
            <p>
              El Nido is an absolute tropical paradise. Once you get there, you’ll want to spend most of your time outdoors, on a boat, or walking along sandy shores. That’s why the best time to
              experience the island is when the sun is at her peak!
            </p>
            <p>
              Be mindful, though. The peak of tourist traffic on the island happens during the summer months, particularly in March and April. If you’re not a fan of swimming the seas with big crowds,
              it might be best to schedule your trip some other time.
            </p>
            <p>May is a good alternative month, too. There might be a bit of rain here and there, but you can still enjoy El Nido’s attractions even under a light drizzle. (Trust us, we’ve tried!)</p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/01+Rona+Lao+on+Unsplash.png"
              alt="Guide to El Nido, Palawan"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Rona Lao via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>How to get to El Nido, Palawan</h2>

            <div className="info_grp">
              <p>Getting to El Nido is more accessible now than in past years.</p>
              <p>You can choose between two routes.</p>
              <p>The quicker way is to book a direct flight from Manila to the islands of El Nido themselves.</p>
              <p>
                This is possible via just one airline, AirSWIFT. This regional boutique airline offers several flights a day going to El Nido Airport (ENI). This will cost you roughly PHP 10,000, give
                or take.
              </p>
              <p>On the other hand, the most common (and slightly more affordable) way is to fly to Puerto Princesa International Airport (PPS), the nearest major airport to El Nido.</p>
              <p>Major airlines such as Cebu Pacific, Philippine Airlines, and Air Asia offer daily flights for this. You can get a ticket for as low as PHP 3,000.</p>
            </div>

            <div className="galaGuide">
              <h3>GalaGuide!</h3>
              <p>Book flights via GalaGO! to get a better comparison of available flight prices. You might even catch a GalaGO! exclusive promo if you’re lucky.</p>
            </div>

            <div className="bullet_grp">
              <div className="bullet_list">
                <p>From the Puerto Princesa Airport, you can choose between</p>
                <ul>
                  <li>a four-hour private car transfer straight to your booked hotel, which will cost about PHP 3,000 per day; or</li>
                  <li>a scenic six-hour van ride that will drop you off at Corong-Corong Bus Terminal.</li>
                </ul>
              </div>
            </div>

            <div className="info_grp">
              <p>
                From the bus terminal, you just have to take a five-minute tricycle ride to El Nido’s poblacion or town proper. The regular price for this short ride is PHP 15 per trip, but prices can
                go up to PHP 50 at night.
              </p>
              <p>
                The <i>poblacion</i> is where most hotels and homestays are. You can just request for your tricycle driver to take you directly to your booked accommodations once in the town.
              </p>
              <p>If you’re traveling light, one last option to explore is renting a motorbike for about PHP 600 a day.</p>
              <p>
                Something to note: traveling by motorbike from PPS to El Nido’s poblacion can take up to five hours. While this might be one of the longer and more tiring ways to get to your
                destination, it also offers an opportunity to take in the picturesque landscapes of Palawan, making it an adventure in itself.
              </p>
            </div>
          </div>

          <div className="hotel_container">
            <div className="head">
              <h2>Where to stay in El Nido</h2>
              <p>El Nido offers a wide range of accommodations, catering to different budgets and preferences.</p>
              <p>Here are our recommended stays for every budget:</p>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Budget Reco: Spin Designer Hostel</h2>
                <p>Address: Balinsasayaw Road corner Calle Real, El Nido, Palawan</p>
                <p>Contact: +63 917 625 7846</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/02+SPIN+Hostel+via+FB.png"
                  alt="Spin Designer Hostel"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by SPIN Hostel via Facebook</p>
              </div>

              <div className="info_grp">
                <p>If you're a backpacker seeking a more affordable option, this hip and cozy hostel is a wonderful option for you.</p>
                <p>
                  Tucked away amid tropical gardens, this charming hostel is situated at a convenient 5 kilometers away from El Nido Airport. It’s also just a four-minute walk to the town’s beach and
                  other attractions!
                </p>

                <div className="bullet_grp">
                  <div className="bullet_list">
                    <p>Here, you’ll get all the essentials for a comfortable stay:</p>
                    <ul>
                      <li>bunk beds</li>
                      <li>(clean!) shared bathrooms</li>
                      <li>lockers</li>
                      <li>Wi-Fi access</li>
                      <li>guest laundry</li>
                      <li>an airport shuttle</li>
                    </ul>
                    <p>Plus there’s a shared kitchen where they serve free continental breakfast and evening hot drinks.</p>
                  </div>
                </div>

                <p>
                  They also have a garden, lounge areas, and a scenic rooftop deck where they regularly host barbecues and live music sessions — a great opportunity to meet and share experiences with
                  fellow travelers.
                </p>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Midrange Reco: Blue Mango Rooms & Villas</h2>
                <p>Address: 594X+CMJ, Sitio Lugadia, Barangay Corong Corong, El Nido, Palawan</p>
                <p>Contact: +63 995 231 2587</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/03+Mango+and+Villas.png"
                  alt="Blue Mango Rooms and Villas"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Blue Mango Rooms & Villas via Facebook</p>
              </div>

              <div className="info_grp">
                <p>Got more wiggle room in your travel budget? Blue Mango Rooms & Villas offers excellent value for money.</p>
                <p>Named after their landmark centennial mango tree, this boutique luxury hotel lets you stay in a modern bahay kubo and rest under the coverage of tropical trees.</p>
                <p>
                  Every room and villa here boasts terrific terrace views of both the jungle and the sea. And when the call of the water becomes irresistible, Coron Beach and Maremegmeg Beach are just
                  a short walking distance away.
                </p>
                <p>To top it off, they also have an onsite restaurant where you can savor specialty cuisines and enjoy evening socials.</p>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Luxury Reco: Matinloc Resort</h2>
                <p>Address: Palio, Matinloc Island, El Nido, Palawan</p>
                <p>Contact: +63 917 817 5191</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/04+Matinloc+Resort+via+FB.png"
                  alt="Matinloc Resort"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo by Matinloc Resort via Facebook</p>
              </div>

              <div className="info_grp">
                <p>For those seeking more comfort and luxury, El Nido is home to Matinloc Resort.</p>
                <p>This upscale resort blends harmoniously with its natural surroundings, caught between a white-sand beach on one side and a jungle-clad hillside on the other.</p>
                <p>
                  You’ll need to take a boat ride from El Nido’s town proper to reach this destination, but the onsite spa center, open-air bar, modern suites with private balconies, and whirlpool
                  tubs make it all worth it.
                </p>
                <p>Plus, you get a private beach all to yourself (well, and the other resort guests).</p>
              </div>
            </div>

            <div className="hotel_details">
              <div className="contacts">
                <h2>Luxury Reco: Cauayan Island Resort</h2>
                <p>Address: Bacuit Bay, Cauayan Island, El Nido, Palawan</p>
                <p>Contact: +63 917 152 9409</p>
              </div>

              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/05+Cauwayan+Island+Resort.png"
                  alt="Cauayan Island Resort"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />
                <p>Photo via Cauayan Island Resort</p>
              </div>

              <div className="info_grp">
                <p>Want a stay that focuses on that feeling of escape and highlights the pristine beauty of the island? Then Cauayan Island Resort is the place to be.</p>
                <p>
                  This laidback luxury resort is surrounded by lush greenery and serene ambiance. Whichever type of lodging you select, you’ll wake up to the sound of waves and the sight of sun-kissed
                  beaches.
                </p>
                <p>
                  Beyond that, they also offer first-class personalized services. If you’re the vacationer type, they offer spa services, a sunset cruise, and access to a private beach. If you’re the
                  more fun-loving kind of traveler, they host a variety of recreational activities such as diving, trekking, fauna watching, and more.
                </p>
                <p>Whichever of these accommodations you choose, you’re sure to have find the respite you’re looking for from El Nido.</p>
              </div>
            </div>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>How to enjoy El Nido</h2>

              <div className="head_desc">
                <p>
                  What <i>can</i> you do on such a remote island? You’d be surprised by how much. El Nido is home to a plethora of natural wonders and has long since been renewed by an upbeat drinking
                  and dining culture.
                </p>
                <p>Let’s quickly go through the different experiences that await you here:</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Go island hopping, of course</h2>
              </div>

              <div className="info-grp">
                <p>Island hopping is undoubtedly the most popular activity in El Nido, and for good reason.</p>
                <p>
                  The island-hopping tours here take you to some of the most stunning beaches (the secret beach is different from the hidden beach), lagoons (there’s the hidden lagoon, which is still
                  different from the hidden beach), and snorkeling spots in the region.
                </p>
                <p>Each tour is unique, and you can opt for different packages to explore the islands at your own pace.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Work your biceps kayaking the Big Lagoon</h2>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/06+Ehmir+Bautista+via+Unsplash.png"
                    alt="Big Lagoon"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Ehmir Bautista via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>This destination is a mainstay in most of the tour packages offered on the island.</p>
                <p>
                  It is also one of the most photographed spots in El Nido. That’s because each turn in here is as picturesque as the last, and there are quite a lot to take in this not-so-little
                  pocket of tropical heaven.
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Emote along pristine beaches</h2>
              </div>

              <div className="info-grp">
                <p>From the unspoiled Nacpan Beach to the scenic Las Cabanas Beach, and more — there are plenty of beaches to visit on the island.</p>
                <p>Whether you want to swim, bask in the sun, or take a leisurely walk along the shore, each beach offers a tranquil atmosphere that you can savor.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Hike the Taraw Cliff</h2>

                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ultimate+Guide+-+El+Nido%2C+Palawan/07+Luca+Calderone+via+Unsplash.png"
                    alt="Taraw Cliff"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Luca Calderone via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Aside from the clear, calm waters and the white sandy shores, El Nido is also wildly popular for its breathtaking limestone formations. Most of them are in the middle of the sea, but
                  one that you can climb is the Taraw Cliff.
                </p>
                <p>
                  At this destination, you can opt for a regular hike or a more leisurely Canopy Walk. Whichever you choose, you’re sure to get a panoramic view of El Nido’s town proper and probably a
                  bunch of scrapes from the sharp limestone rocks.
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Catch the sandbar of Snake Island</h2>
              </div>

              <div className="info-grp">
                <p>This sandbar only appears during low tide, but catching it is a reward of its own.</p>
                <p>
                  Connecting Snake Island and its neighboring island, this gorgeous sandbar strip hidden in Bacuit Bay makes for a breathtaking view. It’s the ideal place to appreciate the majesty of
                  the seas, indulge in a romantic stroll, or make some social media-worthy grand gesture. (Just an idea, if you’re headed there with your significant other.)
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Drink with a beach-y view</h2>
              </div>

              <div className="info-grp">
                <p>
                  A boom in tourism gave birth to bustling nightlife and drinking culture on this island. There are plenty of pubs and speakeasies to choose from, located in different areas of El
                  Nido’s poblacion.
                </p>
                <p>
                  A couple of good places to start are i{' '}
                  <a href="https://thebeachhouse.ph/" target="_blank" rel="noreferrer">
                    Hot Gossip Beach Bar & Cafe
                  </a>{' '}
                  and{' '}
                  <a href="https://instagram.com/savabeachbar?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                    Sava Beach Bar
                  </a>
                  . Hot Gossip is a refreshing place for day drinking with a Santorini-esque vibe and an early happy hour (B.O.G.O. drinks start at 3PM!). Meanwhile, Sava offers a laidback night out
                  courtesy of a jazzy live band, handcrafted cocktails, and bean bags on the beach-side front.
                </p>
                <p>Wherever you enjoy your happy hour or day drinking session, the views are sure to be stunning.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>Eat all the seafood</h2>
              </div>

              <div className="info-grp">
                <p>For the foodies wanting to go resto-hopping, Scape Skydeck and No Mames come highly recommended by folks who’ve been to the islands.</p>
                <p>
                  <a href="https://web.facebook.com/scapeelnido/?_rdc=1&_rdr" target="_blank" rel="noreferrer">
                    Scape Skydeck
                  </a>{' '}
                  is a scenic rooftop restaurant on top of Cuna Hotel. They offer a menu of signature dishes and delicate desserts, a great place for an intimate and tasteful fare.
                </p>
                <p>
                  <a href="https://www.facebook.com/nomamesrestaurantelnido/" target="_blank" rel="noreferrer">
                    No Mames
                  </a>{' '}
                  is the livelier alternative with a vibrant atmosphere and a not-so-crowded sunset view. Here, you can feast on colorful Mexican cuisine while enjoying drinks with friends.
                </p>
                <p>
                  If you don’t feel like dining out, another (perhaps more responsible) option is to buy from the wet market and have it cooked at your booked accommodations. Wherever or however you
                  choose to enjoy your El Nido meal, each dish is certified to be mouthwateringly fresh and delicious.
                </p>
              </div>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>So, that’s the hype of El Nido!</h2>
            </div>

            <div className="info">
              <p>
                Traveling to El Nido, Palawan is a dream come true for many travelers seeking an idyllic tropical escape. From the journey to the destination itself, every aspect of your trip is bound
                to be filled with awe-inspiring beauty and thrilling adventures.
              </p>
              <p>
                Whether you're island hopping, exploring the inland wonders, or enjoying a beachside meal, El Nido promises an unforgettable experience that will leave you yearning to return time and
                time again.
              </p>
              <p>So pack your bags, bring your sense of adventure, and get ready to immerse yourself in the sheer wonder of El Nido, Palawan!</p>
              <p>Make the booking experience hassle-free by booking your El Nido stay and flight at GalaGO!</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>

          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UltimateGuideToElNido;