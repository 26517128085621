import React from 'react';
import { Modal } from 'react-bootstrap';
import { ASSETS_API } from '../utils/apiRoutes';
import { AnimatedLogoLoadingStore } from '../store/AnimatedLogoLoadingStore';

interface dialogInterface {
  show?: boolean;
  onHide?: () => void;
  loadingText?: string;
}

const imagesUrl = ASSETS_API().public.gif;

export const AnimatedGalagoLoading = ({ show, onHide, loadingText }: dialogInterface) => {
  const { animatedLogoText, showAnimatedLogoLoading, setShowAnimatedLogoLoading } = AnimatedLogoLoadingStore();
  return (
    <Modal
      show={show ?? showAnimatedLogoLoading}
      onHide={() => (show ? !show : setShowAnimatedLogoLoading(false))}
      className="galago-modal-md"
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="p-0"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={`${imagesUrl}/gally.gif`} alt="" style={{ width: '50%', display: 'flex' }} />
        </div>
        <p style={{ textAlign: 'center', fontSize: '14px' }}>{show ? loadingText : animatedLogoText}</p>
      </Modal.Body>
    </Modal>
  );
};

export const AnimatedGalagoHotelLoading = ({ show, onHide, loadingText }: dialogInterface) => {
  return (
    <>
      <div
        className="flex flex-col items-center "
        style={{
          padding: '54px 40px',
        }}
      >
        <img src={`${imagesUrl}/gally.gif`} className="rounded-full w-[10%] flex mt-7 bg-white" style={{ boxShadow: '0 0 0 15px #E6F1F3' }} alt="Gally GIF" />
        <p className="mt-5 text-base text-center font-medium">{loadingText}</p>
      </div>
    </>
  );
};
