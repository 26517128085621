import 'react-loading-skeleton/dist/skeleton.css';
import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { NavBar } from '../../../components/NavBar';
import HotelSearchResults from './HotelSearchResults';
import HotelsSearchbox from './HotelsSearchbox';
import HotelFilters from './HotelFilters';

// type HotelSearchResultsPageProps = {};

const HotelSearchResultsPage = () => {
  const urlData = useLocation();
  const searchData = urlData?.state?.payload;

  const [searchCriteria, setSearchCriteria] = useState({
    location: searchData?.location,
    checkInDate: searchData?.checkInDate,
    checkOutDate: searchData?.checkOutDate,
    adults: searchData?.adults,
    rooms: searchData?.rooms,
    longitude: searchData?.longitude,
    latitude: searchData?.latitude,
  });
  const [enableQuery, setEnableQuery] = useState(true);

  const [searchParams] = useSearchParams();

  return (
    <>
      <NavBar />
      <main className="relative flex  w-full flex-col items-center justify-stretch bg-[#f9f9f9]">
        <div className="bg-primary-primary-100 mb-1 w-full" style={{ zIndex: 3, height: '110px' }}>
          <HotelsSearchbox searchParams={searchParams} inputData={searchCriteria} setInputData={setSearchCriteria} setEnableQuery={setEnableQuery} />
        </div>

        <section
          className="container text-text-colors-text-primary font-footnote flex flex-row items-start justify-center gap-[1.5rem] py-32 self-stretch text-left text-[1.25rem]"
          style={{ minHeight: '400px', zIndex: 1 }}
        >
          {/* <div className="search-container"> */}
          <HotelFilters />
          {/* </div> */}

          <HotelSearchResults searchParams={searchParams} inputData={searchCriteria} enableQuery={enableQuery} setEnableQuery={setEnableQuery} />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default HotelSearchResultsPage;
