import React, { useEffect, useState } from 'react';
import { CountriesHook } from '../hooks/CountriesHook';
import { SelectFloatingLabelFormControl } from './FormControl';
import '../resources/css/hotel-option-dropdown.scss';
// import { values } from 'lodash';

interface RestCountriesSelectInputInterface extends React.InputHTMLAttributes<HTMLSelectElement> {
  output: 'nationalities' | 'countries';
  props?: any;
  important?: boolean;
  label: string;
  setPassengers: (passengers: any) => void;
  passengers: any;
  index: number;
  setNationalityErrors: (nationalityErrors: any) => void;
  nationalityErrors: any;
  setCountryIssuedErrors?: (countryIssuedErrors: any) => void;
  countryIssuedErrors?: any;
  inputFocus?: string;
  setInputFocus?: (inputFocus: string) => void;
}

export const RestCountriesSelectInput = ({
  label,
  important,
  output,
  setPassengers,
  setNationalityErrors,
  nationalityErrors,
  setCountryIssuedErrors,
  countryIssuedErrors,
  passengers,
  index,
  inputFocus,
  setInputFocus,
  ...props
}: RestCountriesSelectInputInterface) => {
  const { countries, nationalities } = CountriesHook();

  let getOutput = countries;
  if (output === 'nationalities') {
    getOutput = nationalities;
  }

  const [showCountriesDropdown, setShowCountriesDropdown] = useState(false);
  const dropdownContainerRef = React.createRef<HTMLDivElement>();

  const handleInputChange = (inputValue: string) => {
    const dropdownContainer = dropdownContainerRef.current;
    if (!dropdownContainer) return;

    const countryElements = Array.from(dropdownContainer.querySelectorAll('div'));
    const matchingCountry = countryElements.find((country: any) => country.textContent.toLowerCase().startsWith(inputValue.toLowerCase()));

    if (matchingCountry) {
      const countryRect = matchingCountry.getBoundingClientRect();
      const dropdownRect = dropdownContainer.getBoundingClientRect();
      const scrollTop = countryRect.top - dropdownRect.top + dropdownContainer.scrollTop;
      dropdownContainer.scrollTop = scrollTop;
    }
  };

  // set default state for nationality
  useEffect(() => {
    if (!passengers?.[index - 1]?.nationality) {
      setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ nationality: 'PH' } } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const selectedOption = options.find(option => option.value === e.target.value);
  // setSelectedOption(selectedOption);
  const nationalityLabel = getOutput?.find((option: { value: string; label: string }) => option.value === passengers?.[index - 1]?.nationality)?.label;
  const countryLabel = getOutput?.find((option: { value: string; label: string }) => option.value === passengers?.[index - 1]?.countryIssued)?.label;
  return (
    <div className=" w-full relative transparent-line floating-label ">
      <SelectFloatingLabelFormControl
        placeholder={`${label} ${important ? ' *' : ''} `}
        label={`${label} ${important ? ' *' : ''} `}
        type="text"
        onChange={(e) => handleInputChange(e.target.value)}
        onClick={() => {
          setShowCountriesDropdown(true);
          output === 'nationalities' ? setInputFocus?.('nationality') : setInputFocus?.('countryIssued');
        }}
        success={output === 'nationalities' ? passengers?.[index - 1]?.nationality || inputFocus === 'nationality' : passengers?.[index - 1]?.countryIssued || inputFocus === 'countryIssued'}
        error={output === 'nationalities' ? nationalityErrors[index - 1] && !passengers?.[index - 1]?.nationality : countryIssuedErrors[index - 1] && !passengers?.[index - 1]?.countryIssued}
        value={output === 'nationalities' ? nationalityLabel : countryLabel}
        style={{
          height: '58px',
          borderColor: output === 'nationalities' && passengers?.[index - 1]?.nationality ? '#4fa2b4' : output !== 'nationalities' && passengers?.[index - 1]?.countryIssued ? '#4fa2b4' : '',
        }}
        receivedValue={output === 'nationalities' ? (passengers?.[index - 1]?.nationality ? true : false) : passengers?.[index - 1]?.countryIssued ? true : false}
        useBirthdayStyle={true}
      />

      {showCountriesDropdown && (
        <div
          ref={dropdownContainerRef}
          onMouseLeave={() => {
            setShowCountriesDropdown(false);
            output === 'nationalities'
              ? passengers?.[index - 1]?.nationality
                ? setNationalityErrors?.((prevErrors: boolean[]) => {
                    const newErrors = [...prevErrors] as boolean[];
                    newErrors[index - 1] = false;
                    return newErrors;
                  })
                : setNationalityErrors?.((prevErrors: boolean[]) => {
                    const newErrors = [...prevErrors] as boolean[];
                    newErrors[index - 1] = true;
                    return newErrors;
                  })
              : passengers?.[index - 1]?.countryIssued
              ? setCountryIssuedErrors?.((prevErrors: boolean[]) => {
                  const newErrors = [...prevErrors] as boolean[];
                  newErrors[index - 1] = false;
                  return newErrors;
                })
              : setCountryIssuedErrors?.((prevErrors: boolean[]) => {
                  const newErrors = [...prevErrors] as boolean[];
                  newErrors[index - 1] = true;
                  return newErrors;
                });
          }}
          className="dropdown-countries generalsans-medium"
        >
          {' '}
          {getOutput?.map((value: { value: string; label: string }) => {
            return (
              <div
                onClick={() => {
                  // Set the country label for UI
                  if (output === 'nationalities') {
                    setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ nationality: value.value } } } });
                  } else {
                    setPassengers({ ...passengers, ...{ [index - 1]: { ...passengers?.[index - 1], ...{ countryIssued: value.value } } } });
                  }
                  setShowCountriesDropdown(false);
                }}
                key={index}
              >
                {value.label}
              </div>
            );
          })}
        </div>
      )}
      {/* <select className="form-select w-full" aria-label="" {...props}>
        <option value="" disabled selected>
          {label} {important ? ' *' : ''}
        </option>
        {getOutput?.map((value: { value: string; label: string }, index: any) => {
          return (
            <option value={value.value} key={index} selected={value.value === 'Filipino' || value.value === 'PH' ? true : false}>
              {value.label}
            </option>
          );
        })}
      </select> */}
      {/* <label htmlFor="floatingSelectGrid">
        {label}
        {important ? ' *' : ''}
      </label> */}
    </div>
  );
};
