import React from 'react';
import { EmailSentStore } from '../../store/EmailSentStore';
import { AlertBox } from '../../components/AlertBox';
import { GalagoButton } from '../../components/Button';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import { EmailSvg } from '../../resources/svg/EmailSvg';
import EmailHook from '../../hooks/EmailHook';

export default function EmailSentDialog() {
  const { resendEmailApi } = EmailHook();
  const { showEmailSentDialog, setShowEmailSentDialog } = EmailSentStore();
  const { userEmail } = EmailDialogStore();
  const [disableResend, setDisableResend] = React.useState(false);

  const handleResendEmail = async () => {
    setDisableResend(true);
    const response = await resendEmailApi(userEmail as string);
    const { statusCode } = response.data;
    if (statusCode === 200) {
      setDisableResend(false);
      setShowEmailSentDialog(false);
    }
  };

  return (
    <AlertBox size={'lg'} show={showEmailSentDialog} onHide={(): void => setShowEmailSentDialog(false)}>
      <div className="d-flex flex-column align-items-center py-5 px-8">
        <EmailSvg _width={140} />
        <p className="font-medium fs-3 text-center mb-4 mt-4">Verification Link Sent!</p>
        <p className="text-center">
          You've got mail at <span style={{ color: '#016E7F' }}>{userEmail}.</span> Check your inbox (or spam) and confirm your email address to continue your signup.
        </p>
        <div className="d-flex flex-column pl-8 pr-8 pt-8 gap-2 w-[70%] relative">
          <GalagoButton onClick={() => handleResendEmail()} disabled={disableResend} label={'Resend Email'} className="w-100" />
          <GalagoButton onClick={() => setShowEmailSentDialog(false)} label={'Close'} className="text-decoration-none" btn="link" />
        </div>
      </div>
    </AlertBox>
  );
}
