import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import React from 'react';
import '../../../resources/css/articles.scss';

const PhilippineRoadTrip = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Philippines+road+trip+guide_+Destinations+you+can+reach+by+car.jpg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Philippines Road Trip Guide Destinations You Can Reach by Car
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Philippines Road Trip Guide: Destinations You Can Reach by Car</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Zoe Alcazaren</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Nov 6, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">7 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              Sometimes, the hustle and bustle of Metro Manila isn’t the vibe. Fortunately, there are a handful of tranquil, exciting, and picturesque destinations just a few hours from the city where
              you can relax, recoup, and recharge.
            </p>
            <p>
              Whether you’re an{' '}
              <a href="/there-are-3-types-of-travelers:-which-one-are-you?" target="_blank" rel="noreferrer">
                adventure-seeker
              </a>
              , culinary enthusiast, or just after something different, these road trip destinations near Manila are options you need to shortlist!
            </p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/01+Ferdie+Drone+via+Pexels.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Ferdie Drone via Pexels</p>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Why drive instead of fly?</h2>
            </div>

            <div className="info_grp">
              <p>
                With over 7,000 islands to explore, opting for a road trip instead of booking a flight to Palawan or Boracay may seem counterintuitive—not for the budget traveler! Flights to premiere
                destinations in the Philippines can get <span>notoriously expensive</span>, so renting a car or taking a bus is an inexpensive solution for the wander-lusting Manileño.
              </p>
              <p>
                Another reason you might hit the road instead of taking the air is the <span>flexibility</span> a road trip allows. If you drive past a roadside gem, nothing is stopping you from
                pulling over and checking it out. Whether you prefer to drive early in the morning or late at night, you won’t have to worry about making it to the airport on time to catch a flight.
              </p>
              <p>
                Finally, road-trippers can bid farewell to the packing restrictions an airport enforces. With enough space in your vehicle, you can pack camping gear, board games, and ingredients for
                a potluck.
              </p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/02+Alice+Donovan+Rouse.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Alice Donovan Rouse via Unsplash</p>
            </div>
          </div>

          <div className="extensive_story">
            <div className="sub_content">
              <h2>Road trip packing checklist</h2>
              <p>
                Whether driving an hour out of Manila or heading toward a farther destination, you can’t be too prepared. Here is a comprehensive road{' '}
                <a href="/whats-in-our-beach-bags:-island-hopping-essentials" target="_blank" rel="noreferrer">
                  trip packing checklist
                </a>{' '}
                to ensure a smooth and enjoyable mini-holiday.
              </p>
            </div>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Bug Repellent</h3>
                  <p>
                    If you aren’t trying to swat a flying insect out of the way, are you really in the Philippines? While many Philippine destinations are notorious for the mosquitos they attract, a
                    heavy-duty bug spray can keep them at bay.
                  </p>
                  <p>Find a full-coverage DEET-free repellent you can throw into your backpack. Combat humidity with a non-greasy product, picking sprays over thick lotions.</p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Power Bank</h3>
                  <p>
                    As the saying goes, never underestimate the traffic situation in the Philippines. If you can’t leave Manila at a low-peak hour, you’ll want to equip yourself for long hours on the
                    road.
                  </p>
                  <p>
                    Nowadays, every traveler’s prime source of entertainment when spending long hours on the road is a smartphone. While constant streaming or mobile gaming may last you a few hours,
                    you may find yourself scrambling to catch the last few minutes of your favorite show with an hour left of your drive.
                  </p>
                  <p>
                    If you’re constantly depleting your battery, it might be time to invest in a power bank. Choose one with at least 10,000 mAh and dual USB ports for multi-device charging,
                    especially if you’re a heavy gadget user.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Sunblock</h3>
                  <p>
                    Don’t be fooled—even the rainy season can bring about nasty cases of sunburn, especially during sunshowers. Pack a broad-spectrum sunscreen with a sun protection factor (SPF) of at
                    least 50 to protect against the harsh Philippine sun. For easy access, use a spray.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Reusable Water Bottle</h3>
                  <p>
                    You can’t underestimate the benefits of hydration. Being on a tropical island often leaves travelers itching for water. Be eco-conscious about your hydration habits with a
                    reusable, insulated water bottle—your insides (and the environment) will thank you for it.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="head">
              <h2>Top road trip destinations near Manila</h2>
              <p>Are you ready to hit the road? Below are the top destinations for a much-needed nearby getaway.</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/03+Jerome+Vergara+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Jerome Vergara via Unsplash</p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Tagaytay City</h3>
              <p>Travel time: 1 - 1.5 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/04+Tagaytay+-+Christian+Enmon+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Christian Enmon via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>
                If you’re road-tripping to escape Metro Manila’s sweltering heat, Tagaytay City is the place to be. Complete with chilly weather and scenic landscapes, Tagaytay City is the go-to
                weekend retreat for a reason.
              </p>
              <p>
                No matter how many times you’ve visited Tagaytay, you can’t go wrong reserving a table at any of the lakeside restaurants overlooking <span>Taal Volcano</span>, the smallest active
                volcano in the world. You’ll get even better views at the <span>Sky Ranch Sky Eye</span>, one of the country's tallest Ferris wheels.
              </p>
              <p>
                If you are lucky, you might snag a reservation at <span>Antonio’s</span> for brunch or <span>Bag of Beans</span>.
              </p>
              <p>
                Other unique Tagaytay hotspots worth a visit are <span>The Puzzle Mansion</span>, a museum housing one of the most extensive collections of finished puzzles, and{' '}
                <span>Museo Orlina</span>, a museum rife with beautiful glass sculptures.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Subic</h3>
              <p>Travel time: 2 - 4 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/05+Subic+-+pixmike+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by pixmike via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>Are you looking for a quick and accessible beach getaway? Subic is the perfect balance of adventure and relaxation.</p>
              <p>
                It’s perhaps most famed for its <span>Inflatable Island</span>, a floating playground spanning 4,200 square meters (about eight basketball courts). It’s perfect for adrenaline junkies
                and laid-back sunbathers alike.
              </p>
              <p>
                Outdoorsy types might prefer to book a stay at <span>Glampz</span>, a unique, nature-centric getaway neighboring Subic’s popular <span>Zoobic Safari</span>. In the same thread, animal
                enthusiasts might enjoy an afternoon at <span>Ocean Adventure</span>, where you’ll come face-to-face with sharks, manta rays, eels, and other fascinating sea creatures.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Pampanga</h3>
              <p>Travel time: About 1 hour</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/06+Pampanga+-+Cody+McLain+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Cody McLain via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>
                Known as the Culinary Capital of the Philippines, Pampanga is an idyllic destination for food enthusiasts looking to try something traditional or eccentric. <span>Aling Lucing’s</span>{' '}
                sisig is a crowd-pleasing dish that reminds us why modern iterations of the popular dish don’t quite live up to its pioneering recipe.
              </p>
              <p>
                Another culinary can’t miss is <span>Bale Dutung</span>, with dishes by Chef Claude Tayag. Menu items that belong on your list of must-tries include the ensaladang pako, Claude 9
                talangka rice, lechon tortilla, sisig babi, and kare-kareng laman dagat.
              </p>
              <p>
                If a day of thrill-seeking is more your vibe, visit <span>Sandbox Pampanga</span>. This 2-hectare outdoor park boasts numerous obstacle courses, a zipline, an aerial walk, and an
                adventure tower. Feeling daring? Give the giant swing a shot!
              </p>
              <p>
                Your adrenaline tour need not end at Sandbox. Bring the fun over to <span>Pradera Verde</span> for an afternoon of wakeboarding or to <span>Aqua Park</span>, one of the largest water
                parks in Asia.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Laguna</h3>
              <p>Travel time: 45 minutes - 1 hour</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/07+laguna+-+Marty+Garcia+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Marty Garcia via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>Are you looking to head south for a quick breather? Drop by Laguna for some fresh air, lush scenery, and historical tours.</p>
              <p>
                Whether an avid Instagram photographer, nature lover, or sightseer, visiting the <span>seven crater lakes</span> across San Pablo can provide much-needed quiet and time to reflect.
              </p>
              <p>
                After a long day of lake-going, you can retire at <span>Casa San Pablo</span>, a quirky, playfully architectured bed and breakfast home to the most delicious Laguna cuisine. We
                recommend trying the adobong manok sa gata, kulawo, and ginataang hipon.
              </p>
              <p>
                If you’re looking for something livelier, consider dropping by the <span>Aquascape Lake Caliraya</span>, where you can spend an afternoon biking, kayaking, motorboating, and jet
                skiing. ‘
              </p>
              <p>
                While we’re still waiting for the Philippines’ equivalent of Universal Studios or Disneyland, you can always drop by the <span>Enchanted Kingdom</span>, which is now home to a handful
                of new and exciting rides.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Rizal</h3>
              <p>Travel time: About 1 hour</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/08+Rizal+-+Paolo+Syiaco+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Paolo Syiaco via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>Just a hop and skip away from the city center is Rizal, a melting pot of attractions and activities spanning art museums to adventurous hikes.</p>
              <p>
                The <span>Pinto Art Museum</span> is always a crowd favorite. Here, you’ll feast your eyes on works by veteran and emerging artists in an almost otherworldly setting akin to Grecian
                architecture. We recommend sitting down for brunch at Cafe Rizal if you arrive early. The pochero con fabada is to die for.
              </p>
              <p>
                If something active is more your scene, you can try your hand (or feet) at a hike atop <span>Mount Daraitan</span>. It’s a hike suitable for beginners. On your way down, you can cool
                off at the <span>Tinipak River</span>, whose emerald-green waters and stunning rock formations are breathtaking.
              </p>
              <p>
                One of Rizal’s little-known gems is the <span>Pililla Wind Farm</span>, home to gigantic windmills similar to those in Ilocos Norte. It’s also the perfect destination for cyclists—the
                views are nothing short of awe-inspiring!
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Batangas</h3>
              <p>Travel time: 1.5 -2 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/09+Batangas+-+Bhanvi+Mehta+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Bhanvi Mehta via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>
                Just a few hours south of Manila is Batangas, home to some of the most popular beach resorts in the country. From <span>Canyon Cove Hotel and Spa</span> to{' '}
                <span>Pico Sands Hotel</span>, you’ll find beachfront destinations that mimic international destinations like Greece and Italy almost anywhere.
              </p>
              <p>
                If you’re dropping by Lipa, we recommend staying at <span>The Farm at San Benito</span>, a luxury wellness retreat known for its holistic sanctuary programs. Here, you can indulge in
                dozens of aesthetic treatments, Yin yoga classes, functional fitness classes, and meditation salas.
              </p>
              <p>
                For sightseers, you can’t miss the <span>Fortune Islands</span>, a cliff diving point with gorgeous white-sand beaches and glistening blue waters. The most breathtaking part of this
                once-posh abandoned resort is the Grecian Acropolis, which still rests upon a limestone cliff. Instagram simply can’t resist.
              </p>
              <p>
                Batangas is also renowned for its hiking destinations. You'll be spoiled for choice, whether a beginner, intermediate, or veteran hiker. Dare to take on new heights (literally) at{' '}
                <span>Mt. Batulao</span>, <span>Mt. Gulugod-Baboy</span>, <span>Mt. Talamitam</span>, and <span>Mt. Maculot</span>.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>La Union</h3>
              <p>Travel time: 6 - 8 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/10+La+Union+-+Kurt+Liwanag+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Kurt Liwanag via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>Surfers unite! Your best chance at catching a (somewhat nearby) wave is at La Union, a renowned weekend beach destination for water sports lovers.</p>
              <p>
                If you can snag a room at the <span>Thunderbird Poro Resort</span>, this Santorini-inspired hotel boasts spectacular views off a 100-foot cliff. Plus, it’s home to eight unique bars
                and restaurants—a one-way street to a happy tummy.
              </p>
              <p>
                For travelers on a budget,<span>San Juan</span> is rife with more affordable hostels, where you’ll meet local and international wanderlusters from all walks of life.
              </p>
              <p>
                If you’ve never been to La Union, you’ll be surprised to know it isn’t just the Philippines’ number one surfing destination. Here, you can visit the <span>Red Clay Pagdamilian</span>,
                a popular pottery studio where you can learn the basics of creating your own vessel (and take it home, of course).
              </p>
              <p>
                Another quaint and adorable must-see is the <span>Gapuz Grapes Farm</span>, where you can pick grapes under the sun. We recommend visiting between March and May, when grape season is
                at its peak.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Bataan</h3>
              <p>Travel time: 2.5 - 3 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/11+Bataan+-+Al+Reile+Dela+Torre+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Al Reile Dela Torre via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>
                Bataan is the Philippines’ top heritage and ecotourism destination, and it’s just a quick drive from the city center. Perhaps the ultimate to-do in this area is visiting{' '}
                <span>Las Casas Filipinas De Azucar</span>, a 400-hectare resort with over 30 heritage homes from the Philippines’ glory days.
              </p>
              <p>
                You can soak in Old Manila on the <span>Heritage Walking Tour</span>, where you’ll come across locals in traditional Filipino clothing, stunning architecture, and the beautiful{' '}
                <span>Paseo Escolta</span>.
              </p>
              <p>
                Another Bataan hotspot garnering attention on social media is <span>Mariveles</span>, home to the <span>Hidden Coves at Five Fingers</span>. Here, you can enjoy an adrenaline-filled
                cliff-diving afternoon off <span>Natsu Lagoon</span> or <span>Longos Kawayan Point</span>.
              </p>
              <p>
                Are you an animal lover or a self-proclaimed conservationist? From August to February, the turtles at the Pawikan Conservation Center start laying their eggs, so you won’t want to miss
                the <span>Pawikan Festival</span> in November when guests can help release hatchlings back into the sea.
              </p>
            </div>
          </div>

          <div className="storywithTitleImageParagraph">
            <div className="subtitle">
              <h3>Baguio</h3>
              <p>Travel time: 6 - 8 hours</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Philippines+Road+Trip+Guide/12+Baguio+-+Carmela+Asistio+via+Unsplash.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Carmela Asistio via Unsplash</p>
            </div>

            <div className="info_grp">
              <p>
                If you don’t mind a longer drive, Baguio is worth your while. Book a stay at the iconic <span>Manor at Camp John Hay</span>, where you can enjoy the crisp air and breathtaking mountain
                views in a jacket you probably don’t get to{' '}
                <a href="/beat-the-heat!-explore-the-coldest-places-in-the-philippines" target="_blank" rel="noreferrer">
                  wear under Manila heat
                </a>
                .
              </p>

              <p>
                You can take your family boating at <span>Burnham Park</span>, the “park of all parks” home to lush greens and blossoming flowers you can smell as you walk past.
              </p>
              <p>
                Art lovers will love a pit stop at <span>BenCab Museum</span>, home to dozens of contemporary Filipino art pieces and seasonal exhibitions. Perhaps the museum’s most famed display is
                the bulol collection. The bulol is an Ifugao rice god figure thought to bring about power and wealth.
              </p>
            </div>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Ready to GO! on a road trip in the Philippines?</h2>
            </div>

            <div className="info">
              <p>
                If you want to take advantage of the long weekend but don’t have the time or budget to take a flight, these road trip destinations near Manila should provide a much-needed travel fix.
              </p>
              <p>Once you’ve shortlisted your destinations, the next step is to decide what you want to do while you’re away!</p>
              <p>Shop options with GalaGO! We’ll provide the best possible rates for the activities you’re eyeing.</p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhilippineRoadTrip;
