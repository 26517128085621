import { SvgProps } from './SvgProps';

export const CopyLinkSVG = ({ _width = 24, _height = 24, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3086 12.209V15.959C16.3086 19.084 15.0586 20.334 11.9336 20.334H8.18359C5.05859 20.334 3.80859 19.084 3.80859 15.959V12.209C3.80859 9.08398 5.05859 7.83398 8.18359 7.83398H11.9336C15.0586 7.83398 16.3086 9.08398 16.3086 12.209Z"
        stroke={_color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4753 6.87435V9.62435C20.4753 11.916 19.5586 12.8327 17.2669 12.8327H16.3086V12.2077C16.3086 9.08268 15.0586 7.83268 11.9336 7.83268H11.3086V6.87435C11.3086 4.58268 12.2253 3.66602 14.5169 3.66602H17.2669C19.5586 3.66602 20.4753 4.58268 20.4753 6.87435Z"
        stroke={_color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
