import React, { ReactElement, useState } from 'react';
// import { ASSETS_API } from '../../../utils/apiRoutes';
import { Star } from '../../../resources/svg/StarSvg';
// import { hotelStars } from '../../../utils/data.utils';
import { CopyLinkSVG } from '../../../resources/svg/CopyLinkSVG';
import { EmailIconSVG } from '../../../resources/svg/EmailIconSVG';
// import { Facebook, FacebookIcon, TwitterIcon } from 'lucide-react';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { MessengerIcon } from '../../../resources/svg/MessengerSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { EmbedIcon } from '../../../resources/svg/EmbedSVG';

type ShareHotelModalType = {
  overviewData: { images: any; propertyName: any; facilities: string[]; bookingDetails: string; rating: number; address: string };
};

const ShareHotelModal = ({ overviewData }: ShareHotelModalType) => {
  const starRating: ReactElement[] = [];

  for (let index = 0; index < overviewData.rating; index++) {
    starRating.push(
      <span className="mr-0.5" key={index}>
        <Star _width={16} _height={16} _color="#FF9843" />
      </span>
    );
  }

  // const publicImageApi = ASSETS_API().public.images;

  const [isCopyBtnHovered, setCopyBtnIsHovered] = useState(false);
  const [isEmailBtnHovered, setEmailBtnIsHovered] = useState(false);
  const [isFacebookBtnHovered, setFaceBtnIsHovered] = useState(false);
  const [isMessengerHovered, setMessengerBtnIsHovered] = useState(false);
  const [isTwitterHovered, setTwitterBtnIsHovered] = useState(false);
  const [isEmbedHovered, setEmbedBtnIsHovered] = useState(false);

  return (
    <div className=" rounded-xl bg-white-white-100  w-full overflow-hidden flex flex-col">
      <div className="self-stretch flex flex-col items-start justify-center pt-[1.5rem] px-[2rem] pb-[2rem] gap-4 text-[1rem] text-text-colors-text-primary">
        <div className="self-stretch flex flex-row items-center justify-start pb-4 gap-3 border-b-[1px] border-solid border-border-colors-border-primary">
          <img className="rounded-md col-4  h-28 object-cover drop-shadow-md" alt="" src={overviewData.images[0]} />

          <div className="flex flex-col gap-2 col">
            <div className="flex flex-row gap-2 align-center">
              <span className="font-semibold text-xl">{overviewData.propertyName}</span>
              <span className="m-0">{starRating}</span>
            </div>
            <span className="text-sm textColor generalsans-regular">{overviewData.address}</span>
          </div>
        </div>

        <div className="flex flex-col items-start justify-start gap-2.5 textColor w-full">
          <div className="self-stretch flex flex-row items-start justify-start gap-[0.75rem]">
            <button
              className="flex-1 rounded-md  flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setCopyBtnIsHovered(true)}
              onMouseLeave={(e) => setCopyBtnIsHovered(false)}
            >
              <CopyLinkSVG _width={24} _height={24} _color={isCopyBtnHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Copy Link</span>
            </button>

            <button
              className="flex-1 rounded-md flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setEmailBtnIsHovered(true)}
              onMouseLeave={(e) => setEmailBtnIsHovered(false)}
            >
              <EmailIconSVG _width={24} _height={24} _color={isEmailBtnHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Email</span>
            </button>

            <button
              className="flex-1 rounded-md flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setFaceBtnIsHovered(true)}
              onMouseLeave={(e) => setFaceBtnIsHovered(false)}
            >
              <FacebookSvg _width={24} _height={24} _color={isFacebookBtnHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Facebook</span>
            </button>
          </div>

          <div className="self-stretch flex flex-row items-start justify-start gap-[0.75rem] ">
            <button
              className="rounded-md w-[9.583rem] flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setMessengerBtnIsHovered(true)}
              onMouseLeave={(e) => setMessengerBtnIsHovered(false)}
            >
              <MessengerIcon _width={24} _height={24} _color={isMessengerHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Messenger</span>
            </button>

            <button
              className="rounded-md box-border w-[9.583rem] flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setTwitterBtnIsHovered(true)}
              onMouseLeave={(e) => setTwitterBtnIsHovered(false)}
            >
              <TwitterSvg _width={24} _height={24} _color={isTwitterHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Twitter</span>
            </button>

            <button
              className="rounded-md box-border  w-[9.583rem] flex flex-row items-center justify-center py-[0.63rem] px-[1.25rem] gap-[0.5rem] btn-style"
              onMouseEnter={(e) => setEmbedBtnIsHovered(true)}
              onMouseLeave={(e) => setEmbedBtnIsHovered(false)}
            >
              <EmbedIcon _width={24} _height={24} _color={isEmbedHovered ? '#016e7f' : '#333'} />
              <span className="generalsans-medium text-sm">Embed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareHotelModal;
