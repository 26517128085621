import { SvgProps } from './SvgProps';

export const HotelSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="hotels"
        d="M11.4 3.16667H7.8C7.64087 3.16667 7.48826 3.2369 7.37574 3.36193C7.26321 3.48695 7.2 3.65652 7.2 3.83333V5.83333H6V1.16667C6 0.989856 5.93679 0.820286 5.82426 0.695262C5.71174 0.570238 5.55913 0.5 5.4 0.5H0.6C0.44087 0.5 0.288258 0.570238 0.175736 0.695262C0.0632141 0.820286 0 0.989856 0 1.16667V11.8333C0 12.0101 0.0632141 12.1797 0.175736 12.3047C0.288258 12.4298 0.44087 12.5 0.6 12.5H11.4C11.5591 12.5 11.7117 12.4298 11.8243 12.3047C11.9368 12.1797 12 12.0101 12 11.8333V3.83333C12 3.65652 11.9368 3.48695 11.8243 3.36193C11.7117 3.2369 11.5591 3.16667 11.4 3.16667ZM3.6 2.5H4.8V3.83333H3.6V2.5ZM2.4 9.16667H1.2V7.83333H2.4V9.16667ZM2.4 6.5H1.2V5.16667H2.4V6.5ZM2.4 3.83333H1.2V2.5H2.4V3.83333ZM4.8 9.16667H3.6V7.83333H4.8V9.16667ZM4.8 6.5H3.6V5.16667H4.8V6.5ZM10.2 9.16667H9V7.83333H10.2V9.16667ZM10.2 6.5H9V5.16667H10.2V6.5Z"
        fill={_color}
      />
    </svg>
  );
};
