import React from 'react';
import Footer from '../../../components/Footer';
import BookingConfirmationContainer from './BookingConfirmationContainer';
import { NavBar } from '../../../components/NavBar';

const BookingConfirmationPage = () => {
  return (
    <>
      <NavBar />
      <main className="flex flex-col items-center justify-center">
        <BookingConfirmationContainer />
      </main>
      <div className="p-4"></div>
      <Footer />
    </>
  );
};

export default BookingConfirmationPage;
