import { SvgProps } from './SvgProps';

export const CabinSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.30364 12.1003H9.13102C9.44332 12.1003 9.69649 12.3535 9.69649 12.6658V12.6658C9.69649 12.9781 9.44332 13.2313 9.13102 13.2313H6.30364C4.74292 13.2313 3.47626 11.9646 3.47626 10.4039V5.88006C3.47626 5.56776 3.72943 5.31458 4.04173 5.31458V5.31458C4.35404 5.31458 4.60721 5.56776 4.60721 5.88006V10.4039C4.60721 11.3426 5.36495 12.1003 6.30364 12.1003ZM7.10661 4.41548C7.54769 3.9744 7.54769 3.25625 7.10661 2.81518C6.66554 2.37411 5.94739 2.37411 5.50632 2.81518C5.06525 3.25625 5.06525 3.9744 5.50632 4.41548C5.94739 4.8622 6.65989 4.8622 7.10661 4.41548ZM7.71733 6.44554C7.71733 5.82351 7.2084 5.31458 6.58638 5.31458H6.30364C5.68161 5.31458 5.17269 5.82351 5.17269 6.44554V9.83839C5.17269 10.7771 5.93042 11.5348 6.86911 11.5348H9.26269C9.5658 11.5348 9.85649 11.6552 10.0708 11.8696L11.3109 13.1097C11.5342 13.333 11.8963 13.333 12.1196 13.1097V13.1097C12.3429 12.8864 12.3429 12.5243 12.1196 12.301L9.99165 10.1731C9.77732 9.9588 9.48663 9.83839 9.18352 9.83839H7.71733V6.44554Z"
        fill={_color}
      />
    </svg>
  );
};
