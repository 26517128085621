import axios from 'axios';
import { useState } from 'react';
import { createBookingFlightTransactionApiRoute, preBookFlightApiRoute, preBookFlightV2ApiRoute, preBookGalagoApiRoute, preBookGalagoV2ApiRoute, X_API_KEY } from '../utils/apiRoutes';
import { useNavigate } from 'react-router';

type validateFareSourceCodeProps = {
  flightType: string;
  departureConversationId: string;
  returnConversationId?: string;
  departureFareSourceCode: string;
  returnFareSourceCode?: string;
};

export const FlightBookingHooks = () => {
  const navigate = useNavigate();
  const [validateFareSourceCodeResponse, setValidateFareSourceCodeResponse] = useState<{ [index: string]: any }>();
  // eslint-disable-next-line
  const [transactionCreated, setTransactionCreated] = useState<boolean>(false);
  // eslint-disable-next-line
  const [transactionId, setTransactionId] = useState<string>();

  // TODO: mystifly fare source code validation, this function is not used anymore and should be removed in the future
  const validateFareSourceCode = async (props: validateFareSourceCodeProps) => {
    // console.log(FareSourceCode);
    if (axios.defaults.headers.common['Authorization'] === undefined) {
      axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    }
    const urlParam = props.flightType === 'one-way' ? 'one-way' : 'roundtrip';

    const payload =
      props.flightType === 'one-way'
        ? {
            fareSourceCode: props.departureFareSourceCode,
            conversationId: props.departureConversationId,
          }
        : {
            departureRequest: {
              fareSourceCode: props.departureFareSourceCode,
              conversationId: props.departureConversationId,
            },
            returnRequest: {
              fareSourceCode: props.returnFareSourceCode,
              conversationId: props.returnConversationId,
            },
          };

    try {
      const a = await axios
        .request({
          method: 'post',
          url: preBookFlightApiRoute(urlParam),
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setValidateFareSourceCodeResponse(res.data);
          return res.data;
        })
        .catch((e) => {
          return e;
        });
      return a;
    } catch (error) {
      return error;
    }
  };

  // mystifly prebooking
  const validateFareSourceCodeV2 = async (props: { flightType: string; fareSourceCode: string; conversationId: string }) => {
    if (axios.defaults.headers.common['Authorization'] === undefined) {
      axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    }
    const urlParam = props.flightType === 'one-way' ? 'one-way' : 'roundtrip';

    const payload = {
      fareSourceCode: props.fareSourceCode,
      conversationId: props.conversationId,
    };

    const url = urlParam === 'one-way' ? preBookFlightApiRoute(urlParam) : preBookFlightV2ApiRoute(urlParam);

    try {
      const a = await axios
        .request({
          method: 'post',
          url: url,
          data: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setValidateFareSourceCodeResponse(res.data);
          return res.data;
        })
        .catch((e) => {
          return e;
        });
      return a;
    } catch (error) {
      return error;
    }
  };

  // TODO: galago pre-booking this function is not used anymore and should be removed in the future
  const galagoPreBooking = async (flightType: string, payload: any) => {
    try {
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['x-api-key'] = X_API_KEY;
      }
      const a = await axios.request({
        method: 'post',
        url: preBookGalagoApiRoute(flightType === 'one-way' ? 'one-way' : 'roundtrip'),
        data: payload,
        headers: { 'Content-Type': 'application/json' },
      });
      return a;
    } catch (error) {
      return error;
    }
  };

  // galago prebooking
  const galagoPreBookingV2 = async (flightType: string, payload: any) => {
    try {
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['x-api-key'] = X_API_KEY;
      }

      let _flightType: string = flightType === 'one-way' ? 'one-way' : 'roundtrip';

      const url = _flightType === 'one-way' ? preBookGalagoApiRoute('one-way') : preBookGalagoV2ApiRoute('roundtrip');
      // console.log(url);
      const a = await axios.request({
        method: 'post',
        url: url,
        data: payload,
        headers: { 'Content-Type': 'application/json' },
      });
      return a;
    } catch (error: any) {
      if (error.response.data.message !== 'TransactionFlight already pre-booked!') {
        navigate('/something-went-wrong');
      }
      return error;
    }
  };

  // used in FlightResults.tsx
  const createFlightTransaction = async (payload: { [index: string]: any }) => {
    try {
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['x-api-key'] = X_API_KEY;
      }
      return await axios.post(createBookingFlightTransactionApiRoute(), payload);
    } catch (error) {
      return error;
    }
  };

  const saveBookingFlight = async (payload: any) => {
    // console.log(payload);
  };

  return {
    validateFareSourceCode,
    validateFareSourceCodeV2,
    galagoPreBooking,
    galagoPreBookingV2,
    saveBookingFlight,
    createFlightTransaction,
    validateFareSourceCodeResponse,
    transactionCreated,
    transactionId,
  };
};
