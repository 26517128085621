import React from 'react';
import '../../resources/css/homepage.scss';
import { Col, Container, Row } from 'react-bootstrap';
// import PromoCard from '../../components/Home/PromoCard';
import TravelGuides from '../../components/Home/TravelGuides';
import '../../resources/css/carousel.scss';
import '../../components/Home/Carousel_Slide';
import ScrollerComponent from '../../components/Home/ScrollerComponent';
import { ASSETS_API } from '../../utils/apiRoutes';
// import { ServerOffIcon } from 'lucide-react';

const Homepage = () => {
  const imagesUrl = ASSETS_API().resources.images;
  return (
    <>
      {/* DO NOT DELETE, HIDDEN DUE TO UNAVAILABILITY OF PROMOS */}
      {/* <Container>
        <section id="go-on-adventure" className="adventure">
          <div className="promo-container">
            <div className="promo-title">
              <h1 className="m-0">Go On An Adventure!</h1>
              <h2 className="m-0">
                Ready to explore the Philippines and the world? <br /> Check out our latest travel promos!
              </h2>
            </div>

            <div className="promo-grid">
              <PromoCard />
            </div>
          </div>
        </section>
      </Container> */}

      <Container>
        <section id="go-on-adventure" className="travel-guides">
          <div className="travel-container">
            <div className="travel-title">
              <h1 className="m-0">Travel Guides</h1>
              <h2 className="m-0">
                Looking for travel inspiration? <br /> Don't miss out on the latest travel updates, guides and itineraries!
              </h2>
            </div>

            <div className="travel-column">
              <TravelGuides />

              <span className="travel-btn">
                <a href="/#/blogs" className="text-white" style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                  Read more
                </a>
              </span>
            </div>
          </div>
        </section>
      </Container>

      <div id="cta">
        <p className='cta__title'>"Unforgettable experiences crafted for you by an all-Filipino team of travel experts."</p>
      </div>

      

      <div id="divider"></div>


      <Container>
        <div className="scroller-container">
          <Row className="d-flex align-items-center justify-content-center carousel">
            <Col lg={4} sm={12}>
              <h2 className="fw-bold" style={{ fontSize: '28px', wordBreak: 'break-word', color: '#333333', fontFamily: 'Syne, sans-serif', margin: '0' }}>
                Discover the best of the Philippines and beyond!
              </h2>
            </Col>
            <Col lg={8}>
              <ScrollerComponent />
            </Col>
          </Row>
        </div>

        <section className="acc-aff">
          <Container className="acc-aff-container">
            <h1>Accreditation and Affiliations</h1>
            <div className="acc-aff-grid">
              <div className="partner-box">
                <img src={`${imagesUrl}/homepage/dot.png`} loading="lazy" alt="" />
              </div>
              <div className="partner-box">
                <img src={`${imagesUrl}/homepage/hoho-circled.png`} loading="lazy" alt="" />
              </div>
              <div className="partner-box">
                <img src={`${imagesUrl}/homepage/philtoa.png`} loading="lazy" alt="" />
              </div>
              <div className="partner-box">
                <img src={`${imagesUrl}/homepage/MissisUniv.png`} loading="lazy" alt="" />
              </div>
            </div>
          </Container>
        </section>
      </Container>
    </>
  );
};

export default Homepage;
