import React from 'react';
import { RangeSlider } from 'rsuite';
// import 'rsuite/RangeSlider/styles/index.css';
import 'rsuite/dist/rsuite.min.css';
interface SliderFilterProps {
  min: number;
  max: number;
  defaultValue: [number, number];
  tooltip?: boolean;
  step?: number;
  onChange?: (value: number[]) => void;
  constraint?: (value: number[]) => boolean;
}

const SliderFilter = ({ min, max, defaultValue, tooltip, step, onChange, constraint }: SliderFilterProps) => {
  return (
    <>
      <RangeSlider min={min} max={max} defaultValue={defaultValue} tooltip={tooltip} step={step} onChange={onChange} constraint={constraint} />
    </>
  );
};

export default SliderFilter;
