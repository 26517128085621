import { create } from "zustand";
import { combine } from "zustand/middleware";

export const useInternationalBooking = create(
  combine(
    {
      isDepartureSummaryClicked: false,
      isReturnSummaryClicked: false,
      isSwitchOn: false,
      isPassengerDetailsCheckboxTicked: false,
      isBookingTncCheckboxTicked: false,
      isRoundTrip: false,
      isOneWay: false,
      isDomestic: false,
      isLoggedIn: false,
      bookingPayment: false,
      bookingPaymentConfirm: false,

      isTotalBaseFareClicked: false,
    },
    (set, get) => ({
      // togglers
      toggleDepartureSummary: () =>
        set(() => ({
          isDepartureSummaryClicked: !get().isDepartureSummaryClicked,
        })),

      toggleReturnSummary: () =>
        set(() => ({
          isReturnSummaryClicked: !get().isReturnSummaryClicked,
        })),

      toggleSwitch: () =>
        set(() => ({
          isSwitchOn: !get().isSwitchOn,
        })),

      togglePassengerDetailsCheckbox: () =>
        set(() => ({
          isPassengerDetailsCheckboxTicked: !get().isPassengerDetailsCheckboxTicked,
        })),

      toggleBookingTncCheckbox: () =>
        set(() => ({
          isBookingTncCheckboxTicked: !get().isBookingTncCheckboxTicked,
        })),

      toggleLogin: () =>
        set(() => ({
          isLoggedIn: !get().isLoggedIn,
        })),

        toggleTotalBaseFare: () =>
        set(() => ({
          isTotalBaseFareClicked: !get().isTotalBaseFareClicked,
        })),


        

      // setters
      setRoundFlight: () => set(() => ({ isRoundTrip: true })),
      setOneWay: () => set(() => ({ isOneWay: true })),
      paymentProceed: () => set(() => ({ bookingPayment: true })),
      paymentCancel: () => set(() => ({ bookingPayment: false })),
      payNow: () => set(() => ({ bookingPaymentConfirm: true })),
    })
  )
);
