import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import '../../../resources/css/articles.scss';

const GuideToEthical = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />
      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/A+guide+to+Ethical.jpg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              A Guide to Ethical and Sustainable Tourism in the Philippines
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>A Guide to Ethical and Sustainable Tourism in the Philippines</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Zoe Alcazaren</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Dec 6, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">7 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              Home to over 7,000 islands, the Philippines is a tourism magnet, attracting visitors worldwide who long to grace its white-sand beaches and behold its rich history. However, welcoming
              over a million tourists every year means the country has become susceptible to irresponsible tourism, affecting beloved natural attractions and sustainable practices.
            </p>
            <p>
              Unfortunately, the Philippines has a long way to go when promoting ethical tourism, ranking 94th out of 99 countries in promoting sustainable travel in 2020. Thus, it’s often up to
              tourists to be responsible travelers.
            </p>
            <p>If you’re visiting the country’s ecotourism hotspots any time soon, here’s what you need to know.</p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/01+whereslugo+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by charlesdeluvio via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Understanding the Philippines’ Tourism Landscape</h2>
            <p>The Philippines boasts a diverse and rich ecotourism background, stunning natural beauty, and unique biodiversity. Here’s a quick overview of its tourism landscape.</p>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Key Tourist Destinations</h3>
                  <p>The Philippines is a diverse country with a high level of species endemism, with prized fauna like the Philippine eagle, tarsier, and a handful of marine life.</p>
                  <p>
                    Its key destinations are protected areas and national parks, such as Palawan's Subterranean River National Park, Tubbataha Reefs Natural Park, and Mount Apo National Park, which
                    showcase the country’s natural topography and flora.
                  </p>
                  <p>Marine and coastal ecosystems and wildlife sanctuaries are popular destinations, with tourists visiting Apo Island, Tubbataha Reefs, and the Philippine Eagle Center.</p>
                  <p>Community-based ecotourism is another popular travel theme, with tourists taking guided tours, participating in community activities, and bunking with homestays.</p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Current Tourism Trends & Challenges</h3>
                  <p>
                    Unfortunately, overdevelopment and commercialization are a top concern within the Philippines’ tourism industry. Balancing the need for infrastructure and visitor facilities with
                    environmental conservation has led to habitat loss and harm to delicate ecosystems.
                  </p>
                  <p>
                    Further environmental degradation and increased waste result from over-tourism, particularly in places with limited carrying capacity. With a lack of regulation and enforcement,
                    local communities often fall victim to these consequences and struggle to recover.
                  </p>
                  <p>Cultural insensitivities are also not uncommon, harming heritage sites and indigenous communities.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/02+Charles+Salutan+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by charlesdeluvio via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Ethical and Sustainable Tourism Principles in the Philippines</h2>
            <p>
              Tourism in the Philippines has the potential to be a force for good, driving sustainability, conservation, and empowerment within local communities. Among the two principles that guide
              ethical tourism in the Philippines are sustainability and community engagement.
            </p>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Sustainability and Conservation</h3>
                  <p>
                    The Philippines is rife with marine ecosystems and endangered species. Tour operators in Marine Protected Areas (MPAs) provide visitors with information and educational programs
                    regarding preservation efforts and volunteer opportunities.
                  </p>
                  <p>Snorkeling and diving tours are becoming increasingly sustainable, with instructors being more meticulous about interacting with the wildlife.</p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Community Engagement and Empowerment</h3>
                  <p>Cultural immersions are familiar to backpackers. They often share meals with locals or participate in local activities in tourism hotspots like Buscalan, Sagada, and Banaue.</p>
                  <p>Many of these cultural immersion tours expose tourists to experiences like traditional dances, rituals, and storytelling to provide context for a specific region’s history.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/louwel+nicolas+via+unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by louwel nicolas via Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Tips for Responsible Tourists</h2>
            <p>
              Philippine tourism can be a force for good, especially when travelers consider their impact on the environment around them. Here are a few ways to practice sustainability when traveling
              to the Philippines.
            </p>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Choose Responsible Tour Operators</h3>
                  <p>
                    Ensure your tour operators are DOT-accredited. When shortlisting tour operators, don’t hesitate to ask questions—a reliable and responsible group should be able to provide
                    information regarding their sustainability practices, licenses, and certifications.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Support Local Businesses</h3>
                  <p>
                    Patronize local businesses as much as possible. If you’re on a culinary expedition, eat at locally-owned restaurants. For visitors with adventurous palettes, try street food
                    stalls—the Philippines has some of the best street food in Asia.
                  </p>
                  <p>Skip the luxuries and purchase handicrafts made by local artisans. Chances are your luggage will be full of beautiful and authentic pieces.</p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Respect Local Customs and Traditions</h3>
                  <p>The Philippines is a cultural melting pot rife with nuances. It’ll pay to research Filipino customs and traditions, especially if you opt for a homestay.</p>
                  <p>Should you plan to document your immersion, ask for permission before taking photographs and videos.</p>
                  <p>When visiting religious sites, dress modestly, covering your shoulders and knees and removing your shoes whenever necessary.</p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Leave No Trace</h3>
                  <p>
                    Filipinos are no strangers to CLAYGO (clean as you go), which should apply to natural tourist destinations. Some locations, such as the Pink Beach in Zamboanga, enforce fines and
                    even jail time for travelers who litter.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Choose Eco-Friendly Transportation</h3>
                  <p>
                    The occasional flight or road trip is unavoidable when traveling in the Philippines. However, walking or biking can reduce your carbon footprint within your destination.
                    Intramuros, Cebu, and Boracay are just a few Philippine biking hotspots that offer scenic routes.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Use Environmentally Friendly Travel Products</h3>
                  <p>
                    You never know how much of an impact sustainable products can be on your country tour. Chemicals in sunblock and insect repellent can harm sea and forest life. Something as simple
                    as shopping for all-natural products means you’re fulfilling your role as a responsible tourist.
                  </p>
                  <p>Consider using shampoo bars to avoid plastic waste. Use water-repellent towels to prevent sand from sticking to your beach bag on the way home.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/03+Datingscout+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Datingscout from Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Government and NGO Initiatives for Responsible Tourism</h2>
            <p>
              With tourism being a primary economy driver in the Philippines, many government and non-government organizations have taken it upon themselves to introduce proactive initiatives for
              responsible tourism.
            </p>

            <div className="bullet_grp">
              <div className="bullet_list">
                <p className="title">Department of Tourism</p>
                <div className="title_desc">
                  <p>In 2022, the Department of Tourism (DOT) launched the Keep the Fun Going campaign to gamify sustainable tourism practices. It promoted four activities:</p>
                </div>

                <ul>
                  <li>
                    <span>Certified Foot Soldier Challenge:</span> encouraged participants to walk, hike, and bike
                  </li>
                  <li>
                    <span>Zero-Waste Warrior:</span> encouraged participants to avoid using single-use plastics by bringing along reusable water bottles and utensils
                  </li>
                  <li>
                    <span>ResTOURism Advocate Challenge:</span> encouraged participants to volunteer in community restoration efforts like tree plantings, beach clean-ups, and wildlife preservation
                  </li>
                  <li>
                    <span>Eco-Staycationer Challenge:</span> encouraged participants to book eco-friendly accommodations like local homestays and ANAHAW-accredited hotels
                  </li>
                </ul>
              </div>
              <p>The DOT also implements a Community-Based Sustainable Tourism (CBST) program focused on empowering local communities while ensuring the sustainable use of cultural resources.</p>
              <p>
                The Tourism, Infrastructure, and Enterprize Zone Authority (TIEZA), another government agency under DOT, regulates and supervises tourism infrastructure projects to ensure development
                aligns with sustainability goals.
              </p>
            </div>
          </div>

          <div className="extensive_story">
            <h2>Conservation and Eco-Tourism Programs</h2>
            <p>Rife with endangered species, the Philippines is also home to dozens of wildlife conservation and eco-tourism programs, including the following.</p>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Green Fins Philippines</h3>
                  <p>
                    Green Fins aims to regulate tourist activities like marine tourism and is a proven conservation management approach. It promotes sustainable diving and snorkeling to protect the
                    country’s most valued coral reefs, providing the only internationally recognized environmental standards for marine tourism.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>World Wildlife Fund for Nature PH (WWF PH)</h3>
                  <p>
                    The Philippine faction of WWF functions upon four focuses: food, climate, wildlife, and water. It aims to counter ecological deficits by safeguarding natural resources. Its climate
                    change initiatives promote low-carbon and climate-smart development, responding to extreme weather events and inspiring volunteer-led activities like Earth Hour.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Save the Philippine Seas (SPS)</h3>
                  <p>
                    SPS is a movement advocating for the Philippine seas and its species, including the beloved Cebu thresher shark. It helped develop the country’s first shark and ray sanctuary on
                    Gato Island, installing mooring buoys to demarcate marine protected areas. SPS also hosts Sea Camp, an intensive environmental education program for youths.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Harbion Foundation</h3>
                  <p>
                    Haribon is a non-governmental organization that focuses on biodiversity conservation. It engages in various forest and marine conservation projects, working towards sustainable
                    ecosystems that support responsible tourism. As its title suggests, Haribon protects Philippine birds, hosting birdwatching sessions to impart an appreciation for national species
                    like the Philippine Eagle.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/04+Secret+Travel+Guide+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Secret Travel Guide from Unsplash</p>
          </div>

          <div className="extensive_story">
            <h2>Best Ecotourism Sites to Visit in the Philippines</h2>
            <p>
              Beyond its pristine beaches, the Philippines has many renowned UNESCO World Heritage Sites teeming with natural wonders. Here are a few of the best ecotourism sites to visit on your
              Philippine island hopping tour.
            </p>

            <div className="checklist_container">
              <div className="item">
                <div className="head">
                  <h3>Puerto Princesa Underground River Tour</h3>
                  <p>
                    Palawan is a natural haven with a mountain-to-sea ecosystem added to UNESCO’s New 7 Wonders of Nature list. The Puerto Princesa Underground River Tour is one of the top activities
                    in Puerto Princesa, taking guests through limestone formations and caves filled with bats. Guests will also come face-to-face with 15 endemic bird species, one of which is the
                    famed Palawan hornbill.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Masungi Georeserve Hike</h3>
                  <p>
                    Not only is the Masungi Georeserve Hike a popular destination near Manila, but it is also among the top eco parks in the country. It’s lush with rainforest and houses over 400
                    species of animals. Some animals are exclusive to the georeserve, such as a subspecies of micro snail.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Tubbataha Reefs Natural Park Diving</h3>
                  <p>
                    Should you find yourself visiting the Sulu Sea, the Tubbataha Reefs Natural Park is one of the best places to visit. It spans over 97,000 hectares and is home to 360 coral species
                    and 700 fish species.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Batad Rice Terraces</h3>
                  <p>
                    Located in Banaue, the Batad Rice Terraces has a history of over 2,000 years. Here, you’ll gain a deeper understanding of how tribes in the Philippine highlands plant and harvest
                    rice and develop an immense appreciation for the country’s food ecosystem.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Danjugan Island Sanctuary Sustainability Camp</h3>
                  <p>
                    The Danjugan Island Sanctuary Sustainability Camp is located in Negros Occidental and is home to seven ecosystems rife with fish, butterflies, bats, and birds. Visitors booking
                    tours in this area can enjoy various activities, such as snorkeling, trekking, kayaking, skin diving, and birdwatching.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Forest and Folklore Subic Bay Freeport Eco Tour</h3>
                  <p>
                    Just a hop and skip away from Metro Manila is the Subic Bay Freeport, an area hosting the last 3% of rainforests in the entire country. During the 45 to 60-minute eco hike, guests
                    will see natural pools, waterfalls, and small farms. Depending on when you visit, you can experience drinking water from the vines and even tasting insects.
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Las Pinas Paranaque Wetland Park Eco Tour</h3>
                  <p>
                    If you’re looking for an accessible eco-friendly experience within the city, the Las Pinas Paranaque Wetland Park Eco Tour belongs on your list of to-dos. This park is lush with
                    mangroves, lagoons, ponds, and mixed beach forests that will make it hard to believe you’re not out of Metro Manila. It’s also a temporary home to about 41 migratory bird species
                    from Japan and Siberia—you never know what you’ll see!
                  </p>
                </div>
              </div>

              <div className="item">
                <div className="head">
                  <h3>Iloilo City Carbon Offset Tour</h3>
                  <p>
                    Iloilo City is among one of the most sustainable in the country, and the Carbon Offset Tour will explain why. On this tour, guests will visit the Calle Real while counterbalancing
                    their carbon emissions. Guides from Sustainable Travel International will explain the city’s carbon-reducing initiatives and guests will receive a carbon offset certificate to
                    verify that proceeds from the tour tickets will be used to invest in sustainability projects.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/A+Guide+to+Ethical+and+Sustainable+Tourism+in+the+Philippines/05+Ashton+Jalra+Garcia+via+Unsplash.png"
              alt=""
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Ashton Jalra Garcia from Unsplash</p>
          </div>

          <div className="footnote">
            <div className="title_container">
              <h2>Ready to be a responsible tourist?</h2>
            </div>

            <div className="info">
              <p>
                When touring the Philippines, you’ll inevitably run into thousands of lush green spaces and encounter stunning flora and fauna you won’t see elsewhere. However, being a responsible
                tourist is essential to the survival of these heritage sites.
              </p>
              <p>
                For a hassle-free and enjoyable trip around the Philippines,{' '}
                <a href="/" target="_blank" rel="noreferrer">
                  GalaGO!
                </a>{' '}
                has all-inclusive sustainable vacation packages. Whether you’re keen on swimming with the whale sharks, conquering a challenging hike, or giving back to the environment, we have a
                plethora of options ready to scratch your travel itch!
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuideToEthical;
