import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router';
import { createBookingHotelTransactionApiRoute } from '../utils/apiRoutes';
import { HTTP_CREATED } from '../utils/httpResponse.utils';

export const HotelTransactionHooks = () => {
  const navigate = useNavigate();
  const [newTransactionId, setNewTransactionId] = React.useState<string>();

  const createHotelTransaction = (
    payload: { location: any; adults: any; rooms: any; checkInDate: any; checkOutDate: any },
    state: {
      selectedRooms: { [index: string]: any };
      hotelInfo: { [index: string]: any };
      searchCriteria: { [index: string]: any };
      facilitiesData: { [index: string]: any };
      hotel: { [index: string]: any };
    }
    // setDisableCartButton: (e: boolean) => void
  ) => {
    axios
      .request({
        method: 'post',
        url: createBookingHotelTransactionApiRoute(),
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((data) => {
        const { transactionId } = data.data.data;
        const { statusCode } = data.data;
        // setDisableCartButton(false);
        setNewTransactionId(transactionId);
        if (statusCode === HTTP_CREATED()) {
          navigate('/hotel/guest/form?transactionId=' + transactionId, {
            state: { ...state, ...{ transactionId: transactionId } },
          });
        }
      })
      .catch((error) => {
        // setDisableCartButton(false);
        alert('Error encountered while creating transaction id');
      });

    return payload;
  };

  return { createHotelTransaction, newTransactionId };
};
