import { create } from 'zustand';

export const EmailDialogStore = create((set: any) => ({
  showEmailDialog: false,
  setShowEmailDialog: (isShow: boolean) => {
    set((state: { showEmailDialog: boolean }) => ({
      showEmailDialog: isShow,
    }))
  },
  userEmail: '',
  setUserEmail: (email: string) => {
    set((state: { userEmail: string }) => ({
      userEmail: email,
    }))
  },
  showEmailConfirmationDialog: false,
  setShowEmailConfirmationDialog: (isShow: boolean) => {
    set((state: { showEmailConfirmationDialog: boolean }) => ({
      showEmailConfirmationDialog: isShow,
    }))
  },
}))
