import React from 'react';

const TravelGuides = () => {
  return (
    <>
      <div>
        <div className="travel-guides-card">
          <div className="image col-lg-4">
            <img src={`https://galago-assets.s3.ap-southeast-1.amazonaws.com/homepage/homepage/boholcard.png`} loading="lazy" alt="" onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />
          </div>
          <div className="info">
          <p className='blog__title_homepage'>Bohol Countryside Tour: The Best Tour for Bohol First-Timers</p>
            <p>
              <span>Known for its natural and cultural wonders, Bohol is a must-visit destination in the Philippines! Whether you’re a local or tourist,.... </span>
              <a href="/#/galago-guide-things-to-do-in-bohol" target="_blank" rel="noreferrer">
                Read More
              </a>
            </p>
            <p>September 25, 2023 . 6 mins read</p>
          </div>
        </div>
      </div>

      <div>
        <div className="travel-guides-card">
          <div className="image col-lg-4">
            <img
              src={`https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/island+hopping+essentials.jpg`}
              loading="lazy"
              alt=""
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
          </div>
          <div className="info">
            <p className='blog__title_homepage'>Must-Bring Items for Your Next Island Hopping Adventure</p>
            <p>
              <span>The beauty of the Philippines is that it’s an archipelago. With over 7,000 islands, this Southeast Asian treasure...</span>
              <a href="/#/whats-in-our-beach-bags-island-hopping-essentials" target="_blank" rel="noreferrer">
                Read more
              </a>
            </p>
            <p>September 27, 2023 . 6 mins read</p>
          </div>
        </div>
      </div>

      <div>
        <div className="travel-guides-card">
          <div className="image col-lg-4">
            <img
              src={`https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/How+to+Survive+NAIA+Immigration.jpg`}
              loading="lazy"
              alt=""
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
          </div>
          <div className="info">
          <p className='blog__title_homepage'>How to Survive NAIA Immigration</p>
            <p>
              <span>As of September 3, 2023, the Inter-Agency Council Against Traffic (IACAT) revised departure rules for outbound Filipino citizens. All...</span>
              <a href="/#/how-to-survive-naia-immigration" target="_blank" rel="noreferrer">
                {' '}
                Read more
              </a>
            </p>
            <p>September 25, 2023 . 6 mins read</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelGuides;
