import { create } from 'zustand';

export const HotelRoomFiltersStore = create((set: any) => ({
  filterKey: '',
  setFilterKey: (key: string) => {
    set((state: { filterKey: string }) => ({
      filterKey: key,
    }));
  },

  bedPreferencesList: [] as { [index: string]: any },
  setBedPreferencesList: (bedPreferences: { [index: string]: any }) => {
    set((state: { bedPreferencesList: { [index: string]: any } }) => ({
      bedPreferencesList: bedPreferences,
    }));
  },

  selectedBedPreferences: [] as string[],
  setSelectedBedPreferences: (bedPreferences: string[], remove = false) => {
    if (remove) {
      set((state: { selectedBedPreferences: string[] }) => ({
        selectedBedPreferences: state.selectedBedPreferences.filter((value) => !bedPreferences.includes(value)),
      }));
    } else {
      set((state: { selectedBedPreferences: string[] }) => ({
        selectedBedPreferences: Array.from(new Set([...state.selectedBedPreferences, ...bedPreferences])),
      }));
    }
  },

  selectedMealType: [] as string[],
  setSelectedMealType: (mealType: string[], remove = false) => {
    if (remove) {
      set((state: { selectedMealType: string[] }) => ({
        selectedMealType: state.selectedMealType.filter((value) => !mealType.includes(value)),
      }));
    } else {
      set((state: { selectedMealType: string[] }) => ({
        selectedMealType: Array.from(new Set([...state.selectedMealType, ...mealType])),
      }));
    }
  },


  selectedFacilities: [] as string[],
  setSelectedFacilities: (facilities: string[], remove = false) => {
    if (remove) {
      set((state: { selectedFacilities: string[] }) => ({
        selectedFacilities: state.selectedFacilities.filter((value) => !facilities.includes(value)),
      }));
    } else {
      set((state: { selectedFacilities: string[] }) => ({
        selectedFacilities: Array.from(new Set([...state.selectedFacilities, ...facilities])),
      }));
    }
  },
}));

