import '../../resources/css/signup-modal.scss';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import { SignUpDialogStore } from '../../store/SignupDialogStore';

interface signUpDialogInteface {
  show: boolean;
  onHide: () => void;
  // onClickEmail: () => void;
  // onClickFacebook: () => void;
}

export const SignUpDialog = ({ show, onHide }: signUpDialogInteface) => {
  const { setShowSignUpDialog } = SignUpDialogStore();
  const { setShowEmailDialog } = EmailDialogStore();
  const onClickEmail = () => {
    setShowEmailDialog(true);
    setShowSignUpDialog(false);
  };
  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="galago-modal-header">
          Log in or Sign up
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0">
        <div className="">
          <div className="signup-modal-header">
            <h4>Welcome to GalaGO!</h4>
            <span>Your next adventure starts here!</span>
          </div>

          <div className="signup-modal-button-holder">
            {/* <button type="button" className="btn position-relative">
              <img alt="" src="/svg/googlegoogleiconesvg150px.svg" className="position-absolute" />
              Continue with Google
            </button> */}
            {/* <button type="button" className="btn position-relative" onClick={onClickFacebook}>
              <img className="position-absolute" alt="" src="/svg/facebook.svg" />
              Continue with Facebook
            </button> */}
            {/* <button type="button" className="btn position-relative">
              <img alt="" src="/svg/vector7.svg" className="position-absolute" />
              Continue with Apple
            </button> */}

            {/* <div className="position-relative" id="button-break">
              <span>or</span>
            </div> */}

            <button type="button" className="btn position-relative" onClick={onClickEmail}>
              <img alt="" src="/svg/vuesaxoutlinesms.svg" className="position-absolute" />
              Continue with Email
            </button>
          </div>

          <div className="signup-modal-footer">
            By proceeding, you acknowledge that you have read and accepted the GalaGO!{' '}
            <a href="#/terms-and-conditions" target="_blank">
              Terms & Conditions.
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
