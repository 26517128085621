import React from 'react';
import Footer from '../../../components/Footer';
import BookingSummaryContainer from './BookingSummaryContainer';
import { NavBar } from '../../../components/NavBar';
import { useLocation } from 'react-router';
import { MystiflyFlightSegmentHook } from '../../../hooks/MystiflyFlightSegmentHook';
// import { url } from 'inspector';

const BookingSummaryPage = () => {
  const urlState = useLocation();
  const transactionId: string = urlState.state.transactionId;
  const selectedFlight: { [index: string]: any } = urlState.state.flightDetails;
  const conversationId: string = selectedFlight.conversationId;

  const flight: { [index: string]: any } = selectedFlight.flight;
  // const passengersCount: { [index: string]: any } = selectedFlight.passengers;
  const flightType: 'one-way' | 'round-trip' = selectedFlight.type;
  const contacts: { [index: string]: any } = urlState.state.contacts;
  const passengers: { [index: string]: any } = urlState.state.passengers;
  const { itinerary } = MystiflyFlightSegmentHook();
  const { departureItinerary, returnItinerary } = itinerary(flightType, flight);

  const PassengerCount: number | string = urlState.state.passengers.length;
  const AdultCount: number | string = urlState.state.flightDetails.passengers.adults;

  const ChildrenCount: number | string = urlState.state.flightDetails.passengers.children;
  const InfantCount: number | string = urlState.state.flightDetails.passengers.infants;

  return (
    <>
      <NavBar />
      <main className="flex flex-col items-center justify-center">
        {/* <BookingNavbar /> */}

        <BookingSummaryContainer
          Flight={flight}
          FlightType={flightType}
          SelectedFlight={selectedFlight}
          Contacts={contacts}
          Passengers={passengers}
          ConversationId={conversationId}
          TransactionId={transactionId}
          departureItinerary={departureItinerary}
          returnItinerary={returnItinerary}
          PassengerCount={PassengerCount}
          AdultCount={AdultCount}
          ChildrenCount={ChildrenCount}
          InfantCount={InfantCount}
        />
        {/* <BookingSummary /> */}
      </main>

      <Footer />
    </>
  );
};

export default BookingSummaryPage;
