import { SvgProps } from './SvgProps';

export const MessengerIcon = ({ _width = 24, _height = 24, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1649 3C7.24488 3 3.25488 6.729 3.25488 11.332C3.25488 13.948 4.54588 16.284 6.56588 17.811V21L9.60688 19.313C10.4179 19.541 11.2749 19.663 12.1659 19.663C17.0859 19.663 21.0759 15.933 21.0759 11.332C21.0749 6.729 17.0849 3 12.1649 3ZM13.1029 14.172L10.7979 11.778L6.35988 14.232L11.2249 9.069L13.5299 11.464L17.9689 9.009L13.1029 14.172Z"
        fill={_color}
      />
    </svg>
  );
};
