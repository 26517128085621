import React from 'react';
import { AlertBox } from '../../components/AlertBox';
import { GalagoButton } from '../../components/Button';
import { EmailDialogStore } from '../../store/EmailDialogStore';
import { PendingVerificationStore } from '../../store/PendingVerificationStore';
import { ThinkingGallySvg } from '../../resources/svg/GallySvg';
// import { EmailSentStore } from '../../store/EmailSentStore';
import EmailHook from '../../hooks/EmailHook';

const PendingVerificationDialog = () => {
  const { resendEmailApi } = EmailHook();
  const { userEmail } = EmailDialogStore();
  const { showPendingDialog, setShowPendingDialog } = PendingVerificationStore();
  // const { setShowEmailSentDialog } = EmailSentStore();

  const [disableResend, setDisableResend] = React.useState(true);
  const [timeLeft, setTimeLeft] = React.useState(0);

  React.useEffect(() => {
    if (showPendingDialog === false) {
      setTimeLeft(0);
    }
  }, [showPendingDialog]);

  React.useEffect(() => {
    // prevent running when the dialog is not shown
    setDisableResend(true);
    if (showPendingDialog === false) return;
    // if (disableResend === false) return;
    // console.log('triggered');
    // exit early when we reach 0
    if (!timeLeft) {
      setDisableResend(false);
      return;
    }
    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, showPendingDialog]);

  const handleResendEmail = async () => {
    setDisableResend(true);
    const response = await resendEmailApi(userEmail as string);
    const { statusCode } = response.data;
    setTimeLeft(60);
    if (statusCode === 200) {
      setDisableResend(false);
      // setShowPendingDialog(false);
      // setShowEmailSentDialog(true);
    }
  };

  return (
    <AlertBox
      size={'lg'}
      show={showPendingDialog}
      onHide={(): void => {
        setShowPendingDialog(false);
      }}
    >
      <div className="d-flex flex-column align-items-center py-5 px-8">
        <ThinkingGallySvg _height={200} _width={200} />
        <p className="font-medium fs-5 text-center">You have a pending verification</p>
        <p style={{ color: '#ADADAD' }} className="text-center">
          We found an active verification link for your account. Please check your email to continue.
        </p>
        <div className="d-flex flex-column pl-8 pr-8 pt-8 gap-2 w-[70%] relative">
          {timeLeft !== 0 ? (
            <span className="absolute top-[10px] right-[30px] muted text-secondary" style={{ fontSize: '14px' }}>
              Resend in {timeLeft}s
            </span>
          ) : null}

          <GalagoButton onClick={() => handleResendEmail()} disabled={disableResend} label={'Resend Email'} className="w-100" />
          <GalagoButton onClick={() => setShowPendingDialog(false)} label={'Cancel'} className="text-decoration-none" btn="link" />
        </div>
      </div>
    </AlertBox>
  );
};

export default PendingVerificationDialog;
