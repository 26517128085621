import React from 'react';
import { SvgProps } from './SvgProps';

export const ChevronRoundTrip = ({ _width = 15, _height = 15, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6725 0.908238L13.0058 4.23824C13.1494 4.38163 13.2358 4.57249 13.2487 4.77501C13.2616 4.97754 13.2001 5.1778 13.0758 5.33824L13.0067 5.41657L9.67333 8.7549C9.52372 8.90595 9.32203 8.9941 9.10954 9.00131C8.89706 9.00852 8.68986 8.93424 8.53035 8.79368C8.37084 8.65313 8.27108 8.45692 8.2515 8.24522C8.23191 8.03352 8.29398 7.82234 8.425 7.6549L8.49417 7.57657L10.4025 5.66657H1.58333C1.37922 5.66654 1.18222 5.59161 1.02969 5.45598C0.877161 5.32034 0.779714 5.13345 0.755833 4.93074L0.75 4.83241C0.750027 4.62829 0.824963 4.43129 0.960596 4.27876C1.09623 4.12623 1.28312 4.02879 1.48583 4.0049L1.58333 3.99907H10.4083L8.495 2.08824C8.3514 1.94485 8.26505 1.75399 8.25216 1.55146C8.23927 1.34894 8.30073 1.14867 8.425 0.988238L8.49417 0.909071C8.63756 0.765468 8.82842 0.679122 9.03094 0.666234C9.23347 0.653346 9.43373 0.714802 9.59417 0.839071L9.6725 0.908238ZM13.2425 13.0682L13.2475 13.1657C13.2475 13.3699 13.1725 13.5669 13.0369 13.7194C12.9013 13.8719 12.7144 13.9694 12.5117 13.9932L12.4142 13.9991H3.595L5.50667 15.9099C5.65015 16.0534 5.73634 16.2443 5.74907 16.4468C5.76181 16.6494 5.70021 16.8496 5.57583 17.0099L5.50667 17.0882C5.36328 17.2318 5.17242 17.3182 4.96989 17.3311C4.76737 17.344 4.5671 17.2825 4.40667 17.1582L4.32833 17.0882L0.995 13.7582C0.851397 13.6148 0.76505 13.424 0.752162 13.2215C0.739275 13.0189 0.800731 12.8187 0.925 12.6582L0.994167 12.5799L4.3275 9.24324C4.47712 9.09219 4.67881 9.00404 4.89129 8.99684C5.10377 8.98963 5.31097 9.0639 5.47048 9.20446C5.62999 9.34502 5.72975 9.54123 5.74934 9.75292C5.76892 9.96462 5.70685 10.1758 5.57583 10.3432L5.50667 10.4216L3.59833 12.3324H12.415C12.6191 12.3324 12.8161 12.4074 12.9686 12.543C13.1212 12.6786 13.2186 12.8655 13.2425 13.0682Z"
        fill={_color}
      />
    </svg>
  );
};

export const ChevronRightSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.70825 6.95882H14.0624L10.2812 2.41716C10.1044 2.20443 10.0193 1.93018 10.0447 1.65474C10.0701 1.37929 10.2039 1.12522 10.4166 0.948407C10.6293 0.771596 10.9036 0.686532 11.179 0.711927C11.4544 0.737323 11.7085 0.871098 11.8853 1.08382L17.0937 7.33382C17.1287 7.38354 17.16 7.43576 17.1874 7.49007C17.1874 7.54216 17.1874 7.57341 17.2603 7.62549C17.3076 7.74493 17.3323 7.87206 17.3333 8.00049C17.3323 8.12892 17.3076 8.25605 17.2603 8.37549C17.2603 8.42757 17.2603 8.45882 17.1874 8.51091C17.16 8.56522 17.1287 8.61745 17.0937 8.66716L11.8853 14.9172C11.7874 15.0347 11.6647 15.1293 11.5261 15.1941C11.3875 15.2589 11.2363 15.2924 11.0833 15.2922C10.8399 15.2926 10.604 15.2079 10.4166 15.0526C10.3111 14.9651 10.2239 14.8577 10.16 14.7365C10.0961 14.6153 10.0567 14.4827 10.0442 14.3463C10.0316 14.2099 10.046 14.0723 10.0867 13.9414C10.1274 13.8106 10.1935 13.6891 10.2812 13.5838L14.0624 9.04216H1.70825C1.43198 9.04216 1.16703 8.93241 0.97168 8.73706C0.77633 8.54171 0.666584 8.27676 0.666584 8.00049C0.666584 7.72422 0.77633 7.45927 0.97168 7.26392C1.16703 7.06857 1.43198 6.95882 1.70825 6.95882Z"
        fill={_color}
      />
    </svg>
  );
};
