import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GalagoDialogStore } from '../store/GalagoDialogStore';
import { HurtGallySvg } from '../resources/svg/GallySvg';
/**
 * @description To use this component you need to use the GalagoDialogStore hook
 * @example const { setGalagoErrorDialogMessage, setShowGalagoErrorDialog } = GalagoDialogStore();
 * @returns Modal dialog with error message
 */

export const ErrorGalagoDialog = () => {
  const { showGalagoErrorDialog, setShowGalagoErrorDialog, galagoErrorDialogMessage, galagoErrorDialogTitle, galagoErrorButtonLabel, galagoErrorButtonOnClick } = GalagoDialogStore();
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleButtonOnClick = () => {
    setButtonClicked(true);
    galagoErrorButtonOnClick();
  };

  const handleOnHide = () => {
    if (buttonClicked) {
      setShowGalagoErrorDialog(false);
    }
  };
  return (
    <Modal
      show={showGalagoErrorDialog}
      onHide={handleOnHide}
      className="galago-modal-md"
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="p-0"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HurtGallySvg _width={150} _height={150} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <h5 className="mb-2 mt-2">{galagoErrorDialogTitle}</h5>
          <p className="mb-3" style={{ width: '85%', textAlign: 'center', fontSize: '14px', color: '#ADADAD' }}>
            {galagoErrorDialogMessage}
          </p>
          <button onClick={handleButtonOnClick} className="btn btn-primary" style={{ width: '60%' }}>
            {galagoErrorButtonLabel}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

/**
 * @description To use this component you need to use the GalagoDialogStore hook
 * @example const { setGalagoSuccessDialogMessage, setShowGalagoSuccessDialog } = GalagoDialogStore();
 * @param param show | boolean
 * @param param onHide | function
 * @param param text | string
 * @param param title | string
 * @returns Modal dialog with success message
 */
export const SuccessGalagoDialog = () => {
  const { galagoSuccessDialogMessage, showGalagoSuccessDialog, galagoSuccessTitle, setShowGalagoSuccessDialog } = GalagoDialogStore();
  return (
    <Modal
      show={showGalagoSuccessDialog}
      onHide={() => setShowGalagoSuccessDialog(false)}
      size="sm"
      className="galago-modal-md"
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        className="p-0"
        style={{
          height: '350px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          {/* <HappyGallySvg _width={200} _height={200} /> */}
          {/* <CheckCircleOutlinedSvg _width={150} _height={150} _color="#2AA23D" /> */}
          {galagoSuccessDialogMessage && galagoSuccessDialogMessage.icon}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h5 style={{ marginTop: '10px' }}>{galagoSuccessTitle}</h5>
          <p style={{ width: '85%', textAlign: 'center', fontSize: '14px' }}>{galagoSuccessDialogMessage && galagoSuccessDialogMessage.message}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const GalagoDialog = () => {
  const { showGalagoDialog, setShowGalagoDialog, galagoDialogMessage, galagoDialogTitle } = GalagoDialogStore();
  return (
    <Modal show={showGalagoDialog} onHide={() => setShowGalagoDialog(false)} className="galago-modal-md" animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body
        className="p-0"
        style={{
          height: '400px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'x',
            justifyContent: 'center',
          }}
        >
          {/* <img src="/svg/hurt-gally.svg" alt="" style={{ width: '50%', display: 'flex' }} /> */}
          {galagoDialogMessage && galagoDialogMessage.icon}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <h5 className="mb-2 mt-2">{galagoDialogTitle}</h5>
          <p className="mb-3" style={{ width: '85%', textAlign: 'center', fontSize: '14px', color: '#ADADAD' }}>
            {galagoDialogMessage && galagoDialogMessage.message}
          </p>
          <button onClick={() => setShowGalagoDialog(false)} className="btn btn-primary" style={{ width: '60%' }}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
