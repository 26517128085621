// import Cookies from "universal-cookie";
import { deleteUserRoute } from '../utils/apiRoutes';
import axios, { AxiosResponse } from 'axios';
import { AuthenticationHooks } from './AuthenticationHooks';
import { useNavigate } from 'react-router';

const UserHooks = () => {
  //   const cookie = new Cookies();
  const { signOut } = AuthenticationHooks();
  const navigate = useNavigate();

  const deleteUserAccount = async () => {
    try {
      const response: AxiosResponse = await axios.post(deleteUserRoute());
      //   console.log('response:', response);
      if (response.status === 201) {
        signOut();
        navigate('/');
        // window.location.href = '/';
        // return response;
      } else {
        throw new Error('Failed to delete user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      throw new Error('Failed to delete user');
    }
  };

  return { deleteUserAccount };
};

export default UserHooks;
