import React from 'react';
import './../resources/css/Loading.scss';

type LoadingProps = {
  color?: string;
};

export const LoadingBouncingDots = ({ color = '#fff' }: LoadingProps) => {
  return (
    <>
      <span className="circle-line">
        <span className="circle-red" style={{ backgroundColor: color }}>
          &nbsp;
        </span>
        <span className="circle-blue" style={{ backgroundColor: color }}>
          &nbsp;
        </span>
        <span className="circle-green" style={{ backgroundColor: color }}>
          &nbsp;
        </span>
        <span className="circle-yellow" style={{ backgroundColor: color }}>
          &nbsp;
        </span>
      </span>
    </>
  );
};
