import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import React from 'react';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import '../../../resources/css/articles.scss';

const EthicalCrashCourse = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />

      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+Etiquette/00+Cover+-+Toa+Heftiba+via+Unsplash.jpeg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Traveler’s Crash Course on Filipino Customs, Traditions, and Cultural Etiquette
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Traveler’s Crash Course on Filipino Customs, Traditions, and Cultural Etiquette</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Dec 6, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">3 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>Pack your sunscreen, handheld mini fans, and your “po” and “opo.”</p>
            <p>The Philippines is calling, so it's time to dive headfirst into the cultural melting pot that is this tropical paradise.</p>
            <p>
              Before you sprint to the nearest idyllic island or sign up for the trendiest food crawl, it’s important to take a moment and learn how to be a courteous traveler. Whether you’re island
              hopping in a remote province or exploring one of the Philippines’ colorful cities, here are some cultural etiquette to learn and practice.
            </p>
            <p>
              Trust us, having these in your traveling arsenal will ensure you’re being respectful of these Filipino customs and traditions. And that might just be the key to unlocking a whole new
              level of Pinoy hospitality!
            </p>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+Etiquette/01+Toa+Heftiba+via+Unsplash.png"
              alt="Philippines"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Toa Heftiba via Unsplash</p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>1. Smile and Say "Kamusta!"</h3>
                <p>First things first, flash those pearly whites and greet locals with a warm "kamusta!" (kah-mus-tah) – that's "how are you?" in Tagalog.</p>
                <p>
                  An accompanying smile can go a long way, breaking down language barriers and opening hearts faster. It might even help you score an extra leche flan or ice cream scoop on your
                  "halo-halo."
                </p>
              </div>

              <div className="tip">
                <h3>2. Remember Your “Po” and “Opo”</h3>
                <p>Another common courtesy is adding “po” or “opo” in your day-to-day exchanges.</p>
                <p>
                  “Opo” is a more polite way of saying ‘yes’ or giving an affirmative. It’s usually used when speaking with the elderly or figures of authority (like your boss), but it’s also used
                  when talking to strangers or new acquaintances as a sign of respect.
                </p>
                <p>
                  “Po” has the same function of expressing respect, like “opo”, but this is more of a suffix or an address added at the end of phrases or sentences. For example, “Thank you po” or
                  “We’ll be leaving po.”
                </p>
              </div>

              <div className="tip">
                <h3>3. Mind Your Footwear Etiquette</h3>
                <p>Filipinos are sticklers for cleanliness, and this extends to their homes.</p>
                <p>
                  So, when you enter someone's abode, leave your shoes at the doorstep. It's a simple act of respect that'll keep the floors sparkling clean and your experience as a guest more
                  friendly.
                </p>
              </div>

              <div className="tip">
                <h3>4. Join The Salamat Shuffle</h3>
                <p>
                  The Philippines is all about gratitude, and "salamat" (sa-lah-mat) means "thank you." You'll be saying it a lot, especially if you're indulging in the country's delectable street
                  food
                </p>
                <p>A "salamat po" and a smile will earn you brownie points with every local vendor.</p>
              </div>

              <div className="tip">
                <h3>5. Belt It Out In Karaoke</h3>
                <p>Prepare your vocal cords because Filipinos love their karaoke!</p>
                <p>If invited to join in, give it your best shot. It's all about having fun, even if you can't hit those high notes.</p>
                <p>Just don't forget to clap and cheer for others – karaoke is a sport here!</p>
              </div>
            </div>
          </div>

          <div className="img_container">
            <img
              src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Travel+Etiquette/Avel+Chuklanov+via+Unsplash.png"
              alt="Food Parade"
              loading="lazy"
              onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
            />
            <p>Photo by Avel Chuklanov via Unsplash</p>
          </div>

          <div className="extensive_story">
            <div className="tip_container">
              <div className="tip">
                <h3>6. Navigating The Fiesta Food Parade</h3>
                <p>The Philippines is a festive paradise with a multitude of colorful fiestas happening year-round. Joining in the celebrations also means savoring the local cuisine.</p>
                <p>
                  There will be food spreads in every street, house, and corner. As you enjoy this wide selection of delicious dishes, you will reach a point where you become too full or encounter a
                  dish that might not match your tastes.
                </p>
                <p>
                  In case either or both of these happen to you, the polite thing to do is to get a little bit of at least one of the dishes being served. Declining food outright might be considered
                  disrespectful or a disappointment from you by your host.
                </p>
              </div>

              <div className="tip">
                <h3>7. Don't Forget the “Pasalubong”</h3>
                <p>When visiting someone's home, bringing a small gift, or "pasalubong" (pa-sah-loo-bong), is a thoughtful gesture.</p>
                <p>It shows appreciation for the hospitality you'll undoubtedly receive.</p>
                <p>
                  So, there you have it! Remember, the key to a memorable trip to the Philippines isn't just the stunning beaches and delicious cuisine; it's also about embracing the local culture
                  with open arms and an open heart.
                </p>
                <p>By practicing cultural etiquette and respecting traditions, you'll find that the Philippines will welcome you with a whole new, deeper level of hospitality.</p>
                <p>Now, go forth and explore armed with your newfound cultural savvy.</p>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EthicalCrashCourse;
