/**
 * Flight Sorting Options
 * @description Flight sorting options for the flight search results
 * @used_in SelectedFlightLabel.tsx, RoundTripFlight.tsx components
 * @type {Array<{ label: string, fullLabel: string, value: string }>}
 */

export const FlightSortingOptions = [
  { label: 'Price', fullLabel: 'Lowest Price First', value: 'LowestPrice' },
  { label: 'Price', fullLabel: 'Highest Price First', value: 'HighestPrice' },
  { label: 'Departure', fullLabel: 'Earliest Departure First', value: 'EarliestDeparture' },
  { label: 'Departure', fullLabel: 'Latest Departure First', value: 'LatestDeparture' },
  { label: 'Arrival', fullLabel: 'Earliest Arrival First', value: 'EarliestArrival' },
  { label: 'Arrival', fullLabel: 'Latest Arrival First', value: 'LatestArrival' },
  { label: 'Duration', fullLabel: 'Shortest Duration First', value: 'ShortestDuration' },
  { label: 'Duration', fullLabel: 'Longest Duration First', value: 'LongestDuration' },
];
