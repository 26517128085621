interface SvgProps extends React.SVGAttributes<HTMLOrSVGElement> {
  _width?: number;
  _height?: number;
  _color?: string;
  props?: any;
}

export const LocationSvg = ({ _width = 15, _height = 15, _color = '#333', ...props }: SvgProps) => {
  return (
    <svg width={_width} height={_height} {...props} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/location">
        <g id="location">
          <path
            id="Vector"
            d="M6.50006 7.27453C7.43342 7.27453 8.19006 6.51789 8.19006 5.58453C8.19006 4.65117 7.43342 3.89453 6.50006 3.89453C5.5667 3.89453 4.81006 4.65117 4.81006 5.58453C4.81006 6.51789 5.5667 7.27453 6.50006 7.27453Z"
            stroke={_color}
            strokeWidth="1.5"
          />
          <path
            id="Vector_2"
            d="M1.96059 4.59843C3.02768 -0.0924076 9.97726 -0.0869909 11.0389 4.60384C11.6618 7.35551 9.95018 9.68468 8.44976 11.1255C7.36101 12.1763 5.63851 12.1763 4.54434 11.1255C3.04934 9.68468 1.33768 7.35009 1.96059 4.59843Z"
            stroke={_color}
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export const LocationFilledSvg = ({ _width = 15, _height = 15, _color = '#016E7F', ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 16 17" fill="none">
      <path
        d="M8.00014 1.78613C9.47777 1.78613 10.8949 2.37312 11.9397 3.41797C12.9846 4.46281 13.5716 5.87993 13.5716 7.35756C13.5716 9.71185 11.9173 12.2776 8.65157 15.0821C8.46998 15.2381 8.23846 15.3238 7.99907 15.3236C7.75968 15.3234 7.52831 15.2373 7.347 15.081L7.131 14.8936C4.00985 12.1621 2.42871 9.65928 2.42871 7.35756C2.42871 5.87993 3.0157 4.46281 4.06054 3.41797C5.10539 2.37312 6.5225 1.78613 8.00014 1.78613ZM8.00014 5.2147C7.43182 5.2147 6.88677 5.44047 6.48491 5.84233C6.08305 6.2442 5.85728 6.78924 5.85728 7.35756C5.85728 7.92588 6.08305 8.47093 6.48491 8.87279C6.88677 9.27465 7.43182 9.50042 8.00014 9.50042C8.56846 9.50042 9.11351 9.27465 9.51537 8.87279C9.91723 8.47093 10.143 7.92588 10.143 7.35756C10.143 6.78924 9.91723 6.2442 9.51537 5.84233C9.11351 5.44047 8.56846 5.2147 8.00014 5.2147Z"
        fill={_color}
      />
    </svg>
  );
};
