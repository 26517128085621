import { SvgProps } from './SvgProps';

export const AirplaneUpRightSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M0.927792 6.93259L0 7.86038L3.24727 9.25207L4.63962 12.5L5.56741 11.5722L5.10351 9.25207L7.27733 7.07825L9.64602 12.1332L10.5246 11.2546L9.74379 4.61245L11.5994 2.75621C11.7247 2.63516 11.8247 2.49035 11.8935 2.33025C11.9622 2.17014 11.9984 1.99794 12 1.8237C12.0015 1.64945 11.9683 1.47665 11.9023 1.31537C11.8363 1.1541 11.7389 1.00758 11.6156 0.884362C11.4924 0.761147 11.3459 0.663706 11.1846 0.597722C11.0233 0.531739 10.8505 0.498535 10.6763 0.50005C10.5021 0.501564 10.3299 0.537765 10.1698 0.606541C10.0096 0.675317 9.86484 0.775291 9.74379 0.900628L7.83899 2.80542L1.19681 2.02395L0.366786 2.85463L5.38238 5.26269L3.24793 7.39714L0.927792 6.93259Z"
        fill={_color}
      />
    </svg>
  );
};

export const AirplaneAscendSvg = ({ _width = 15, _height = 15, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 51 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M3.05209 30.7598H48.9897V35.864H3.05209V30.7598ZM50.2887 6.5839C49.9445 5.55003 49.2037 4.69524 48.2293 4.20755C47.2548 3.71985 46.1266 3.6392 45.0927 3.98332L33.6772 7.79104L13.2604 0.134766L8.15627 2.68686L23.4688 12.8952L13.2604 17.9994L3.05209 12.8952L0.5 15.4473L10.7084 25.6557L47.8336 11.734C48.8297 11.3601 49.642 10.6145 50.0999 9.65411C50.5577 8.6937 50.6254 7.59319 50.2887 6.5839Z"
        fill={_color}
      />
    </svg>
  );
};

export const AirplaneDescendSvg = ({ _width = 15, _height = 15, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M16.451 11.311C16.8589 11.3962 17.284 11.3199 17.6369 11.0982C17.9897 10.8764 18.2428 10.5265 18.343 10.122V10.121C18.3938 9.91574 18.4037 9.70248 18.372 9.4934C18.3404 9.28433 18.2678 9.08354 18.1585 8.90253C18.0492 8.72152 17.9053 8.56383 17.735 8.43848C17.5647 8.31313 17.3713 8.22258 17.166 8.172L12.59 7.039L7.434 0.225L5.21 0L8.141 6.589L3.692 6.14L1.38 2.311L0 2.621L1.24 8.141L16.451 11.311ZM0.609 14.015H18.609V16.015H0.609V14.015Z"
        fill={_color}
      />
    </svg>
  );
};

export const AirplaneUpSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Vector" filter="url(#filter0_d_627_2326)">
        <path
          d="M12.8177 8.17904L10.0229 6.97611L9.40044 6.71278C9.30468 6.6649 9.22089 6.53922 9.22089 6.43149V4.60015C9.22089 4.02562 8.79597 3.34335 8.28128 3.08002C8.10174 2.99025 7.88629 2.99025 7.70675 3.08002C7.19804 3.34335 6.77312 4.0316 6.77312 4.60614V6.43748C6.77312 6.5452 6.68934 6.67088 6.59358 6.71876L3.18226 8.18503C2.80522 8.34063 2.5 8.81343 2.5 9.22039V10.0104C2.5 10.5191 2.88302 10.7704 3.35582 10.567L6.35419 9.27426C6.58759 9.17252 6.77911 9.2982 6.77911 9.55554V10.2198V11.2971C6.77911 11.4348 6.70131 11.6323 6.60555 11.728L5.21708 13.1225C5.07345 13.2661 5.00762 13.5474 5.07345 13.7509L5.34276 14.5648C5.45049 14.9179 5.85147 15.0855 6.18063 14.9179L7.60501 13.7209C7.82046 13.5354 8.17356 13.5354 8.38901 13.7209L9.81338 14.9179C10.1425 15.0795 10.5435 14.9179 10.6632 14.5648L10.9325 13.7509C10.9984 13.5534 10.9325 13.2661 10.7889 13.1225L9.40044 11.728C9.29869 11.6323 9.22089 11.4348 9.22089 11.2971V9.55554C9.22089 9.2982 9.40642 9.1785 9.64581 9.27426L12.6442 10.567C13.117 10.7704 13.5 10.5191 13.5 10.0104V9.22039C13.5 8.81343 13.1948 8.34063 12.8177 8.17904Z"
          fill={_color}
        />
      </g>
      <defs>
        <filter id="filter0_d_627_2326" x="-12.5" y="-7.9873" width="41" height="41.9707" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_2326" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_2326" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
