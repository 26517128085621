import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import {LinkedInSvg} from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';

const RemoteIslandsInPhilippines = () =>{
    const [fbHover, setFbHover] = React.useState('#ADADAD');
    const [liHover, setLiHover] = React.useState('#ADADAD');
    const [twtHover, setTwtHover] = React.useState('#ADADAD');
    const [linkHover, setLinkHover] = React.useState('#ADADAD');


    return (
      <>
        <NavBarTransparent />

        <div className="hero_sec">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Remote+Islands+to+Escape+in+the+Philippines/00+COVER+01+Photo+by+Michael+Louie+via+Unsplash.jpg"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <div className="gradient_overlay">
            <h1>Top Destinations</h1>

            <div className="bread_crumbs">
              <a href="/#/blogs">Blogs</a>
              <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                Unplug and Unwind: 5 Remote Islands in the Philippines for a Digital Detox
              </a>
            </div>
          </div>
        </div>

        <div className="articleBody">
          <div className="articleHeader">
            <h1>Unplug and Unwind: 5 Remote Islands in the Philippines for a Digital Detox</h1>

            <div className="info">
              <p className="authorSec">
                {' '}
                By <u>Anthea Reyes</u>
              </p>
              <p className="separator">•</p>
              <p className="articleDate">Nov 11, 2023</p>
              <p className="separator">•</p>
              <p className="article-read">8 min read</p>
            </div>

            <div className="shareBtnContainer">
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
                <FacebookSvg _color={fbHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
                <LinkedInSvg _color={liHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
                <TwitterSvg _color={twtHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
                <LinkSvg _color={linkHover} _width={24} _height={24} />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="articleDescription">
              <p>The Philippines is a nation known for its captivating archipelago. </p>
              <p>
                Home to a staggering 7,641 islands, this tropical destination boasts of a collection of remote paradises, each offering an escape from the digital clamor of modern life. Let's set sail
                to explore five secluded islands where you can unwind, recharge, and indulge in the moment.{' '}
              </p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Remote+Islands+to+Escape+in+the+Philippines/02+Photo+by+Alejandro+Luengo.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Alejandro Luengo via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>Remote islands in the Philippines to escape to</h2>
              <div className="info_grp">
                <p>
                  If the wifi can’t reach, you can enjoy the beach (in peace). This is a fact of modern life that no one can refute. Luckily for you and me, the Philippines is literally made of
                  islands, a lot of which internet connection is still a challenge.{' '}
                </p>
                <p>Here are a few notable ones where you can partake in a true digital detox — no emails, no zoom calls, no social media, none.</p>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Remote+Islands+to+Escape+in+the+Philippines/03+Photo+by+Secret+Travel+Guide.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Secret Travel Guide via Unsplash</p>
            </div>

            <div className="storieswithTitleImageParagraphs">
              <div className="story">
                <div className="head">
                  <h2>Amanpulo, Palawan</h2>
                </div>

                <div className="info-grp">
                  <p>Amanpulo is a private island in the Sulu Sea in Palawan. It hosts a luxury beachfront resort accessible only via private plane. </p>
                  <p>
                    A quick 7-minute walk from the airstrip will take you to a collection of sophisticated, island-style cottages with sea and rainforest views, private pools, and beachfront access.
                  </p>
                  <p>
                    There are also one to 4-bedroom villas fully-equipped with kitchens, lounges, butlers, chefs, and loaner cars. With multiple dining options and a spa conveniently located near
                    pristine beaches, lush forests, and crystal-clear waters with coral reefs, this oasis is ideal for those seeking indulgent tranquility.{' '}
                  </p>
                  <p>Leave your devices behind and embrace a peaceful yet lavish island life. </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Siargao, Surigao del Norte</h2>
                </div>

                <div className="info-grp">
                  <p>
                    Known for its world-class surfing spots, Siargao offers more than just the thrill of the waves. Venture into the teardrop-shaped island's quieter corners, and you'll discover
                    hidden lagoons, secret beaches, and laid-back villages.{' '}
                  </p>
                  <p>
                    Here, you can live the thrilling version of the island life by riding the waves, going on a long motorcycle ride, savoring fresh seafood, and stargazing under a pristine night sky.
                  </p>
                </div>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Remote+Islands+to+Escape+in+the+Philippines/04+Photo+by+Maryjoy+Caballero.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Maryjoy Caballero via Unsplash</p>
            </div>

            <div className="storieswithTitleImageParagraphs">
              <div className="story">
                <div className="head">
                  <h2>Apo Island, Negros Oriental</h2>
                </div>

                <div className="info-grp">
                  <p>
                    Apo Island is a volcanic island in the Visayas that boasts of a vibrant marine life with a variety of coral reefs, turtles, and fish. While it's no stranger to tourists, the
                    island’s serene charm remains untouched.{' '}
                  </p>
                  <p>Its marine sanctuary makes it a dream destination for divers and snorkelers, while its beaches with immaculate white sand make it paradise for beach-lovers. </p>
                  <p>
                    There are no ATMs or large resorts here. This offers quite the challenge, but it’s also what makes Apo Island a truly ideal place to disconnect online and reconnect with nature.
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Bantayan Island, Cebu</h2>
                </div>

                <div className="info-grp">
                  <p>Bantayan Island is a tranquil haven known for its powdery white sand and azure waters. </p>
                  <p>
                    Located at the northernmost tip of Cebu, this remote island boasts of stunning beaches and ecotourism sites like where you can stroll along movie-famous mangrove gardens,
                    experience local life in charming villages, and savor the freshest seafood.{' '}
                  </p>
                  <p>
                    For the daring looking to pass the time, there are also activities you can do on the island like cliff-jumping during the Virgin Island Hopping tour and skydiving with Skydive
                    Cebu.{' '}
                  </p>
                  <p>With breathtaking sceneries and things to do, this slice of paradise beckons you to put down your screens and immerse yourself in island time.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Balabac, Palawan</h2>
                </div>

                <div className="info-grp">
                  <p>
                    Balabac is one of the Philippines' most remote destinations. This group of 31 islands located at the southernmost end of Palawan offers a pristine sanctuary for nature enthusiasts.
                  </p>
                  <p>
                    Its natural wonders include unique indigenous plant and animal species, rare glory-of-the-seas cone shells, pink-sand beaches, and vibrant coral reefs. There are also swamps,
                    mangrove forests, and secluded sandbars to explore.{' '}
                  </p>
                  <p>A visit here will truly beckon you to unplug from the digital world, if only because there will be so much of its raw beauty to embrace and explore.</p>
                </div>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Remote+Islands+to+Escape+in+the+Philippines/05+Photo+by+Cris+Tagupa.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Cris Tagupa via Unsplash</p>
            </div>

            <div className="extensive_story">
              <h2>Tips for living the (responsible) island life</h2>
              <div className="info_grp">
                <p>
                  Let’s be honest, a digital detox is a very city-dweller thing to do. It’s undeniably beneficial to one’s mental health but it’s also not a universal experience or need, especially
                  for the inhabitants of these remote islands we want to run to.{' '}
                </p>
                <p>So as we disconnect for some peace of mind, it’s important to make an effort towards being respectful visitors of the destinations listed above. </p>
                <p>Here are a few quick tips on how to live your fantasy island life while maintaining a harmonious coexistence with the locals and the environment of your island paradise.</p>
              </div>

              <div className="tip_container">
                <div className="tip">
                  <h3>1. Embrace sustainable practices</h3>
                  <p>
                    Respect the environment by picking up after yourself and avoiding single-use plastics as you go about your explorations. Leave the destinations you go to just as you found them.
                    Don’t leave trash or gadgets behind, because these can disrupt the balance of the location’s ecosystem.
                  </p>
                  <p>Be mindful not to take anything from these destinations too. This means don’t pick flowers, break branches, pocket shells or starfish, etc.</p>
                </div>

                <div className="tip">
                  <h3>2. Connect with the locals</h3>
                  <p>
                    Immerse yourself in the island's culture. Take the time to learn about the local community’s way of life, and forge meaningful connections. Be open to exploring different cuisines.
                    If you’re presented with a dish that you really don’t want to try, respectfully decline while still being appreciative of the offer of food.{' '}
                  </p>
                  <p>
                    If you have the time, join local conservation projects in the area. This could be an eye-opening experience for you and a chance to share knowledge on how to be a steward of the
                    Philippines’ natural resources.
                  </p>
                </div>

                <div className="tip">
                  <h3>3. Explore responsibly </h3>
                  <p>
                    Exploring off the beaten path is part of the fun in traveling. This is especially true if your goal is to digital detox. However, one very important reminder is to listen to your
                    local guides as you go about your adventure. <span>Always</span> listen to the locals.
                  </p>
                  <p>
                    If they say a certain trail is dangerous, don’t trek it. If they say you can’t go to a certain beach because of the tide, don’t go to that beach. This will keep you safe as you
                    appreciate the island's natural beauty and history.
                  </p>
                </div>
              </div>
            </div>

            <div className="footnote">
              <div className="title_container">
                <h2>Disconnect and reconnect with GalaGO!</h2>
                <div className="info">
                  <p>In a world awash with screens and constant connectivity, these remote islands in the Philippines offer a respite from the digital noise. </p>
                  <p>
                    Embrace the serenity and immerse yourself in the simple pleasures of island life. As you embark on a digital detox journey, these destinations will help you rediscover the value of
                    being present in the moment and nurturing your connection with the natural world.
                  </p>
                  <p>Discover, learn more, and book tours to all these locations and more at GalaGO! </p>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="featured_blogs">
            <h2>Featured Blogs</h2>
            <div className="card_container">
              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                    alt="Discover the Best Things to Do in Bohol All in One Tour!"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                  <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                    alt="Guide to Travel Requirements in the Philippines"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Guide to Travel Requirements in the Philippines</h3>

                  <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
export default RemoteIslandsInPhilippines;