import React from 'react';
import { Modal } from 'react-bootstrap';
import { CheckCircleOutlinedSvg } from '../../../resources/svg/CheckSvg';
import '../../../resources/css/hotel-amenities-modal.scss';

interface HotelAmenitiesModalProps {
  show?: boolean;
  onHide?: () => void;
  amenities?: string[];
}

const HotelAmenitiesModal = ({ show, onHide, amenities }: HotelAmenitiesModalProps) => {
  return (
    <Modal show={show} onHide={onHide} animation={false} className="hotel-amenities-modal-container" centered>
      <Modal.Header closeButton>
        <Modal.Title>Facilities and Amenities</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {amenities
          ? amenities.map((amenity) => (
              <div>
                <CheckCircleOutlinedSvg _color="#333333" _width={20} _height={20} />
                {amenity}
              </div>
            ))
          : null}
      </Modal.Body>
      <Modal.Footer> </Modal.Footer>
    </Modal>
  );
};

export default HotelAmenitiesModal;
