import React, { useState } from 'react';
import { FloatingLabelFormControl } from '../../components/FormControl';
import { CalendarSvg } from '../../resources/svg/CalendarSvg';
import { CountryCodesSelectInput } from '../../components/CountryCodesSelectInput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { checkPasswordStrength } from '../../utils/data.utils';
import { CheckCircleOutlinedSvg } from '../../resources/svg/CheckSvg';
import ErrorMarkCircleSvg from '../../resources/svg/ErrorMarkCircleSvg';
import Calendar from 'react-calendar';
import moment, { MomentInput } from 'moment';
import { GalagoButton } from '../../components/Button';
import { GalagoDialogStore } from '../../store/GalagoDialogStore';
import { AuthenticationHooks } from '../../hooks/AuthenticationHooks';
import { HTTP_CREATED } from '../../utils/httpResponse.utils';
// import { RegistrationDialogStore } from '../../store/RegistrationDialogStore';
import Cookies from 'universal-cookie';
import { ASSETS_API, verifyEmailToken } from '../../utils/apiRoutes';
import axios from 'axios';
// import { values } from 'lodash';
// import { countryCodes } from '../../lib/countriesMockData';

const Register = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { verifyEmailHook } = AuthenticationHooks();
  // const { showRegistrationDialog, setShowRegistrationDialog } = RegistrationDialogStore();
  const { setShowGalagoSuccessDialog, setGalagoSuccessDialogMessage, setGalagoErrorDialogMessage, setShowGalagoErrorDialog, setGalagoErrorDialogTitle } = GalagoDialogStore();
  const { registerUserHook } = AuthenticationHooks();
  const confirmationCode = searchParams.get('confirmationCode') as string;
  const decodedConfirmationCode = jwtDecode(confirmationCode);
  // console.log(decodedConfirmationCode);
  const { email, exp } = decodedConfirmationCode as { email: string; exp: number };
  const [isEmailLinkExpired, setIsEmailLinkExpired] = React.useState(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState<{ [index: string]: any }>();

  const cookie = new Cookies();
  // const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  // conditions for email links
  React.useEffect(() => {
    const isLinkExpired: boolean = exp < moment().unix();
    setIsEmailLinkExpired(isLinkExpired);

    // check if the user has already filled up the details
    (async () => {
      const response = await verifyEmailHook({ email: email });
      // console.log(response);
      const { details } = response.data.data;
      const isAccountExist = details?.firstName !== null && details?.lastName !== null && details?.birthDate !== null;
      const verifyEmail = await axios.get(verifyEmailToken(confirmationCode));
      console.log(verifyEmail.data.data.isAccepted);
      setIsEmailVerified(verifyEmail);

      // console.log({ isLinkExpired, isAccountExist });
      // return;

      // if link is not yet expired and the user has already filled up the details
      if (isLinkExpired === false && isAccountExist === true) {
        navigate('/account-exist?confirmationCode=' + confirmationCode);
        return;
      }

      // redirect to index page if the link is expired
      if (isLinkExpired === true && isAccountExist === true) {
        // if the user has already filled up the details and the link is expired
        navigate('/expired-email-link-account?confirmationCode=' + confirmationCode);
        return;
      }

      if (isLinkExpired === true && isAccountExist === false) {
        // if the user has not yet filled up the details and the link is expired
        navigate('/expired-email-link?confirmationCode=' + confirmationCode);
        return;
      }
      // navigate('/expired-email-link?confirmationCode=' + confirmationCode);
    })();
    // eslint-disable-next-line
  }, []);

  const [user, setUser] = React.useState({
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    email: email,
    countryCode: '',
    mobile: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    birthDate: false,
    password: false,
    confirmPassword: false,
  });

  const [showBirthDateCalendar, setShowBirthDateCalendar] = React.useState(false);
  // const [passwordLength, setPasswordLength] = React.useState(0);

  const validateMobileNumber = (number: number) => {
    const regex = /^[0-9]+$/;
    return regex.test(number.toString());
  };

  /**
   * 1 - personal information
   * 2 - contact information
   * 3 - password information
   */
  const [activeTab, setActiveTab] = React.useState(1);

  const handleAccountRegistration = async () => {
    // check again the token if it is expired
    if (isEmailLinkExpired) {
      navigate('/expired-email-link?confirmationCode=' + confirmationCode);
      return;
    }

    // console.log(verifyEmail);
    setShowGalagoSuccessDialog(true);
    setGalagoSuccessDialogMessage({
      message: 'Creating your Account. One moment, please!',
      icon: <img src={`${ASSETS_API().public.gif}/gally.gif`} alt="" style={{ width: '50%', display: 'flex' }} />,
    });

    if (isEmailVerified?.data?.data.isAccepted === true) {
      const registerResponse = await registerUserHook({
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: moment(user.birthDate).format('YYYY-MM-DD'),
        middleName: user.middleName,
        countryCode: user.countryCode,
        mobileNumber: user.mobile,
      });
      const { data } = registerResponse;
      if (registerResponse.status !== HTTP_CREATED()) {
        setGalagoErrorDialogTitle('Something went wrong');
        setGalagoErrorDialogMessage(
          'An error has occurred and we’re working to fix the problem. Try refreshing the page or contact our offline booking team for assistance and access to more exciting deals!'
        );
        setShowGalagoErrorDialog(true);
        return;
      }
      // setShowRegistrationDialog(false);
      if (registerResponse.status === HTTP_CREATED()) {
        setTimeout(() => {
          // show animated galago loading
          setShowGalagoSuccessDialog(true);
          setGalagoSuccessDialogMessage({
            message: 'Account successfully created!',
            icon: <CheckCircleOutlinedSvg _width={150} _height={150} _color="#2AA23D" />,
          });
          // resetRegistrationStates(); // reset states value
          // setLoading(false);
          cookie.set('g-token', data.accessToken, { path: '/' });
          cookie.set('g-refresh-token', data.refreshToken, { path: '/' });

          window.location.href = '/';
        }, 3000);
      }
    }
  };

  return (
    <>
      <div className="d-flex">
        <div className="w-[45%]">
          <div className="p-8 m-8">
            <div className="row p-8">
              <div className="col">
                <div className="d-flex mb-3">
                  <img src="/img/galago-teal-600.webp" alt="" loading="lazy" width={250} />
                </div>

                <div className="py-5 d-flex gap-[14px]">
                  <div className="w-[64px] h-[6px] rounded-xl bg-color-teal-teal-900"></div>
                  <div className={`w-[64px] h-[6px] rounded-xl " ${activeTab === 1 ? 'bg-[#E7EAEB]' : 'bg-color-teal-teal-900'}`}></div>
                  <div className={`w-[64px] h-[6px] rounded-xl " ${activeTab === 1 || activeTab === 2 ? 'bg-[#E7EAEB]' : 'bg-color-teal-teal-900'}`}></div>
                </div>
                {activeTab === 1 ? (
                  <div id="personal-information">
                    <h6 className="mb-3 font-medium text-lg">Personal Information</h6>
                    <div className="pb-3">
                      <FloatingLabelFormControl
                        success={user.firstName.length > 0 ? true : false}
                        error={error.firstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length === 0) setError((prev) => ({ ...prev, firstName: true }));
                          else setError((prev) => ({ ...prev, firstName: false }));
                          setUser((prev) => ({ ...prev, firstName: value }));
                        }}
                        label={'First Name*'}
                        type={'text'}
                        placeholder="First Name*"
                      />
                      {error.firstName ? (
                        <span className="text-danger d-flex gap-1 mt-1">
                          <img src="/icons/error-mark-circle.svg" alt="" />
                          Must contain your first name
                        </span>
                      ) : null}
                    </div>
                    <div className="pb-3">
                      <FloatingLabelFormControl
                        success={user.middleName.length > 0 ? true : false}
                        onChange={(e) => setUser((prev) => ({ ...prev, middleName: e.target.value }))}
                        label={'Middle Name(Optional)'}
                        type={'text'}
                        placeholder="Middle Name(Optional)"
                      />
                    </div>
                    <div className="pb-3">
                      <FloatingLabelFormControl
                        success={user.lastName.length > 0 ? true : false}
                        error={error.lastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length === 0) setError((prev) => ({ ...prev, lastName: true }));
                          else setError((prev) => ({ ...prev, lastName: false }));
                          setUser((prev) => ({ ...prev, lastName: value }));
                        }}
                        label={'Last Name*'}
                        type={'text'}
                        placeholder="Last Name*"
                      />

                      {error.lastName ? (
                        <span className="text-danger d-flex gap-1 mt-1">
                          <img src="/icons/error-mark-circle.svg" alt="" />
                          Must contain your last name
                        </span>
                      ) : null}
                    </div>
                    <p className="text-left mt-1 mb-[24px] font-medium">Make sure your name matches the name on your passport or valid government IDs!</p>
                    <div className="mt-3 relative mb-5">
                      <FloatingLabelFormControl
                        defaultValue={user.birthDate}
                        success={user.birthDate.length > 0 ? true : false}
                        onClick={() => setShowBirthDateCalendar(true)}
                        label={'Birthdate'}
                        type={'text'}
                        placeholder="Birthdate"
                        icon={<CalendarSvg _color="#9D9D9D" />}
                      />

                      {showBirthDateCalendar && (
                        <div
                          className="position-absolute"
                          style={{
                            zIndex: 10,
                            borderRadius: '8px',
                            background: 'var(--color-shades-white-800, #FFF)',
                            boxShadow:
                              '0px 16px 16px 0px rgba(0, 0, 0, 0.07), 0px 8px 8px 0px rgba(0, 0, 0, 0.07), 0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 2px 2px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px 0px rgba(0, 0, 0, 0.07)',
                          }}
                          onMouseLeave={() => setShowBirthDateCalendar(false)}
                        >
                          <Calendar
                            onChange={(date) => {
                              const newDate = moment(date as MomentInput).format('MMM DD, YYYY');
                              setUser((prev) => ({ ...prev, birthDate: newDate }));
                              setError((prev) => ({ ...prev, birthDate: false }));
                              setShowBirthDateCalendar(false);
                            }}
                            defaultActiveStartDate={new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))}
                            // defaultValue={departing}
                            maxDate={new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))}
                          />
                        </div>
                      )}

                      {/* //! UNCOMMENT IF BIRTHDATE IS SOON NEEDED */}
                      {/* {error.birthDate ? (
                        <span className="text-danger d-flex gap-1 mt-1">
                          <img src="/icons/error-mark-circle.svg" alt="" />
                          You must be at least 18 to create an account
                        </span>
                      ) : (
                        <p className="mt-2 font-medium">You must be at least 18 to create an account. Don't worry, we won't share your age or birthday with other GalaGO! users.</p>
                      )} */}
                    </div>
                    <div className="d-flex gap-4 mt-4">
                      <GalagoButton label="Go Back" btn="dark" disabled style={{ backgroundColor: '#adadad', border: 'none', opacity: '1' }} />
                      <GalagoButton
                        label="Next"
                        btn="primary"
                        onClick={() => {
                          if (error.firstName || error.lastName || user.firstName === '' || user.lastName === '') {
                            if (user.firstName === '') setError((prev) => ({ ...prev, firstName: true }));
                            if (user.lastName === '') setError((prev) => ({ ...prev, lastName: true }));
                            if (user.birthDate === '') setError((prev) => ({ ...prev, birthDate: true }));
                            return;
                          }
                          setActiveTab(2);
                        }}
                        disabled={error.firstName || error.lastName || user.firstName === '' || user.lastName === ''}
                        style={error.firstName || error.lastName || user.firstName === '' || user.lastName === '' ? { backgroundColor: '#adadad', border: 'none', opacity: '1' } : {}}
                      />
                    </div>
                  </div>
                ) : null}

                {activeTab === 2 ? (
                  <div id="contact-information">
                    <h6 className="mb-8">Contact Details</h6>
                    <div className="pb-3">
                      <FloatingLabelFormControl label={'Email Address*'} value={user.email} type={'email'} placeholder="Email Address*" disabled />
                      <span>Here's where we will send your booking confirmations and receipts.</span>
                    </div>
                    <div className="pb-3 d-flex gap-[15px]">
                      <div className="w-[25%]">
                        <CountryCodesSelectInput onChange={(e) => setUser((prev) => ({ ...prev, countryCode: e.target.value }))} />
                      </div>

                      <div className="w-[75%]">
                        <FloatingLabelFormControl
                          onChange={(e) => {
                            if (validateMobileNumber(+e.target.value)) {
                              setUser((prev) => ({ ...prev, mobile: e.target.value }));
                            }
                          }}
                          type={'text'}
                          onKeyDown={(e) => {
                            // Allow backspace, left and right keys
                            if (e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                              return;
                            }
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          min={7}
                          max={15}
                          placeholder="Mobile Number (Optional)"
                          label={'Mobile Number (Optional)'}
                          maxLength={10}
                          success={user.mobile.length > 0 ? true : false}
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-4 mt-4">
                      <GalagoButton
                        label="Go Back"
                        btn="light"
                        style={{ backgroundColor: isHovered ? '#E6F1F3' : 'white' }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        onClick={() => {
                          setActiveTab(1);
                          setIsHovered(false);
                        }}
                      />
                      <GalagoButton label="Next" btn="primary" onClick={() => setActiveTab(3)} />
                    </div>
                  </div>
                ) : null}

                {activeTab === 3 ? (
                  <div id="password-information">
                    <h6 className="mb-8">Password</h6>
                    <div className="pb-3">
                      <FloatingLabelFormControl
                        error={error.password}
                        success={user.password.length > 0 ? true : false}
                        notEmpty={user.password.length > 0 ? true : false}
                        onChange={(e) => {
                          const value = e.target.value;
                          // setPasswordLength(value.length);
                          const passwordStrength = checkPasswordStrength(value);
                          if (value.length === 0 || passwordStrength === false) setError((prev) => ({ ...prev, password: true }));
                          else setError((prev) => ({ ...prev, password: false }));
                          setUser((prev) => ({ ...prev, password: value }));
                        }}
                        label={'Password*'}
                        type={'password'}
                        placeholder="Password*"
                      />

                      {user.password.length > 0 && error.password === true ? (
                        <p className="text-danger d-flex align-items-center gap-1 mt-2">
                          <ErrorMarkCircleSvg _width={26} _height={26} _color="#BD0A00" />
                          <span>A strong password must be at least 8 alphanumeric characters with a mix of uppercase and lowercase letters, numbers, and symbols.</span>
                        </p>
                      ) : (
                        <></>
                      )}

                      {user.password.length > 0 && error.password === false ? (
                        <p className="text-success d-flex align-items-center gap-1 mt-2">
                          <CheckCircleOutlinedSvg _width={16} _height={16} _color="#2EAE4E" />
                          Password Strength: Looking good!
                        </p>
                      ) : (
                        <></>
                      )}

                      {user.password.length === 0 ? <p className="mt-2">Password must be at least 8 alpha numeric characters with at least 1 upper case and 1 special character.</p> : <></>}
                    </div>
                    <div className="pb-3">
                      <FloatingLabelFormControl
                        error={error.confirmPassword}
                        success={user.password === user.confirmPassword && user.confirmPassword.length > 0 ? true : false}
                        notEmpty={user.confirmPassword.length > 0 ? true : false}
                        onChange={(e) => {
                          const value = e.target.value;
                          const passwordStrength = checkPasswordStrength(value);
                          if (value.length === 0 || passwordStrength === false) setError((prev) => ({ ...prev, confirmPassword: true }));
                          else setError((prev) => ({ ...prev, confirmPassword: false }));
                          setUser((prev) => ({ ...prev, confirmPassword: value }));
                        }}
                        label={'Confirm Password*'}
                        type={'password'}
                        placeholder="Confirm Password*"
                      />
                      {user.password === user.confirmPassword && user.confirmPassword.length > 0 ? (
                        <p className="text-success d-flex align-items-center gap-1 mt-2">
                          <CheckCircleOutlinedSvg _width={16} _height={16} _color="#2EAE4E" />
                          You've got a match!
                        </p>
                      ) : (
                        <></>
                      )}

                      {user.password !== user.confirmPassword && user.confirmPassword.length > 0 ? (
                        <p className="text-danger d-flex align-items-center gap-1 mt-2">
                          <ErrorMarkCircleSvg _width={16} _height={16} _color="#BD0A00" />
                          <span>Password did not match</span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="d-flex gap-4 mt-4">
                      <GalagoButton
                        label="Go Back"
                        btn="light"
                        style={{ backgroundColor: isHovered ? '#E6F1F3' : 'white' }}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        onClick={() => {
                          setActiveTab(2);
                          setIsHovered(false);
                        }}
                      />
                      <GalagoButton
                        label="Create Account"
                        btn="primary"
                        disabled={error.password || error.confirmPassword || user.password === '' || user.confirmPassword === ''}
                        onClick={() => {
                          if (error.password || error.confirmPassword || user.password === '' || user.confirmPassword === '') {
                            if (user.password === '') setError((prev) => ({ ...prev, password: true }));
                            if (user.confirmPassword === '') setError((prev) => ({ ...prev, confirmPassword: true }));
                            return;
                          }

                          if (user.password !== user.confirmPassword) {
                            setError((prev) => ({ ...prev, confirmPassword: true }));
                            return;
                          }

                          handleAccountRegistration();
                        }}
                        style={
                          error.password || error.confirmPassword || user.password === '' || user.confirmPassword === '' || user.password !== user.confirmPassword
                            ? { backgroundColor: '#adadad', border: 'none', opacity: '1' }
                            : {}
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[55%]">
          <img src="/img/registration-side-banner.webp" alt="" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default Register;
