import React from 'react';
import { Modal } from 'react-bootstrap';
import { DialogBoxStore } from '../store/DialogBoxStore';

/**
 * @how to use
 * @import DialogBoxStore()
 * @use: const { setDialogSize, setDialogTitle, setDialogMessage, setShowDialog } = DialogBoxStore();
 * @dialog size: sm | lg | xl
 * @dialog title: string
 * @dialog message: any html elements
 * @show dialog: boolean
 * @OR 
  <AlertBox size={"sm"} title={" "} show={true} onHide={(): void => {}}>
    <any html elements>
    </any html elements>
  </AlertBox>
 *
 */

export const DialogBox = () => {
  const { dialogSize, dialogTitle, dialogMessage, showDialog, setShowDialog, center } = DialogBoxStore();

  const location: boolean = center ? true : false;

  return (
    <Modal show={showDialog} onHide={() => setShowDialog(false)} size={dialogSize} aria-labelledby="contained-modal-title-vcenter" centered={location} animation={false}>
      {dialogTitle !== undefined ? (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="galago-modal-header">
            {dialogTitle}
          </Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body className="p-0">{dialogMessage}</Modal.Body>
    </Modal>
  );
};
