import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
// import { Link } from 'react-router-dom';

function GuideToTravelRequirements(){
const [fbHover, setFbHover] = React.useState('#ADADAD');
const [liHover, setLiHover] = React.useState('#ADADAD');
const [twtHover, setTwtHover] = React.useState('#ADADAD');
const [linkHover, setLinkHover] = React.useState('#ADADAD');


return (
  <>
    <NavBarTransparent />

    <div className="hero_sec">
      <img
        src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Updated+Travel+Requirements+in+the+PH/01+Cover.jpg"
        alt="Guide to Travel Requirements in the Philippines"
        loading="lazy"
        onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
      />
      <div className="gradient_overlay">
        <h1>Travel Tips</h1>

        <div className="bread_crumbs">
          <a href="/#/blogs">Blogs</a>
          <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
            Guide to Travel Requirements in the Philippines
          </a>
        </div>
      </div>
    </div>

    <div className="articleBody">
      <div className="articleHeader">
        <h1>Guide to Travel Requirements in the Philippines</h1>

        <div className="info">
          <p className="authorSec">
            By <u>Anthea Reyes</u>
          </p>
          <p className="separator">•</p>
          <p className="articleDate">Sep 25, 2023</p>
          <p className="separator">•</p>
          <p className="article-read">4 min read</p>
        </div>

        <div className="shareBtnContainer">
          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
            <FacebookSvg _color={fbHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
            <LinkedInSvg _color={liHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
            <TwitterSvg _color={twtHover} _width={24} _height={24} />
          </a>

          <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
            <LinkSvg _color={linkHover} _width={24} _height={24} />
          </a>
        </div>
      </div>

      <div className="content">
        <div className="articleDescription">
          <p>
            The Philippines is known for a colorful number of things. It’s home to exotic natural wonders, rich and delectable cuisine, top-tier hospitality, and a diverse and thriving culture.
            Whether you’re a foreign traveler or a local explorer, you are sure to bookmark this country as a top destination.
          </p>
          <p>Luckily, after an almost three-year-long pandemic (there were headlines about the Philippines having the longest lockdown!), the country’s tourism industry is now back in full swing.</p>
          <p>
            In an effort to recover from the unprecedented challenges brought on by the previous international health crisis and in anticipation of the upcoming wave of revenge travel, the local
            government is doubling its efforts in welcoming travelers from near and far.
          </p>
          <p>
            As of July 2023, travel requirements have been updated and somewhat relaxed. We are now one step closer to normalcy, while still safeguarding the health of tourists and locals, of course.
          </p>
          <p>
            Thinking of traveling to or around the Philippines soon? Then let’s jump right into the latest travel guidelines you need to know. Make sure to take notes for a smooth and enjoyable
            journey!
          </p>
        </div>

        <div className="img_container">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Updated+Travel+Requirements+in+the+PH/01+Ina+Carolino+via+Unsplash.png"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <p>Photo by Ina Carolino via Unsplash</p>
        </div>

        <div className="storywithTitleImageParagraph">
          <div className="head">
            <h2>General Philippine travel requirements</h2>
          </div>

          <div className="info_grp">
            <p>
              The first important thing to know is this: Yes, the Philippines is now open to all international tourists. The country welcomes and highly encourages local travel as well. But what about
              COVID-19?
            </p>
            <p>
              While the government has lifted its restrictions for inbound travel to the Philippines, it hasn’t completely removed special protocols quite yet. This country is painfully aware COVID-19
              is NOT yet a thing of the past!
            </p>

            <div className="bullet_grp">
              <div className="bullet_list">
                <p className="title_desc">So, what does this mean? This means that you no longer need:</p>
                <ul>
                  <li>to quarantine before your flight,</li>
                  <li>to quarantine after you arrive in the country,</li>
                  <li>an exemption document, which was originally required for visa-required countries; and</li>
                  <li>an International Certificate of Vaccination (ICV).</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="info_grp">
            <div className="bullet_grp">
              <div className="bullet_list">
                <p className="title_desc">Now, speaking of vaccines, here are the non-negotiable rules you have to follow when traveling to the Philippines.</p>
                <ul>
                  <li>
                    You <i>can</i> enter the Philippines without a negative COVID-19 test result, but you have to show proof of complete vaccination—1st dose, 2nd dose, and booster.
                  </li>
                  <li>
                    You <i>can</i> enter the country without COVID-19 vaccination, but you have to present a negative COVID-19 RT-PCR test result taken within 24 hours before your flight.
                  </li>
                  <li>
                    You <i>can</i> enter the Philippines with only partial COVID-19 vaccination, but you have to present a negative COVID-19 RT-PCR test result taken within 24 hours.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="info_grp">
            <div className="bullet_grp">
              <div className="bullet_list">
                <p className="title_desc">The Philippine Food and Drug Administration (FDA) accepts and acknowledges eight COVID-19 vaccines:</p>
                <ul>
                  <li>Pfizer-BioNTech</li>
                  <li>AstraZeneca</li>
                  <li>Sinovac-Coronavac</li>
                  <li>Sputnik V</li>
                  <li>Janssen</li>
                  <li>Covaxin</li>
                  <li>Moderna</li>
                  <li>Sinopharm</li>
                </ul>
              </div>
            </div>

            <p>Vaccines and COVID-19 test results aside, here are the other requirements you need for a successful entry to the country.</p>
          </div>
        </div>

        <div className="extensive_story">
          <h2>For foreign nationals from visa-required countries</h2>
          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title_desc">If you’re coming from one of the countries under this category, it’s important to secure the following requirements along with your visa:</p>

              <ul>
                <li>
                  Registration to the{' '}
                  <a href="https://etravel.gov.ph/authentication" target="_blank" rel="noreferrer">
                    eTravel Portal
                  </a>{' '}
                </li>
                <li>Valid visa and/or ACR I-Card</li>
                <li>Ticket back to the port of origin or the next port of destination outside of the Philippines scheduled within the allowed maximum time of stay in the Philippines.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="extensive_story">
          <h2>For foreign nationals from non-visa required countries</h2>
          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title_desc">
                Not needing a visa is certainly one thing less to worry about as you make your travel plans to the Philippines. Still, it’s important to note these other requirements for entering the
                country to make your travel hassle-free through and through:
              </p>

              <ul>
                <li>Registration to the eTravel Portal,</li>
                <li>Ticket back to the port of origin or the next port of destination outside the Philippines scheduled within 30 days from arrival,</li>
                <li>An Entry Extended Document (EED) in case staying beyond 30 days.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="extensive_story">
          <h2>For visiting Filipino nationals or balikbayans</h2>
          <div className="bullet_grp">
            <div className="bullet_list">
              <p className="title_desc">
                We love local travel here on GalaGO! That’s why we do our best to make any local exploration as seamless as possible. Reminding you to have the following requirements is step one:
              </p>

              <ul>
                <li>Registration to the eTravel Portal,</li>
                <li>Must come from non-visa required country</li>
                <li>Proof of filiation in case traveling with your Filipino spouses and/or parents</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="storywithTitleImageParagraph">
          <div className="info_grp">
            <p>
              The eTravel Portal is a replacement of the One Health Pass requirement. Registration for this must be done upon arrival. There is{' '}
              <a href="/how-to-survive-naia-immigration" target="_blank" rel="noreferrer">
                a special lane in the airport
              </a>{' '}
              where you can accomplish this. You can also opt to pre-register within 72 hours prior to your flight. You can do this via the{' '}
              <a href="https://etravel.gov.ph/authentication" target="_blank" rel="noreferrer">
                pre-registration form on the eTravel website!
              </a>
            </p>
          </div>
        </div>

        <div className="img_container">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Updated+Travel+Requirements+in+the+PH/02+Xavier+Summer+via+Unsplash.png"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <p>Photo by Xavier Summer via Unsplash</p>
        </div>

        <div className="extensive_story">
          <h2>Domestic flight requirements in the Philippines</h2>
          <div className="info_grp">
            <p>Travel within the Philippines is now open, too!</p>
            <p>
              In fact, the country’s Department of Tourism is highly encouraging all Filipinos to explore and get to know their homeland a little better. Attend festivals! Visit hidden caves! Conquer
              mountain peaks! You can search, choose, and book the best exploration (or vacation, if that’s more your speed) for you right here on GalaGO!
            </p>
            <p>
              A quick little caveat for local explorers though: You can visit select destinations within the country, as long as you comply with the restrictions of the respective local government
              units (LGU).
            </p>
            <p>
              Most of these places encourage fully-vaccinated travel. So, if you have a completed COVID-19 vaccination, you don’t need to show a negative COVID-19 test result (antigen or RT-PCR)
              anymore.
            </p>
            <p>Here are the places that will welcome you with open arms, as long as you’re fully-vaxxed!</p>
          </div>
        </div>

        <div className="TableContainer">
          <h2>Destinations that won't require RT-PCR for the fully vaccinated against COVID-19</h2>
          <table>
            <tr>
              <th>Luzon</th>
              <th>Visayas</th>
              <th>Mindanao</th>
            </tr>

            <tr>
              <td>Abra</td>
              <td>Aklan</td>
              <td>Bukidnon</td>
            </tr>

            <tr>
              <td>Albay</td>
              <td>Biliran</td>
              <td>Butuan City</td>
            </tr>

            <tr>
              <td>Baguio City</td>
              <td>Bohol</td>
              <td>Camiguin</td>
            </tr>

            <tr>
              <td>Bataan</td>
              <td>Boracay</td>
              <td>Cotabato</td>
            </tr>

            <tr>
              <td>Batanes</td>
              <td>Capiz</td>
              <td>Davao</td>
            </tr>

            <tr>
              <td>Batangas</td>
              <td>Cebu</td>
              <td>General Santos City</td>
            </tr>

            <tr>
              <td>Benguet</td>
              <td>Dumaguete City</td>
              <td>Misamis Occidental</td>
            </tr>

            <tr>
              <td>Bulacan</td>
              <td>Eastern Samar</td>
              <td>Sarangani</td>
            </tr>

            <tr>
              <td>Cagayan</td>
              <td>Guimaras</td>
              <td>Sultan Kudarat</td>
            </tr>

            <tr>
              <td>Camarines Norte</td>
              <td>Iloilo</td>
              <td>Sulu</td>
            </tr>

            <tr>
              <td>Camarines Sur</td>
              <td>Leyte</td>
              <td>Surigao Del Norte</td>
            </tr>

            <tr>
              <td>Cavite</td>
              <td>Negros Occidental</td>
              <td>Surigao Del Sur</td>
            </tr>

            <tr>
              <td>Clark</td>
              <td>Negros Oriental</td>
              <td>Tawi-Tawi</td>
            </tr>

            <tr>
              <td>Coron</td>
              <td>Ormoc</td>
              <td>Zamboanga</td>
            </tr>

            <tr>
              <td>El Nido</td>
              <td>Siquijor</td>
              <td></td>
            </tr>

            <tr>
              <td>Ilocos Sur</td>
              <td>Tacloban</td>
              <td></td>
            </tr>

            <tr>
              <td>Isabela</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>La Union</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Laguna</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Masbate</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Metro Manila</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Nueva Ecija</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Nueva Vizcaya</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Occidental Mindoro</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Pampanga</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Pampanga</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Pangasinan</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Puerto Princesa</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Rizal</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Sagada</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Subic Bay Freeport Area</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Tagaytay</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Tarlac</td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>Zambales</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>

        <div className="extensive_story">
          <div className="bullet_grp">
            <div className="bullet_list">
              <p>Proof of vaccination may still be required when crossing these local borders. Documents that qualify for these are:</p>

              <ul>
                <li>Your vaccination card (if you’re a local),</li>
                <li>The International Certificate of Vaccination (ICV),</li>
                <li>A VaxCertPH digital vaccination certificate; or</li>
                <li>A national/state digital certificate of the foreign government where you were vaccinated (if you’re a foreign national).</li>
              </ul>

              <p>Aside from these, the only other requirements for domestic flights are your flight ticket and your government-issued ID.</p>
            </div>
          </div>
        </div>

        <div className="footnote">
          <div className="title_container">
            <h2>Are you travel-ready yet for the Philippines?</h2>

            <p>
              As the world continues to navigate the complexities of the COVID-19 pandemic, the Philippines is also taking significant steps to update its travel requirements. It’s a game of balance
              right now between resuming tourism and safeguarding public health. For a{' '}
              <a href="/flying-for-the-first-time-here-are-some-helpful-tips-for-you" target="_blank" rel="noreferrer">
                smooth sailing journey
              </a>
              , we only have three reminders:
            </p>

            <ul>
              <li>Remain vigilant,</li>
              <li>Stay informed about the latest guidelines, and</li>
              <li>Be prepared to adapt to any changes in the travel landscape.</li>
            </ul>

            <p>
              By working together and following the guidelines, we can all continue to experience the exotic beauty and wonders of the Philippines while contributing to the safe and responsible
              revival of travel.
            </p>

            <p>
              One last tip: embrace convenience where it’s offered. Once you have your Philippines travel plans laid out, go straight on to{' '}
              <a href="/" target="_blank" rel="noreferrer">
                GalaGO!
              </a>{' '}
              Here, you can book your flight and accommodations with just one click. Less tabs to keep track of, less paperwork to mind.
            </p>
          </div>
        </div>
      </div>

      <hr />

      <div className="featured_blogs">
        <h2>Featured Blogs</h2>
        <div className="card_container">
          <div className="blog_card col-md-6 col-12">
            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                alt="Discover the Best Things to Do in Bohol All in One Tour!"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />

              <div className="badge">
                <h2>Travel Tip</h2>
              </div>
            </div>

            <div className="info_grp">
              <div className="detail">
                <p className="date"> September 25, 2023</p>
                <p className="separator">·</p>
                <p className="duration">6 min read</p>
              </div>

              <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

              <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                <div className="read_btn">
                  <p>Read Article</p>
                  <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                </div>
              </a>
            </div>
          </div>

          <div className="blog_card col-md-6 col-12">
            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                alt="Guide to Travel Requirements in the Philippines"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />

              <div className="badge">
                <h2>Travel Tip</h2>
              </div>
            </div>

            <div className="info_grp">
              <div className="detail">
                <p className="date"> September 25, 2023</p>
                <p className="separator">·</p>
                <p className="duration">6 min read</p>
              </div>

              <h3> Guide to Travel Requirements in the Philippines</h3>

              <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                <div className="read_btn">
                  <p>Read Article</p>
                  <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

}
export default GuideToTravelRequirements