import { create } from 'zustand';

export const FlightCity = create((set: any) => ({
  selectedFromCityName: '',
  setSelectedFromCityName: (string: string) => {
    set((state: { selectedFromCityName: string }) => ({
        selectedFromCityName: string,
    }));
  },
  selectedToCityName: '',
  setSelectedToCityName: (string: string) => {
    set((state: { selectedToCityName: string }) => ({
        selectedToCityName: string,
    }));
  },
}));
