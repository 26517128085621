import React from 'react';
import { SadGallySvg } from '../../resources/svg/GallySvg';
import { GalagoButton } from '../../components/Button';
import { WaveDown, WaveUp } from '../../resources/svg/WaveSvg';
import { useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { EmailSentStore } from '../../store/EmailSentStore';
import EmailSentDialog from '../login/EmailSentDialog';
import { EmailDialogStore } from '../../store/EmailDialogStore';

export const ExpiredEmailLink = () => {
  const { setShowEmailSentDialog } = EmailSentStore();
  const { setUserEmail } = EmailDialogStore();

  const [searchParams] = useSearchParams();
  let email: string = '';
  const confirmationCode = searchParams.get('confirmationCode') as string;
  const decodedConfirmationCode: { email: string } = confirmationCode ? jwtDecode(confirmationCode as string) : { email: '' };
  email = decodedConfirmationCode.email;

  const handleResendEmail = async () => {
    setUserEmail(email);
    if (email) setShowEmailSentDialog(true);
  };

  const disableSendEmailButton = email.length === 0 ? true : false;

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 right-0 w-100">
        <WaveUp _width={'100%'} colors={{ primary: '#C8C0BF', secondary: '#E1D8D7', tertiary: '#FAF0EF', quaternary: '#FDFAFA' }} />
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-100 z-0">
        <WaveDown _width={'100%'} colors={{ primary: '#C8C0BF', secondary: '#E1D8D7', tertiary: '#FAF0EF', quaternary: '#FDFAFA' }} />
      </div>
      <div className="relative d-flex flex-column justify-center align-items-center !z-10" style={{ height: '100vh' }}>
        <SadGallySvg />
        <div className="d-flex flex-column align-items-center">
          <p className="mb-2 mt-2 font-medium fs-2">Oops! Link Expired</p>
          <p className="mb-3" style={{ width: '85%', textAlign: 'center', fontSize: '20px', color: '#ADADAD' }}>
            No worries! Let’s start fresh. Please click the button below for a new verification link
          </p>
          <GalagoButton label="Send New Verification Link" onClick={() => handleResendEmail()} disabled={disableSendEmailButton} size="lg" className="!w-[300px] p-3" />
        </div>
      </div>
      <EmailSentDialog />
    </div>
  );
};

