import { SvgProps } from './SvgProps';

export const InfantSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M11.7994 6.44984C11.8395 6.32304 11.8737 6.19458 11.902 6.06484C11.9038 6.055 11.9044 6.04574 11.9068 6.0359C11.9188 5.97916 11.9242 5.92069 11.9338 5.86337C11.9488 5.77247 11.9668 5.68216 11.9758 5.59069C12.0472 4.90831 11.9602 4.21916 11.7212 3.57325C11.4822 2.92734 11.0972 2.34089 10.594 1.85648C10.1487 1.42586 9.61961 1.08436 9.03715 0.851577C8.45469 0.618795 7.83031 0.499317 7.19987 0.500003V5.13158H2.71015L2.19536 3.97369H0V5.13158H1.40457L2.50135 7.60137C2.11684 7.75426 1.78796 8.01375 1.55631 8.34703C1.32466 8.68031 1.20066 9.07239 1.19998 9.47368C1.19998 10.5911 2.14196 11.5 3.29994 11.5C4.35532 11.5 5.2223 10.7422 5.3693 9.76316H6.63048C6.77747 10.7422 7.64446 11.5 8.69984 11.5C9.85782 11.5 10.7998 10.5911 10.7998 9.47368C10.7998 9.10895 10.6912 8.77142 10.516 8.475C10.5412 8.45126 10.5694 8.43042 10.5946 8.40611C10.8106 8.19711 11.0074 7.96668 11.1808 7.72063C11.3536 7.47168 11.5024 7.20768 11.6224 6.93384C11.632 6.91242 11.638 6.88984 11.647 6.86842L11.6566 6.84411C11.7094 6.71674 11.7574 6.58879 11.7976 6.45737L11.7994 6.44984ZM3.29994 10.3421C2.80375 10.3421 2.39996 9.95247 2.39996 9.47368C2.39996 8.99489 2.80375 8.60526 3.29994 8.60526C3.79613 8.60526 4.19992 8.99489 4.19992 9.47368C4.19992 9.95247 3.79613 10.3421 3.29994 10.3421ZM8.69984 10.3421C8.20365 10.3421 7.79986 9.95247 7.79986 9.47368C7.79986 8.99489 8.20365 8.60526 8.69984 8.60526C9.19603 8.60526 9.59982 8.99489 9.59982 9.47368C9.59982 9.95247 9.19603 10.3421 8.69984 10.3421Z"
        fill={_color}
      />
    </svg>
  );
};
