// import { ReactElement } from 'react';
// import { ReactElement } from 'react';
// import { Star } from '../resources/svg/StarSvg';

type getDataOptions = {
  method: string;
  headers?: any;
  body?: string;
  queryParams?: object;
};
/**
 * ajax request
 * @param url string
 * @returns object
 */
export const getData = async <T,>(url: string, options: getDataOptions): Promise<T> => {
  const response = await fetch(url, options);
  return response.json();
};

/**
 * check email using regex
 * @param email string
 * @returns boolean
 */
export const checkEmail = (email: string): boolean => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) return true;
  return false;
};

/**
 * password must be 8 char long, 1 special char, 1 upper case letter, and 1 number
 * @param password string
 * @returns boolean
 */
export const checkPasswordStrength = (password: string): boolean => {
  let expression = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()-__+.]){1,}).{8,}$/;
  if (expression.test(password)) return true;
  return false;
};

export const checkMobileNumber = () => {
  // let expression =
};

export const formatNumber = (number: number = 0, decimalPlace: number = 2) => {
  return parseFloat(number.toString().slice(0, number.toString().indexOf('.') + (decimalPlace + 1))).toLocaleString('en-US');
};

/**
 *
 * @param number the number to format
 * @param decimalPlace the number of decimal places
 * @returns  string
 */
export const formatPriceNoRound = (number: number, decimalPlace: number = 2) => {
  const price = number.toString().split('.');
  const decimal = price[1] ? (price[1].length > 1 ? price[1].substring(0, decimalPlace) : `${price[1].substring(0, decimalPlace)}0`) : '00';
  return `${Number(price[0]).toLocaleString('en')}.${decimal}`;
};

/**
 *
 * @param number the number to format
 * @param decimalPlace the number of decimal places
 * @returns number
 */
export const numberDecimal = (number: number, decimalPlace: number = 2) => {
  const price = number.toString().split('.');
  const decimal = price[1] ? (price[1].length > 1 ? price[1].substring(0, decimalPlace) : `${price[1].substring(0, decimalPlace)}0`) : '00';
  return +`${price[0]}.${decimal}`;
};

export const cabinClassCode = (classCode: string) => {
  // F - First
  // C - Club (Business)
  // W - Economy/Coach Premium
  // M - Economy but not Economy/Coach Premium
  // Y - Economy (Y = W + M)
  switch (classCode) {
    case 'Y':
    case 'M':
    case 'W':
      return 'Economy';
    case 'C':
      return 'Business';
    case 'F':
      return 'First';
    case 'S':
      return 'Premium Economy';
    default:
      return 'Undefined Class';
  }
};

/**
 *
 * @param rating number
 * @returns star element
 */
// export const HotelStars = (rating?: number) => {
  // return <Star />;
  // let element: React.ReactNode;
  // const starRating: ReactElement[] = [];
  // for (let index = 0; index < rating; index++) {
  //   // return <Star />;
  //   starRating.push(
  //     <span className="text-primary" key={index}>
  //       ★
  //     </span>
  //   );
  // }
  // // console.log(element);
  // return <>{starRating}</>;
// };

/**
 * back to top function
 */
export const backToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'instant',
  } as any);
};

export const _setCurrentUrl = (url: string) => {
  localStorage.setItem('currentUrl', url);
};

export const _getCurrentUrl = () => {
  return localStorage.getItem('currentUrl');
};

export const _setPreviousUrl = (url: string) => {
  localStorage.setItem('previousUrl', url);
};

export const _getPreviousUrl = () => {
  return localStorage.getItem('previousUrl');
};

// Date Format
export const dateFormat = (date: string | undefined = '') => {
  const parts = date.split(', ');

  // Extract the month and day from the parts
  const monthAndDay = parts.slice(1).join(' ');

  return monthAndDay;
};

// add title case in prototype
export const titleCase = (str: string) => {
  if (str === null || str === undefined || str.length <= 0) return '';
  return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
};