import React, { useState } from 'react';
// import { useAirlineFlyerHooks } from '../hooks/AirlineFlyerHooks';
import { SelectFloatingLabelFormControl } from './FormControl';
import '../resources/css/hotel-option-dropdown.scss';

interface RestAirlineFlyerSelectInputInterface extends React.InputHTMLAttributes<HTMLSelectElement> {
  props?: any;
  important?: boolean;
  label: string;
  carrier?: string;
  DepartureAirline: { [index: string]: any } | undefined;
  ReturnAirline: { [index: string]: any } | undefined;
  setInputFocus: (inputFocus: string) => void;
  inputFocus: string;
}

export const AirlineFlyerSelectInput = ({ label, important, carrier, DepartureAirline, ReturnAirline, setInputFocus, inputFocus, ...props }: RestAirlineFlyerSelectInputInterface) => {
  // const { carrierList } = AirlineFlyerHooks();
  // const { freqFlyer } = useAirlineFlyerHooks(carrier);
  const [showAirlineDropdown, setShowAirlineDropdown] = useState(false);
  const [value, setValue] = useState('');
  return (
    <div className=" w-full relative transparent-line">
      <SelectFloatingLabelFormControl
        type={'text'}
        label={label}
        onFocus={() => setInputFocus('airline')}
        onBlur={() => setInputFocus('')}
        success={value !== '' || inputFocus === 'airline'}
        onClick={() => {
          setShowAirlineDropdown(true);
        }}
        placeholder="Airline"
        value={value}
        receivedValue={value ? true : false}
        style={{ borderColor: value ? '#4FA2b4' : '' }}
      />

      {showAirlineDropdown && (
        <div className="dropdown-option w-100 " onMouseLeave={() => setShowAirlineDropdown(false)}>
          <div
            onClick={() => {
              setShowAirlineDropdown(false);
              setValue(DepartureAirline?.name);
            }}
          >
            {DepartureAirline?.name}
          </div>
          {DepartureAirline?.name !== ReturnAirline?.name && (
            <div
              onClick={() => {
                setShowAirlineDropdown(false);
                setValue(DepartureAirline?.name);
              }}
            >
              {' '}
              {ReturnAirline?.name}
            </div>
          )}
        </div>
      )}

      {/* <select className="form-select w-full" aria-label="" {...props}>
        <option value="" disabled selected>
          {label} {important ? ' *' : ''}
        </option>
        
            <option value='Airlines'  >
             {freqFlyer.airlineName}
            </option>
         
      </select> */}
      {/* <label htmlFor="floatingSelectGrid">
        {label}
        {important ? ' *' : ''}
      </label> */}
    </div>
  );
};
