import { SvgProps } from './SvgProps';

export const CircledInfoSvg = ({ _width = 24, _height = 24, _color = '#4fa2b4' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z" stroke={_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16V11" stroke={_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0059 8H11.9969" stroke={_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
