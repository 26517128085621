import React from 'react';
import { NavBar } from '../../components/NavBar';
import Footer from '../../components/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FlightHistoryInformationHooks from '../../hooks/FlightHistoryInformationHooks';
import '../../resources/css/booking-history-details.scss';
import moment from 'moment';
import { ClockRoundSvg } from '../../resources/svg/ClockSvg';
import { UserSvg } from '../../resources/svg/UserSvg';
import { AirplaneAscendSvg, AirplaneDescendSvg, AirplaneUpRightSvg } from '../../resources/svg/AirplanesSvg';
import { ChevronRight } from 'lucide-react';
import { AirlineLogo } from '../../utils/airlines-logo';
import { BaggageSvg } from '../../resources/svg/BaggageSvg';
import { CheckInSvg } from '../../resources/svg/CheckInSvg';
import { FlightResultsHook } from '../../hooks/FlightResultsHook';
import { FlightPassengerList } from './FlightPassengerList';
import { formatPriceNoRound, titleCase } from '../../utils/data.utils';
import { HTTP_FORBIDDEN } from '../../utils/httpResponse.utils';
import { PaymentMethodLogo } from './PaymentMethodLogo';
import pluralize from 'pluralize';

export const BookingHistoryFlightInformation = () => {
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const id = param.get('id') as string;
  const { flightDetails } = FlightHistoryInformationHooks(id);
  const { flightSegments } = FlightResultsHook();

  const {
    transactionId,
    status,
    flightType,
    originCode,
    originCity,
    destinationCode,
    destinationCity,
    passengersTotal,
    passengerDetails,
    paymentDetails,
    departureSegments,
    returningSegments,
    customerDetails,
  } = flightDetails;

  if (flightDetails?.statusCode === HTTP_FORBIDDEN() || id === null) {
    navigate('/404');
  }

  // aircraft details
  const airlineName = departureSegments ? departureSegments[0].FlightSegment.OperatingCarrierDetails.descriptions : '';
  const airlineCode = departureSegments ? departureSegments[0].FlightSegment.MarketingCarriercode : '';
  const airline = AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === airlineCode);
  // laggage details
  const cabinBaggage = departureSegments && departureSegments[0].ItineraryReference.CabinBaggage.length > 0 ? departureSegments[0].ItineraryReference.CabinBaggage[0].Value : null;

  const checkInBaggage = departureSegments && departureSegments[0].ItineraryReference.CheckinBaggage.length > 0 ? departureSegments[0].ItineraryReference.CheckinBaggage[0].Value : null;

  // flight segments
  const departureSegmentsDetails = flightSegments(departureSegments);
  let returningSegmentsDetails: any = undefined;
  if (flightType?.toLowerCase() === 'roundtrip') {
    returningSegmentsDetails = flightSegments(returningSegments);
  }
  // console.log({ departureSegmentsDetails });
  // console.log({ returningSegmentsDetails });
  return (
    <>
      <NavBar />
      <main id="flight-history-page">
        <div className="container">
          <div className="row pb-5 justify-content-center">
            <div className="col-10 history-breadcrumb generalsans-medium" id="">
              <ul>
                <li className="cursor-pointer">
                  <a
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Bookings
                  </a>
                </li>
                <li>
                  <ChevronRight color="#0B5B6C" width={16} height={16} />
                </li>
                <li>Booking#{transactionId}</li>
              </ul>
            </div>

            <div className="col-10" id="flight-title">
              <h3>
                {originCity} ({originCode}) - {destinationCity} ({destinationCode})
              </h3>
              <span>{titleCase(status)}</span>
            </div>

            <div className="col-10" id="date-information">
              <span className="flex gap-2">
                <ClockRoundSvg _color="#0B5B6C" />
                {/* <span>{moment(departureDate).format('ddd, MMMM D')} </span>-<span>{returnDate}</span> */}
                <span>{departureSegmentsDetails.arrivalDate}</span> -
                {returningSegmentsDetails ? <span>{returningSegmentsDetails.arrivalDate}</span> : <span>{departureSegmentsDetails?.arrivalDate}</span>}
              </span>
              <span>
                <UserSvg _height={12} _width={12} _color="#0B5B6C" />
                {passengersTotal} {pluralize('Passenger', passengersTotal)}
              </span>
              <span>
                <AirplaneUpRightSvg _color="#0B5B6C" />
                {flightType?.toLowerCase() === 'oneway' ? 'One Way Flight' : 'Round Trip Flight'}
              </span>
            </div>

            <div className="col-10 galago-card mb-4" id="itinerary">
              <div className="galago-card-title">Itinerary</div>
              <div className="galago-card-body">
                <div>
                  <div>
                    <div>
                      <span className="departure-return">
                        <AirplaneAscendSvg _color="#1F5B6B" /> Departure
                      </span>
                      <span className="airline-logo-and-name">
                        <img src={airline?.logo} alt="" loading="lazy" width="30" />
                        <span>{airlineName}</span>
                      </span>
                    </div>
                    <div>
                      {cabinBaggage && cabinBaggage !== "0PC" ? <BaggageSvg _width={24} _height={24} _color="#016E7F" /> : ''}
                      {checkInBaggage && checkInBaggage !== "0PC" ? <CheckInSvg _width={24} _height={24} _color="#016E7F" /> : ''}
                      <span>included</span>
                    </div>
                  </div>
                  <div>
                    <div className="w-[20%] text-right">
                      <span>{departureSegmentsDetails.departureTime}</span>
                      <span>
                        {departureSegmentsDetails.departureCode} | {moment(departureSegmentsDetails.departureDateTime).format('MMM DD').toString().toUpperCase()}
                      </span>
                    </div>
                    <div className="w-[60%] align-items-center">
                      {/* <span>{departureSegmentsDetails.flightDuration}</span> */}
                      <span>&nbsp; {/* remove this span if you want to enable the flight duration above */}</span>
                      <div className="middle-arrow">
                        <div className="absolute top-[-7px] right-[0px]"></div>
                      </div>
                      <span>{departureSegmentsDetails.stops}</span>
                    </div>
                    <div className="w-[20%]">
                      <span>{departureSegmentsDetails.arrivalTime}</span>
                      <span>
                        {departureSegmentsDetails.arrivalCode} | {moment(departureSegmentsDetails.arrivalDateTime).format('MMM DD').toString().toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>

                {returningSegmentsDetails !== undefined ? (
                  <div className="border-t-1">
                    <div>
                      <div>
                        <span className="departure-return">
                          <AirplaneDescendSvg _color="#1F5B6B" /> Return
                        </span>
                        <span className="airline-logo-and-name">
                          <img src={airline?.logo} alt="" loading="lazy" width="30" />
                          <span>{airlineName}</span>
                        </span>
                      </div>
                      <div>
                        {cabinBaggage && cabinBaggage !== '0PC' ? <BaggageSvg _width={24} _height={24} _color="#016E7F" /> : ''}
                        {checkInBaggage && checkInBaggage !== '0PC' ? <CheckInSvg _width={24} _height={24} _color="#016E7F" /> : ''}
                        <span>included</span>
                      </div>
                    </div>
                    <div>
                      <div className="w-[20%] text-right">
                        <span>{returningSegmentsDetails?.departureTime}</span>
                        <span>
                          {returningSegmentsDetails?.departureCode} | {moment(returningSegmentsDetails?.departureDateTime).format('MMM DD').toString().toUpperCase()}
                        </span>
                      </div>
                      <div className="w-[60%] align-items-center">
                        {/* <span>{returningSegmentsDetails?.flightDuration}</span> */}
                        <span>&nbsp;</span>
                        <div className="middle-arrow">
                          <div className="absolute top-[-7px] right-[0px]"></div>
                        </div>
                        <span>{returningSegmentsDetails?.stops}</span>
                      </div>
                      <div className="w-[20%]">
                        <span>{returningSegmentsDetails?.arrivalTime}</span>
                        <span>
                          {returningSegmentsDetails?.arrivalCode} | {moment(returningSegmentsDetails?.arrivalDateTime).format('MMM DD').toString().toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="galago-card col-10 mb-4" id="passengers">
              <div className="galago-card-title">Passengers Details</div>
              <div className="galago-card-body">
                <ul className="passenger-accordion">
                  {passengerDetails?.adults.map((passenger: { [index: string]: any }, index: React.Key) => {
                    return <FlightPassengerList passenger={passenger} type="adult" key={index} />;
                  })}

                  {passengerDetails?.children.map((passenger: { [index: string]: any }, index: React.Key) => {
                    return <FlightPassengerList passenger={passenger} type="child" key={index} />;
                  })}

                  {passengerDetails?.infants.map((passenger: { [index: string]: any }, index: React.Key) => {
                    return <FlightPassengerList passenger={passenger} type="infant" key={index} />;
                  })}
                </ul>
              </div>
            </div>

            <div className="galago-card col-10 mb-4" id="contact-details">
              <div className="galago-card-title">Contact Details</div>
              <div className="galago-card-body flex p-4">
                <div className="flex flex-column w-50 gap-2">
                  <span className="text-sm">Email</span>
                  <span className="font-medium">{customerDetails?.email}</span>
                </div>
                <div className="flex flex-column gap-2 w-50 border-l-1 border-color-[#E3E6E8] pl-4">
                  <span className="text-sm">Contact Number</span>
                  <span className="font-medium">{customerDetails?.mobileNumber}</span>
                </div>
              </div>
            </div>

            <div className="galago-card col-10" id="price-overview">
              <div className="galago-card-title">Price Overview</div>
              <div className="galago-card-body p-4">
                <div className="flex justify-between items-center mb-3">
                  <span className="font-medium">
                    Total Base Price <br /> (x{passengersTotal} {pluralize('Passenger', passengersTotal)})
                  </span>
                  <span className="text-right">PHP {formatPriceNoRound(paymentDetails?.totalBaseFare ?? 0)}</span>
                </div>
                <div className="flex justify-between mb-3 border-b-1 border-color-[#E3E6E8] pb-3">
                  <span className="font-medium">Taxes</span>
                  <span className="text-right">PHP {formatPriceNoRound(paymentDetails?.totalTaxesAndFees ?? 0)}</span>
                </div>
                <div className="flex justify-between">
                  <div className="text-lg font-semibold">Total Charges</div>
                  <div className="text-right flex flex-column">
                    <span className="text-[#0C5C6C] text-lg font-semibold">PHP {formatPriceNoRound(paymentDetails?.totalCharges ?? 0)}</span>
                    <span className="flex items-center">
                      paid via <PaymentMethodLogo method={paymentDetails?.paymentGateway === '' ? 'others' : paymentDetails?.paymentGateway} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
