import React, { useEffect, useState } from 'react';
import { EmailDialog } from '../pages/login/EmailDialog';
import { PasswordDialog } from '../pages/login/PasswordDialog';
import { SignUpDialog } from '../pages/login/SignUpDialog';
import { GalagoButton } from './Button';
import '../resources/css/alert-confirm-email.scss';
import { UserStore } from '../store/UserStore';
import { useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { ChevronDownSvg } from '../resources/svg/ChevronDownSvg';
import { LogoSvg } from '../resources/svg/Logo';
import EmailConfirmationDialog from '../pages/login/EmailConfirmationDialog';
import PendingVerificationDialog from '../pages/login/PendingVerificationDialog';
import EmailSentDialog from '../pages/login/EmailSentDialog';
import { SignUpDialogStore } from '../store/SignupDialogStore';
import { EmailDialogStore } from '../store/EmailDialogStore';
import { PasswordDialogStore } from '../store/PasswordDialogStore';
import { AuthenticationHooks } from '../hooks/AuthenticationHooks';

// turn off signupdialog modal on success registration. replace with
interface Props {
  photo?: string;
}

export const NavBar = ({ photo }: Props) => {
  // const cookie = new Cookies();
  // const navigate = useNavigate();
  ScrollToTop();
  // const placeholder = photo ? photo : `${ASSETS_API().resources.images}/photo-placeholder.png`;
  const location = useLocation();
  // const { fbLoginSuccess } = useContext(FacebookContext);
  const { isLoggedIn, isGuest } = UserStore();
  const { showSignUpDialog, setShowSignUpDialog } = SignUpDialogStore();
  const { showEmailDialog, setShowEmailDialog } = EmailDialogStore();
  const { showPasswordDialog, setShowPasswordDialog } = PasswordDialogStore();
  const [hideHeaderLinks, setHideHeaderLinks] = useState(false);

  // Dropdown Toggle Icon
  const [dropdownToggle, setActiveDropdownToggle] = useState(false);

  // Profile photo and name on navbar
  const initialUser = {
    firstName: '',
    lastName: '',
    profilePic: '',
  };
  const [user, setUser] = useState(initialUser);
  const { checkLoggedUser } = AuthenticationHooks();

  const fetchUser = async () => {
    const userDetails = await checkLoggedUser();
    if (userDetails.status === 200) {
      const { data } = userDetails.data;
      const appendToPreventCache = Math.random();
      setUser({ ...data, profilePic: data.profilePic ? data.profilePic + `?id=${appendToPreventCache}` : '' });
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pathsToHide = ['/book/flight', '/booking/summary', '/payment-confirmation'];
    if (pathsToHide.includes(location.pathname)) {
      setHideHeaderLinks(true);
    } else setHideHeaderLinks(false);
  }, [location]);

  // useEffect(() => {
  //   const appendToPreventCache = '?id=' + Math.random();
  //   if (photo?.length) setProfilePhoto(photo + appendToPreventCache);
  //   // else setProfilePhoto(placeholder);
  //   // eslint-disable-next-line
  // }, [photo]);

  // useEffect(() => {
  //   if (info !== undefined) {
  //     const appendToPreventCache = '?id=' + Math.random();
  //     // // Wrap the code block inside an async function
  //     // const infoData = await info; // Await the 'info' promise
  //     const infoData = info;
  //     setLoggedFirstName(infoData?.firstName); // Access the 'firstName' property
  //     setLoggedLastName(infoData?.lastName);
  //     if (infoData?.profilePic) setProfilePhoto(infoData?.profilePic + appendToPreventCache);
  //     // else setProfilePhoto(placeholder);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <div className="shadow-sm py-4">
        <div className="container ">
          <header className="flex-wrap d-flex justify-content-center">
            <a href="/" className="mb-3 d-flex align-items-center mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
              <LogoSvg _color="#006F80" />
            </a>

            <ul className="nav nav-pills d-flex align-items-center">
              {!hideHeaderLinks && (
                <React.Fragment>
                  <li className="nav-item">
                    <a href="/#" className="nav-link" aria-current="page">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/#/blogs" className="nav-link">
                      Blogs
                    </a>
                  </li>
                  {/* HIDDEN DUE TO UNAVAILABILITY OF CONTENT */}
                  {/* <li className="nav-item">
                    <a href="/#" className="nav-link">
                      Promos
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a href="/#" className="nav-link">
                      About Us
                    </a>
                  </li>
                </React.Fragment>
              )}

              <li className="nav-item position-relative">
                {isLoggedIn && !isGuest ? (
                  <>
                    <a
                      className="image-navbar nav-link d-flex align-items-center gap-2"
                      href="/#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => setActiveDropdownToggle(!dropdownToggle)}
                    >
                      {/* {profilePhoto.length ? <img width={80} style={{border: '1px solid grey'}} loading='lazy' src={profilePhoto} /> : <img width={40} style={{border: '1px solid red'}} src={placeholder} /> }  */}
                      {user?.profilePic ? (
                        <img width={40} src={user?.profilePic} loading="lazy" alt="" />
                      ) : (
                        <div className="image">
                          <span className="initial">{user?.firstName?.charAt(0)}</span>
                        </div>
                      )}
                      {/* <img width={40} src={profilePhoto} alt="AM" loading="lazy" /> */}
                      <div className="flex flex-row gap-1 border-l-1 border-[#333] px-2 text-sm" id="user-name">
                        <span>{user?.firstName}</span>
                        <span>{user?.lastName}</span>
                      </div>
                      <span className={dropdownToggle ? 'rotate-180' : ''}>
                        <ChevronDownSvg _color="#333" _width={14} _height={14} />
                      </span>
                    </a>
                    <ul
                      className="dropdown-menu mt-5"
                      style={{
                        left: 'auto',
                        marginTop: '34px',
                        borderRadius: '12px',
                        borderTop: '6px solid #4fa2b4',
                        boxShadow: '0px 17px 60px 0px rgba(51, 51, 51, 0.1)',
                        paddingRight: '0px',
                      }}
                    >
                      <li>
                        <a href="#/profile" className="dropdown-item">
                          Account
                        </a>
                        <a href="#/history" className="dropdown-item">
                          Bookings
                        </a>
                        <hr />
                        <a href="#/terms-and-conditions" className="dropdown-item" target="_blank" rel="noreferrer">
                          Terms & Conditions
                        </a>
                        <a href="#/privacy-policy" className="dropdown-item" target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                        <hr />
                        <a className="dropdown-item" href="/#/logout">
                          Logout
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  <GalagoButton
                    btn="primary"
                    size="sm"
                    className="me-2"
                    onClick={() => setShowSignUpDialog(true)}
                    label="Login or Sign Up"
                    style={{ margin: '0px', fontSize: '16px', borderRadius: '8px', padding: '8px 0' }}
                  />
                )}
              </li>
            </ul>
          </header>
        </div>
      </div>
      {/* <SignUpDialog show={showSignUpModal} onHide={() => setShowSignUpModal((prev) => !prev)} /> */}

      <SignUpDialog show={showSignUpDialog} onHide={() => setShowSignUpDialog(false)} />
      <EmailDialog show={showEmailDialog} onHide={() => setShowEmailDialog(false)} />
      <PasswordDialog show={showPasswordDialog} onHide={() => setShowPasswordDialog(false)} />
      {/* <RegistrationDialog /> */}
      <EmailConfirmationDialog />
      <PendingVerificationDialog />
      <EmailSentDialog />
    </>
  );
};
