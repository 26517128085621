import { create } from 'zustand';

export const FlightFiltersStoreOneWay = create((set: any) => ({
  filterKey: '',
  setFilterKey: (key: string) => {
    set((state: { filterKey: string }) => ({
      filterKey: key,
    }));
  },

  priceRange: { start: 0, end: 0 },
  setPriceRange: (price: { start: number; end: number }) => {
    set((state: { priceRange: { start: number; end: number } }) => ({
      priceRange: price,
    }));
  },

  selectedPriceRange: { start: 0, end: 0 },
  setSelectedPriceRange: (price: { start: number; end: number }) => {
    set((state: { selectedPriceRange: { start: number; end: number } }) => ({
      selectedPriceRange: price,
    }));
  },

  selectedPriceRangeLabel: { start: 0, end: 0 },
  setSelectedPriceRangeLabel: (price: { start: number; end: number }) => {
    set((state: { selectedPriceRangeLabel: { start: number; end: number } }) => ({
      selectedPriceRangeLabel: price,
    }));
  },

  selectedDepartureTimeRange: { start: '', end: '' } as { start: string; end: string },
  setSelectedDepartureTimeRange: (time: { start: string; end: string }) => {
    set((state: { selectedDepartureTimeRange: { start: string; end: string } }) => ({
      selectedDepartureTimeRange: time,
    }));
  },

  selectedArrivalTimeRange: { start: '', end: '' } as { start: string; end: string },
  setSelectedArrivalTimeRange: (time: { start: string; end: string }) => {
    set((state: { selectedArrivalTimeRange: { start: string; end: string } }) => ({
      selectedArrivalTimeRange: time,
    }));
  },

  selectedStops: [] as string[],
  setSelectedStops: (stops: string[]) => {
    set((state: { selectedStops: string[] }) => ({
      // selectedStops: merge(state.selectedStops, stops),
      // merge the previous selected stops with the new selected stops and remove duplicates
      selectedStops: Array.from(new Set([...state.selectedStops, ...stops])),
    }));
  },

  selectedCabinAndCheckInBaggage: false as boolean,
  setSelectCabinAndCheckInBaggage: (baggage: boolean) => {
    set((state: { selectedCabinAndCheckInBaggage: boolean }) => ({
      selectedCabinAndCheckInBaggage: baggage,
    }));
  },

  // merge the previous selected airlines with the new selected airlines
  selectedAirlines: [] as string[],
  setSelectedAirlines: (airline: string[]) => {
    set((state: { selectedAirlines: string[] }) => ({
      // merge the previous selected airlines with the new selected airlines and remove duplicates
      selectedAirlines: Array.from(new Set([...state.selectedAirlines, ...airline])),
    }));
  },

  airlineList: [] as { [index: string]: any },
  setAirlineList: (airlines: { [index: string]: any }) => {
    set((state: { airlineList: { [index: string]: any } }) => ({
      airlineList: airlines,
    }));
  },
}));
