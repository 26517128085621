import axios from 'axios';
import { useEffect, useState } from 'react';
import { bookingFlightsReference, getBookingFlightsDetails, X_API_KEY } from '../utils/apiRoutes';
import { HTTP_OK } from '../utils/httpResponse.utils';
import { FlightResultsHook } from './FlightResultsHook';

type returnedFlightData = {
  departureTime: string;
  arrivalTime: string;
  departureDate: string;
  arrivalDate: string;
  departureDateTime: any;
  arrivalDateTime: any;
  stops: string;
  departureCode: any;
  arrivalCode: any;
  carrierCode: any;
  flightNumber: any;
  airlineCode: string;
  departure: any;
  arrival: any;
  flightDuration: string;
  cabinClass: any;
  equipment: any;
  isDomesticFlight: boolean;
  MarketingCarriercode: string;
  MarketingFlightNumber: string;
  OperatingCarrierCode: string;
  OperatingFlightNumber: string;
  CabinClassCode: string;
};

export const FlightBookingReferenceHook = (paymentReference: any) => {
  const { flightSegments, flightFares } = FlightResultsHook();

  const [bookingDetails, setBookingDetails] = useState<{ [index: string]: any }>();
  const [bookDetailsStatus, setBookDetailsStatus] = useState(false);
  const [bookPaymentDetails, setBookPaymentDetails] = useState<{ [index: string]: any }>();
  const [bookingFlightFares, setBookingFlightFares] = useState<{ [index: string]: any }>();

  let departureData: returnedFlightData | undefined = undefined;
  let returnData: returnedFlightData | undefined = undefined;

  useEffect(() => {
    getBookingDetails(paymentReference);
    // eslint-disable-next-line
  }, []);

  const getBookingDetails = (paymentReference: string) => {
    let legIndicators: string[] = [];
    let legIndicatorsKey = 0;

    try {
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['x-api-key'] = X_API_KEY;
      }
      setTimeout(async () => {
        const paymentResponse = await axios.get(bookingFlightsReference(paymentReference));
        // console.log(paymentResponse.data.data);
        setBookPaymentDetails(paymentResponse.data.data);

        const response = await axios.get(getBookingFlightsDetails(paymentReference));

        // console.log(response.status);
        if (response.status === HTTP_OK()) {
          setBookDetailsStatus(true);
        }
        // setBookingDetails(response.data[0]);

        if (response.data.data.length === 0) {
          return;
        }
        // console.log(response.data.data[0].originDestinations);
        const origins = response.data.data[0]?.originDestinations;
        // console.log(origins);
        // eslint-disable-next-line
        origins.map((destination: { LegIndicator: string }, index: number) => {
          if (!legIndicators.includes(destination.LegIndicator)) {
            legIndicators[legIndicatorsKey] = destination.LegIndicator;
            ++legIndicatorsKey;
          }
        });

        let _OriginDestinations: { [index: string]: any } = [];

        for (let index = 0; index < legIndicators.length; index++) {
          // const element = ;
          _OriginDestinations[index] = origins.filter((destination: { LegIndicator: string }) => {
            return destination.LegIndicator === legIndicators[index];
          });
        }

        setBookingFlightFares(flightFares(response.data.data[0].flightFares));

        departureData = flightSegments(_OriginDestinations[0]) as returnedFlightData | undefined;
        // console.log(departureData);
        if (departureData) {
          departureData = {
            departureTime: departureData.departureTime || '',
            arrivalTime: departureData.arrivalTime || '',
            departureDate: departureData.departureDate || '',
            arrivalDate: departureData.arrivalDate || '',
            departureDateTime: departureData.departureDateTime || '',
            arrivalDateTime: departureData.arrivalDateTime || '',
            stops: departureData.stops || '',
            departureCode: departureData.departureCode || '',
            arrivalCode: departureData.arrivalCode || '',
            carrierCode: departureData.carrierCode || '',
            flightNumber: departureData.flightNumber || '',
            airlineCode: departureData.airlineCode || '',
            departure: departureData.departure || '',
            arrival: departureData.arrival || '',
            flightDuration: departureData.flightDuration || '',
            cabinClass: departureData.cabinClass || '',
            CabinClassCode: departureData.CabinClassCode || '',
            equipment: departureData.equipment || '',
            isDomesticFlight: departureData.isDomesticFlight || false,
            MarketingCarriercode: departureData.MarketingCarriercode || '',
            MarketingFlightNumber: departureData.MarketingFlightNumber || '',
            OperatingCarrierCode: departureData.OperatingCarrierCode || '',
            OperatingFlightNumber: departureData.OperatingFlightNumber || '',
          };
        }
        if (legIndicators.length === 2) {
          returnData = flightSegments(_OriginDestinations[1]) as returnedFlightData | undefined;
          if (returnData) {
            returnData = {
              departureTime: returnData.departureTime || '',
              arrivalTime: returnData.arrivalTime || '',
              departureDate: returnData.departureDate || '',
              arrivalDate: returnData.arrivalDate || '',
              departureDateTime: returnData.departureDateTime || '',
              arrivalDateTime: returnData.arrivalDateTime || '',
              stops: returnData.stops || '',
              departureCode: returnData.departureCode || '',
              arrivalCode: returnData.arrivalCode || '',
              carrierCode: returnData.carrierCode || '',
              flightNumber: returnData.flightNumber || '',
              airlineCode: returnData.airlineCode || '',
              departure: returnData.departure || '',
              arrival: returnData.arrival || '',
              flightDuration: returnData.flightDuration || '',
              cabinClass: returnData.cabinClass || '',
              CabinClassCode: returnData.CabinClassCode || '',
              equipment: returnData.equipment || '',
              isDomesticFlight: returnData.isDomesticFlight || false,
              MarketingCarriercode: returnData.MarketingCarriercode || '',
              MarketingFlightNumber: returnData.MarketingFlightNumber || '',
              OperatingCarrierCode: returnData.OperatingCarrierCode || '',
              OperatingFlightNumber: returnData.OperatingFlightNumber || '',
            };
          }
        }

        setBookingDetails({
          departure: departureData,
          return: returnData,
        });

        // departure = flightSegments()
      }, 2000);
    } catch (error) {
      return error;
    }
  };

  return { bookingDetails, bookDetailsStatus, bookPaymentDetails, bookingFlightFares };
};
