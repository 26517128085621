import React, { useState } from 'react';
import Footer from '../Footer';
import HotelConfirmationProgress2 from './HotelConfirmationProgress2';
import { useDisclosure } from '@nextui-org/react';
import { useLocation, useNavigate } from 'react-router';
import ModalTemplate from '../ui/ModalTemplate';
import HotelBookingModal from '../../pages/book/hotels/HotelBookingModal';
import { NavBar } from '../NavBar';
import { HotelCartStore } from '../../store/HotelCartStore';
import { HotelPreBookingHooks } from '../../hooks/HotelPreBookingHooks';
import { GalagoButton } from '../Button';
import '../../resources/css/hotel-payment-page.scss';
import { CheckSvg } from '../../resources/svg/CheckSvg';
import { formatPriceNoRound } from '../../utils/data.utils';
import moment from 'moment';
import { HotelGuestStore } from '../../store/HotelGuestsStore';
import CancellationPolicyHooks from '../../hooks/CancellationPolicyHooks';
import pluralize from 'pluralize';

const HotelPaymentPage = () => {
  const navigate = useNavigate();
  const { preBookingGalago } = HotelPreBookingHooks();
  const { cancellationPolicy2 } = CancellationPolicyHooks();
  const { setSelectedHotelRooms } = HotelCartStore();
  const { isOpen, onClose, onOpen, onOpenChange } = useDisclosure();
  const { guest } = HotelGuestStore();

  const urlData = useLocation();
  const { hotelInfo, selectedRooms, nights, searchCriteria, tboDataResponse, transactionId, hotel } = urlData.state;

  // console.log(selectedRooms)

  const [disableButton, setDisableButton] = React.useState(true);

  let hotelPreBookRoomsPayload: { [index: string]: any } = [];

  React.useEffect(() => {
    tboDataResponse.map((value: { [index: string]: any }, key: number) => (hotelPreBookRoomsPayload[key] = value.Rooms[0]));

    let hotelPreBookPayload: { [index: string]: any } = {
      transactionId: transactionId,
      provider: 'tbo',
      providerDetails: {
        HotelCode: tboDataResponse[0].HotelCode,
        Currency: tboDataResponse[0].Currency,
        RateConditions: tboDataResponse[0].RateConditions,
        Rooms: hotelPreBookRoomsPayload,
      },
    };

    if (tboDataResponse.length > 0) {
      preBookingGalago(hotelPreBookPayload, setDisableButton);
    }
    // eslint-disable-next-line
  }, []);

  const firstImage = hotelInfo.images[0] === undefined ? '/img/missing-img.png' : hotelInfo.images[0];
  const secondImage = hotelInfo.images[1] === undefined ? '/img/missing-img.png' : hotelInfo.images[1];
  const thirdImage = hotelInfo.images[2] === undefined ? '/img/missing-img.png' : hotelInfo.images[2];
  const [displayImage, setDisplayImage] = useState(firstImage);
  // const [isTotalRoomPriceClicked, setIsTotalRoomPriceClicked] = React.useState(false);

  const checkInDate = moment(searchCriteria?.checkInDate).format('MMMM DD');
  const checkOutDate =
    moment(searchCriteria?.checkInDate).format('MMMM') === moment(searchCriteria?.checkOutDate).format('MMMM')
      ? moment(searchCriteria?.checkOutDate).format('DD, YYYY')
      : moment(searchCriteria?.checkOutDate).format('MMMM DD, YYYY');

  // let totalRoomPrice = 0;
  let totalFees = 0;
  let grandTotal = 0;

  return (
    <>
      <NavBar />
      <HotelConfirmationProgress2 />
      <main className="container pt-5">
        <p className="mb-2 font-satoshi font-bold" style={{ fontSize: '34px' }}>
          Booking Summary
        </p>
        <p className="generalsans-regular font-medium mb-5" style={{ fontSize: '16px' }}>
          Please review your booking before proceeding to payment!
        </p>

        <div className="payment-page-summary generalsans-regular">
          <div className="collage-container">
            <div className="payment-page-collage" style={{ backgroundImage: `url(${displayImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <div>
                <div style={{ backgroundImage: `url(${firstImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }} onClick={() => setDisplayImage(firstImage)}></div>
                <div style={{ backgroundImage: `url(${secondImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }} onClick={() => setDisplayImage(secondImage)}></div>
                <div style={{ backgroundImage: `url(${thirdImage})`, backgroundPosition: 'center', backgroundSize: 'cover' }} onClick={() => setDisplayImage(thirdImage)}></div>
              </div>
            </div>
            <GalagoButton
              label="Change your selection"
              btn="link"
              className="generalsans-regular text-sm w-max !pl-0 text-[#4FA2B4]"
              onClick={() => {
                setSelectedHotelRooms([], true);
                navigate(-2);
              }}
            />
          </div>
          <div className="px-[47px] booking-summary-container">
            <p className="text-xl satoshi-semibold pb-3">{hotelInfo?.propertyName}</p>
            <p className="generalsans-medium m-0 mb-2">Stay Dates</p>
            <div className="flex items-start gap-5">
              <p className="generalsans-medium mb-3 ">
                {checkInDate} - {checkOutDate}
              </p>
              <p className=" m-0 generalsans-medium ">( 1 Night )</p>
            </div>
            <p className="generalsans-medium m-0 mb-2">Lead Guest</p>
            <p className="generalsans-medium m-0 mb-2">
              {guest.title} {guest.firstName} {guest.lastName}
            </p>

            <p className="generalsans-medium text-text-colors-text-primary mb-2 border-t-[1px] pt-[24px] mt-[24px] ">Selected Room(s)</p>
            <div className="rooms-list bg-white">
              {selectedRooms.map((value: { [index: string]: any }, key: React.Key) => {
                totalFees += value.TotalTax;
                // totalRoomPrice += value.TotalFare - totalFees;
                grandTotal += value.TotalFare;

                const rooms = value.Name[0].split(',');
                // const filteredWords = rooms.filter((word: string) => !word.toLowerCase().includes('smoking'));
                // const finalRoomName = filteredWords.join(' - ');
                const inclusion = value.Inclusion.toLowerCase() !== 'room only' ? value.Inclusion : '';
                let cancellationPolicies = value.CancelPolicies;
                // TODO: double check this function where we are computing the cancellation policy
                const cancellationPolicyResult = cancellationPolicy2({ cancellationObject: cancellationPolicies, selectedRooms: rooms, resultOnly: true });
                return (
                  <div key={key} className={`room ${selectedRooms.length > 1 ? 'multiple-rooms' : ''}`}>
                    <p className="generalsans-medium text-text-colors-text-brand mb-1 ">0{selectedRooms.indexOf(value) + 1} </p>
                    <p className="generalsans-medium text-text-colors-text-brand">{rooms[0]}</p>
                    <div className="flex flex-row gap-1 items-center tag mt-2">
                      <CheckSvg _color="#016E7F" _width={20} _height={20} />
                      <span className=" text-[13px] font-medium">{rooms[1]}</span>
                    </div>
                    {/* <div className="flex items-center mb-1 gap-1 tag mb-2"> */}
                    {inclusion !== '' && (
                      <div className="flex flex-row gap-1 items-center tag mt-2">
                        <CheckSvg _color="#016E7F" _width={20} _height={20} />
                        <p className="text-[13px] font-medium">{inclusion}</p>
                      </div>
                    )}
                    {/* </div> */}
                    <div className="flex flex-row gap-1 items-center tag mt-2">
                      <CheckSvg _color="#016E7F" _width={20} _height={20} />
                      <span className=" text-[13px] font-medium">{cancellationPolicyResult}</span>
                    </div>
                    <div className="flex flex-row gap-1 items-center tag mt-2 ">
                      <CheckSvg _color="#016E7F" _width={20} _height={20} />
                      <span className=" text-[13px] font-medium">{value.Amenities[0]}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mx-[47px] price-overview-container">
            <p className="satoshi-semibold text-xl pb-3">Price Overview</p>

            <div className="">
              {/* <div className="" onClick={() => setIsTotalRoomPriceClicked(!isTotalRoomPriceClicked)}> */}
              <div className="price-overview">
                <div className="flex flex-row gap-1.5 items-center">
                  <span className="textColor generalsans-medium">Total Room Price</span>
                  {/* <span className={isTotalRoomPriceClicked ? 'rotate-180' : ''}>
                    {' '}
                    <ChevronDownSvg _width={14} _height={14} _color="#ADADAD" onClick={() => setIsTotalRoomPriceClicked(!isTotalRoomPriceClicked)} className="cursor-pointer" />{' '}
                  </span> */}
                </div>
                <div className="textColor generalsans-medium text-text-colors-text-brand">PHP {formatPriceNoRound(grandTotal - totalFees)}</div>
              </div>
              <div className="textColor generalsans-regular text-[13px] my-1 mb-3">
                {'(' + selectedRooms.length} {pluralize('room', selectedRooms.length)} x {nights} {pluralize('night', nights) + ')'}
              </div>
              {/* </div> */}
            </div>

            {/* {isTotalRoomPriceClicked && ( */}
            <div className="">
              {selectedRooms.map((value: { [index: string]: any }, index: React.Key) => {
                // Change the room name to be more readable (remove smoking and use '-')
                const rooms = value.Name[0].split(',');
                const filteredWords = rooms.filter((word: string) => !word.toLowerCase().includes('smoking'));
                const finalRoomName = filteredWords.join(' - ');
                return (
                  <React.Fragment key={index}>
                    <div className="flex justify-between mb-1 generalsans-regular">
                      <span className="final-room-name">{finalRoomName}</span>
                      <span>PHP {formatPriceNoRound(value.TotalFare - value.TotalTax)}</span>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {/* )} */}
            <div className="flex flex-row w-full justify-between textColor  mt-3">
              <span>Taxes and Fees</span>
              <span className="">PHP {formatPriceNoRound(totalFees)}</span>
            </div>

            <div className="total-amount">
              <p>Total Amount</p>
              <p className="text-text-colors-text-brand"> PHP {formatPriceNoRound(grandTotal)}</p>
            </div>
          </div>
        </div>

        <div className="row justify-end">
          <div className="col-5">
            {/* <HotelBookingSummary
              setSelectedHotelRooms={setSelectedHotelRooms}
              navigate={navigate}
              searchCriteria={searchCriteria}
              hotelInfo={hotelInfo}
              selectedRooms={selectedRooms}
              nights={nights}
              facilitiesData={hotelInfo?.facilitiesData}
              preBookingTBOResponse={tboDataResponse}
            /> */}

            <div className="flex w-full gap-2 mt-5 generalsans-regular mb-5">
              <GalagoButton onClick={() => navigate(-1)} label="Back" btn="light" />
              <GalagoButton onClick={onOpen} disabled={disableButton} label="Proceed to payment" btn="primary" />
            </div>
          </div>
        </div>
      </main>
      <Footer />

      {isOpen && (
        <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[25.63rem] w-[25.63rem]">
          <HotelBookingModal onClose={onClose} urlPayload={urlData.state} hotel={hotel} />
        </ModalTemplate>
      )}
    </>
  );
};

export default HotelPaymentPage;
