import axios from 'axios';
import React from 'react';
import { countryPhoneCodesApiRoute } from '../utils/apiRoutes';

interface CountryCodesSelectInputTypes extends React.InputHTMLAttributes<HTMLSelectElement> {
  props?: any;
  countryCode?: any;
}

export const CountryCodesSelectInput = ({ countryCode, ...props }: CountryCodesSelectInputTypes) => {
  const [countries, setCountries] = React.useState<{ [index: string]: any }>();
  React.useEffect(() => {
    axios.get(countryPhoneCodesApiRoute()).then((response) => {
      // console.log(response.data);
      let _countries: any[] = [];
      if (response.status === 200) {
        // eslint-disable-next-line
        response.data.map((country: { [index: string]: any }): any => {
          // console.log(country.idd.suffixes.length);

          for (let index = 0; index < country.idd.suffixes.length; index++) {
            // const element = country.idd.suffixes[index];
            _countries.push({
              value: country.name.common,
              label: country.name.common,
              code: `${country.idd.root}${country.idd.suffixes[index]}`,
              flag: country.flag,
              cca2: country.cca2,
              flags: country.flags,
            });
          }
        });
      }

      _countries.sort((a, b) => {
        let text1 = a.value.toUpperCase();
        let text2 = b.value.toUpperCase();
        return text1 < text2 ? -1 : text1 > text2 ? 1 : 0;
      });

      setCountries(_countries);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="form-floating">
      <select className="form-select w-full" aria-label="" {...props}>
        {countries?.map((value: { cca2: string; value: string; flag: string; code: string }, index: any) => {
          return (
            <option value={value.code} selected={value.code === '+63' ? true : false} key={index}>
              {value.flag} {value.code}
            </option>
          );
        })}
      </select>
      <label htmlFor={`floatingSelectGrid ${countryCode !== '' ? 'bg-red text-text-colors-text-brand' : 'text-secondary '}`}>
        <span className={`text-sm ${countryCode !== '' ? 'bg-red text-text-colors-text-brand' : 'text-secondary '}`}>Country Code</span>
      </label>
    </div>
  );
};
