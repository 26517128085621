import { SvgProps } from './SvgProps';

export const EmbedIcon = ({ _width = 24, _height = 24, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.69969 16.7803L8.94969 15.2183L4.92569 11.9993L8.94969 8.78025L7.69969 7.21825L2.69969 11.2183C2.58253 11.3119 2.48796 11.4308 2.42296 11.566C2.35797 11.7012 2.32422 11.8492 2.32422 11.9993C2.32422 12.1493 2.35797 12.2973 2.42296 12.4325C2.48796 12.5677 2.58253 12.6866 2.69969 12.7803L7.69969 16.7803ZM16.9497 7.21825L15.6997 8.78025L19.7237 11.9993L15.6997 15.2183L16.9497 16.7803L21.9497 12.7803C22.0668 12.6866 22.1614 12.5677 22.2264 12.4325C22.2914 12.2973 22.3252 12.1493 22.3252 11.9993C22.3252 11.8492 22.2914 11.7012 22.2264 11.566C22.1614 11.4308 22.0668 11.3119 21.9497 11.2183L16.9497 7.21825ZM15.3007 3.21525L11.3007 21.2153L9.34769 20.7812L13.3477 2.78125L15.3007 3.21525Z"
        fill={_color}
      />
    </svg>
  );
};
