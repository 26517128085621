import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../resources/css/flight-details-modal.scss';
import { GalagoButton } from '../../../components/Button';
import { CheckFilledSvg } from '../../../resources/svg/CheckSvg';
import moment from 'moment';
import { AirplaneUpSvg } from '../../../resources/svg/AirplanesSvg';
import { BaggageSvg } from '../../../resources/svg/BaggageSvg';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import { AirlineLogo } from '../../../utils/airlines-logo';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { CheckInSvg } from '../../../resources/svg/CheckInSvg';

interface flightDetailsProps {
  flightInfo: { [index: string]: any };
  btnClick: (e: any) => void;
  show: boolean;
  onHide: () => void;
}

export const FlightDetailsModal = ({ flightInfo, show, onHide, btnClick }: flightDetailsProps) => {
  const { calcTotalTimeDuration, flightFares } = FlightResultsHook();
  // const { OriginDestinations } = flightInfo !== undefined ? flightInfo : null;

  // console.log(flightInfo);
  // if (flightInfo === undefined) return;
  const RefundAllowed = flightInfo?.PenaltiesInfo?.Penaltydetails[0]?.RefundAllowed;

  // const departure = flightInfo?.OriginDestinations[0]?.FlightSegment;
  // const arrival = flightInfo?.OriginDestinations.slice(-1)[0]?.FlightSegment;
  // const from = FlightSegment.DepartureLocationDetails.cityName;
  // let key = 0;
  // let advanceKey = 1;

  // const totalStopsCount = flightInfo?.OriginDestinations.length - 1;
  // let totalPerson = 0;
  // let priceFare = 0;
  // let currency = flightInfo?.FlightFares.Currency;
  // const lastOriginDestinationsKey = +Object.keys(flightInfo.OriginDestinations).slice(-1)[0];

  let legIndicators: string[] = [];
  let legIndicatorsKey = 0;
  // eslint-disable-next-line
  flightInfo.OriginDestinations.map((destination: { LegIndicator: string }, index: number) => {
    if (!legIndicators.includes(destination.LegIndicator)) {
      legIndicators[legIndicatorsKey] = destination.LegIndicator;
      ++legIndicatorsKey;
    }
  });

  let _OriginDestinations: { [index: string]: any } = [];
  for (let index = 0; index < legIndicators.length; index++) {
    // const element = ;
    _OriginDestinations[index] = flightInfo.OriginDestinations.filter((destination: { LegIndicator: string }) => {
      return destination.LegIndicator === legIndicators[index];
    });
  }

  // console.log(flightFares(flightInfo?.FlightFares));
  const { currency, passengerCount, passengers, adult, child, infant } = flightFares(flightInfo?.FlightFares);
  // console.log(passengers);
  // console.log({ adult });
  const totalAdultPrice = (adult.adultBaseFare + adult.adultTax) * passengers.adultQuantity;
  const totalChildPrice = (child.childBaseFare + child.childTax) * passengers.childQuantity;
  const totalInfantPrice = (infant.infantBaseFare + infant.infantTax) * passengers.infantQuantity;
  const total = totalAdultPrice + totalChildPrice + totalInfantPrice;

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="galago-modal-header satoshi-regular">
          Flight Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 overflow-y-auto" style={{ minHeight: '350px', maxHeight: '500px' }}>
        <div>
          {_OriginDestinations.map((destinations: { [index: string]: any }, key: React.Key) => {
            const stopsCount = destinations.length - 1;

            // console.log(destinations[0].LegIndicator);

            return (
              <React.Fragment key={key}>
                <div className="flight-info-header generalsans-regular">
                  <p>{destinations[0].LegIndicator === '0' ? 'Departure Flight' : 'Return Flight'}</p>

                  <div className="satoshi-regular fw-semibold">
                    {destinations[0].FlightSegment.DepartureLocationDetails.cityName} to {destinations.slice(-1)[0].FlightSegment.ArrivalLocationDetails.cityName}
                  </div>
                </div>
                {destinations.map((destination: any, index: number) => {
                  const airline = AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === destination.FlightSegment?.OperatingCarrierCode);
                  // calculate the time duration of stops
                  let stopDuration: string = '';
                  let nextDepartureTime: any = '';
                  let nextCity = '';

                  if (stopsCount >= 1) {
                    nextDepartureTime = destinations[index + 1]?.FlightSegment?.DepartureDateTime;
                    nextCity = destinations[index + 1]?.FlightSegment?.DepartureLocationDetails?.cityName;
                  }
                  // check if the next departure time is not undefined then calculate the time
                  if (nextDepartureTime !== undefined) {
                    stopDuration = calcTotalTimeDuration(nextDepartureTime, destination.FlightSegment?.ArrivalDateTime);
                  }
                  // console.log(stopDuration);

                  return (
                    <React.Fragment key={index}>
                      <div className="flight-info-body">
                        <div className="flight-start">
                          <div>
                            <span className="d-block generalsans-medium">{moment(destination.FlightSegment?.DepartureDateTime).format('HH:mm')}</span>
                            <span className="d-block satoshi-regular">{moment(destination.FlightSegment?.DepartureDateTime).format('MMM DD')}</span>
                          </div>
                          <div>
                            <span className="d-block generalsans-regular">
                              {destination.FlightSegment?.DepartureLocationDetails?.cityName} ({destination.FlightSegment?.DepartureLocationDetails?.airportCode})
                            </span>
                            <span className="d-block satoshi-regular">{destination.FlightSegment?.DepartureLocationDetails?.airportName}</span>
                          </div>
                        </div>
                        <div className="flight-start-stop-info">
                          <div></div>
                          <div>
                            <span className="d-flex justify-content-start gap-2 generalsans-medium">
                              <img src={airline?.logo} alt="airline logo" loading="lazy" width="24" className="img-fluid" style={{ borderRadius: '20px' }} />
                              {airline?.name}
                            </span>
                            <span className="d-flex align-items-center generalsans-regular">
                              <AirplaneUpSvg _width={20} _height={16} _color="#016E7F" />
                              {destination.FlightSegment?.MarketingCarriercode}
                              {destination.FlightSegment?.MarketingFlightNumber}
                            </span>
                            {/* <span className="d-block">
                          <SpoonAndForkSvg _width={15} _height={15} _color="#016E7F" /> In-Flight Meal
                        </span> */}
                            <span className="d-flex align-items-center generalsans-regular">
                              <BaggageSvg _width={20} _height={16} _color="#016E7F" /> Cabin baggage{' '}
                              {destination.ItineraryReference?.CabinBaggage[0]?.Value === 'SB' ? 'Standard' : destination.ItineraryReference?.CabinBaggage[0]?.Value}
                            </span>
                            {destination.ItineraryReference?.CheckinBaggage[0]?.Value !== '0PC' && (
                              <span className="d-flex align-items-center generalsans-regular">
                                <CheckInSvg _width={20} _height={16} _color="#016E7F" /> Check-in baggage{' '}
                                {destination.ItineraryReference?.CheckinBaggage[0]?.Value === 'SB' ? 'Standard' : destination.ItineraryReference?.CheckinBaggage[0]?.Value}
                              </span>
                            )}
                            {/* <span className="d-block">
                          <WifiSvg _width={15} _height={15} _color="#016E7F" /> Wi-fi
                        </span> */}
                            {/* <span className="d-block">
                          <GraphArrowIncreaseSvg _width={15} _height={15} _color="#016E7F" /> 100% On-Time{' '}
                        </span> */}
                          </div>
                        </div>
                        <div className="flight-stop">
                          <div>
                            <span className="d-block generalsans-medium">{moment(destination.FlightSegment.ArrivalDateTime).format('HH:mm')}</span>
                            <span className="d-block satoshi-regular">{moment(destination.FlightSegment.ArrivalDateTime).format('MMM DD')}</span>
                          </div>
                          <div>
                            <span className="d-block generalsans-regular">
                              {destination.FlightSegment?.ArrivalLocationDetails?.cityName} ({destination.FlightSegment?.ArrivalLocationDetails?.airportCode})
                            </span>
                            <span className="d-block satoshi-regular">{destination.FlightSegment?.ArrivalLocationDetails?.airportName}</span>
                          </div>
                        </div>
                        {stopDuration.length > 0 && stopDuration !== 'NaNh' ? (
                          <div className="stop-information w-100 satoshi-medium">
                            Stop to change plane in {nextCity} ({stopDuration})
                          </div>
                        ) : null}
                      </div>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>

        <div className="flight-info-foot">
          {/* <div>
            <h3 className="fw-bold">What's Included</h3>
            <div>
              <CheckFilledSvg />
              <div>
                <h3 className="fw-bold">Travel Tax Included</h3>
                <p>Fly without hassle. This price already includes Philippine Travel tax!.</p>
                <a href="">See More Details</a>
              </div>
            </div>
          </div> */}
          {RefundAllowed && (
            <div>
              <h3 className="fw-bold">Refund Policy</h3>
              <div>
                <CheckFilledSvg _width={24} _height={20} />
                <div>
                  <h3 className="generalsans-medium">Refund Available</h3>
                  <p className="generalsans-regular">This flight is eligible for a refund request in case of cancellation or delay!</p>
                  {/* <a href="">See More Details</a> */}
                </div>
              </div>
            </div>
          )}
          {/* <div>
            <h3 className="fw-bold">Reschedule Policy</h3>
            <div>
              <CheckFilledSvg />
              <div>
                <h3 className="fw-bold">Reschedule is Available</h3>
                <p>You can easily request to reschedule the time or date of your flight..</p>
                <a href="">See More Details</a>
              </div>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer className="flight-footer">
        <div className="w-75 col">
          <p className="fw-normal ps-3 satoshi-regular mb-1 mt-1">
            Total Fare for {passengerCount} {passengerCount > 1 ? 'Passengers' : 'Passenger'}
          </p>
          <h5 className="ps-3 satoshi-bold mb-1">
            {currency} {formatPriceNoRound(total)}
          </h5>
        </div>
        <div className="w-25">
          <GalagoButton btn="primary" size="sm" className="w-100" label={'Select Flight'} onClick={btnClick} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
