import { create } from 'zustand';

export const HotelCartStore = create((set: any) => ({
  selectedHotelRooms: [] as { [index: string]: any },
  setSelectedHotelRooms: (room: { [index: string]: any }, reset: boolean = false) => {
    set((state: { selectedHotelRooms: [] }) => ({
      selectedHotelRooms: !reset ? [room, ...state.selectedHotelRooms] : [],
    }));
  },
}));
