import React from 'react';

export const HotelStars = ({ stars, color, width, height }: { stars: number; color?: string; width?: number; height?: number }) => {
  const starColor = color || 'currentColor';
  const starWidth = width || 16;
  const starHeight = height || 16;
  return (
    <>
      {Array.from({ length: stars }, (_, index) => (
        <svg key={index} xmlns="http://www.w3.org/2000/svg" width={starWidth} height={starHeight} fill={starColor} className="bi bi-star-fill mr-1" viewBox="0 0 16 16">
          <path
            fillRule="evenodd"
            d="M8 .25a.75.75 0 0 1 .673.418l1.882 3.815 4.21.612a.75.75 0 0 1 .416 1.279l-3.046 2.97.719 4.192a.75.75 0 0 1-1.088.791L8 12.347l-3.766 1.98a.75.75 0 0 1-1.088-.79l.72-4.194-3.046-2.97a.75.75 0 0 1 .416-1.28l4.21-.611 1.882-3.815A.75.75 0 0 1 8 .25z"
          />
        </svg>
      ))}
    </>
  );
};
