import { create } from 'zustand';
import Cookie from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
// import { HTTP_OK } from '../utils/httpResponse.utils';

export const UserStore = create((set: any) => ({
  token: tokenDefaultValue(),
  refreshToken: refreshTokenDefaultValue(),
  isLoggedIn: isLoggedInDefaultValue(),
  isHidden: isHiddenDefaultValue(),
  info: infoDefaultValue(),
  isGuest: isGuestDefaultValue(),
  addAccessToken: (_token: string) => {
    set((state: { token: any }) => ({
      token: _token,
    }));
  },
  addRefreshToken: (_refreshToken: string) => {
    set((state: { refreshToken: string }) => ({
      refreshToken: _refreshToken,
    }));
  },
  setIsLoggedIn: (isLogged: boolean) => {
    set((state: { isLoggedIn: boolean }) => ({
      isLoggedIn: isLogged,
    }));
  },
  setIsHidden: (isLogged: boolean) => {
    set((state: { isLoggedIn: boolean }) => ({
      isLoggedIn: isLogged,
    }));
  },
}));

function isLoggedInDefaultValue(): boolean {
  const { token, isGuest } = jwtCookie();
  // if the token if for guest then return false
  if (isGuest) return false;
  // if the token is not for guest then return true
  if (token && !isGuest) return true;
  return false;
}

function tokenDefaultValue(): string | undefined {
  const { token } = jwtCookie();
  if (token !== null) return token;
  return undefined;
}

function refreshTokenDefaultValue(): string | undefined {
  const { refreshToken } = jwtCookie();
  if (refreshToken !== null) return refreshToken;
  return undefined;
}

function infoDefaultValue() {
  // const { token } = jwtCookie();
  // const { getProfile } = ProfileHooks();
  // const data = (await getProfile()) as { [index: string]: any };
  // if (data?.statusCode !== HTTP_OK()) return undefined;
  // if (data?.data === undefined || data.statusCode !== 200) return undefined;
  // const details = token !== null ? jwtDecode(token) : null;
  // return data.data;
  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
  // console.log(userDetails);
  return userDetails;
}

function isGuestDefaultValue(): boolean {
  const { isGuest } = jwtCookie();
  return isGuest;
}

function isHiddenDefaultValue(): boolean {
  // console.log(localStorage.jwt);
  const cookie = new Cookie();
  // const jwtAccessToken = localStorage.getItem('jwt') ? JSON.parse(localStorage.jwt) : null;
  const jwtAccessToken = cookie.get('g-token') ?? null;
  if (jwtAccessToken !== null) return true;
  return false;
}

function jwtCookie(): { token: string | null; refreshToken: string | null; isGuest: boolean } {
  const cookie = new Cookie();
  const token = cookie.get('g-token') ?? null;
  const refreshToken = cookie.get('g-refresh-token') ?? null;
  const { roles } = token !== null ? (jwtDecode(token) as { roles: string[] }) : { roles: [] };
  const isGuest = roles.includes('guest');

  return { token, refreshToken, isGuest };
}