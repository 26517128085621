import { SvgProps } from './SvgProps';

export const UserSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M3.25 2.84211C3.25 4.40905 4.48383 5.68421 6 5.68421C7.51617 5.68421 8.75 4.40905 8.75 2.84211C8.75 1.27516 7.51617 0 6 0C4.48383 0 3.25 1.27516 3.25 2.84211ZM10.8889 12H11.5V11.3684C11.5 8.93116 9.5805 6.94737 7.22222 6.94737H4.77778C2.41889 6.94737 0.5 8.93116 0.5 11.3684V12H10.8889Z"
        fill={_color}
      />
    </svg>
  );
};
