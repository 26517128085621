import React from 'react';
import { SvgProps } from './SvgProps';

export const GallerySvg = ({ _width = 15, _height = 15, _color = '#016E7F' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 17 16" fill="none">
      <path
        d="M13.8333 1.33301H5.83333C5.098 1.33301 4.5 1.93101 4.5 2.66634V10.6663C4.5 11.4017 5.098 11.9997 5.83333 11.9997H13.8333C14.5687 11.9997 15.1667 11.4017 15.1667 10.6663V2.66634C15.1667 1.93101 14.5687 1.33301 13.8333 1.33301ZM5.83333 10.6663V2.66634H13.8333L13.8347 10.6663H5.83333Z"
        fill={_color}
      />
      <path d="M3.16634 5.33301H1.83301V13.333C1.83301 14.0683 2.43101 14.6663 3.16634 14.6663H11.1663V13.333H3.16634V5.33301Z" fill={_color} />
      <path d="M8.5 7.99967L7.83333 7.33301L6.5 9.33301H13.1667L10.5 5.33301L8.5 7.99967Z" fill={_color} />
    </svg>
  );
};
