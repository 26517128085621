import React from 'react';
import { CheckCircleOutlinedSvg } from '../../../resources/svg/CheckSvg';
import { GalagoButton } from '../../../components/Button';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import moment from 'moment';
// import { AirplaneAscendSvg, AirplaneDescendSvg } from '../../../resources/svg/AirplanesSvg';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { RoundTripFlightHook } from '../../../hooks/RoundTripFlightHook';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
// import { AirlineLogo } from '../../../utils/airlines-logo';
import { LongArrowSvg } from '../../../resources/svg/longArrow.svg';
import { BaggageSvg } from '../../../resources/svg/BaggageSvg';
import { CheckInSvg } from '../../../resources/svg/CheckInSvg';
import { MystiflyFlightSegmentHook } from '../../../hooks/MystiflyFlightSegmentHook';
// import { FlightResults } from './FlightResults';

type selectedFlightInfoInterface = {
  FlightType: 'one-way' | 'round-trip' | 'multi-city';
  changeFlight?: () => void;
  id: string;
  flightInfo: any;
  legIndicator?: string | number;
  buttonLabel?: string;
  selectedFlights?: { [index: string]: any }; // departure and return flights selected
};

export const SelectedFlightInfo = ({ FlightType, id, flightInfo, legIndicator = '0', changeFlight, buttonLabel = 'Change Flight', selectedFlights }: selectedFlightInfoInterface) => {
  const { roundTripFlightSegment } = RoundTripFlightHook();
  const { flightFares } = FlightResultsHook();
  const { segments } = MystiflyFlightSegmentHook();
  const flightType = FlightType;
  const flights = flightInfo?.OriginDestinations.filter((element: { LegIndicator: string }) => {
    return element.LegIndicator === legIndicator;
  });

  const departure = flights?.[0]?.FlightSegment;

  const arrival = flights?.slice(-1)[0]?.FlightSegment;

  const { currency, passengers, adult, child, infant } = flightFares(flightInfo?.FlightFares);

  const { stops, flightDuration, MarketingCarriercode, MarketingFlightNumber } = roundTripFlightSegment(flightInfo?.OriginDestinations, legIndicator);

  const totalAdultPrice = (adult.adultBaseFare + adult.adultTax) * passengers.adultQuantity;
  const totalChildPrice = (child.childBaseFare + child.childTax) * passengers.childQuantity;
  const totalInfantPrice = (infant.infantBaseFare + infant.infantTax) * passengers.infantQuantity;

  let totalFarePerPassenger = totalAdultPrice + totalChildPrice + totalInfantPrice;
  // let totalFarePerPassenger = flightFareGrandTotal;

  if (selectedFlights !== undefined) {
    const departureTrip = selectedFlights.departureTrip;
    const returnTrip = selectedFlights.returnTrip;

    const departureTripPrice = flightFares(departureTrip?.FlightFares);
    const { passengers: departurePassengers, adult: departureAdult, child: departureChild, infant: departureInfant } = departureTripPrice;

    const departureTotalAdultPrice = (departureAdult.adultBaseFare + departureAdult.adultTax) * departurePassengers.adultQuantity;
    const departureTotalChildPrice = (departureChild.childBaseFare + departureChild.childTax) * departurePassengers.childQuantity;
    const departureTotalInfantPrice = (departureInfant.infantBaseFare + departureInfant.infantTax) * departurePassengers.infantQuantity;
    const departureTripPriceTotal = departureTotalAdultPrice + departureTotalChildPrice + departureTotalInfantPrice;

    const returnTripPrice = flightFares(returnTrip?.FlightFares);
    const { passengers: returnPassengers, adult: returnAdult, child: returnChild, infant: returnInfant } = returnTripPrice;
    const returnTotalAdultPrice = (returnAdult.adultBaseFare + returnAdult.adultTax) * returnPassengers.adultQuantity;
    const returnTotalChildPrice = (returnChild.childBaseFare + returnChild.childTax) * returnPassengers.childQuantity;
    const returnTotalInfantPrice = (returnInfant.infantBaseFare + returnInfant.infantTax) * returnPassengers.infantQuantity;
    const returnTripPriceTotal = returnTotalAdultPrice + returnTotalChildPrice + returnTotalInfantPrice;

    totalFarePerPassenger = returnTripPriceTotal - departureTripPriceTotal;
  }

  const Baggage = flightInfo?.OriginDestinations?.[0]?.ItineraryReference || null;

  // A variable that checks if the flight is Round-trip. Indicative to card layout
  // const isRoundTrip = Boolean(flightInfo?.OriginDestinations?.[1]?.LegIndicator);

  const seg = segments({ FlightType: flightType, FlightDetails: flightInfo });
  // console.log(seg);
  // console.log(isRoundTrip);

  const departureAirline = seg.departureAirline;
  const returnAirline = seg.returnAirline;

  return (
    <>
      {/* Web Only */}
      <div className=" result-select-web flex">
        <div className=" selected-departure-arrival-info w-100">
          <span id="selected-flight-floating-label" className="generalsans-regular">
            <CheckCircleOutlinedSvg _color="#fff" />
            Selected
          </span>

          {/* OLD CODE */}
          {/* <div className="text-right flex-column gap-2 justify-content-end d-none">
            <span className="generalsans-medium">{moment(departure?.DepartureDateTime).format('hh:mm A')}</span>
            <span className="text-secondary d-inline-block align-self-center">
              <AirplaneAscendSvg _color="#016E7F" _width={18} _height={18} />{' '}
              <span className="ps-1 generalsans-medium" style={{ color: '#808080' }}>
                {departure?.DepartureAirportLocationCode}
              </span>
            </span>
          </div>

          <div className="flex-column gap-2 d-none" style={{ width: '10%' }}>
            <span className=" generalsans-regular" style={{ fontSize: '16px' }}>
              {stops}
            </span>
            <hr style={{ border: '1px dashed #333', width: '100%', margin: '0px', strokeWidth: '2px' }} />
            <span className="generalsans-regular">{flightDuration}</span>
          </div>


          <div className="flex-column gap-2 d-none">
            <span className="generalsans-medium">{moment(arrival?.ArrivalDateTime).format('hh:mm A')}</span>
            <span className="text-secondary d-inline-block align-self-center">
              <AirplaneDescendSvg _color="#016E7F" _width={18} _height={18} />{' '}
              <span className="ps-1 generalsans-medium" style={{ color: '#808080' }}>
                {arrival?.ArrivalAirportLocationCode}
              </span>
            </span>
          </div>

          <div className="flex-column gap-2 d-none">
            <span className="text-secondary">Flight No.</span>
            <span>
              <b className="generalsans-medium">
                {MarketingCarriercode}
                {MarketingFlightNumber}
              </b>
            </span>
          </div>


          <div className="flex-column gap-2 d-none">
            <span className="text-secondary">Date</span>
            <span className="generalsans-medium">{moment(departure?.DepartureDateTime).format('MMM DD, YYYY')}</span>
          </div>

          <div className="flex-column gap-2 d-none">
            <span>Total Fare</span>
            <span className="generalsans-medium text-text-colors-text-brand">
              {selectedFlights !== undefined ? '+' : null} {currency} {formatPriceNoRound(totalFarePerPassenger)}
            </span>
          </div> */}

          {flightType === 'one-way' ? (
            // One Way Flight Info Container
            <div className="one-way-flight-info-container">
              {/* Airline Logo Holder */}
              <div className="airline-logo-holder">
                <img src={departureAirline?.logo} alt="airline logo" loading="lazy" />
                <span className="airline-name">{departureAirline?.name}</span>
              </div>

              <div className="flight-info">
                {/* Departure Date and Time */}
                <div>
                  <span className="data-head-text text-end">{moment(departure?.DepartureDateTime).format('HH:mm')}</span>
                  <div className="flight-details justify-content-end">
                    <span>{departure?.DepartureAirportLocationCode}</span>
                    <hr />
                    <span>{moment(departure?.DepartureDateTime).format('MMM DD')}</span>
                  </div>
                </div>

                {/*  Flight Duration */}
                <div className="gap-1 d-flex flex-column justify-content-center">
                  <span className="flight-duration">{flightDuration}</span>
                  <div className="w-100 d-flex justify-content-center">
                    <LongArrowSvg _width={100} _height={16} />
                  </div>
                  <span className="stops">{stops}</span>
                </div>

                {/* Arrival Date and Time */}
                <div>
                  <span className="data-head-text text-start">{moment(arrival?.ArrivalDateTime).format('HH:mm')}</span>
                  <div className="flight-details justify-content-start">
                    <span>{arrival?.ArrivalAirportLocationCode}</span>
                    <hr />
                    <span>{moment(arrival?.ArrivalDateTime).format('MMM DD')}</span>
                  </div>
                </div>
              </div>

              {/* Flight Number */}
              <div className="flight-number">
                <span>
                  {MarketingCarriercode}
                  {MarketingFlightNumber}
                </span>
                <span>Flight Number</span>
              </div>
            </div>
          ) : flightType === 'round-trip' ? (
            // Round-trip Flight Info Cobtainer
            <div className="round-trip-flight-info-container">
              {/* Departure Flight */}
              <div>
                {/* Airline Logo Holder */}
                <div className="airline-logo-holder">
                  <img src={departureAirline?.logo} alt="airline logo" loading="lazy" />
                  <span className="airline-name">{departureAirline?.name}</span>
                </div>

                {/* Flight Info */}
                <div className="flight-info">
                  {/* Departure Date and Time */}
                  <div>
                    <span className="data-head-text text-end">{seg.departureFlightSegmentDetails?.departureTime}</span>
                    <div className="flight-details justify-content-end">
                      <span>{seg.departureFlightSegmentDetails?.departureCode}</span>
                      <hr />
                      <span>{moment(seg.departureFlightSegmentDetails?.departureDateTime).format('MMM DD')}</span>
                    </div>
                  </div>

                  {/*  Flight Duration */}
                  <div className="gap-1 d-flex flex-column justify-content-center">
                    <span className="flight-duration">{seg.departureFlightSegmentDetails?.flightDuration}</span>
                    <div className="w-100 d-flex justify-content-center">
                      <LongArrowSvg _width={100} _height={16} />
                    </div>
                    <span className="stops">{seg.departureFlightSegmentDetails?.stops}</span>
                  </div>

                  {/* Arrival Date and Time */}
                  <div>
                    <span className="data-head-text text-start">{seg.departureFlightSegmentDetails?.arrivalTime}</span>
                    <div className="flight-details justify-content-start">
                      <span>{seg.departureFlightSegmentDetails?.arrivalCode}</span>
                      <hr />
                      <span>{moment(seg.departureFlightSegmentDetails?.arrivalDateTime).format('MMM DD')}</span>
                    </div>
                  </div>
                </div>

                {/* Flight Number */}
                <div className="flight-number">
                  <span>
                    {seg.departureFlightSegmentDetails?.MarketingCarriercode}
                    {seg.departureFlightSegmentDetails?.MarketingFlightNumber}
                  </span>
                  <span>Flight Number</span>
                </div>
              </div>
              {/*  Return Flight */}
              <div>
                {/* Airline Logo Holder */}
                <div className="airline-logo-holder">
                  <img src={returnAirline?.logo} alt="airline logo" loading="lazy" />
                  <span className="airline-name">{returnAirline?.name}</span>
                </div>

                {/* Flight Info */}
                <div className="flight-info">
                  {/* Departure Date and Time */}
                  <div>
                    <span className="data-head-text text-end">{seg.returnFlightSegmentDetails?.departureTime}</span>
                    <div className="flight-details justify-content-end">
                      <span>{seg.returnFlightSegmentDetails?.departureCode}</span>
                      <hr />
                      <span>{moment(seg.returnFlightSegmentDetails?.departureDateTime).format('MMM DD')}</span>
                    </div>
                  </div>

                  {/*  Flight Duration */}
                  <div className="gap-1 d-flex flex-column justify-content-center">
                    <span className="flight-duration">{seg.returnFlightSegmentDetails?.flightDuration}</span>
                    <div className="w-100 d-flex justify-content-center">
                      <LongArrowSvg _width={100} _height={16} />
                    </div>
                    <span className="stops">{seg.returnFlightSegmentDetails?.stops}</span>
                  </div>

                  {/* Arrival Date and Time */}
                  <div>
                    <span className="data-head-text text-start">{seg.returnFlightSegmentDetails?.arrivalTime}</span>
                    <div className="flight-details justify-content-start">
                      <span>{arrival?.ArrivalAirportLocationCode}</span>
                      <hr />
                      <span>{moment(seg.returnFlightSegmentDetails?.arrivalDateTime).format('MMM DD')}</span>
                    </div>
                  </div>
                </div>

                {/* Flight Number */}
                <div className="flight-number">
                  <span>
                    {seg.returnFlightSegmentDetails?.MarketingCarriercode}
                    {seg.returnFlightSegmentDetails?.MarketingFlightNumber}
                  </span>
                  <span>Flight Number</span>
                </div>
              </div>
            </div>
          ) : (
            <div>This is multi-city</div>
          )}

          {/* Price Container */}
          <div className="price-container" style={flightType === 'round-trip' ? { minHeight: '344px', gap: '32px', justifyContent: 'center' } : {}}>
            {/* Baggage */}
            <div className="baggage-container">
              <div className="d-flex flex-row gap-1">
                <span className="d-block">
                  <BaggageSvg _width={16} _height={16} _color="#016E7F" />
                </span>
                {Baggage?.CheckinBaggage?.[0]?.Value !== '0PC' && (
                  <span className="d-block">
                    <CheckInSvg _width={16} _height={16} _color="#016E7F" />
                  </span>
                )}
              </div>
              <p>included</p>
            </div>
            {/* Total Fare */}
            <span>
              {selectedFlights !== undefined ? '+' : null} {currency} {formatPriceNoRound(totalFarePerPassenger)}
            </span>
            <GalagoButton btn="primary" size="sm" label={buttonLabel} onClick={changeFlight} />
          </div>
        </div>
      </div>

      {/* Mobile Only */}

      <div className="selected-mobile-card">
        <div className="d-flex justify-content-between">
          <p style={{ color: '#ADADAD', fontSize: 11 }}>Selected Departure Flight</p>
          <span style={{ color: '#016e7f', fontWeight: '500', fontSize: 11, cursor: 'pointer' }} onClick={changeFlight}>
            Change Flight
          </span>
        </div>
        <div className="lugar">
          <p>{departure?.DepartureAirportLocationCode}</p>
          <ArrowRightSvg _color="#017e7f" />
          <p>{arrival?.ArrivalAirportLocationCode}</p>
        </div>
        <div className="flight-date-time">
          <p>{moment(departure?.DepartureDateTime).format('dddd DD MMM')}</p>
          <span>&#x2022;</span>
          <p>
            {moment(departure?.DepartureDateTime).format('hh:mm')} - {moment(arrival?.ArrivalDateTime).format('hh:mm')}
          </p>
          <span>&#x2022;</span>
          <p>{stops}</p>
        </div>
        <div className="name-duration">
          <p>Air Asia</p>
          <span>&#x2022;</span>
          <p>
            <span>{flightDuration}</span>
          </p>
        </div>
        <hr />
        <div className="total-wrapper">
          <p className="fare">Total Fare for 1 Passenger(s)</p>
          <p className="total-fare">PHP 4,500.00</p>
        </div>
      </div>
    </>
  );
};
