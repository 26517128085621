import { SvgProps } from './SvgProps';

export const ClockRoundSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="clock">
        <path
          id="Vector"
          d="M18.8332 10.5001C18.8332 15.1001 15.0998 18.8334 10.4998 18.8334C5.89984 18.8334 2.1665 15.1001 2.1665 10.5001C2.1665 5.90008 5.89984 2.16675 10.4998 2.16675C15.0998 2.16675 18.8332 5.90008 18.8332 10.5001Z"
          stroke={_color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path id="Vector_2" d="M13.5919 13.1497L11.0086 11.6081C10.5586 11.3414 10.1919 10.6997 10.1919 10.1747V6.75806" stroke={_color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
