import axios from 'axios';
import { useEffect, useState } from 'react';
import { bookingHotelConfirmationApiRoute, X_API_KEY } from '../utils/apiRoutes';
import { formatPriceNoRound } from '../utils/data.utils';

export const HotelBookingReferenceHook = (paymentReference?: string | null) => {
  const [bookingDetails, setBookingDetails] = useState<{ [index: string]: any }>();

  useEffect(() => {
    if (paymentReference === null || paymentReference === undefined) return;
    getBookingDetails(paymentReference);
    // eslint-disable-next-line
  }, []);

  const getBookingDetails = (paymentReference: string | undefined) => {
    try {
      if (axios.defaults.headers.common['Authorization'] === undefined) {
        axios.defaults.headers.common['x-api-key'] = X_API_KEY;
      }
      setTimeout(async () => {
        if (paymentReference === undefined) return;
        const paymentResponse = await axios.get(bookingHotelConfirmationApiRoute(paymentReference));

        const { data } = paymentResponse?.data;
        const bookingDetailsData = data ?? [];

        let hotelName: string = '';
        let paymentReferenceNumber: string = '';
        let totalRoomPrice: number = 0;
        let totalTax: number = 0;
        let images: string[] = [];
        let currency: string = 'PHP';
        let checkInDate: string = '';
        let checkOutDate: string = '';

        // eslint-disable-next-line
        bookingDetailsData?.map((detail: { [index: string]: any }) => {
          hotelName = detail.hotelName;
          paymentReferenceNumber = detail.paymentReferenceNumber;
          images = detail?.images;
          currency = detail?.currency;
          checkInDate = detail?.booking_checkInDate;
          checkOutDate = detail?.booking_checkOutDate;
          // eslint-disable-next-line
          detail?.rooms?.map((room: any) => {
            totalRoomPrice += room.TotalFare;
            totalTax += room.TotalTax;
          });
        });

        const roomsPrice = totalRoomPrice - totalTax;
        const totalRoomPriceFormatted = formatPriceNoRound(totalRoomPrice);
        const totalTaxFormatted = formatPriceNoRound(totalTax);
        const totalRoomsPriceFormatted = formatPriceNoRound(roomsPrice);

        setBookingDetails({
          hotelName,
          currency,
          images,
          checkInDate,
          checkOutDate,
          paymentReferenceNumber,
          totalRoomPriceFormatted,
          totalTaxFormatted,
          totalRoomsPriceFormatted,
        });
      }, 2000);
    } catch (error) {
      return error;
    }
  };

  return { bookingDetails };
};
