import { merge } from 'lodash';
import { create } from 'zustand';

export const FlightPassengersStore = create((set: any) => ({
  savePassengers: false,
  setSavePassengers: (isSave: boolean) => {
    set((state: { savePassengers: boolean }) => ({
      savePassengers: isSave,
    }));
  },

  isDomesticFlight: false,
  setIsDomesticFlight: (isDomestic: boolean) => {
    set((state: { isDomesticFlight: boolean }) => ({
      isDomesticFlight: isDomestic,
    }));
  },

  selectedPassengers: [],
  appendSelectedPassenger: (passenger: any) => {
    set((state: { selectedPassengers: [] }) => ({
      selectedPassengers: [...passenger],
    }));
  },

  contacts: [],

  appendContact: (contact: any) => {
    set((state: { contacts: [] }) => ({
      contacts: merge(state.contacts, contact),
    }));
  },

  selectedPassengerKeys: [] as any[],
  appendSelectedPassengerKeys: (contact: number[]) => {
    set((state: { selectedPassengerKeys: number[] }) => ({
      selectedPassengerKeys: [...contact, ...state.selectedPassengerKeys],
    }));
  },
}));
