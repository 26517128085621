import React from 'react';

type CancellationPolicyModalProps = { onClose: () => void };

const CancellationPolicyModal = ({ onClose }: CancellationPolicyModalProps) => {
  return (
    <section className="rounded-xl bg-color-shades-white-800 shadow-[1.9500000476837158px_1.9500000476837158px_2.6px_rgba(0,_0,_0,_0.15)] w-[31.25rem] overflow-hidden flex flex-col items-start justify-start text-left text-[1.38rem] text-text-colors-text-primary font-heading-2 pt-1">
      <button
        onClick={onClose}
        className="self-stretch rounded-t-xl rounded-b-none flex items-center justify-end py-4 px-8 border-b-[1px] border-solid border-gainsboro-300 overflow-hidden p-0.5 gap-[0.63rem]"
      >
        <img className="cursor-pointer relative w-[0.78rem] h-[0.78rem]" alt="" src="/svg/union.svg" />
      </button>

      <div className="self-stretch flex flex-col items-start justify-start py-[34px] px-8 gap-[1.5rem]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[2.13rem]">
          <div className="self-stretch flex flex-col items-start justify-center gap-[0.5rem]">
            <b className="relative leading-[1.75rem]">Cancellation Policy</b>
            <div className="self-stretch relative text-[1rem] leading-[1.31rem] font-medium font-callout">
              Before making a reservation, ensure that you are at ease with the Host's cancellation policy.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-6 gap-[0.75rem] text-[0.88rem] text-color-teal-teal-900 font-callout border-b-[1px] border-solid border-black-black-300">
            <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
              <div className="flex flex-col items-center justify-center p-0.5">
                <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/checkBgFill.svg" />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.13rem]">
                <div className="relative leading-[1.31rem] font-medium font-footnote">Cancel reservation within 48 hours after booking</div>
                <div className="self-stretch relative text-[0.75rem] leading-[1.13rem] font-medium text-text-colors-text-primary font-footnote">Full refund: Get back 100% of what you paid.</div>
              </div>
            </div>

            <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
              <div className="flex flex-row items-start justify-center p-0.5 relative gap-[0.63rem]">
                <div className="relative rounded-[50%] [background:linear-gradient(#bd0a00,_#bd0a00),_#2eae4e] w-[1.25rem] h-[1.25rem] z-[0]" />
                <img className="absolute my-0 mx-[!important] top-[0.44rem] left-[0.69rem] w-[0.13rem] h-[0.63rem] z-[1]" alt="" src="/svg/group-48095981.svg" />
              </div>

              <div className="flex-1 flex flex-col items-start justify-start gap-[0.13rem]">
                <div className="relative leading-[1.31rem] font-medium font-footnote">Cancel the booking on the day of the scheduled check-in.</div>
                <div className="self-stretch relative text-[0.75rem] leading-[1.13rem] font-medium text-text-colors-text-primary font-footnote">Full refund: Get back 100% of what you paid.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-col items-start justify-start gap-[2.13rem]">
          <div className="self-stretch flex flex-col items-start justify-center gap-[0.5rem]">
            <b className="relative leading-[1.75rem]">Payment Policy</b>
            <div className="self-stretch relative text-[1rem] leading-[1.31rem] font-medium font-callout">Before making a reservation, ensure that you are at ease with the Host's payment policy.</div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.75rem] text-[0.88rem] text-color-teal-teal-900 font-callout">
            <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
              <div className="flex flex-col items-center justify-center p-0.5">
                <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/checkBgFill.svg" />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.13rem]">
                <div className="relative leading-[1.31rem] font-medium font-footnote">Cancel reservation within 48 hours after booking</div>
                <div className="self-stretch relative text-[0.75rem] leading-[1.13rem] font-medium text-text-colors-text-primary font-footnote">Full refund: Get back 100% of what you paid.</div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[0.63rem]">
              <div className="flex flex-row items-start justify-center p-0.5 relative gap-[0.63rem]">
                <div className="relative rounded-[50%] [background:linear-gradient(#bd0a00,_#bd0a00),_#2eae4e] w-[1.25rem] h-[1.25rem] z-[0]" />
                <img className="absolute my-0 mx-[!important] top-[0.44rem] left-[0.69rem] w-[0.13rem] h-[0.63rem] z-[1]" alt="" src="/svg/group-48095981.svg" />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.13rem]">
                <div className="relative leading-[1.31rem] font-medium font-footnote">Cancel the booking on the day of the scheduled check-in.</div>
                <div className="self-stretch relative text-[0.75rem] leading-[1.13rem] font-medium text-text-colors-text-primary font-footnote">Full refund: Get back 100% of what you paid.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CancellationPolicyModal;
