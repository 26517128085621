import React from 'react';
import { PassengerChildSvg, PassengerInfantSvg, PassengerMrSvg } from '../../resources/svg/PassengerSvg';
import { ChevronRight, ChevronUp } from 'lucide-react';
import { titleCase } from '../../utils/data.utils';
import moment from 'moment';

export const FlightPassengerList = ({ passenger, type }: { [index: string]: any }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <li>
      <div className="accordion-header" onClick={() => setIsOpen((prev) => !prev)}>
        <div>
          {type === 'adult' ? <PassengerMrSvg /> : ''}
          {type === 'child' ? <PassengerChildSvg /> : ''}
          {type === 'infant' ? <PassengerInfantSvg /> : ''}
          <span className="ml-[.5em]">
            {titleCase(passenger.title)} {titleCase(passenger.name)}
          </span>
        </div>
        <div>{isOpen ? <ChevronUp /> : <ChevronRight />}</div>
      </div>
      {isOpen ? (
        <div className="accordion-body">
          <div>
            <div>
              <span>Date of Birth</span>
              <span>{passenger.birthDate ? moment(passenger.birthDate).format('MMMM DD, YYYY') : '-'}</span>
            </div>
            <div>
              <span>Nationality</span>
              <span>{passenger.nationality ? passenger.nationality : '-'}</span>
            </div>
            {/* <div>
              <span>Frequent Flyer Program</span>
              <span>-</span>
            </div> */}
          </div>

          {passenger.passportNumber ? (
            <div className="border-l-1 border-l-color-[#e4e4e4]">
              <div>
                <span>Passport or Document Number</span>
                <span>{passenger.passportNumber}</span>
              </div>
              {passenger.passportExpiryDate ? (
                <div>
                  <span>Expiration Date</span>
                  <span>{moment(passenger.passportExpiryDate).format('MMMM DD, YYYY')}</span>
                </div>
              ) : (
                ''
              )}
              {passenger.countryIssued ? (
                <div>
                  <span>Country Issued</span>
                  <span>{passenger.countryIssued}</span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </li>
  );
};
