import React from 'react';

type AllHotelPhotosModalGalleryProps = { images: string[] };

const AllHotelPhotosModalGallery = ({ images }: AllHotelPhotosModalGalleryProps) => {
  return (
    <section className="absolute top-[5.69rem] left-[2.5rem] h-[42.44rem] overflow-x-hidden overflow-y-auto flex flex-col items-start justify-start gap-[1rem]">
      {images.map((roomImage, index) =>
        index % 3 === 0 ? (
          <div key={index} className="flex flex-row items-start justify-start gap-[1rem]">
            {images.slice(index, index + 3).map((image, i) => (
              <img
                key={i}
                className="relative w-[17.63rem] h-[11.38rem] object-cover"
                loading="lazy"
                alt={`Room ${index + i + 1}`}
                src={image}
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
            ))}
          </div>
        ) : null
      )}
    </section>
  );
};

export default AllHotelPhotosModalGallery;
