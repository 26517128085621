import { merge } from 'lodash';
import { create } from 'zustand';

export const HotelGuestStore = create((set: any) => ({
  guest: { title: '', firstName: '', middleName: '', lastName: '', email: '', code: '', mobile: '' },
  setGuest: (_guest: { [index: string]: any }) => {
    set((state: { guest: {} }) => ({
      guest: merge(state.guest, _guest),
    }));
  },
  titleError: false,
  setTitleError: (_titleError: boolean) => {
    set((state: { titleError: boolean }) => ({
      titleError: _titleError,
    }));
  },
  firstNameError: false,
  setFirstNameError: (_firstNameError: boolean) => {
    set((state: { firstNameError: boolean }) => ({
      firstNameError: _firstNameError,
    }));
  },
  lastNameError: false,
  setLastNameError: (_lastNameError: boolean) => {
    set((state: { lastNameError: boolean }) => ({
      lastNameError: _lastNameError,
    }));
  },
  emailError: false,
  setEmailError: (_emailError: boolean) => {
    set((state: { emailError: boolean }) => ({
      emailError: _emailError,
    }));
  },
  mobileError: false,
  setMobileError: (_emailError: boolean) => {
    set((state: { mobileError: boolean }) => ({
      mobileError: _emailError,
    }));
  },
}));
