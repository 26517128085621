import React from 'react';
import { HappyGallyCheckedSvg } from '../../resources/svg/GallySvg';
import { GalagoButton } from '../../components/Button';
import { WaveDown, WaveUp } from '../../resources/svg/WaveSvg';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { SignUpDialogStore } from '../../store/SignupDialogStore';
import { EmailDialogStore } from '../../store/EmailDialogStore';

export const ExistingAccount = () => {
  const navigate = useNavigate();
  // const { setShowSignUpDialog } = SignUpDialogStore();
  const { setShowEmailDialog } = EmailDialogStore();
  const [searchParams] = useSearchParams();
  let email: string = '';
  const confirmationCode = searchParams.get('confirmationCode') as string;
  const decodedConfirmationCode: { email: string } = confirmationCode ? jwtDecode(confirmationCode as string) : { email: '' };
  email = decodedConfirmationCode.email;

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 right-0 w-100">
        <WaveUp _width={'100%'} />
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-100">
        <WaveDown _width={'100%'} />
      </div>
      <div className="relative !z-10 d-flex flex-column justify-center align-items-center" style={{ height: '100vh' }}>
        <HappyGallyCheckedSvg _width={256} _height={256} />
        <div className="d-flex flex-column align-items-center">
          <p className="mb-2 mt-2 font-medium fs-2">You already have an account!</p>
          <p className="mb-3" style={{ width: '85%', textAlign: 'center', fontSize: '20px', color: '#ADADAD' }}>
            We recognized the email you're trying to verify {email} already belongs to an existing account. No need to create a new one! Simply click the button below to sign in with your existing
            email.
          </p>
          <GalagoButton
            label="Sign In Now"
            onClick={() => {
              navigate('/');
              setShowEmailDialog(true);
            }}
            size="lg"
            className="!w-[300px] p-3"
          />
        </div>
      </div>
    </div>
  );
};
