import axios, { AxiosResponse } from 'axios';
import { getUserDetails, uploadProfilePhotoUrlOld } from '../utils/apiRoutes';
import Cookies from 'universal-cookie';
// import { jwtDecode } from 'jwt-decode';
import React from 'react';

export const ProfileHooks = () => {
  const cookie = new Cookies();

  const [profileInfo, setProfileInfo] = React.useState();

  const getProfile = async () => {
    const token = cookie.get('g-token');
    // console.log(token);
    // know if the token is expired
    // const { userId } = jwtDecode(token) as { userId: string }; // Add type
    // const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
    // // console.log(userDetails.userId, userId);
    // const user = localStorage.getItem('userDetails');

    // if (user !== undefined) return;

    try {
      const response: AxiosResponse = await axios.get(getUserDetails(), { headers: { Authorization: `Bearer ${token}` } });
      // console.log(response.data);
      if (response.status === 200) {
        setProfileInfo(response.data.data);
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      }
      // localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      return response;
    } catch (error) {
      return error;
    }
  };

  const uploadProfilePhoto = async (payload: { [index: string]: any }) => {
    try {
      const response = await axios.request({
        method: 'post',
        url: uploadProfilePhotoUrlOld(),
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        maxBodyLength: 5000000000000000,
        maxContentLength: 50000000000000000,
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  function base64ToArrayBuffer(base64: string) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  return { getProfile, uploadProfilePhoto, base64ToArrayBuffer, profileInfo };
};

// export default ProfileHooks;
