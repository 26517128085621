import { create } from 'zustand';

export const SignUpDialogStore = create((set: any) => ({
  showSignUpDialog: false,
  setShowSignUpDialog: (isShow: boolean) => {
    set((state: { showSignUpDialog: boolean }) => ({
      showSignUpDialog: isShow,
    }));
  },
}));
