import React from 'react';
import { SvgProps } from './SvgProps';
export const ChevronDownSvg = ({ _width = 15, _height = 15, _color = '#333333', ...props }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Vector"
        d="M11.7436 0.780218C11.3484 0.357938 10.6784 0.357938 10.2833 0.780218L7.23015 4.0427C6.83497 4.46498 6.16503 4.46498 5.76985 4.0427L2.71674 0.780218C2.32156 0.357938 1.65163 0.357938 1.25645 0.780218L1.13944 0.905251C0.779636 1.28973 0.779636 1.88735 1.13944 2.27183L5.76985 7.21978C6.16503 7.64206 6.83497 7.64206 7.23015 7.21978L11.8606 2.27183C12.2204 1.88735 12.2204 1.28973 11.8606 0.905251L11.7436 0.780218Z"
        fill={_color}
      />
    </svg>
  );
};

export const ChevronTriangleDownSvg = ({ _width = 15, _height = 15, _color = '#333333', ...props }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path id="Vector" d="M5.5 5.85686L10.5 0.142578H0.5L5.5 5.85686Z" fill={_color} />
    </svg>
  );
};



