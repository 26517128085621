import { SvgProps } from './SvgProps';

export const PassengerSvg = ({ _width = 12, _height = 13, _color = '#A8A8A8' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12.6333C3 12.6333 4.71387 9.72593 8.08713 9.80556C11.3127 9.88171 13 12.7004 13 12.7004" stroke={_color} strokeWidth="1.71429" strokeLinecap="round" />
      <path
        d="M11.254 6.45677C11.254 8.25386 9.79714 9.7107 8.00005 9.7107C6.20295 9.7107 4.74612 8.25386 4.74612 6.45677C4.74612 4.65968 6.20295 3.20285 8.00005 3.20285C9.79714 3.20285 11.254 4.65968 11.254 6.45677Z"
        stroke={_color}
        strokeWidth="1.71429"
      />
    </svg>
  );
};

export const PassengerMrSvg = ({ _width = 24, _height = 24, _color = '#262626' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FBFEFE" />
      <path
        d="M7.54305 7.54308C7.54305 8.72519 8.01264 9.85888 8.84852 10.6948C9.6844 11.5306 10.8181 12.0002 12.0002 12.0002C13.1823 12.0002 14.316 11.5306 15.1519 10.6948C15.9877 9.85888 16.4573 8.72519 16.4573 7.54308C16.4573 6.36097 15.9877 5.22728 15.1519 4.3914C14.316 3.55553 13.1823 3.08594 12.0002 3.08594C10.8181 3.08594 9.6844 3.55553 8.84852 4.3914C8.01264 5.22728 7.54305 6.36097 7.54305 7.54308ZM10.8337 14.5143L11.4814 15.5938L10.3218 19.9082L9.06823 14.7929C8.99859 14.5108 8.72698 14.3263 8.44493 14.3994C6.00743 15.0088 4.2002 17.2165 4.2002 19.8455C4.2002 20.4375 4.68073 20.9145 5.26921 20.9145H18.7312C19.3231 20.9145 19.8002 20.434 19.8002 19.8455C19.8002 17.2165 17.993 15.0088 15.5555 14.3994C15.2734 14.3298 15.0018 14.5143 14.9322 14.7929L13.6786 19.9082L12.519 15.5938L13.1667 14.5143C13.3896 14.1417 13.1214 13.6717 12.6897 13.6717H11.3142C10.8824 13.6717 10.6143 14.1452 10.8372 14.5143H10.8337Z"
        fill={_color}
      />
    </svg>
  );
};

export const PassengerMrsSvg = ({ _width = 24, _height = 24, _color = '#262626' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1998 9.6C12.2306 9.60006 13.2215 9.99803 13.966 10.7109C14.7105 11.4238 15.1511 12.3966 15.1958 13.4264L15.1998 13.6V14.4C15.1999 14.8037 15.0475 15.1925 14.773 15.4884C14.4985 15.7844 14.1223 15.9657 13.7198 15.996L13.5998 16H2.3998C1.99614 16.0001 1.60735 15.8477 1.31137 15.5732C1.01538 15.2987 0.834079 14.9225 0.803805 14.52L0.799805 14.4V13.6C0.799864 12.5692 1.19784 11.5783 1.91073 10.8338C2.62363 10.0893 3.59641 9.64874 4.6262 9.604L4.7998 9.6H11.1998ZM7.9998 0C9.06067 0 10.0781 0.421427 10.8282 1.17157C11.5784 1.92172 11.9998 2.93913 11.9998 4C11.9998 5.06087 11.5784 6.07828 10.8282 6.82843C10.0781 7.57857 9.06067 8 7.9998 8C6.93894 8 5.92152 7.57857 5.17138 6.82843C4.42123 6.07828 3.9998 5.06087 3.9998 4C3.9998 2.93913 4.42123 1.92172 5.17138 1.17157C5.92152 0.421427 6.93894 0 7.9998 0Z"
        fill={_color}
      />
    </svg>
  );
};

export const PassengerChildSvg = ({ _width = 24, _height = 24, _color = '#262626' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11059_50338)">
        <path
          d="M9.2998 5.10039C9.2998 3.6092 10.5086 2.40039 11.9998 2.40039C13.491 2.40039 14.6998 3.6092 14.6998 5.10039C14.6998 6.59158 13.491 7.80039 11.9998 7.80039C10.5086 7.80039 9.2998 6.59154 9.2998 5.10039ZM18.8483 5.15188C18.3797 4.68324 17.6199 4.68324 17.1513 5.15188L13.9027 8.40039H10.0968L6.84831 5.15188C6.37971 4.68324 5.61988 4.68324 5.15128 5.15188C4.68265 5.62052 4.68265 6.3803 5.15128 6.84894L8.6998 10.3974V20.4004C8.6998 21.0631 9.23706 21.6004 9.8998 21.6004H10.4998C11.1625 21.6004 11.6998 21.0631 11.6998 20.4004V16.2004H12.2998V20.4004C12.2998 21.0631 12.8371 21.6004 13.4998 21.6004H14.0998C14.7625 21.6004 15.2998 21.0631 15.2998 20.4004V10.3974L18.8483 6.8489C19.3169 6.38027 19.3169 5.62052 18.8483 5.15188Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11059_50338">
          <rect width="14.4" height="19.2" fill="white" transform="translate(4.7998 2.40039)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PassengerInfantSvg = ({ _width = 24, _height = 24, _color = '#262626' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#F9F9F9" />
      <path
        d="M21.2794 12.7856C21.3436 12.5642 21.3983 12.34 21.4436 12.1136C21.4464 12.0964 21.4474 12.0802 21.4512 12.063C21.4704 11.964 21.4791 11.862 21.4944 11.7619C21.5184 11.6033 21.5472 11.4456 21.5616 11.286C21.6759 10.0949 21.5368 8.89201 21.1544 7.76461C20.7719 6.63721 20.1559 5.61359 19.3508 4.76806C18.6383 4.01644 17.7918 3.42036 16.8598 3.01405C16.5199 2.86587 16.1711 2.74398 15.8162 2.64899C14.7927 2.37508 13.9202 3.25862 13.9202 4.31812V8.56461C13.9202 9.62499 13.0606 10.4846 12.0002 10.4846H7.82785C7.168 10.4846 6.57383 10.0851 6.3248 9.47408C6.07577 8.86303 5.48159 8.46355 4.82174 8.46355H3.41092C2.85282 8.46355 2.40039 8.91598 2.40039 9.47408C2.40039 10.0322 2.85282 10.4846 3.41092 10.4846H3.74399C4.29042 10.4846 4.78242 10.8155 4.98844 11.3216L5.6949 13.0571C6.0883 14.0235 5.56929 15.1182 4.97247 15.9741C4.94436 16.0144 4.91703 16.0554 4.89049 16.097C4.51985 16.6787 4.32144 17.3631 4.32036 18.0635C4.32036 20.0139 5.82753 21.6004 7.68029 21.6004C9.03221 21.6004 10.1909 20.7525 10.7199 19.5375C10.9507 19.0074 11.422 18.5688 12.0002 18.5688C12.5784 18.5688 13.0497 19.0074 13.2805 19.5375C13.8095 20.7525 14.9682 21.6004 16.3201 21.6004C18.1729 21.6004 19.6801 20.0139 19.6801 18.0635C19.6801 17.4269 19.5063 16.8378 19.226 16.3204C19.2663 16.279 19.3114 16.2426 19.3518 16.2001C19.6974 15.8353 20.0122 15.4332 20.2897 15.0037C20.5661 14.5691 20.8042 14.1083 20.9962 13.6304C21.0116 13.593 21.0212 13.5536 21.0356 13.5162L21.0509 13.4737C21.1354 13.2514 21.2122 13.0281 21.2765 12.7987L21.2794 12.7856ZM7.68029 19.5793C6.88639 19.5793 6.24032 18.8993 6.24032 18.0635C6.24032 17.2278 6.88639 16.5478 7.68029 16.5478C8.4742 16.5478 9.12027 17.2278 9.12027 18.0635C9.12027 18.8993 8.4742 19.5793 7.68029 19.5793ZM16.3201 19.5793C15.5262 19.5793 14.8802 18.8993 14.8802 18.0635C14.8802 17.2278 15.5262 16.5478 16.3201 16.5478C17.114 16.5478 17.7601 17.2278 17.7601 18.0635C17.7601 18.8993 17.114 19.5793 16.3201 19.5793Z"
        fill={_color}
      />
    </svg>
  );
};