import React from 'react';
import { NavBar } from '../../components/NavBar';
import Footer from '../../components/Footer';
import '../../resources/css/booking-history.scss';
import BookHistoryHooks from '../../hooks/BookHistoryHooks';
import { FlightHistory } from './FlightHistory';
import { HotelHistory } from './HotelHistory';

const BookingHistory = () => {
  const { flightHistory, hotelHistory } = BookHistoryHooks();

  const [history, setHistory] = React.useState<'flight' | 'hotel'>('flight');

  const [activeTab, setActiveTab] = React.useState<'active' | 'past' | 'cancelled'>('active');

  return (
    <>
      <NavBar />
      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="my-10 fs-3">Bookings</h3>

              <div id="history-tab" className="text-sm font-medium text-center text-gray-500 border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px bg-[#F9FFFF]">
                  <li className="me-2">
                    <a
                      href="#/history"
                      onClick={(e) => {
                        setHistory('flight');
                        setActiveTab('active');
                      }}
                      data-active={history === 'flight' ? true : false}
                      className="inline-block pt-3 pb-2 px-4 text-lg cursor-pointer"
                    >
                      Flight
                    </a>
                  </li>
                  <li className="me-2">
                    <a
                      href="#/history"
                      onClick={(e) => {
                        setHistory('hotel');
                        setActiveTab('active');
                      }}
                      data-active={history === 'hotel' ? true : false}
                      className="inline-block pt-3 pb-2 px-4 text-lg cursor-pointer"
                      aria-current="page"
                    >
                      Hotel
                    </a>
                  </li>
                </ul>
              </div>

              <div className="tab-content" id="history-pills">
                <a href="#/history" onClick={() => setActiveTab('active')} className={activeTab === 'active' ? 'active' : ''}>
                  Active
                </a>
                <a href="#/history" onClick={() => setActiveTab('past')} className={activeTab === 'past' ? 'active' : ''}>
                  Past
                </a>
                <a href="#/history" onClick={() => setActiveTab('cancelled')} className={activeTab === 'cancelled' ? 'active' : ''}>
                  Cancelled
                </a>
              </div>

              <div id="history-list">
                {history === 'flight' && <FlightHistory activeTab={activeTab} flightHistory={flightHistory} />}
                {history === 'hotel' && <HotelHistory activeTab={activeTab} hotelHistory={hotelHistory} />}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BookingHistory;
