import { SvgProps } from './SvgProps';

export const GridSvg = ({ _width = 24, _height = 24, _color = '#333333' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 24 24" fill="none">
      <path
        d="M4.77778 10.1111C4.28889 10.1111 3.87022 9.93689 3.52178 9.58844C3.17333 9.24 2.99941 8.82163 3 8.33333V4.77778C3 4.28889 3.17422 3.87022 3.52267 3.52178C3.87111 3.17333 4.28948 2.99941 4.77778 3H8.33333C8.82222 3 9.24089 3.17422 9.58933 3.52267C9.93778 3.87111 10.1117 4.28948 10.1111 4.77778V8.33333C10.1111 8.82222 9.93689 9.24089 9.58844 9.58933C9.24 9.93778 8.82163 10.1117 8.33333 10.1111H4.77778ZM4.77778 19C4.28889 19 3.87022 18.8258 3.52178 18.4773C3.17333 18.1289 2.99941 17.7105 3 17.2222V13.6667C3 13.1778 3.17422 12.7591 3.52267 12.4107C3.87111 12.0622 4.28948 11.8883 4.77778 11.8889H8.33333C8.82222 11.8889 9.24089 12.0631 9.58933 12.4116C9.93778 12.76 10.1117 13.1784 10.1111 13.6667V17.2222C10.1111 17.7111 9.93689 18.1298 9.58844 18.4782C9.24 18.8267 8.82163 19.0006 8.33333 19H4.77778ZM13.6667 10.1111C13.1778 10.1111 12.7591 9.93689 12.4107 9.58844C12.0622 9.24 11.8883 8.82163 11.8889 8.33333V4.77778C11.8889 4.28889 12.0631 3.87022 12.4116 3.52178C12.76 3.17333 13.1784 2.99941 13.6667 3H17.2222C17.7111 3 18.1298 3.17422 18.4782 3.52267C18.8267 3.87111 19.0006 4.28948 19 4.77778V8.33333C19 8.82222 18.8258 9.24089 18.4773 9.58933C18.1289 9.93778 17.7105 10.1117 17.2222 10.1111H13.6667ZM13.6667 19C13.1778 19 12.7591 18.8258 12.4107 18.4773C12.0622 18.1289 11.8883 17.7105 11.8889 17.2222V13.6667C11.8889 13.1778 12.0631 12.7591 12.4116 12.4107C12.76 12.0622 13.1784 11.8883 13.6667 11.8889H17.2222C17.7111 11.8889 18.1298 12.0631 18.4782 12.4116C18.8267 12.76 19.0006 13.1784 19 13.6667V17.2222C19 17.7111 18.8258 18.1298 18.4773 18.4782C18.1289 18.8267 17.7105 19.0006 17.2222 19H13.6667Z"
        fill={_color}
      />
    </svg>
  );
};
