import React, { useEffect } from 'react';
import { Button } from '@nextui-org/react';
import { FlightBookingReferenceHook } from '../../../hooks/FlightBookingReferenceHook';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { AirlineLogo } from '../../../utils/airlines-logo';
import { cabinClassCode, formatPriceNoRound } from '../../../utils/data.utils';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import { CheckSvg } from '../../../resources/svg/CheckSvg';
import { AlertBox } from '../../../components/AlertBox';
import { useInternationalBooking } from '../../../store/InternationalStore';
import { ChevronDownSvg } from '../../../resources/svg/ChevronDownSvg';

const BookingConfirmationContainer = () => {
  const [searchParams] = useSearchParams();
  //For Toggling Total Base Fare
  const { isTotalBaseFareClicked, toggleTotalBaseFare } = useInternationalBooking();

  const [showBreakdown, setShowBreakdown] = React.useState(false);

  const paymentReference = searchParams.get('paymentReference');
  const platform = searchParams.get('platform');

  const { bookingDetails, bookPaymentDetails, bookingFlightFares } = FlightBookingReferenceHook(paymentReference);
  useEffect(() => {
    if (platform === 'mobile') {
      setTimeout(() => {
        window.location.assign(`myapp://book/flight/form/PaymentCallbackScreen?paymentReference=${paymentReference}`);
      }, 2000);
    }
    if (platform === 'pwa') {
      window.open(`${process.env.REACT_APP_PWA_URL}/book/flight/form/PaymentCallbackScreen?paymentReference=${paymentReference ?? ''}`);
    }
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const airline = (carrierCode: string) => {
    return AirlineLogo?.find((_airline: { [index: string]: any }) => _airline.id === carrierCode);
  };

  // passengers
  const AdultCount = bookingFlightFares?.passengers?.adultQuantity;
  const ChildrenCount = bookingFlightFares?.passengers?.childQuantity;
  const InfantCount = bookingFlightFares?.passengers?.infantQuantity;
  // prices
  const adultPrice = bookingFlightFares?.adult.adultBaseFare * AdultCount;
  const childPrice = bookingFlightFares?.child.childBaseFare * ChildrenCount;
  const infantPrice = bookingFlightFares?.infant.infantBaseFare * InfantCount;
  // taxes
  const adultTaxes = bookingFlightFares?.adult.adultTax * AdultCount;
  const childTaxes = bookingFlightFares?.child.childTax * ChildrenCount;
  const infantTaxes = bookingFlightFares?.infant.infantTax * InfantCount;

  const totalBaseFare = adultPrice + childPrice + infantPrice;
  const totalTaxes = adultTaxes + childTaxes + infantTaxes;

  return (
    <>
      <div className="container">
        <section className="wrapper pt-16 pb-10">
          <div className="bookingConfirmed ">
            <div className="flex flex-column gap-2.5">
              <span className="mx-auto">
                <CheckSvg _color="#fff" _width={24} _height={24} />
              </span>
              <p className="m-0 confirmed text-center textColor">Your booking is confirmed!</p>
            </div>

            <p className="note generalsans-medium textColor">{`Payment for your flight booking is successful. Thanks for booking with GalaGO!`}</p>

            <Button className="back mt-8" onClick={() => navigate('/')}>
              Back to Home
            </Button>
          </div>

          <div className="bookingConfirmedSummary">
            <p className="text-sm textColor generalsans-regular">Booking Summary</p>

            <div className="my-3.5">
              <div className="">
                {bookingDetails ? (
                  <div className="d-flex align-items-center gap-3.5 textColor">
                    <p style={{ fontSize: 28, fontWeight: 700 }}>{bookingDetails?.departure.departure.cityName}</p>
                    <ArrowRightSvg _width={25} _height={25} />
                    <p style={{ fontSize: 28, fontWeight: 700 }}>{bookingDetails?.departure.arrival.cityName}</p>
                  </div>
                ) : (
                  <Skeleton count={1} height={30} width={350} />
                )}
              </div>

              <div className="mt-2">
                {bookingDetails ? (
                  <>
                    <div className="flex flex-row generalsans-medium textColor gap-2 my-2">
                      <span>{bookingDetails?.departure.departure.airportName}</span>
                      <span>-</span>
                      <span>{bookingDetails?.departure.arrival.airportName}</span>
                    </div>
                  </>
                ) : (
                  <Skeleton count={1} height={20} width={150} />
                )}
              </div>

              <div className="mt-2">
                <div className="">
                  {bookingDetails ? (
                    <>
                      <div className="flex flex-row gap-2 my-2 text-base textColor generalsans-medium">
                        <span>{moment(bookingDetails?.departure.departureDateTime).format('MMM DD, YYYY')}</span>
                        <span>-</span>
                        <span>{moment(bookingDetails?.departure.arrivalDateTime).format('MMM DD, YYYY')}</span>
                      </div>
                    </>
                  ) : (
                    <Skeleton count={1} height={20} width={150} />
                  )}
                </div>
              </div>
            </div>

            <div className="">
              <div className="">
                <div className="mt-4">
                  <div className="my-2">
                    {bookingDetails ? (
                      <div className="d-flex gap-2 ">
                        <img className="" alt="" src="/svg/fly.svg" />
                        <p className="tealtext" style={{ fontSize: 16, fontWeight: 500 }}>
                          Departure
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4 border-b-1 border-[#D9D9D9]">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="">
                      {bookingDetails ? (
                        <>
                          <div className="d-flex align-items-center gap-2 rounded-full">
                            {airline(bookingDetails?.departure.MarketingCarriercode) && (
                              <img className="w-8" loading="lazy" alt="" src={airline(bookingDetails?.departure.MarketingCarriercode)?.logo} />
                            )}
                            <p className="bookingText textColor generalsans-medium">{airline(bookingDetails?.departure.MarketingCarriercode)?.name}</p>
                          </div>
                        </>
                      ) : (
                        <Skeleton count={1} height={25} width={150} />
                      )}
                    </div>
                    <div className="">
                      {bookingDetails ? (
                        <>
                          <div className="flex flex-row gap-1.5 text-secondary generalsans-medium">
                            <span>
                              {bookingDetails?.departure.MarketingCarriercode} {bookingDetails?.departure.MarketingFlightNumber}
                            </span>
                            <span>·</span>
                            <span>{bookingDetails?.departure.equipment ? `${bookingDetails?.departure.equipment} ·` : ''}</span>
                            <span>{cabinClassCode(bookingDetails?.departure.CabinClassCode)}</span>
                          </div>
                        </>
                      ) : (
                        <Skeleton count={1} height={25} width={150} />
                      )}
                    </div>
                  </div>
                  <div className="mt-8">
                    {bookingDetails ? (
                      <div className="d-flex justify-between mt-2 gap-2 mb-8">
                        <div className="flex flex-col col-auto textColor generalsans-medium">
                          <p className="text-xl font-semibold">{bookingDetails?.departure.departureTime}</p>
                          <p className="text-base text-secondary">{bookingDetails?.departure.departureCode}</p>
                        </div>

                        <div className="flex flex-col col-8 gap-1.5 text-center generalsans-medium">
                          <p className="text-sm text-secondary">{bookingDetails?.departure.flightDuration}</p>
                          <div className="flightDurationLine" />
                          <p className="text-sm text-secondary">{bookingDetails?.departure.stops}</p>
                        </div>

                        <div className="flex flex-col col-auto textColor generalsans-medium">
                          <p className="text-xl font-semibold">{bookingDetails?.departure.arrivalTime}</p>
                          <p className="text-base text-secondary text-end">{bookingDetails?.departure.arrivalCode}</p>
                        </div>
                      </div>
                    ) : (
                      <Skeleton count={1} height={55} width="100%" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {bookingDetails?.return !== undefined ? (
              <>
                <div className="mt-8">
                  <div className="d-flex align-items-center gap-3 textColor">
                    <p style={{ fontSize: 28, fontWeight: 700 }}>{bookingDetails?.return.departure.cityName}</p>
                    <ArrowRightSvg _width={25} _height={25} />
                    <p style={{ fontSize: 28, fontWeight: 700 }}>{bookingDetails?.return.arrival.cityName}</p>
                  </div>

                  <div className="flex flex-row generalsans-medium textColor gap-2 my-2">
                    <span>{bookingDetails?.return.departure.airportName}</span>
                    <span>-</span>
                    <span>{bookingDetails?.return.arrival.airportName}</span>
                  </div>

                  <div className="flex flex-row gap-2 my-2 text-base textColor generalsans-medium">
                    <span>{moment(bookingDetails?.return.departureDateTime).format('MMM DD, YYYY')}</span>
                    <span>-</span>
                    <span>{moment(bookingDetails?.return.arrivalDateTime).format('MMM DD, YYYY')}</span>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <div className="">
                      <div className="my-2">
                        <div className="d-flex gap-2 mt-4">
                          <img className="" alt="" src="/svg/fly.svg" style={{ WebkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)' }} />
                          <p className="tealtext text-base font-medium">Return</p>
                        </div>
                      </div>
                    </div>

                    <div className="border-b-1 border-[#D9D9D9]">
                      <div className="d-flex gap-2 align-items-center mt-4">
                        <div className="">
                          <div className="d-flex align-items-center gap-2 rounded-full">
                            {airline(bookingDetails?.return.MarketingCarriercode) && <img className="w-8" loading="lazy" alt="" src={airline(bookingDetails?.return.MarketingCarriercode)?.logo} />}
                            <p className="bookingText textColor generalsans-medium">{airline(bookingDetails?.return.MarketingCarriercode)?.name}</p>
                          </div>
                        </div>
                        <div className="flex flex-row gap-1.5 text-secondary generalsans-medium">
                          <span>
                            {bookingDetails?.return.MarketingCarriercode} {bookingDetails?.return.MarketingFlightNumber}
                          </span>
                          <span>· </span>
                          <span>{bookingDetails?.return.equipment ? `${bookingDetails?.return.equipment} ·` : ''}</span>
                          <span>{cabinClassCode(bookingDetails?.departure.CabinClassCode)}</span>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="d-flex justify-between mt-2 gap-2 mb-8">
                          <div className="flex flex-col col-auto textColor generalsans-medium">
                            <p className="text-xl font-semibold">{bookingDetails?.return.departureTime}</p>
                            <p className="text-base text-secondary">{bookingDetails?.return.departureCode}</p>
                          </div>

                          <div className="flex flex-col col-8 gap-1.5 text-center generalsans-medium">
                            <p className="text-sm text-secondary">{bookingDetails?.return.flightDuration}</p>
                            <div className="flightDurationLine" />
                            <p className="text-sm text-secondary">{bookingDetails?.return.stops}</p>
                          </div>

                          <div className="flex flex-col col-auto textColor generalsans-medium">
                            <p className="text-xl font-semibold">{bookingDetails?.return.arrivalTime}</p>
                            <p className="text-base text-secondary text-end">{bookingDetails?.return.arrivalCode}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {bookPaymentDetails ? (
              <>
                <div className="d-flex justify-between align-items-center py-4 border-b-1 border-[#D9D9D9]">
                  <div className="flex flex-col">
                    <p className="textColor generalsans-medium text-lg"> Paid Today</p>
                    <a
                      className="text-sm generalsans-medium"
                      href="#/"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                        e.preventDefault();
                        setShowBreakdown(true);
                      }}
                    >
                      View price overview
                    </a>
                  </div>

                  <p className="text-xl textColor font-semibold">PHP {formatPriceNoRound(bookPaymentDetails?.amount)}</p>
                </div>
              </>
            ) : (
              <Skeleton count={1} height={50} width="100%" />
            )}

            {bookPaymentDetails ? (
              <div className="d-flex justify-between align-items-center mt-4 mb-2">
                <p className="text-base textColor generalsans-medium">Booking Reference Number</p>
                <p className="tealtext text-xl generalsans-medium">{bookPaymentDetails?.referenceNumber}</p>
              </div>
            ) : (
              <Skeleton count={1} height={50} width="100%" />
            )}
          </div>
        </section>
      </div>

      <AlertBox
        show={showBreakdown}
        onHide={() => setShowBreakdown(false)}
        title="&nbsp;"
        children={
          <div className="py-4 px-8">
            <h4 className="textColor font-semibold mb-1.5 text-xl">Price Overview</h4>

            <div className="mb-4 text-secondary generalsans-regular flex flex-row gap-1.5">
              <span>{bookingDetails?.return !== undefined ? 'Round-trip' : 'One-way'}</span>
              <span>·</span>
              <span>{moment(bookingDetails?.departure.departureDateTime).format('MMM DD, YYYY')}</span>
              <span>- </span>
              <span>
                {' '}
                {bookingDetails?.return !== undefined
                  ? moment(bookingDetails?.return.arrivalDateTime).format('MMM DD, YYYY')
                  : moment(bookingDetails?.departure.arrivalDateTime).format('MMM DD, YYYY')}
              </span>
            </div>

            <div className="flex justify-content-between cursor-pointer" onClick={toggleTotalBaseFare}>
              <div className="textColor generalsans-medium flex gap-2">
                <span>Total Base Fare</span>
                <span className={`${isTotalBaseFareClicked === true ? 'rotate-180' : ''}`}>
                  <ChevronDownSvg _color="#ADADAD" _height={14} _width={14} />
                </span>
              </div>
              <div className="textColor generalsans-medium">PHP {formatPriceNoRound(totalBaseFare)}</div>
            </div>

            {isTotalBaseFareClicked && (
              <>
                <div className="text-sm textColor generalsans-regular">
                  ( {bookingFlightFares?.passengerCount} Passenger {bookingFlightFares?.passengerCount > 1 ? 's' : ''})
                </div>
                <div className="flex mt-2 flex-column gap-1 text-start text-secondary generalsans-regular text-sm">
                  {AdultCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{AdultCount > 1 ? `Adults x ${AdultCount}` : `Adult x ${AdultCount}`}</span>
                      <span>
                        {bookingFlightFares?.currency} {formatPriceNoRound(adultPrice)}
                      </span>
                    </div>
                  )}

                  {ChildrenCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{ChildrenCount > 1 ? `Children x ${ChildrenCount}` : `Child x ${ChildrenCount}`}</span>
                      <span>
                        {bookingFlightFares?.currency} {formatPriceNoRound(childPrice)}
                      </span>
                    </div>
                  )}

                  {InfantCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{InfantCount > 1 ? `Infants x ${InfantCount}` : `Infant x ${InfantCount}`}</span>
                      <span>
                        {bookingFlightFares?.currency} {formatPriceNoRound(infantPrice)}
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="flex justify-content-between mt-2.5 mb-4 text-secondary generalsans-regular">
              <span>Taxes and Fees</span>
              <span>PHP {formatPriceNoRound(totalTaxes)}</span>
            </div>

            <div className="border-top pt-4">
              <div className="flex flex-column align-items-end gap-1 generalsans-regular ">
                <div className="text-sm text-secondary">Total Price (includes taxes, charges, and fees)</div>
                <div className="text-primary fw-semibold text-xl">PHP {formatPriceNoRound(totalBaseFare + totalTaxes)}</div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default BookingConfirmationContainer;
