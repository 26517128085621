import React from 'react';
import { Modal, ModalContent } from '@nextui-org/react';

type ModalTemplateType = {
  children?: React.ReactNode;
  optionalClassName?: string;
  isOpen: boolean;
  onOpenChange: () => void;
};

export default function ModalTemplate({ children, optionalClassName, isOpen, onOpenChange }: ModalTemplateType) {
  return (
    <Modal
      radius="lg"
      className="w-full overflow-hidden !rounded-lg"
      size="full"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      closeButton={<></>}
      // Dont allow the modal to be closed by clicking outside or pressing the esc key 
      isDismissable={false}
      isKeyboardDismissDisabled={false}
    >
      <ModalContent className={`${optionalClassName}`}>{children}</ModalContent>
    </Modal>

    // <div
    //   className="modal show"
    //   style={{ display: "block", position: "initial", border: "" }}
    // >
    //   <Modal.Dialog>{children}</Modal.Dialog>
    // </div>
  );
}
