import { create } from 'zustand';

export const HotelFiltersStore = create((set: any) => ({
  filterKey: '',
  setFilterKey: (key: string) => {
    set((state: { filterKey: string }) => ({
      filterKey: key,
    }));
  },

  priceRange: { start: 0, end: 0 },
  setPriceRange: (price: { start: number; end: number }) => {
    set((state: { priceRange: { start: number; end: number } }) => ({
      priceRange: price,
    }));
  },

  selectedPriceRange: { start: 0, end: 0 },
  setSelectedPriceRange: (price: { start: number; end: number }) => {
    set((state: { selectedPriceRange: { start: number; end: number } }) => ({
      selectedPriceRange: price,
    }));
  },

  selectedRating: '',
  setSelectedRating: (rating: string) => {
    set((state: { selectedRating: string }) => ({
      selectedRating: rating,
    }));
  },

  facilitiesList: [] as { [index: string]: any },
  setFacilitiesList: (facilities: { [index: string]: any }) => {
    set((state: { facilitiesList: { [index: string]: any } }) => ({
      facilitiesList: facilities,
    }));
  },

  selectedFacilities: [] as string[],
  setSelectedFacilities: (facilities: string[], remove = false) => {
    if (remove) {
      set((state: { selectedFacilities: string[] }) => ({
        selectedFacilities: state.selectedFacilities.filter((value) => !facilities.includes(value)),
      }));
    } else {
      set((state: { selectedFacilities: string[] }) => ({
        selectedFacilities: Array.from(new Set([...state.selectedFacilities, ...facilities])),
      }));
    }
  },

  bedPreferencesList: [] as { [index: string]: any },
  setBedPreferencesList: (bedPreferences: { [index: string]: any }) => {
    set((state: { bedPreferencesList: { [index: string]: any } }) => ({
      bedPreferencesList: bedPreferences,
    }));
  },

  selectedBedPreferences: [] as string[],
  setSelectedBedPreferences: (bedPreferences: string[], remove = false) => {
    if (remove) {
      set((state: { selectedBedPreferences: string[] }) => ({
        selectedBedPreferences: state.selectedBedPreferences.filter((value) => !bedPreferences.includes(value)),
      }));
    } else {
      set((state: { selectedBedPreferences: string[] }) => ({
        selectedBedPreferences: Array.from(new Set([...state.selectedBedPreferences, ...bedPreferences])),
      }));
    }
  },

  selectedFilterTags: [] as string[],
  setSelectedFilterTags: (filterTags: string[], remove = false) => {
    if (remove) {
      set((state: { selectedFilterTags: string[] }) => ({
        selectedFilterTags: state.selectedFilterTags.filter((value) => !filterTags.includes(value)),
      }));
    } else {
      set((state: { selectedFilterTags: string[] }) => ({
        selectedFilterTags: Array.from(new Set([...state.selectedFilterTags, ...filterTags])),
      }));
    }
  },

  filterReset: () => {},
  setFilterReset: (onClick: () => void) => {
    set((state: { filterReset: () => void }) => ({
      filterReset: onClick,
    }));
  },

  // UI SIDE ------------------------------------------

  selectedPriceRangeLabel: { start: 0, end: 0 },
  setSelectedPriceRangeLabel: (price: { start: number; end: number }) => {
    set((state: { selectedPriceRangeLabel: { start: number; end: number } }) => ({
      selectedPriceRangeLabel: price,
    }));
  },

  // starRating: [] as number[],
  // setStarRating: (rate: number[]) => {
  //   set((state: { starRating: number[]}) => ({
  //     starRating: rate,
  //   }));
  // },


}));

