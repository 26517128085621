import { create } from 'zustand';

export const FlightFilterStoreRoundTrip = create((set: any) => ({
  filterKey: '',
  setFilterKey: (key: string) => {
    set((state: { filterKey: string }) => ({
      filterKey: key,
    }));
  },

  returnPriceRange: { start: 0.0, end: 0.0 },
  setReturnPriceRange: (price: { start: number; end: number }) => {
    set((state: { returnPriceRange: { start: number; end: number } }) => ({
      returnPriceRange: price,
    }));
  },

  selectedReturnPriceRange: { start: 0, end: 0 },
  setSelectedReturnPriceRange: (price: { start: number; end: number }) => {
    set((state: { selectedReturnPriceRange: { start: number; end: number } }) => ({
      selectedReturnPriceRange: price,
    }));
  },

  selectedReturnDepartureTimeRange: { start: '', end: '' } as { start: string; end: string },
  setSelectedReturnDepartureTimeRange: (time: { start: string; end: string }) => {
    set((state: { selectedReturnDepartureTimeRange: { start: string; end: string } }) => ({
      selectedReturnDepartureTimeRange: time,
    }));
  },

  selectedReturnArrivalTimeRange: { start: '', end: '' } as { start: string; end: string },
  setSelectedReturnArrivalTimeRange: (time: { start: string; end: string }) => {
    set((state: { selectedReturnArrivalTimeRange: { start: string; end: string } }) => ({
      selectedReturnArrivalTimeRange: time,
    }));
  },

  selectedReturnStops: [] as string[],
  setSelectedReturnStops: (stops: string[]) => {
    set((state: { selectedReturnStops: string[] }) => ({
      // merge the previous selected stops with the new selected stops and remove duplicates
      selectedReturnStops: Array.from(new Set([...state.selectedReturnStops, ...stops])),
    }));
  },

  selectedReturnCabinAndCheckInBaggage: false as boolean,
  setSelectedReturnCabinAndCheckInBaggage: (baggage: boolean) => {
    set((state: { selectedReturnCabinAndCheckInBaggage: boolean }) => ({
      selectedReturnCabinAndCheckInBaggage: baggage,
    }));
  },

  // merge the previous selected airlines with the new selected airlines
  selectedReturnAirlines: [] as string[],
  setSelectedReturnAirlines: (airline: string[]) => {
    set((state: { selectedReturnAirlines: string[] }) => ({
      // merge the previous selected airlines with the new selected airlines and remove duplicates
      selectedReturnAirlines: Array.from(new Set([...state.selectedReturnAirlines, ...airline])),
    }));
  },

  returnAirlineList: [] as { [index: string]: any },
  setReturnAirlineList: (airlines: { [index: string]: any }) => {
    set((state: { returnAirlineList: { [index: string]: any } }) => ({
      returnAirlineList: airlines,
    }));
  },
}));
