import NavBarTransparent from '../../../components/NavBarTransparent';
import { ChevronRightSvg } from '../../../resources/svg/ChevronRightSvg';
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import { TwitterSvg } from '../../../resources/svg/TwitterSvg';
import { LinkSvg } from '../../../resources/svg/LinkSvg';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import React from 'react';
import '../../../resources/css/articles.scss';

const HauntedPlaces = () => {
  const [fbHover, setFbHover] = React.useState('#ADADAD');
  const [liHover, setLiHover] = React.useState('#ADADAD');
  const [twtHover, setTwtHover] = React.useState('#ADADAD');
  const [linkHover, setLinkHover] = React.useState('#ADADAD');

  return (
    <>
      <NavBarTransparent />
      <div className="hero_sec">
        <img
          src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Ghostly+Adventure.jpg"
          alt=""
          loading="lazy"
          onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
        />
        <div className="gradient_overlay">
          <h1>Travel Tips</h1>

          <div className="bread_crumbs">
            <a href="/#/blogs">Blogs</a>
            <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
              Add These 11 Haunted Places in the Philippines to Your Ghost Tour Bucket List
            </a>
          </div>
        </div>
      </div>

      <div className="articleBody">
        <div className="articleHeader">
          <h1>Add These 11 Haunted Places in the Philippines to Your Ghost Tour Bucket List</h1>

          <div className="info">
            <p className="authorSec">
              {' '}
              By <u>Anthea Reyes</u>
            </p>
            <p className="separator">•</p>
            <p className="articleDate">Oct 24, 2023</p>
            <p className="separator">•</p>
            <p className="article-read">5 min read</p>
          </div>

          <div className="shareBtnContainer">
            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
              <FacebookSvg _color={fbHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
              <LinkedInSvg _color={liHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
              <TwitterSvg _color={twtHover} _width={24} _height={24} />
            </a>

            <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
              <LinkSvg _color={linkHover} _width={24} _height={24} />
            </a>
          </div>
        </div>

        <div className="content">
          <div className="articleDescription">
            <p>
              Halloween is right around the corner, and we are ready for some spooks! As the veil thins between the world of the living and of all things supernatural, the boldness of paranormal
              enthusiasts everywhere doubles to the nth degree.
            </p>
            <p>Luckily, the Philippines is home to many spine-tingling ghost tours and eerie haunted places that'll have you sleeping with the lights on for weeks!</p>
            <p>Grab your EMF meters and sage smudge sticks! It's time to get spooky.</p>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>
                <b>5 must-try ghostly tours in the Philippines</b>
              </h2>
              <div className="head_desc">
                <p>Ain’t afraid of no ghosts? Or maybe you are but your curiosity is getting the best of you this Halloween season.</p>
                <p>Whichever way your ghost tolerance goes, here’s a list of ghost tours to experience to sate your craving for some scaring.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>1. Baguio Ghost Tours</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/01+el+retiro+baguio.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo via El Retiro Baguio</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Nestled in the cool highlands, Baguio is known for more than its pine trees and fresh air. With a history as rich as their culture, this summer hotspot is also home to a number of
                  notoriously haunted locations. And now, you can tour the most hair-raising of all of them by joining the Baguio Ghost Tours. This tour is organized by{' '}
                  <a href="https://www.facebook.com/frighttours" target="_blank" rel="noreferrer">
                    Pine City Fright Tours
                  </a>
                  , the first-ever licensed ghost tour operator in Baguio City.
                </p>
                <p>
                  With the guidance of former filmmaker and horror aficionado Anton Villa-Abrille, the Baguio Ghost Tour takes you to famous haunted sites in the city, including Laperal Mansion,
                  Teacher’s Camp, the old Hyatt Hotel, and Loakan Road. The entire experience lasts for two hours, usually starting at 5 p.m., and highlights both historical facts and urban legends
                  from the locals.
                </p>
                <p>Get ready for hair-raising stories and ghostly encounters that will send shivers down your spine.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>2. Siquijor Aswang Tours</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/02+franzi+engel+unsplash.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Franzi Engel via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Unlike the Baguio Ghost Tours, the Siquijor Aswang Tours are less organized and intentional. Mysticism is alive and well and a cause of infamy in the province, so the locals are
                  still very careful about focusing on aswang mythology and folkloric magic during tours. However, most local tour guides are open to discussing this part of their culture nowadays.
                  They will cover topics such as the aforementioned aswang mythology, town hearsay about elementals, and age-old “magical” practices including voodoo and the creation of gayuma.
                </p>

                <p>
                  As for the destinations to visit, most tours in the province make their final stop by a hundreds-old balete tree. Here, tourists can stroll through stalls of gayuma and other
                  potions. There’s also the option of stopping by a traditional faith healer’s house, where tourists can witness or have traditional tawas or cleansing performed. During this
                  experience, brace yourself for tales of folklore, mysterious rituals, and spine-chilling encounters.
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>3. Corregidor Overnight Tours</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/03+JC+gellidon+unsplash.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by JC Gellidon via Unsplash</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Corregidor Island is a wartime relic with a haunted twist. Once a Philippine stronghold during World War II, this destination is rife with stories of torture, imprisonment, and
                  restless souls. Sun Cruises' Overnight Tour package lets you go on an eerie adventure and explore the province’s most haunted sites.
                </p>
                <p>This includes walking through abandoned hospital rooms and the Malinta Tunnel interiors where the charred bones of those who perished still remain.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>4. Iloilo Mysterious House Tours</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/04+via+inquirer.net.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo via Inquirer.net</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Halloween isn’t complete without a good haunted house, and in Iloilo, you’re in for a good dose of homes with both a trick and a treat. Join{' '}
                  <a href="https://www.viator.com/en-IE/tours/Panay-Island/MYSTERY-HOUSE-TOUR-IN-ILOILO/d50531-20200P406" target="_blank" rel="noreferrer">
                    Iloilo's Mysterious House Tours
                  </a>{' '}
                  for a chance to explore not one, not two, but a whole handful of haunted houses. And these houses are no ordinary residences. These homes are well-preserved manors with entire
                  estates attached to them. The tours take you through a deep dive into Iloilo’s heritage and social history, uncovering the unsettling stories hidden within the walls of the alta de
                  ciudad of bygone years.
                </p>

                <p>
                  Stops along the way include Casa Real, Eusebio-Villanueva House, Antillan House, and Casa Mariquit. But that’s not all! The tours also take you through unique haunted places such as
                  the Iloilo Prison, American Cemetery, and Jaro Cathedral. Some tours focus on thrilling mysteries while some explore the otherworldly facets of these sites — take your pick.
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>5. Intramuros Night Walking Tours</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/05+don't+skip+manila.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo by Don't Skip Manila</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Step into the heart of Manila's history with the Intramuros Night Walking Tours. This walled city holds secrets that come alive after dark, and the{' '}
                  <a href="https://intramuros.gov.ph/packages/#:~:text=Itinerary%3A%20Fort%20Santiago%2C%20Old%20City,(after%205%3A00PM)" target="_blank" rel="noreferrer">
                    Intramuros Administration teamed up with Don’t Skip Manila
                  </a>{' '}
                  to give you a chance to see these secrets up close.
                </p>

                <p>
                  Join their{' '}
                  <a href="https://dontskipmanilaph.wixsite.com/manila" target="_blank" rel="noreferrer">
                    {' '}
                    Night Walking Tours
                  </a>{' '}
                  to wander through cobblestone streets, visit haunted churches, and listen to ghostly tales of Spanish-era tragedies that continue to haunt the present. The tour lasts 3.5 hours or
                  more and is priced at PHP 1000 per guest.
                </p>
              </div>
            </div>
          </div>

          <div className="storieswithTitleImageParagraphs">
            <div className="head">
              <h2>
                <b>7 dare-to-visit haunted places in the Philippines</b>
              </h2>
              <div className="head_desc">
                <p>For those who prefer their ghostly adventures sans the organized group activity, we also have a list of Philippine haunted sites that you can visit separately.</p>
                <p>Get those notepads or note apps ready, and let’s jump right into it.</p>
              </div>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/06+lad+hara+caingcoy.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo by Lad Hara Caingcoy via Unsplash</p>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>1. The Diplomat Hotel – Baguio City</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  Our haunted journey kicks off in the chilly highlands of Baguio City at none other than the infamous Diplomat Hotel. This imposing structure, formerly a seminary and later a hotel,
                  saw its fair share of dark history during World War II. Now, it's a playground for restless spirits.
                </p>
                <p>Visitors have reported ghostly apparitions, echoing footsteps, and even hair-raising whispers in the night. Would you be brave enough for a three-days and two-nights stay here?</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>2. Balete Drive – Quezon City</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  Up next, we're venturing into the heart of Quezon City to brave the eerie streets of Balete Drive. Legend has it that a white lady, often seen with long black hair and a flowing
                  white dress, haunts this area.
                </p>

                <p>She's a hitchhiking ghost, folks! Dare to pick her up, and you might just end up with an extra passenger in your car. Ready your heart before you check that rearview mirror.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>3. Fort Santiago – Manila</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  Now, let's time-travel to the historical Fort Santiago in Manila. Built during the Spanish colonial era, this place has seen its fair share of bloodshed. It's said to be home to a
                  spectral sentry, forever guarding the fort's gates.
                </p>

                <p>He's been spotted in his ghostly garb, rifle in hand, making sure no unauthorized souls enter his domain. Don't forget to salute!</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>4. Clark Air Base Hospital – Angeles City</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  For those who enjoy a good scare served with a side of history, the Clark Air Base Hospital in Angeles City is your destination. Abandoned since the eruption of Mount Pinatubo, this
                  place has a reputation for ghostly encounters.
                </p>

                <p>Nurses, patients, and even American soldiers have been spotted wandering its halls. You might want to bring a spare pair of flashlights for this one.</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>5. Laperal White House – Baguio City</b>
                </h2>
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Ghost+tours+in+PH/07+amy's+crypt.png"
                    alt=""
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />
                  <p>Photo via Amy's Crypt</p>
                </div>
              </div>

              <div className="info-grp">
                <p>
                  Back to Baguio we go, but this time, we're stepping into the chilling Laperal White House. It's a charming white mansion with a dark past. Rumors of murder, suicide, and tragic
                  accidents surround this place.
                </p>

                <p>
                  Visitors have experienced flickering lights, icy chills, and ghostly figures lurking in the shadows. There are even social media personalities who have shared video footage of
                  paranormal activity. Imagine spending a night with this house’s malevolent spirits. Who needs sleep, anyway?
                </p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>6. Ozone Disco – Quezon City</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  Fancy dancing with the dead? Head over to the Ozone Disco in Quezon City, where tragedy struck in 1996 when a fire broke out during a party, claiming the lives of 162 people. Reports
                  of ghostly apparitions and eerie whispers are common here.
                </p>

                <p>Dancing shoes are optional, but a flashlight is a must!</p>
              </div>
            </div>

            <div className="story">
              <div className="head">
                <h2>
                  <b>7. Clark Museum and 4D Theater – Angeles City</b>
                </h2>
              </div>

              <div className="info-grp">
                <p>
                  Our next stop is the Clark Museum and 4D Theater in Angeles City. This seemingly innocent spot is home to more than just historical artifacts. Visitors have reported strange
                  occurrences like objects moving on their own and ghostly children giggling in the halls.
                </p>

                <p>Who knew history could be this spooky?</p>
              </div>
            </div>

            <div className="footnote">
              <div className="title_container">
                <h2>Let’s GalaGO! on a ghostly adventure!</h2>
                <div className="info">
                  <p>
                    So, there you have it, the Philippines' creepiest and most haunted spots, all ready to send shivers down your spine. Whether you're a die-hard paranormal investigator or just
                    looking for a hair-raising thrill, these ghost tours and haunted sites are sure to leave you with spine-tingling memories you won't soon forget.
                  </p>
                  <p>Discover, research, and reserve all these tours and more spooktacular experiences at GalaGO!</p>
                  <p>Happy ghost hunting!</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="featured_blogs">
          <h2>Featured Blogs</h2>
          <div className="card_container">
            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                  alt="Discover the Best Things to Do in Bohol All in One Tour!"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>

            <div className="blog_card col-md-6 col-12">
              <div className="img_container">
                <img
                  src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                  alt="Guide to Travel Requirements in the Philippines"
                  loading="lazy"
                  onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                />

                <div className="badge">
                  <h2>Travel Tip</h2>
                </div>
              </div>

              <div className="info_grp">
                <div className="detail">
                  <p className="date"> September 25, 2023</p>
                  <p className="separator">·</p>
                  <p className="duration">6 min read</p>
                </div>

                <h3>Guide to Travel Requirements in the Philippines</h3>

                <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                  <div className="read_btn">
                    <p>Read Article</p>
                    <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HauntedPlaces;
