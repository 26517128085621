import { create } from 'zustand';

export const EmailSentStore = create((set: any) => ({
  showEmailSentDialog: false,
  setShowEmailSentDialog: (isShow: boolean) => {
    set((state: { showEmailSentDialog: boolean }) => ({
      showEmailSentDialog: isShow,
    }));
  },
}));
