import { AxiosError } from 'axios';
import React, { createContext } from 'react';

// import useFacebookLogin from '..//useFacebookLogin';

interface FacebookProviderProps {
  children: React.ReactNode;
  error: AxiosError;
  isError: boolean;
  fbLoginSuccess: boolean;
}

export const FacebookContext = createContext({
  error: new AxiosError(),
  isError: false,
  fbLoginSuccess: false,
});

export const FacebookProvider = ({ children, error, isError, fbLoginSuccess }: FacebookProviderProps) => {
  const value = { error, isError, fbLoginSuccess };
  return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
};
