// 200+ response
/**
 * @returns 200
 */
export const HTTP_OK = (): number => 200;
/**
 * @returns 201
 */
export const HTTP_CREATED = (): number => 201;
/**
 * @returns 202
 */
export const HTTP_ACCEPTED = (): number => 202;

// 400+ response
/**
 * @returns 400
 */
export const HTTP_BAD_REQUEST = (): number => 400;
/**
 * @returns 401
 */
export const HTTP_UNAUTHORIZED = (): number => 401;
/**
 * @returns 402
 */
export const HTTP_PAYMENT_REQUIRED = (): number => 402;
/**
 * @returns 403
 */
export const HTTP_FORBIDDEN = (): number => 403;
/**
 * @returns 404
 */
export const HTTP_NOT_FOUND = (): number => 404;
