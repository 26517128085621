import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { facebookLoginRedirect, getUserDetails } from '../utils/apiRoutes';

const useFacebookLogin = () => {
  const [loadingTimer, setLoadingTimer] = useState(true);
  const [fbLoginSuccess, setFbLoginSuccess] = useState(false);

  const url = window.location.href;
  if (url.includes('?code')) {
    setTimeout(() => {
      setLoadingTimer(false);
      setFbLoginSuccess(true);
      window.history.replaceState(null, '', url.split('?')[0]);
    }, 4500);
  }

  const fbCode = url.split('?')[1];
  const fbTokens = useQuery({
    queryKey: ['fbTokens'],
    queryFn: async () => (await axios.get(`${facebookLoginRedirect()}?${fbCode}`)).data,
    enabled: url.includes('?code'),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const fbDetails = useQuery({
    queryKey: ['fbDetails'],
    queryFn: async () => (await axios.get(getUserDetails(), { headers: { Authorization: `Bearer ${fbTokens.data?.accessToken}` } })).data,
    enabled: fbTokens.isFetched && !fbTokens.isError,
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (fbTokens.data) localStorage.setItem('user', JSON.stringify(fbTokens.data));
  if (fbDetails.data) localStorage.setItem('userDetails', JSON.stringify(fbDetails.data.data));

  return {
    isLoading: fbTokens.isLoading,
    isFetching: fbTokens.isFetching,
    minLoadingTime: url.includes('?code') && loadingTimer,
    isError: fbTokens.isError,
    error: fbTokens.error as AxiosError,
    fbLoginSuccess: fbLoginSuccess,
  };
};

export default useFacebookLogin;
