import { create } from 'zustand';

export const FlightInfoStore = create((set: any) => ({
  showFlightInfoModal: false,
  setShowFlightInfoModal: (isShow: boolean) => {
    set((state: { showFlightInfoModal: boolean }) => ({
      showFlightInfoModal: isShow,
    }));
  },
  flightInfo: {} as { [index: string]: any },
  setFlightInfo: (info: { [index: string]: any }) => {
    set((state: { flightInfo: { [index: string]: any } }) => ({
      flightInfo: info,
    }));
  },

  showReturnFlights: false,
  setShowReturnFlights: (isShow: boolean) => {
    set((state: { showReturnFlights: boolean }) => ({
      showReturnFlights: isShow,
    }));
  },
  showReturnFlightsInfo: [] as { [index: string]: any },
  setShowReturnFlightsInfo: (info: { [index: string]: any } | undefined) => {
    set((state: { showReturnFlightsInfo: { [index: string]: any } } | undefined) => ({
      showReturnFlightsInfo: info,
    }));
  },
}));
