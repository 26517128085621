import React from 'react';
import BookFlightStepsProgressBar from './BookFlightStepsProgressBar';
import FlightForm from './FlightForm';
import Footer from '../../../components/Footer';
import { NavBar } from '../../../components/NavBar';
import '../../../resources/css/flight-book-index.scss';
import { useLocation, useSearchParams } from 'react-router-dom';

// type FlightIndexProps = {};

const FlightIndex = () => {
  const [searchParams] = useSearchParams();
  const urlState = useLocation();
  // prevent user to continue if no transaction id
  if (urlState.state === null || searchParams.get('id') === null) {
    window.location.href = '/';
    return <></>;
  } else {
    return (
      <>
        <NavBar />
        <div className="flex flex-col items-center justify-center">
          <BookFlightStepsProgressBar />
          <FlightForm />
        </div>
        <Footer />
      </>
    );
  }
};

export default FlightIndex;
