import React from 'react';

type OffersProps = { hotelFacilityHolder: string[] };

const Offers = ({ hotelFacilityHolder }: OffersProps) => {
  return (
    <div className="flex flex-col items-start justify-start">
      <div className="flex flex-col items-start justify-end gap-[1.25rem]">
        <div className="flex flex-row items-center justify-start gap-[0.25rem] text-center">
          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border gap-[0.63rem]">
            <img className="relative w-[0.75rem] h-[0.75rem] hidden" alt="" src="/svg/vuesaxlinearactivity.svg" />
            <img className="relative w-[1rem] h-[1rem]" alt="" src="/svg/vuesaxlinearwifi2.svg" />
          </div>
          {/* <div className="relative leading-[1.13rem] font-medium flex items-center justify-center w-[2.44rem] h-[1rem] shrink-0 font-footnote"> */}
          <span className="relative leading-[1.13rem] font-medium flex items-center justify-start w-[8rem] h-[1rem] shrink-0 font-footnote my-3 text-left">{hotelFacilityHolder[0]}</span>
        </div>

        <div className="flex flex-row items-center justify-start gap-[0.25rem]">
          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border gap-[0.63rem]">
            <img className="relative w-[0.75rem] h-[0.75rem] hidden" alt="" src="/svg/vuesaxlinearactivity.svg" />
            <img className="relative w-[0.75rem] h-[0.75rem] hidden" alt="" src="/svg/vuesaxlinearwifi4.svg" />
            <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/vuesaxlinearshieldsecurity1.svg" />
          </div>
          {/* <div className="relative leading-[1.13rem] font-medium flex items-center w-[4.38rem] h-[1rem] shrink-0 font-footnote"> */}
          <span className="relative leading-[1.13rem] font-medium flex items-center justify-start w-[8rem] h-[1rem] shrink-0 font-footnote my-3">{hotelFacilityHolder[1]}</span>
        </div>
        <div className="flex flex-row items-center justify-start gap-[0.25rem]">
          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-row items-center justify-center p-0.5 box-border">
            <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/group-480957021.svg" />
          </div>
          {/* <div className="relative leading-[1.13rem] font-medium flex items-center w-[4.38rem] h-[1rem] shrink-0 font-footnote"> */}
          <span className="relative leading-[1.13rem] font-medium flex items-center justify-start w-[8rem] h-[1rem] shrink-0 font-footnote my-3">{hotelFacilityHolder[2]}</span>
        </div>
        <div className="flex flex-row items-center justify-start gap-[0.25rem]">
          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border">
            <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/sunfog1.svg" />
          </div>
          {/* <div className="relative leading-[1.13rem] font-medium flex items-center w-[4.38rem] h-[1rem] shrink-0 font-footnote"> */}
          <span className="relative leading-[1.13rem] font-medium flex items-center justify-start w-[8rem] h-[1rem] shrink-0 font-footnote my-3">{hotelFacilityHolder[3]}</span>
        </div>
        <div className="flex flex-row items-center justify-start gap-[0.25rem]">
          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col items-center justify-center p-0.5 box-border">
            <img className="relative w-[1.25rem] h-[1.25rem]" alt="" src="/svg/shop1.svg" />
          </div>
          {/* <div className="relative leading-[1.13rem] font-medium flex items-center w-[4.38rem] h-[1rem] shrink-0 font-footnote"> */}
          <span className="relative leading-[1.13rem] font-medium flex items-center justify-start w-[8rem] h-[1rem] shrink-0 font-footnote my-3">{hotelFacilityHolder[4]}</span>
        </div>
      </div>
    </div>
  );
};

export default Offers;
