import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import {LinkedInSvg} from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';


const MysticalPlacesPhilippines = () =>
{
    const [fbHover, setFbHover] = React.useState('#ADADAD');
    const [liHover, setLiHover] = React.useState('#ADADAD');
    const [twtHover, setTwtHover] = React.useState('#ADADAD');
    const [linkHover, setLinkHover] = React.useState('#ADADAD');

    return (
      <>
        <NavBarTransparent />
        <div className="hero_sec">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Mystical+places+to+visit+in+the+Philippines.jpg"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <div className="gradient_overlay">
            <h1>Top Destinations</h1>

            <div className="bread_crumbs">
              <a href="/#/blogs">Blogs</a>
              <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                Beyond the Veil: 5 Mystical Places to Visit in the Philippines
              </a>
            </div>
          </div>
        </div>

        <div className="articleBody">
          <div className="articleHeader">
            <h1>Beyond the Veil: 5 Mystical Places to Visit in the Philippines</h1>

            <div className="info">
              <p className="authorSec">
                {' '}
                By <u>Anthea Reyes</u>
              </p>
              <p className="separator">•</p>
              <p className="articleDate">Nov 11, 2023</p>
              <p className="separator">•</p>
              <p className="article-read">13.5 min read</p>
            </div>

            <div className="shareBtnContainer">
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
                <FacebookSvg _color={fbHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
                <LinkedInSvg _color={liHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
                <TwitterSvg _color={twtHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
                <LinkSvg _color={linkHover} _width={24} _height={24} />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="articleDescription">
              <p>
                Beyond biodiversity and hospitality, the Philippines is a hotspot for a few other, more magical things — namely, captivating folklore and myth. With a history steeped in peculiar tales
                and gripping legends, to adventure here is to explore mysterious caves and enchanting islands.
              </p>

              <p>If you wish to take yourself on a journey through Philippine’s myths and legends, here are five mystical destinations that you simply must visit.</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/00+COVER+01+Photo+via+riley.png"
                alt=""
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>Photo via riley.ph</p>
            </div>

            <div className="storieswithTitleImageParagraphs">
              <div className="story">
                <div className="head">
                  <h2>Mt. Kanlaon, Negros Occidental</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/02+Photo+via+dakilanglaagan.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo via dakilanglaagan</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Mount Kanlaon, the highest peak in Negros Occidental, is not just a hiker's paradise but also a site embedded in Philippine folklore. Quite literally, if the stories are to be
                    believed. This majestic mountain offers an exhilarating ascent that takes you closer to the sky and, supposedly, closer to the realm of mythical creatures and gods.
                  </p>

                  <p>
                    It is said that Mt. Kanlaon used to be the dwelling place of a dragon-like mythological monster, which was killed by the epic hero, Kan and his datu-lover, Laon. Some also believe
                    that the mountain is the home of the Hiligaynon supreme goddess Kanlaon, and hides a divine portal to the godly realm.
                  </p>
                  <p>
                    Local lore also claims that the craters at the mountain’s peak were the footprint of a warrior god. Would you climb all the way up a mountain just to be metaphorically stepped on
                    by a deity?{' '}
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Island of Enchantment, Siquijor</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/03+Photo+by+Louie+Martinez.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by JLouie Martinez via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Siquijor is often referred to as the "Island of Enchantment" for a reason. In this province, the practice of traditional magic such as tawas, voodoo, and kulam is alive and well.
                    While there are serene beaches and enchanted caves for you to explore, this picturesque island is most famous for the local’s healing rituals and gayuma or potion-making.
                  </p>
                  <p>
                    Aside from these, another noteworthy visit here is a century-old balete tree with a crystal clear pool at the bottom. Here, you can dip your feet in the waters for a quick cool
                    down and admire what’s believed to be the residence or doorway of aswangs or mythical beasts and demons.{' '}
                  </p>
                  <p>Quick reminder: Always, always be mindful and respectful as you adventure around this destination where myths, culture, and reality intertwine (a little too) seamlessly. </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>The Millenium Tree, Aurora Province</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/04+Photo+by+Carlo+Velasquez.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Carlo Lorenzo via Manila and More</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Aurora Province also boasts their own mystical <span>balete tree</span>, only this one holds the claim for being the biggest of its kind in Asia. Dubbed The Millenium Tree by
                    locals, it’s believed that the core of this centuries-old balete serves as the home of <span>engkantos</span> or magic-wielding supernatural beings.
                  </p>

                  <p>
                    And you can determine for yourself whether this myth is true or false! This particular <span>balete</span> tree features gaps at its base that you can enter and climb to get closer
                    to its mysterious middle. It even has an entrance that leads to a hollow chamber inside the tree that can fit several people.
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Taal Volcano, Batangas</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/05+Photo+by+Joshua+Salva.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Joshua Salva via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Taal Volcano is a scenic wonder nestled within a mystical lake. Legend has it that it wasn’t always so. </p>
                  <p>
                    According to the folktale, the volcano used to be a mountain and was part of an expansive landscape. Near the mountain was a thriving town founded and lead by a wise old man called
                    Lakan Taal. It’s said that Lakan Taal guided his people to prosperity through harvesting crops like coffee and avocado. His one rule was for the townsfolk to never climb the peak
                    of the mountain.
                  </p>
                  <p>
                    The one day, Lakan Taal disappeared. The people tried to live on, but due to a longing for their wise old guide, they went on a search that lead them to the forbidden mountain
                    peak. There, the townsfolk found a crater filled with precious gems, which inspired greed and in-fighting among them.{' '}
                  </p>
                  <p>
                    This is when Lakan Taal reappeared and cursed the people. With Bathala’s help the people were struck with deadly lightning, the crater spewed fire and lava, and the mountain was
                    sunk into a lake.{' '}
                  </p>
                  <p>
                    This is supposedly the origin of Taal’s ethereal location. And superstition warns that anyone crossing the Crater Lake to get to the volcano should be mindful not to touch its
                    azure waters. You might forget who you are and disappear just like Lakan Taal and his people did.{' '}
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Calbiga Caves, Samar</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/06+Photo+by+Langun+Gobingob+Cave.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Langun, Gobingob Cave via Facebook</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    If you want a glimpse of mysterious underground realms, head to Samar, the Caving Capital of the country. In this province lies the Langun-Gobingob Caves, more popularly known as
                    the Calbiga Caves. This is the largest cave system in the Philippines and is reputed to have the world’s third largest karst formation.{' '}
                  </p>
                  <p>
                    For the longest time, locals avoided the Calbiga Caves because of local belief that they were the lair of <span>malignos</span> and other monstrous mythical creatures. It’s also
                    treated by some as sacred land, the resting place of the spirits of Waray ancestors.{' '}
                  </p>
                  <p>
                    Booming tourism, however, has emboldened local spelunkers to explore Callao Cave’s systems and open it to the public. Nowadays, you can explore its massive 12-cave complex
                    consisting of stalactite and stalagmite columns, unique rock formations, subterranean water courses, and natural springs.
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Mt. Banahaw</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/07+Photo+via+Group+7.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Group 7</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Mt. Banahaw is a sacred mountain in the province of Quezon. Some say that four Archangels once resided on the mountain, while others believe that it’s the dwelling place of
                    mystical beings known as engkantos.
                  </p>
                  <p>
                    Regardless of the superstition, Mt. Banahaw is acknowledged as a sacred site by the general public. There are many locations here considered as holy sites and springs hailed as
                    “holy water”.{' '}
                  </p>
                  <p>
                    Pilgrims and trekkers alike visit this mountain to experience its unique blend of spirituality and natural beauty — some to reconnect with nature, others to restore their spiritual
                    health.{' '}
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Mt. Cristobal</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/08+Photo+by+Darwin+Dalisay+via+Travel+Cup.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Darwin Dalisay via Travel Cup</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Towering along the border of Laguna and Quezon provinces, Mt. Cristobal is an infamous destination known for its stunning landscapes and eerie legends. Dubbed The Devil’s Mountain,
                    local folklore warns that this mountain is home to malicious supernatural creatures and aggrieved spirits.
                  </p>
                  <p>
                    The belief is that Mt. Cristobal stands as the balance to Mt. Banahaw. Where Mt. Banahaw offers sacred spirituality, Mt. Cristobal stores an overwhelming amount of negative energy.{' '}
                  </p>
                  <p>
                    Adventurous trekkers can explore the mountain’s infamous "Devil's Trail," a narrow, steep ridge leading to a breathtaking summit. But be forewarned, previous hikers have reported
                    supernatural occurrences and ghostly apparitions while trekking its chilling forests.{' '}
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Islas de Gigantes</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/09+Photo+by+Kezia+Guerrero+via+philippinebeaches.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Kezia Guerrero via philippinebeaches.org</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Off the coast of Iloilo lies the Islas de Gigantes or "Islands of the Giants," a cluster of islands shrouded in mystery. Local legends speak of giants who once inhabited these
                    isles, leaving behind massive caves, rock formations, and inexplicable grave sites.
                  </p>
                  <p>
                    According to urban legend, Spanish colonizers discovered coffins inside Bakwitan Cave, one of the caves on the island, that contained gigantic sets of human bones. Because of this
                    “discovery”, locals have since believed that the island was home to giants and other <span>engkantos</span>.
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Mt. Makiling</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/10+Photo+via+touristspotsfinder.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo via touristspotsfinder.com</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Nestled in the province of Laguna, Mt. Makiling is famous for the legend of Maria Makiling, a mystical fairy said to inhabit the mountain's slopes. Nestled in the province of
                    Laguna, Mt. Makiling is famous for the legend of Maria Makiling, a mystical fairy said to inhabit the mountain's slopes.
                  </p>
                  <p>Hikers who've ventured deep into the forest have reported hearing melodious voices and experiencing unexplained phenomena. </p>
                  <p>
                    These phenomena are said to be tests of kindness from the fairy. If you succeed, she lets you go on your journey. If you fail, she might chase you out of her forest with the
                    howling of monsters and other creatures.{' '}
                  </p>
                  <p>While the peak offers a stunning view of Laguna de Bay, the greatest allure of the mountain remains to be the enchanting Maria Makiling.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Hinatuan Enchanted River</h2>

                  <div className="img_container">
                    <img
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Mystical+Places+to+Visit+in+the+Philippines/11+Photo+by+Vacation+Hive.png"
                      alt=""
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo via Vacation Hive</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>In the southern province of Surigao del Sur, the Hinatuan Enchanted River is known for its crystal-clear blue waters and intriguing mysteries. </p>
                  <p>
                    Local folklore suggests that spirits guard the river, making it a mystical and enchanting place. One story tells of how <span>engkantos</span> dropped sapphires and jade in its
                    waters to give it its vibrant colors.{' '}
                  </p>
                  <p>
                    Other stories talk of uncatchable fish in the river, and deceptive depths that have drawn young folk to drown in its waters. Between the mystery and the thrill, snorkeling or
                    diving in this river is an experience unlike any other. You'll definitely feel like you've stepped into a hidden underwater world filled with wonder.
                  </p>
                </div>
              </div>
            </div>

            <div className="footnote">
              <div className="title_container">
                <h2>Start your mystical Philippine journey today</h2>
              </div>

              <div className="info">
                <p>
                  Mystical destinations in the Philippines offer a unique blend of natural beauty and cultural heritage. Whether you're a seasoned adventurer or just a curious traveler, these
                  locations promise to transport you to a world where reality and fantasy coexist in harmony.
                </p>
                <p>
                  Immerse yourself in the wonders of Philippine folklore as you journey through enchanting caves, mystical mountains, and islands shrouded in captivating (sometimes frightening) myths
                  and legends.{' '}
                </p>
                <p>Find and book all of them at GalaGO! Who knows, you might even discover more enchanting adventures. </p>
              </div>
            </div>
          </div>

          <hr />

          <div className="featured_blogs">
            <h2>Featured Blogs</h2>
            <div className="card_container">
              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                    alt="Discover the Best Things to Do in Bohol All in One Tour!"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                  <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                    alt="Guide to Travel Requirements in the Philippines"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Guide to Travel Requirements in the Philippines</h3>

                  <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default MysticalPlacesPhilippines;