import React from 'react';
import { SvgProps } from './SvgProps';

export const EmailSendSvg = ({ _width, _height, _color }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M29.7778 43.2597H7.55556C6.08213 43.2597 4.66905 42.64 3.62718 41.5368C2.58532 40.4337 2 38.9375 2 37.3774V7.9654C2 6.40529 2.58532 4.90908 3.62718 3.80592C4.66905 2.70276 6.08213 2.08301 7.55556 2.08301H46.4444C47.9179 2.08301 49.3309 2.70276 50.3728 3.80592C51.4147 4.90908 52 6.40529 52 7.9654V25.6126"
          stroke={_color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M2 7.96533L27 25.6125L52 7.96533M38.1111 52.0833L52 37.3773M52 37.3773V50.6127M52 37.3773H39.5"
          stroke={_color}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const EmailSvg = ({ _width, _height, _color = '#016E7F' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 160 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="85.4257" cy="67.7968" rx="67.7968" ry="67.7968" fill="#016E7F" fill-opacity="0.04" />
      <ellipse cx="92.2031" cy="85.4253" rx="67.7968" ry="67.7968" fill="#016E7F" fill-opacity="0.04" />
      <ellipse cx="67.7968" cy="85.4253" rx="67.7968" ry="67.7968" fill="#016E7F" fill-opacity="0.04" />
      <path
        d="M85.4907 104.311H63.2684C61.795 104.311 60.3819 103.691 59.3401 102.588C58.2982 101.485 57.7129 99.9887 57.7129 98.4286V69.0167C57.7129 67.4566 58.2982 65.9604 59.3401 64.8572C60.3819 63.754 61.795 63.1343 63.2684 63.1343H102.157C103.631 63.1343 105.044 63.754 106.086 64.8572C107.128 65.9604 107.713 67.4566 107.713 69.0167V86.6638"
        stroke={_color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7129 69.0166L82.7129 86.6638L107.713 69.0166M93.824 113.135L107.713 98.4286M107.713 98.4286V111.664M107.713 98.4286H95.2129"
        stroke={_color}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
