import axios, { AxiosResponse } from 'axios';
import { changeOldPasswordRoute, validateOldPasswordRoute } from '../utils/apiRoutes';
import { AuthenticationHooks } from './AuthenticationHooks';

export const ChangePasswordHooks = () => {
  const { refreshToken } = AuthenticationHooks();
  const validateOldPassword = async (oldPassword: string) => {
    try {
      const response: AxiosResponse = await axios.post(validateOldPasswordRoute(), {
        oldPassword: oldPassword,
      });
      return response;
    } catch (error) {
      await refreshToken();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  const validateNewPassword = async (password: string, confirmPassword: string) => {
    // console.log(password,confirmPassword)
    if (password === confirmPassword) {
      try {
        const response: AxiosResponse = await axios.put(changeOldPasswordRoute(), {
          newPassword: password,
        });
        return response;
      } catch (error) {
        await refreshToken();
        const { response } = error as { response: AxiosResponse };
        return response;
      }
    }
  };

  return { validateOldPassword, validateNewPassword };
};
