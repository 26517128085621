import axios from 'axios';
import React, { useEffect } from 'react';
import { signOutApiRoute, X_API_KEY } from '../utils/apiRoutes';
import { UserStore } from '../store/UserStore';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';

export const Logout = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  // const router = useRouter()
  const { setIsLoggedIn } = UserStore();

  useEffect(() => {
    axios.defaults.headers.common['x-api-key'] = X_API_KEY;
    axios
      .post(signOutApiRoute())
      .then((data) => {
        setIsLoggedIn(false);
        cookie.remove('g-token');
        cookie.remove('g-refresh-token');
        cookie.remove('g-request-cookie');
        navigate('/');
      })
      .catch((e) => {
        setIsLoggedIn(false);
        cookie.remove('g-token');
        cookie.remove('g-refresh-token');
        cookie.remove('g-request-cookie');
        navigate('/');
      });
    // eslint-disable-next-line
  }, []);

  return <></>;
};
