import React from 'react';
import { create } from 'zustand';

export const GalagoDialogStore = create((set: any) => ({
  /** -------- success dialog -------- */
  galagoSuccessTitle: '',
  setGalagoSuccessTitle: (title: string) => {
    set((state: { galagoSuccessTitle: string }) => ({
      galagoSuccessTitle: title,
    }));
  },
  showGalagoSuccessDialog: false,
  setShowGalagoSuccessDialog: (isShow: boolean) => {
    set((state: { showGalagoSuccessDialog: boolean }) => ({
      showGalagoSuccessDialog: isShow,
    }));
  },
  /**
   * return { message: string, <component> }
   */
  galagoSuccessDialogMessage: { message: '', icon: null },
  /**
   * @param message string, icon as <component>
   */
  setGalagoSuccessDialogMessage: ({ message, icon }: { message: string; icon?: React.ReactNode }) => {
    set((state: { galagoSuccessDialogMessage: string }) => ({
      galagoSuccessDialogMessage: { message, icon },
    }));
  },

  /** -------- error dialog -------- */
  showGalagoErrorDialog: false,
  setShowGalagoErrorDialog: (isShow: boolean) => {
    set((state: { showGalagoErrorDialog: boolean }) => ({
      showGalagoErrorDialog: isShow,
    }));
  },
  galagoErrorDialogTitle: '',
  setGalagoErrorDialogTitle: (title: string) => {
    set((state: { galagoErrorDialogTitle: string }) => ({
      galagoErrorDialogTitle: title,
    }));
  },
  galagoErrorDialogMessage: '',
  setGalagoErrorDialogMessage: (message: string) => {
    set((state: { galagoErrorDialogMessage: string }) => ({
      galagoErrorDialogMessage: message,
    }));
  },

  galagoErrorButtonLabel: 'Try Again',
  setGalagoErrorButtonLabel: (message: string) => {
    set((state: { galagoErrorButtonLabel: string }) => ({
      galagoErrorButtonLabel: message,
    }));
  },

  galagoErrorButtonOnClick: () => {},
  setGalagoErrorButtonOnClick: (onClick: () => void) => {
    set((state: { galagoErrorButtonOnClick: () => void }) => ({
      galagoErrorButtonOnClick: onClick,
    }));
  },


  /** -------- normal dialog -------- */
  galagoDialogTitle: '',
  setGalagoDialogTitle: (title: string) => {
    set((state: { galagoDialogTitle: string }) => ({
      galagoDialogTitle: title,
    }));
  },
  showGalagoDialog: false,
  setShowGalagoDialog: (isShow: boolean) => {
    set((state: { showGalagoDialog: boolean }) => ({
      showGalagoDialog: isShow,
    }));
  },
  /**
   * return { message: string, <component> }
   */
  galagoDialogMessage: { message: '', icon: null },
  /**
   * @param message string, icon as <component>
   */
  setGalagoDialogMessage: ({ message, icon }: { message: string; icon?: React.ReactNode }) => {
    set((state: { galagoDialogMessage: string }) => ({
      galagoDialogMessage: { message, icon },
    }));
  },
}));
