import { SvgProps } from './SvgProps';
export const ListSvg = ({ _width = 24, _height = 24, _color = '#333333' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_width} height={_height} viewBox="0 0 24 24" fill="none">
      <path
        d="M4 9C3.71667 9 3.479 8.904 3.287 8.712C3.095 8.52 2.99934 8.28267 3 8V6C3 5.71667 3.096 5.479 3.288 5.287C3.48 5.095 3.71734 4.99934 4 5H6C6.28334 5 6.521 5.096 6.713 5.288C6.905 5.48 7.00067 5.71734 7 6V8C7 8.28334 6.904 8.521 6.712 8.713C6.52 8.905 6.28267 9.00067 6 9H4ZM9 9C8.71667 9 8.479 8.904 8.287 8.712C8.095 8.52 7.99934 8.28267 8 8V6C8 5.71667 8.096 5.479 8.288 5.287C8.48 5.095 8.71734 4.99934 9 5H20C20.2833 5 20.521 5.096 20.713 5.288C20.905 5.48 21.0007 5.71734 21 6V8C21 8.28334 20.904 8.521 20.712 8.713C20.52 8.905 20.2827 9.00067 20 9H9ZM9 14C8.71667 14 8.479 13.904 8.287 13.712C8.095 13.52 7.99934 13.2827 8 13V11C8 10.7167 8.096 10.479 8.288 10.287C8.48 10.095 8.71734 9.99934 9 10H20C20.2833 10 20.521 10.096 20.713 10.288C20.905 10.48 21.0007 10.7173 21 11V13C21 13.2833 20.904 13.521 20.712 13.713C20.52 13.905 20.2827 14.0007 20 14H9ZM9 19C8.71667 19 8.479 18.904 8.287 18.712C8.095 18.52 7.99934 18.2827 8 18V16C8 15.7167 8.096 15.479 8.288 15.287C8.48 15.095 8.71734 14.9993 9 15H20C20.2833 15 20.521 15.096 20.713 15.288C20.905 15.48 21.0007 15.7173 21 16V18C21 18.2833 20.904 18.521 20.712 18.713C20.52 18.905 20.2827 19.0007 20 19H9ZM4 19C3.71667 19 3.479 18.904 3.287 18.712C3.095 18.52 2.99934 18.2827 3 18V16C3 15.7167 3.096 15.479 3.288 15.287C3.48 15.095 3.71734 14.9993 4 15H6C6.28334 15 6.521 15.096 6.713 15.288C6.905 15.48 7.00067 15.7173 7 16V18C7 18.2833 6.904 18.521 6.712 18.713C6.52 18.905 6.28267 19.0007 6 19H4ZM4 14C3.71667 14 3.479 13.904 3.287 13.712C3.095 13.52 2.99934 13.2827 3 13V11C3 10.7167 3.096 10.479 3.288 10.287C3.48 10.095 3.71734 9.99934 4 10H6C6.28334 10 6.521 10.096 6.713 10.288C6.905 10.48 7.00067 10.7173 7 11V13C7 13.2833 6.904 13.521 6.712 13.713C6.52 13.905 6.28267 14.0007 6 14H4Z"
        fill={_color}
      />
    </svg>
  );
};
