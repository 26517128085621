import "react-loading-skeleton/dist/skeleton.css";
import { CSSProperties, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

type HotelCardType = {
  cards?: number;

  /** Style props */
  frameDivBorderRadius?: CSSProperties["borderRadius"];
  frameDivBorder?: CSSProperties["border"];
  frameDivBoxShadow?: CSSProperties["boxShadow"];
};

const HotelSearchResultsSkeletonCard = ({ frameDivBorderRadius, frameDivBorder, frameDivBoxShadow, cards }: HotelCardType) => {
  const frameDiv11Style: CSSProperties = useMemo(() => {
    return {
      borderRadius: frameDivBorderRadius,
      border: frameDivBorder,
      boxShadow: frameDivBoxShadow,
    };
  }, [frameDivBorderRadius, frameDivBorder, frameDivBoxShadow]);

  return (
    <>
      {Array(cards)
        .fill(0)
        .map((item, index) => {
          return (
            <div
              className="rounded-xl bg-white-white-100 box-border w-100 flex flex-row p-6 items-center justify-start gap-[6.19rem] text-left text-[1.25rem] text-black font-footnote border-[1px] border-solid border-border-colors-border-primary"
              style={frameDiv11Style}
              key={index}
            >
              <div className="w-[40.5rem] flex flex-row items-center justify-start gap-[1rem]">
                <div className="container relative rounded-3xs w-[15.38rem] h-[13.25rem] object-cover">
                  <Skeleton count={1} height={200} borderRadius={10} />
                </div>
                <div className="flex flex-col w-[25rem] items-start justify-start gap-[0.75rem]">
                  <div className="container">
                    <Skeleton count={1} height={30} borderRadius={10} />
                  </div>
                  <div className="container">
                    <Skeleton count={1} height={30} borderRadius={10} />
                  </div>
                  <div className="container">
                    <Skeleton count={1} height={30} borderRadius={10} />
                  </div>
                </div>
              </div>

              <div className="relative box-border w-[0.06rem] h-[13.31rem] border-r-[1px] border-solid border-gainsboro-200" />

              <div className="flex flex-col items-end w-[20rem] justify-start gap-[1rem] text-[1rem]">
                <div className="container">
                  <Skeleton count={1} height={20} borderRadius={10} />
                </div>
                <div className="container">
                  <Skeleton count={1} height={40} borderRadius={10} />
                </div>
                <div className="container">
                  <Skeleton count={1} height={40} borderRadius={10} />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default HotelSearchResultsSkeletonCard;
