import { SvgProps } from './SvgProps';

export const ArrowRightSvg = ({ _width = 12, _height = 13, _color = '#333333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Arrow 1"
        d="M19.2071 8.70711C19.5976 8.31658 19.5976 7.68342 19.2071 7.29289L12.8431 0.928931C12.4526 0.538407 11.8195 0.538407 11.4289 0.928931C11.0384 1.31946 11.0384 1.95262 11.4289 2.34314L17.0858 8L11.4289 13.6569C11.0384 14.0474 11.0384 14.6805 11.4289 15.0711C11.8195 15.4616 12.4526 15.4616 12.8431 15.0711L19.2071 8.70711ZM0.5 9L18.5 9L18.5 7L0.5 7L0.5 9Z"
        fill={_color}
      />
    </svg>
  );
};
