import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { roomsPrebookingApiRoute } from '../utils/apiRoutes';

const HotelRoomsPrebookingHooks = () => {
  // effects
  const [roomsPrebookingResult, setRoomsPrebookingResult] = useState<{[index : string]: any}>();

  const roomsPrebooking = async (payload: { [index: string]: any }) => {
    // console.log(payload)
    try {
      const response: AxiosResponse = await axios.post(roomsPrebookingApiRoute(), payload);
      setRoomsPrebookingResult(response.data);

      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  return { roomsPrebooking, roomsPrebookingResult };
};

export default HotelRoomsPrebookingHooks;
