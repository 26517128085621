import React from 'react';
import { CheckProgressBar } from '../../../resources/svg/CheckSvg';

const BookFlightStepsProgressBar = () => {
  return (
    <div className="bg-background-colors-surface-secondary text-background-colors-surface-secondary font-footnote relative box-border flex h-[7.31rem] flex-row items-center justify-center self-stretch px-[104px] py-0 text-center text-[1.06rem] ">
      <div className="flex flex-row items-start justify-start gap-[0.5rem]">
        <div className="text-color-shades-white-800 flex flex-row items-center justify-center gap-[0.5rem] text-[0.94rem]">
          <div className="rounded-81xl bg-color-shades-white-800 box-border flex h-[2rem] w-[2rem] flex-col items-center justify-center p-2.5">
            {/* <img className="relative shrink-0 scale-150 overflow-hidden " alt="" src="/svg/checkicon1.svg" /> */}
            <CheckProgressBar/>
          </div>
          <div className="font-footnote relative font-normal leading-[1.25rem]">Flight Selection</div>
          <div className="bg-color-shades-white-800 relative h-[0.13rem] w-[4.06rem]" />
        </div>
        <div className="flex flex-row items-center justify-center gap-[0.5rem] ">
          <div className="rounded-81xl bg-color-shades-white-800 box-border flex h-[1.75rem] w-[1.75rem] flex-col items-center justify-center p-2.5 ">
            <b className="relative leading-[1.38rem]">2</b>
          </div>
          <div className="text-color-shades-white-800 font-footnote relative text-[0.94rem] font-semibold leading-[1.25rem]">Passenger Details</div>
          <div className="bg-color-shades-white-800 relative h-[0.13rem] w-[4.06rem]" />
        </div>
        <div className="flex flex-row items-center justify-center gap-[0.5rem] opacity-[0.7]">
          <div className="rounded-81xl bg-color-shades-white-800 box-border flex h-[1.75rem] w-[1.75rem] flex-col items-center justify-center p-2.5 ">
            <b className="relative leading-[1.38rem] ">3</b>
          </div>
          <div className="text-color-shades-white-800 font-footnote relative  text-[0.94rem] font-normal leading-[1.25rem]">Payment</div>
          <div className="bg-color-shades-white-800 relative h-[0.13rem] w-[4.06rem] " />
        </div>
        <div className="flex flex-row items-center justify-center gap-[0.5rem] opacity-[0.7] ">
          <div className="rounded-81xl bg-color-shades-white-800 box-border flex h-[1.75rem] w-[1.75rem] flex-col items-center justify-center p-2.5 ">
            <b className="relative leading-[1.38rem]">4</b>
          </div>
          <div className="text-color-shades-white-800 font-footnote relative text-[0.94rem] font-normal leading-[1.25rem]">Confirmation</div>
        </div>
      </div>
    </div>
  );
};

export default BookFlightStepsProgressBar;
