import axios, { AxiosResponse } from 'axios';
import { getUserDetails, logIn, logInGuest, refreshTokenUrl, signOutApiRoute, signUp, verifyEmail, X_API_KEY } from '../utils/apiRoutes';
import { UserStore } from '../store/UserStore';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';

interface Payload {
  [index: string]: any;
}

export const AuthenticationHooks = () => {
  const navigate = useNavigate();
  const userStore = UserStore();
  const cookie = new Cookies();

  // if (axios.defaults.headers.common['Authorization'] === undefined) {
  //   axios.defaults.headers.common['x-api-key'] = X_API_KEY;
  // }

  // const { userStore.setIsLoggedIn, addAccessToken, addRefreshToken, token } = UserStore();

  /**
   * @param payload {email: string}
   * @returns { isEmailExists: boolean }
   */
  const verifyEmailHook = async (payload: Payload) => {
    try {
      const response: AxiosResponse = await axios.request({
        method: 'post',
        url: verifyEmail(),
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      await refreshToken();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  const registerUserHook = async (payload: Payload) => {
    try {
      const response: AxiosResponse = await axios.post(signUp(), payload);
      return response;
    } catch (error) {
      await refreshToken();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  const loginUserHook = async (payload: { email: string; password: string }) => {
    try {
      const response: AxiosResponse = await axios.post(logIn(), payload, {
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true,
        },
      });

      return response;
    } catch (error) {
      await refreshToken();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  const loginGuestHook = async () => {
    try {
      const isLoggedIn = userStore.isLoggedIn;
      const isGuest = userStore.isGuest;
      // console.log(isLoggedIn);
      // if not a guest and not logged in then stop this function
      if (isLoggedIn === true || isGuest === true) {
        return;
      }

      let response: AxiosResponse = await axios.post(logInGuest());

      if (response.status === 200 || response.status === 201) {
        cookie.set('g-token', response.data.accessToken, { path: '/' });
        cookie.set('g-refresh-token', response.data.refreshToken, { path: '/' });
        cookie.set('g-request-cookie', response.headers['set-cookie'], { path: '/' });
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      }

      // console.log(response);
      // Handle the response here
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  };

  const checkLoggedUser = async () => {
    try {
      const response: AxiosResponse = await axios.get(getUserDetails());
      return response;
    } catch (error) {
      await refreshToken();
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  /**
   * @description sends a request to the server to refresh the token
   * @returns nothing
   */
  const refreshToken = async () => {
    try {
      const response: AxiosResponse = await axios.post(
        refreshTokenUrl(),
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookie.get('g-refresh-token')}`,
            'x-api-key': X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        cookie.set('g-token', response.data.accessToken, { path: '/' });
        cookie.set('g-refresh-token', response.data.refreshToken, { path: '/' });
        cookie.set('g-request-cookie', response.headers['set-cookie'], { path: '/' });

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      }
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      return response;
    }
  };

  /**
   * @description this function is not used anymore and can be removed in the future
   */
  const _refreshToken = async () => {
    // console.log(refreshTokenUrl());
    // if (localStorage.jwt) {
    let _user: string = localStorage.jwt;

    axios.defaults.headers.common['x-api-key'] = undefined;

    // const tokenExpiration = JSON.parse(atob(JSON.parse(_user).accessToken.split('.')[1])).exp;
    // const tokenExpirationDate = moment(tokenExpiration * 1000).format('YYYY-MM-DD');
    // const dateNow = moment().format('YYYY-MM-DD');
    // if (tokenExpirationDate !== dateNow) {
    //   return;
    // }

    const user: { [index: string]: string } = JSON.parse(_user);
    const refresh = user?.refreshToken;

    return await axios
      .request({
        method: 'POST',
        url: refreshTokenUrl(),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refresh}`,
        },
      })
      .then((response: { [index: string]: any }) => {
        if (response.status === 200) {
          // console.log(response);
          // userStore.addAccessToken(response.data.accessToken);
          // userStore.addRefreshToken(response.data.refreshToken);
          // localStorage.setItem('user', JSON.stringify(response.data));
          // localStorage.setItem('jwt', JSON.stringify(response.data));
          cookie.set('g-token', response.data.accessToken, { path: '/' });
          cookie.set('g-refresh-token', response.data.refreshToken, { path: '/' });
          cookie.set('g-request-cookie', response.headers['set-cookie'], { path: '/' });

          /**
           * attach token into axios request
           */
          // const jwtAccessToken = localStorage.getItem('jwt') ? JSON.parse(localStorage.jwt) : null;
          axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
          // userStore.setIsLoggedIn(true);
          return response.data;
        }
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          // userStore.addAccessToken('');
          // userStore.addRefreshToken('');
          // localStorage.removeItem('user');
          // localStorage.removeItem('jwt');
          cookie.remove('g-token');
          cookie.remove('g-refresh-token');
          cookie.remove('g-request-cookie');

          // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
          // const jwt_token = `Bearer ${response.data.accessToken}`;
          axios.defaults.headers.common['Authorization'] = null;
          // userStore.setIsLoggedIn(false);

          navigate('/');
        }
      });
    // }
    // return false;
  };

  const signOut = () => {
    try {
      return axios.post(signOutApiRoute()).then((data) => {
        // console.log(data.data.statusCode);
        // if (data.data.statusCode === 200) {
        //   userStore.setIsLoggedIn(true);
        // localStorage.removeItem('user');
        // localStorage.removeItem('userDetails');
        // localStorage.removeItem('jwt');
        cookie.remove('g-token');
        cookie.remove('g-refresh-token');
        cookie.remove('g-request-cookie');
        localStorage.removeItem('userDetails');
        // }
      });
    } catch (error) {
      // console.log(error);
    }
  };

  return { verifyEmailHook, registerUserHook, loginUserHook, loginGuestHook, checkLoggedUser, refreshToken, _refreshToken, signOut };
};
