import React from 'react';
import { Modal } from 'react-bootstrap';

/**
 * how to use
 * 
  <AlertBox size={"sm"} title={" "} show={true} onHide={(): void => {}}>
    <any html elements>
    </any html elements>
  </AlertBox>
 *
 */

interface AlertBoxProps {
  size?: 'sm' | 'lg' | 'xl' | undefined;
  children?: React.ReactNode;
  show: boolean;
  onHide?: () => void;
  title?: string;
  footer?: React.ReactNode;
}

export const AlertBox = ({ children, show, onHide, size, title }: AlertBoxProps) => {
  return (
    <Modal show={show} onHide={onHide} size={size} aria-labelledby="contained-modal-title-vcenter" centered animation={false}>
      {title !== undefined ? (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="galago-modal-header">
            {title}
          </Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
  );
};
