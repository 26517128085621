import { SvgProps } from './SvgProps';
export const AirportSvg = ({ _width = 28, _height = 16, _color = '#333333' }: SvgProps) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={_color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.707 0.302604C12.1003 0.695937 12.1003 1.32927 11.707 1.71594L9.11362 4.30927L10.527 10.4359L9.58695 11.3826L7.00029 6.42927L4.40029 9.02927L4.64029 10.6759L3.92695 11.3826L2.75362 9.2626L0.626953 8.0826L1.33362 7.3626L3.00029 7.60927L5.58029 5.02927L0.626953 2.4226L1.57362 1.4826L7.70029 2.89594L10.2936 0.302604C10.667 -0.0840629 11.3336 -0.0840629 11.707 0.302604Z"
        fill={_color}
      />
    </svg>
  );
};
