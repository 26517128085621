import { AirlineLogo } from '../utils/airlines-logo';
import { FlightResultsHook } from './FlightResultsHook';

type MystiflyFlightSegmentHookType = {
  FlightType: 'one-way' | 'round-trip' | 'multi-city';
  FlightDetails: { [index: string]: any };
};

export const MystiflyFlightSegmentHook = () => {
  const { flightSegments } = FlightResultsHook();
  // const { setIsDomesticFlight } = FlightPassengersStore();

  // const [departureFlightSegmentDetails, setDepartureFlightSegmentDetails] = React.useState<{ [index: string]: any } | undefined>();
  // const [departureAirline, setDepartureAirline] = React.useState<{ [index: string]: any } | undefined>();
  // const [returnFlightSegmentDetails, setReturnFlightSegmentDetails] = React.useState<{ [index: string]: any } | undefined>();
  // const [returnAirline, setReturnAirline] = React.useState<{ [index: string]: any } | undefined>();

  const segments = ({ FlightType, FlightDetails }: MystiflyFlightSegmentHookType) => {
    let departureFlightSegmentDetails: { [index: string]: any } | undefined;
    let returnFlightSegmentDetails: { [index: string]: any } | undefined;
    let departureAirline: { [index: string]: any } | undefined;
    let returnAirline: { [index: string]: any } | undefined;

    // React.useEffect(() => {
    switch (FlightType) {
      case 'one-way':
        // setDepartureFlightSegmentDetails(flightSegments(FlightDetails?.OriginDestinations));
        departureFlightSegmentDetails = flightSegments(FlightDetails?.OriginDestinations);
        break;
      case 'round-trip':
        const departureOriginDestinations = FlightDetails?.OriginDestinations.filter((destination: { LegIndicator: string }) => {
          return destination.LegIndicator === '0';
        });
        const returnOriginDestinations = FlightDetails?.OriginDestinations.filter((destination: { LegIndicator: string }) => {
          return destination.LegIndicator === '1';
        });
        departureFlightSegmentDetails = flightSegments(departureOriginDestinations);
        returnFlightSegmentDetails = flightSegments(returnOriginDestinations);
        // setDepartureFlightSegmentDetails(flightSegments(departureOriginDestinations));
        // setDepartureAirline(AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === departureFlightSegmentDetails?.carrierCode));
        // setReturnFlightSegmentDetails(flightSegments(returnOriginDestinations));
        break;
      default:
        break;
    }

    departureAirline = AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === departureFlightSegmentDetails?.carrierCode);
    returnAirline = AirlineLogo?.find((airline: { [index: string]: any }) => airline.id === returnFlightSegmentDetails?.carrierCode);
    // setIsDomesticFlight(departureFlightSegmentDetails?.isDomestic);

    return { departureFlightSegmentDetails, departureAirline, returnFlightSegmentDetails, returnAirline };
  };

  const itinerary = (FlightType: 'one-way' | 'round-trip', FlightDetails: { [index: string]: any }) => {
    const originDestinations = FlightDetails.OriginDestinations;

    let departureItinerary: { [index: string]: any } | undefined;
    let returnItinerary: { [index: string]: any } | undefined;

    switch (FlightType) {
      case 'one-way':
        departureItinerary = originDestinations[0].ItineraryReference;
        break;
      case 'round-trip':
        const departureOriginDestinations = FlightDetails.OriginDestinations.filter((destination: { LegIndicator: string }) => {
          return destination.LegIndicator === '0';
        });
        const returnOriginDestinations = FlightDetails.OriginDestinations.filter((destination: { LegIndicator: string }) => {
          return destination.LegIndicator === '1';
        });

        departureItinerary = departureOriginDestinations[0].ItineraryReference;
        returnItinerary = returnOriginDestinations[0].ItineraryReference;

        break;

      default:
        break;
    }

    return { departureItinerary, returnItinerary };
  };

  return { segments, itinerary };
};
