// import parse from 'html-react-parser';
import React from 'react';

type AboutAccommodationModalProps = {
  onClose: () => void;
  aboutAccommodationModalData: {
    description: string;
    propertyName: string;
  };
};

const AboutAccommodationModal = ({ onClose, aboutAccommodationModalData }: AboutAccommodationModalProps) => {
  const { propertyName } = aboutAccommodationModalData;
  return (
    <section className="rounded-xl  overflow-hidden flex flex-col items-start justify-start text-left textColor">
      {/* header */}
      <div className="self-stretch rounded-t-xl rounded-b-none flex flex-row items-center justify-between py-4 px-8 border-b-[1px] border-solid border-gainsboro-300">
        <div className="text-base generalsans-medium">About your stay</div>

        <button onClick={onClose} className="overflow-hidden flex items-center justify-center p-0.5 gap-[0.63rem]">
          <img className="relative w-[0.78rem] h-[0.78rem]" alt="" src="/svg/union.svg" />
        </button>
      </div>

      {/* body */}
      <div className="self-stretch relative text-text-colors-text-primary overflow-y-auto">
        <div className="flex flex-row items-center justify-center py-4 px-8 box-border">
          <div className="flex flex-col items-center justify-center flex-1">
            <div className="self-stretch flex flex-col items-start justify-center gap-[1rem]">
              <div className="self-stretch flex flex-col items-start justify-center gap-[0.25rem]">
                <div className="flex flex-row items-center justify-center p-0.5">
                  <div className="relative text-xl generalsans-semibold">{propertyName}</div>
                </div>
              </div>

              <div className="self-stretch relative text-[0.88rem] leading-[1.31rem] font-medium text-justify ">
                <p className=" [margin-block-start:0] [margin-block-end:14px] m-0 font-normal text-base font-footnote"></p>
                {/* {parse(description.replaceAll('<p>', '<p className=" [margin-block-start:0] [margin-block-end:14px] m-0 font-normal text-base font-footnote">'))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAccommodationModal;
