import React from 'react';
// import { api } from '../../lib/axios-interceptors/api';
import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../utils/apiRoutes';

type payMongoHookProps = {
  setPayNowClicked: React.Dispatch<React.SetStateAction<boolean>>;
  description: string; // transaction id
  line_items: [
    {
      amount: number;
      currency: 'PHP';
      description: string; // transaction id
      images: null;
      name: 'Air Ticket' | 'Hotel Ticket';
      quantity: number;
    }
  ];
  product: 'flights' | 'hotels';
  platform: 'web' | 'mobile';
  referenceNumber: string; // transaction id
  stage?: string;
};

/**
 * @description This is the PayMongoHook that is used to pay for the booking of flights and hotels.
 * @returns object
 */
export const PayMongoHook = () => {
  const pay = async ({ setPayNowClicked, description, line_items, product, platform, referenceNumber, stage }: payMongoHookProps) => {
    let apiResponse: AxiosResponse;
    let maxRedirects = 1;
    do {
      try {
        apiResponse = await axios.post(`${API_BASE_URL}/payments/checkout-session`, {
          description: description,
          line_items: line_items,
          product: product,
          platform: platform,
          referenceNumber: referenceNumber,
          stage: stage,
        });
      } catch (err: any) {
        // console.log('err: ', err);
        apiResponse = err.response;
        setPayNowClicked(false);
      }
      maxRedirects++;
    } while (apiResponse.status > 299 && maxRedirects <= 5);

    if (maxRedirects >= 5) {
      setPayNowClicked(false);
      alert('Error encountered in checkout session');
      return;
    }

    // return apiResponse.data.data.attributes;
    // checkout url: this is the pay mongo payment page
    const { checkout_url, payment_intent, reference_number } = apiResponse.data.data.attributes;

    return { checkout_url, payment_intent, reference_number };
  };

  return { pay };
};

/**
 * @deprecated
 * @description This is the old version of the PayMongoHook and will be removed in the future.
 * @returns object
 */
// export const PayMongoHookOld = () => {
//   const pay = async ({ setPayNowClicked, description, line_items, product, platform, referenceNumber }: payMongoHookProps) => {
//     let apiResponse: AxiosResponse;
//     let maxRedirects = 1;
//     do {
//       try {
//         apiResponse = await api.post('/payments/checkout-session', {
//           description: description,
//           line_items: line_items,
//           product: product,
//           platform: platform,
//           referenceNumber: referenceNumber,
//         });
//       } catch (err: any) {
//         // console.log('err: ', err);
//         apiResponse = err.response;
//         setPayNowClicked(false);
//       }
//       maxRedirects++;
//     } while (apiResponse.status > 299 && maxRedirects <= 5);

//     if (maxRedirects >= 5) {
//       setPayNowClicked(false);
//       alert('Error encountered in checkout session');
//       return;
//     }

//     // return apiResponse.data.data.attributes;
//     // checkout url: this is the pay mongo payment page
//     const { checkout_url, payment_intent, reference_number } = apiResponse.data.data.attributes;

//     return { checkout_url, payment_intent, reference_number };
//   };

//   return { pay };
// };
