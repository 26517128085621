import React from 'react';
import { create } from 'zustand';

export const DialogBoxStore = create((set: any) => ({
  dialogSize: 'lg' as 'sm' | 'lg' | 'xl' | undefined,
  setDialogSize: (size: 'sm' | 'lg' | 'xl' | undefined) => {
    set((state: { dialogSize: 'sm' | 'lg' | 'xl' | undefined }) => ({
      dialogSize: size,
    }));
  },
  dialogTitle: undefined as string | undefined,
  setDialogTitle: (title: string) => {
    set((state: { dialogTitle: string }) => ({
      dialogTitle: title,
    }));
  },
  showDialog: false,
  setShowDialog: (isShow: boolean) => {
    set((state: { showDialog: boolean }) => ({
      showDialog: isShow,
    }));
  },
  /**
   * return { <component> }
   */
  dialogMessage: null,
  /**
   * @param children as <component>
   */
  setDialogMessage: (children: React.ReactNode) => {
    set((state: { dialogMessage: React.ReactNode }) => ({
      dialogMessage: children,
    }));
  },
  center: true,
  setCenter: (isCenter: boolean) => {
    set((state: { center: boolean }) => ({
      center: isCenter,
    }));
  },
}));
