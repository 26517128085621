import NavBarTransparent from "../../../components/NavBarTransparent";
import { ChevronRightSvg } from "../../../resources/svg/ChevronRightSvg";
import { FacebookSvg } from '../../../resources/svg/FacebookSvg';
import { LinkedInSvg } from '../../../resources/svg/LinkedinSvg';
import {TwitterSvg} from '../../../resources/svg/TwitterSvg';
import {LinkSvg} from '../../../resources/svg/LinkSvg';
import React from "react";
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';



function CebuTours(){

    const [fbHover, setFbHover] = React.useState('#ADADAD');
    const [liHover, setLiHover] = React.useState('#ADADAD');
    const [twtHover, setTwtHover] = React.useState('#ADADAD');
    const [linkHover, setLinkHover] = React.useState('#ADADAD');

    return (
      <>
        <NavBarTransparent />

        <div className="hero_sec">
          <img
            src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/blogHeaderV2_Cebu_What+to+do.jpg"
            alt=""
            loading="lazy"
            onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
          />
          <div className="gradient_overlay">
            <h1>Top Destination</h1>

            <div className="bread_crumbs">
              <a href="/#/blogs">Blogs</a>
              <ChevronRightSvg _color="#ffffff" _width={20} _height={20} />
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                What to Do in Cebu: Top 10 Best Cebu Tours
              </a>
            </div>
          </div>
        </div>

        <div className="articleBody">
          <div className="articleHeader">
            <h1>What to Do in Cebu: Top 10 Best Cebu Tours</h1>

            <div className="info">
              <p className="authorSec">
                By <u>Anthea Reyes</u>
              </p>
              <p className="separator">•</p>
              <p className="articleDate">Oct 18, 2023</p>
              <p className="separator">•</p>
              <p className="article-read">8 min read</p>
            </div>

            <div className="shareBtnContainer">
              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setFbHover('#016E7F')} onMouseLeave={() => setFbHover('#ADADAD')}>
                <FacebookSvg _color={fbHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLiHover('#016E7F')} onMouseLeave={() => setLiHover('#ADADAD')}>
                <LinkedInSvg _color={liHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setTwtHover('#016E7F')} onMouseLeave={() => setTwtHover('#ADADAD')}>
                <TwitterSvg _color={twtHover} _width={24} _height={24} />
              </a>

              <a href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()} onMouseEnter={() => setLinkHover('#016E7F')} onMouseLeave={() => setLinkHover('#ADADAD')}>
                <LinkSvg _color={linkHover} _width={24} _height={24} />
              </a>
            </div>
          </div>

          <div className="content">
            <div className="articleDescription">
              <p>Cebu is one the most visited and popular destinations in the Philippines — and for good reason!</p>
              <p>
                This captivating urban tropical paradise offers an array of breathtaking landscapes, rich history, and vibrant culture. From the dazzling Cebu City to serene highlands and stunning
                islands, this destination has something to offer for every type of traveler.
              </p>
              <p>Find out where to go, where to eat, and what to do in Cebu through this guide. Let’s make the most of your trip to the Queen City of the South!</p>
            </div>

            <div className="img_container">
              <img
                src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/01+L-R+MJ+Tangonan%2C+Marty+Garcia%2C+lorenz+narbs+via+Unsplash.png"
                alt="Cebu Tours"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
              />
              <p>(Left to Right) Photo by MJ Tangonan, Marty Garcia, and lorenz narbs via Unsplash</p>
            </div>

            <div className="textual_story">
              <h2>What is Cebu known for?</h2>
              <p>
                For most Filipinos, Cebu is best known for its premium quality lechon. Every Filipino household knows that the best lechon for a top-tier party is one made with the unique roasting
                methods of Cebu. (So while in the province, you definitely have to try some!)
              </p>
              <p>
                This claim to fame is only bested by the province’s rich historical significance. It is home to Mactan Island, the site where legendary indigenous hero Lapu-Lapu fought and won against
                European colonizers. Because of this, Cebu is hailed as one of the cornerstones of Filipino bravery.
              </p>
              <p>
                More recently, however, this destination is becoming better known for its magnificent natural attractions and spectacular tourist spots. Travelers worldwide flock here to enjoy a swim
                with the colorful marine life of Moalboal and Oslob. Meanwhile, younger and more social media-savvy adventurers are captivated by some of the more Insta-worthy sites like the Temple of
                Leah and Sirao Garden.
              </p>
              <p>
                Beyond these popular spots though, Cebu captivates with more stunning landscapes and history-rich heritage sites. Keep on reading to find out how you can get to know Cebu on a whole
                new level!
              </p>
            </div>

            <div className="storieswithTitleImageParagraphs">
              <div className="head">
                <h2>Here's what to do in Cebu!</h2>

                <div className="head_desc">
                  <p>
                    Cebu was the first capital of the Philippines. Centuries in the making, this province now features a seamless blend of dynamic urban energy and enchanting nature-bound tranquility.
                  </p>
                  <p>To get the full experience of Cebu’s unique mix of history, nature, and adventure, be sure to add these remarkable experiences to your itinerary.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Cebu City Tour</h2>
                  <div className="img_container">
                    <img
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/02+Christian+Paul+del+Rosario+via+Pexels.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                      alt=""
                    />
                    <p>Photo by Christian Paul del Rosario via Pexels</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Embark on a Cebu City tour package to immerse yourself in the bustling heritage of the Queen City of the South.</p>
                  <p>
                    This day tour will take you through sites integral to the country’s Christianization. Some highlights that you’ll be stopping by are the iconic Magellan's Cross, the Pedro Calunsod
                    Church, and the grand Basilica Minore del Santo Niño, the oldest Roman Catholic church in the country.
                  </p>
                  <p>
                    This city tour also provides a perfect introduction to Cebu's history and culture with sites like the Yap-San Diego Ancestral House, the Heritage of Cebu monument, Colon street,
                    Fuente Osmena Circle, and Capitol site. There’s also the Running Tour of South Road properties.
                  </p>
                  <p>
                    Book your tour with GalaGO! and rest assured that your package will include air-conditioned transportation, an expert tour coordinator, a delicious lunch, and all entrance fees
                    covered.
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Highland Vista Tour</h2>
                  <div className="img_container">
                    <img
                      alt=""
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/03+John+Alvin+Merin.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by John Alvin Merin via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>
                    Want something away from the city's hustle and bustle? Try the Highland Vista Tour. This tour package offers a refreshing journey through lush and scenic sites. Savor the panoramic
                    views of the city and its neighboring islands from Tops Lookout. Feel the cool breeze as you visit the Temple of Leah, a majestic tribute to love and beauty. Connect with nature
                    and enjoy a much-needed breather among the flower fields of Sirao Garden. Along the way, you will also get to go resto-hopping through three of Cebu’s top restaurants — Top of
                    Cebu, Balay sa Busay, and La Vie in the Sky. All three restaurants offer delectable cuisine as well as spectacular views of the city and Cebu’s mountainside.
                  </p>
                  <p>With GalaGO! you can book this tour with air-conditioned transportation, an expert tour coordinator, and entrance fees included in the package.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Oslob Whale Shark Watching</h2>
                  <div className="img_container">
                    <img
                      alt=""
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/04+Cameron+Armstrong.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Cameron Armstrong via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Immerse yourself in an awe-inspiring adventure by visiting Oslob for whale shark watching.</p>
                  <p>
                    You’ll have to rise with the sun (quite literally) for this experience, but it will definitely be worth it when you witness the gentle giants up close. You’ll be snorkeling
                    alongside them in crystal-clear waters, giving you the chance to marvel at their grace and magnificence. This is one heart-stopping (but in a good way!) encounter that promises to
                    be etched in your memory forever.
                  </p>
                  <p>
                    You can find this experience in GalaGO! in a package that comes complete with air-conditioned transport, a tour coordinator, lunch at a local restaurant, the whale shark briefing
                    and permit, and a banka with a steward. All entrance fees including the whale shark viewing and snorkeling fee will already be covered as well.
                  </p>
                  <p>We will also take care of the hotel pickup and drop-off for you. But again, this will be an early morning experience with the pickup time being set at 3:00 AM.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Tumalog Falls</h2>
                  <div className="img_container">
                    <img
                      alt=""
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/05+Photo+by+SparksPH+via+Suroy.ph.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by SPARKSPH via Suroy.PH</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Tucked away in lush foliage, Tumalog Falls offers a tranquil oasis where you can unwind and rejuvenate.</p>
                  <p>
                    It features a stunning curtain of water cascading down rocky cliffs, creating an otherworldly sight (almost like it came out of the Avatar movie). At its bottom is a natural pool
                    where you can take a refreshing dip and relish the soothing embrace of nature's beauty.
                  </p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>A trip to this waterfall site can be added to your Oslob Whale Shark encounter as a duo package. As part of this experience, you can:</p>

                      <ul>
                        <li>take a running tour or go picture-taking at Baluarte</li>
                        <li>drop by Boljoon Church, and</li>
                        <li>visit the Carcar Shoe Expo and Cartel Studio (for that Cebu nightlife vibe).</li>
                      </ul>
                    </div>

                    <div className="bullet_list">
                      <p>Whether you book the Tumalog Falls experience as an exclusive or as part of a duo package, inclusions would cover:</p>

                      <ul>
                        <li>air-conditioned transportation</li>
                        <li>a trusted tour coordinator, entrance fees and</li>
                        <li>lunch at a local restaurant</li>
                      </ul>
                    </div>
                  </div>

                  <p>In case you choose the duo package with Oslob whale shark watching, all necessary permits and briefing for that experience will be covered as well.</p>
                  <p>Transportation includes pick-up and drop-off from Cebu City hotels. If your hotel is in Mactan, there is an additional fee of PHP 400 per head.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Sumilon Sandbar</h2>
                  <div className="img_container">
                    <img
                      alt=""
                      className="image"
                      src=" https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/06+Hitoshi+Namura.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Hitoshi Namura via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>If you're looking for more surreal natural sites, then we recommend a venture to Sumilon Island to walk the Sumilon Sandbar.</p>
                  <p>
                    As the tides change, you’ll get to witness (in real time) the magical transformation of the sandbar's shape and size. And while you’re there, you can also explore the island’s
                    vibrant marine life through snorkeling its pristine waters.
                  </p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>On its own, the Sumilon Sandbar experience that you can book on GalaGO! includes:</p>

                      <ul>
                        <li>a side trip to Tumalog Falls</li>
                        <li>the running tour or a picture taking session at Baluarte</li>
                        <li>a visit to Boljoon Church, and</li>
                        <li>a drop by at the lively Cartel and Carcar Shoe Expo.</li>
                      </ul>

                      <p>Just like the Tumalog Falls, you can also add the Sumilon Sandbar to the Oslob Whale Shark encounter as a duo package.</p>
                    </div>

                    <div className="bullet_list">
                      <p>Whether you book the Sumilon Sandbar experience as an exclusive or as part of a duo package, inclusions would cover:</p>

                      <ul>
                        <li>air-conditioned transportation,</li>
                        <li>a trusted tour coordinator,</li>
                        <li>entrance fees, and</li>
                        <li>lunch at a local restaurant.</li>
                      </ul>
                    </div>
                  </div>

                  <p>In case you choose the duo package with Oslob whale shark watching, all necessary permits and briefing for that experience will be covered as well.</p>
                  <p>Transportation includes pick-up and drop-off from Cebu City hotels. If your hotel is in Mactan, there is an additional fee of PHP 400 per head.</p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Moalboal Island Hopping</h2>
                  <div className="img_container">
                    <img
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/07+Olga+ga.png"
                      loading="lazy"
                      alt=""
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Olga ga via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>For those looking to indulge in an island-hopping adventure, Moalboal is the must-visit Cebu destination for you.</p>
                  <p>
                    Here, you can discover a vibrant underwater world. Whether you choose to snorkel or dive, you’re sure to witness a kaleidoscope of marine life, including schools of sardines,
                    fascinating sea turtles, and other tropical fish.
                  </p>
                  <p>
                    The highlights of this water-bound adventure include a visit to Turtle Point, an exploration of Pescador Island, and of course, an encounter with the famous Sardine Run. Of all
                    these, Pescador Island in particular is renowned for its diverse marine ecosystem and thrilling underwater landscapes.
                  </p>
                  <p>Moalboal island hopping can also be added to the Oslob Whale Shark encounter as a duo package when you book with GalaGO!</p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>Whether you book this experience as an exclusive or as part of a duo package, inclusions would cover:</p>

                      <ul>
                        <li>air-conditioned transportation</li>
                        <li>trusted tour coordinator</li>
                        <li>entrance fees, and</li>
                        <li>lunch at a local restaurant</li>
                      </ul>

                      <p>In case you choose the duo package with Oslob whale shark watching, all necessary permits and briefing for that experience will be covered as well.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Simala Shrine</h2>
                  <div className="img_container">
                    <img
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/08+Ralph+Olazo.png"
                      loading="lazy"
                      alt=""
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Ralph Olazo via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Are you traveling to discover yourself? Then we suggest embarking on a spiritual journey to the Simala Shrine.</p>
                  <p>
                    This captivating place of worship boasts ornate architecture, creating a beautiful home for a magnificent sculpture of the Blessed Virgin Mary (along with other celebrated figures
                    within the Catholic faith).
                  </p>
                  <p>There’s also an impressive garden that looks straight out of a Bridgerton set. There’s even a man-made lake and a gazebo!</p>
                  <p>
                    The intricate details of the shrine’s sprawling structure and the serene ambience make it a must-visit destination, whether you're a devout believer or simply seeking cultural
                    exploration.
                  </p>
                  <p>
                    You can visit Simala Shrine on its own or you can add it to your Cebu City Tour via booking with GalaGO! Through the latter, you’ll be able to secure your air-conditioned
                    transportation, tour coordinator, entrance fees, and lunch with bottled water with just one platform!
                  </p>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Twin City Tour: Cebu City and Mactan</h2>
                  <div className="img_container">
                    <img
                      className="image"
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/09+Bei+Ayson+via+unsplash.png"
                      loading="lazy"
                      alt=""
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Bei Ayson via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>If you want your trip to give you a taste of both the old and the new in Cebu, the Twin City Tour is the experience for you.</p>
                  <p>With this tour, you get to dive into the vibrant contrast of modernity and history encompassing Cebu City and Mactan.</p>
                  <p>
                    By choosing this experience, you’ll get to visit all the highlights of the Cebu City Tour. And then you’ll cross the bridge to Mactan, where you can visit the Lapu-Lapu Monument,
                    Magellan’s Shrine, and the Alegre Guitar Factory.
                  </p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>The inclusions of the Twin City Tour with GalaGO! are:</p>

                      <ul>
                        <li>air-conditioned transportation</li>
                        <li>tour coordinator</li>
                        <li>entrance fees, and</li>
                        <li>lunch with bottled water</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Cebu City and 10,000 Roses Tour</h2>
                  <div className="img_container">
                    <img className="image" alt="" src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/10+Shelly+Mae+via+Shellwanders.com.png" />
                    <p>Photo by Shelly Mae via shellwanders.com</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>For the romantics (single or taken), you can experience a fairytale-like ambiance at the 10,000 Roses Café.</p>
                  <p>
                    Here, there are thousands of LED roses waiting to illuminate the night sky for you. During the day, the roses are a stunning sight of stark white roses set against the backdrop of
                    a wide open sky. As the sun sets, the garden comes alive with a magical glow, making it a perfect spot for romantic strolls and stunning photographs.
                  </p>
                  <p>Past the field of technical and fantastical flowers is the cafe, where you can enjoy a delicious meal with a view of the coastline.</p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>You can add this spot as a highlight to your Cebu City Tour via GalaGO! This experience will include your:</p>

                      <ul>
                        <li>air-conditioned transportation</li>
                        <li>tour coordinator</li>
                        <li>entrance fees, and</li>
                        <li>lunch with bottled water</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="story">
                <div className="head">
                  <h2>Mactan Island Hopping</h2>
                  <div className="img_container">
                    <img
                      className="image"
                      alt=""
                      src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/What+to+do+in+Cebu/11+Jessa+Marie+Pedrola.png"
                      loading="lazy"
                      onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                    />
                    <p>Photo by Jessa Marie Pedrola via Unsplash</p>
                  </div>
                </div>

                <div className="info-grp">
                  <p>Conclude your Cebu adventure with an island-hopping escapade around Mactan's picturesque coastline.</p>
                  <p>Here you can explore a vibrant underwater world while snorkeling or diving in the immaculate aquamarine waters of Hilutungan Marine Sanctuary and San Vicente Marine Sanctuary.</p>
                  <p>
                    You’ll also get ample time to revel in the natural beauty of the islands and beaches that make Mactan a tropical paradise. One that stands out is Caohagan Island, an idyllic beach
                    getaway with fine white sand and pristine shallow waters ideal for a relaxing swim.
                  </p>

                  <div className="bullet_grp">
                    <div className="bullet_list">
                      <p>Book your Mactan Island Hopping with GalaGO! and enjoy the following inclusions:</p>

                      <ul>
                        <li>air-conditioned transportation to port</li>
                        <li>private motorized boat ride</li>
                        <li>island barbecue lunch with drink</li>
                        <li>steward services</li>
                        <li>snorkel sets (mask and fins)</li>
                        <li>life jackets</li>
                        <li>island entrance fees</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footnote">
              <div className="title_container">
                <h2>What Cebu promises</h2>
              </div>

              <div className="info">
                <p>Cebu beckons with a diverse array of attractions that cater to your every desire — for adventure, culture, and relaxation.</p>
                <p>
                  Whether you choose to explore Cebu's rich history, indulge in its natural beauty, or immerse yourself in its thriving culture (or all of the above!), this destination promises to
                  inspire moments and memories that will linger long after your journey home.
                </p>
                <p>The experiences outlined in this guide helps fulfill that promise and make sure to make your trip worth it.</p>
                <p>Book your Cebu experience/s with GalaGO! now to make sure that you explore the best of the province with the least amount of stress or hassle (or FOMO)!</p>
              </div>
            </div>
          </div>

          <hr />

          <div className="featured_blogs">
            <h2>Featured Blogs</h2>
            <div className="card_container">
              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Bohol_GalaGO!+Guide.jpg"
                    alt="Discover the Best Things to Do in Bohol All in One Tour!"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Discover the Best Things to Do in Bohol All in One Tour!</h3>

                  <a href="/#/discover-the-best-things-to-do-in-bohol-all-in-one-tour" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog_card col-md-6 col-12">
                <div className="img_container">
                  <img
                    src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/GalaGo+Web+Assets/Blog+Images/Blog+Headers/Updated+travel+requirements+in+the+philippines.jpg"
                    alt="Guide to Travel Requirements in the Philippines"
                    loading="lazy"
                    onError={(e) => (e.currentTarget.src = '/img/missing-img.png')}
                  />

                  <div className="badge">
                    <h2>Travel Tip</h2>
                  </div>
                </div>

                <div className="info_grp">
                  <div className="detail">
                    <p className="date"> September 25, 2023</p>
                    <p className="separator">·</p>
                    <p className="duration">6 min read</p>
                  </div>

                  <h3>Guide to Travel Requirements in the Philippines</h3>

                  <a href="/#/guide-to-travel-requirements-in-the-philippines" className="read_article_btn">
                    <div className="read_btn">
                      <p>Read Article</p>
                      <ArrowRightSvg _color="#016E7F" _width={34} _height={14} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default CebuTours