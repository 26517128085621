import React from 'react';
// import BookingNavbar from '../Booking/BookingNavbar';
import Footer from '../Footer';
import HotelConfirmationProgress3 from './HotelConfirmationProgress3';
import HotelBookingConfirmationContainer from '../../pages/book/hotels/HotelBookingConfirmedContainer';
// import NavBarTransparent from '../NavBarTransparent';
import { NavBar } from '../NavBar';

type HotelBookingConfirmedPageProps = {
  platform?: string | null;
  paymentReference?: string | null;
};

const HotelBookingConfirmedPage = ({ platform, paymentReference }: HotelBookingConfirmedPageProps) => {
  return (
    <>
      <NavBar />
      <main className="flex flex-col items-center justify-center" >
        {/* <BookingNavbar /> */}
        <HotelConfirmationProgress3 />
        <HotelBookingConfirmationContainer platform={platform} paymentReference={paymentReference} />
      </main>
      <div className="p-5"></div>
      <Footer />
    </>
  );
};

export default HotelBookingConfirmedPage;
