import axios, { AxiosResponse } from 'axios';
import React from 'react';
import { getTransactionDetails } from '../utils/apiRoutes';

export const HotelHistoryInformationHooks = (id: string) => {
  const [hotelDetails, setHotelDetails] = React.useState<{ [index: string]: any }>([]);

  React.useEffect(() => {
    getHotelDetails();
    // eslint-disable-next-line
  }, []);

  const getHotelDetails = async () => {
    try {
      const response: AxiosResponse = await axios.get(getTransactionDetails(id));
      setHotelDetails(response.data.data);
      return response;
    } catch (error) {
      const { response } = error as { response: AxiosResponse };
      setHotelDetails(response.data);
      return response;
    }
  };

  return { hotelDetails };
};
