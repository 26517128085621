import { SvgProps } from './SvgProps';

export const CalendarSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_width} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="calendar">
        <path id="Vector" d="M4 1V2.5" stroke={_color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M8 1V2.5" stroke={_color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M1.75 4.54492H10.25" stroke={_color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path
          id="Vector_4"
          d="M10.5 4.25V8.5C10.5 10 9.75 11 8 11H4C2.25 11 1.5 10 1.5 8.5V4.25C1.5 2.75 2.25 1.75 4 1.75H8C9.75 1.75 10.5 2.75 10.5 4.25Z"
          stroke={_color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path id="Vector_5" d="M7.84735 6.84961H7.85184" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_6" d="M7.84735 8.34961H7.85184" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_7" d="M5.99774 6.84961H6.00223" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_8" d="M5.99774 8.34961H6.00223" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_9" d="M4.14716 6.84961H4.15165" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_10" d="M4.14716 8.34961H4.15165" stroke={_color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
