import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@nextui-org/react';
import { useInternationalBooking } from '../../../store/InternationalStore';
import ModalTemplate from '../../../components/ui/ModalTemplate';
import BookingModal from './BookingModal';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import moment from 'moment';
import { FlightBookingHooks } from '../../../hooks/FlightBookingHooks';
import { HTTP_OK } from '../../../utils/httpResponse.utils';
import { ChevronDownSvg } from '../../../resources/svg/ChevronDownSvg';
import { MystiflyFlightSegmentHook } from '../../../hooks/MystiflyFlightSegmentHook';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { GalagoButton } from '../../../components/Button';
import { StoparrowSVG } from '../../../resources/svg/StoparrowSvg';
import BookingVerification from './BookingVerification';
import BookFlightStepsProgressBar from './BookFlightStepsProgressBar';
import { GalagoDialogStore } from '../../../store/GalagoDialogStore';

type BookingSummaryContainerProps = {
  Flight: { [index: string]: any };
  FlightType: 'one-way' | 'round-trip';
  SelectedFlight: { [index: string]: any };
  ConversationId: string;
  TransactionId: string;
  Passengers: { [index: string]: any };
  Contacts: { [index: string]: any };
  departureItinerary?: { [index: string]: any };
  returnItinerary?: { [index: string]: any };
  PassengerCount: number | string;
  AdultCount: number | string;
  ChildrenCount: number | string;
  InfantCount: number | string;
};

const BookingSummaryContainer = ({
  Flight,
  FlightType,
  SelectedFlight,
  Passengers,
  Contacts,
  ConversationId,
  TransactionId,
  departureItinerary,
  returnItinerary,
  PassengerCount,
  AdultCount,
  ChildrenCount,
  InfantCount,
}: BookingSummaryContainerProps) => {
  // const imageApi = ASSETS_API().public.images;
  // hooks
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { bookingPayment, paymentProceed } = useInternationalBooking();
  const { flightFares } = FlightResultsHook();
  const { validateFareSourceCodeV2, galagoPreBookingV2 } = FlightBookingHooks();
  const { setGalagoErrorDialogMessage, setShowGalagoErrorDialog, setGalagoErrorDialogTitle, setGalagoErrorButtonLabel, setGalagoErrorButtonOnClick } = GalagoDialogStore();
  // states
  // const [showDepartureSummary, setShowDepartureSummary] = useState<boolean>(false);
  // const [showReturnSummary, setShowReturnSummary] = useState<boolean>(false);
  const [preBookDetails, setPreBookDetails] = useState<{ [index: string]: any }>();
  const [disablePaymentButton, setDisablePaymentButton] = useState(true);
  // const [payload, setPayload] = useState<{ [index: string]: any }>();

  const flightType = FlightType; // one-way | round-trip
  const flightDetails = SelectedFlight?.flight;

  const fareSourceCode = flightDetails.FareSourceCode;
  const conversationId = ConversationId;
  const transactionId = TransactionId;
  // const provider = flightDetails.Provider;
  const provider = 'MYSTIFLY';

  useEffect(() => {
    // mystifly pre-booking
    const response = validateFareSourceCodeV2({ flightType, fareSourceCode, conversationId });
    response.then((e: { [index: string]: any }) => {
      if (e.statusCode === HTTP_OK()) {
        setPreBookDetails(e);

        let galagoPreBookingPayload = {
          fareRules: e.data.fareRules,
          revalidation: e.data.revalidation,
        };

        galagoPreBookingV2(flightType, {
          transactionId: transactionId,
          flightType: flightType,
          provider: provider,
          providerDetails: galagoPreBookingPayload,
        }).then((e: { [index: string]: any }) => {
          setDisablePaymentButton(false);
        });
      } else {
        setDisablePaymentButton(false);
        setGalagoErrorDialogTitle('Sorry, this flight is no longer available');
        setGalagoErrorDialogMessage(
          "Don't worry, flights fill up quickly! We update our availability in real-time. We recommend searching for flights with similar travel times or adjusting your travel dates."
        );
        setGalagoErrorButtonLabel('Find New Flights');
        setShowGalagoErrorDialog(true);
        setGalagoErrorButtonOnClick(() => {
          setShowGalagoErrorDialog(false);
          window.history.go(-2);
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  const { segments } = MystiflyFlightSegmentHook();
  const { departureFlightSegmentDetails, departureAirline, returnFlightSegmentDetails, returnAirline } = segments({ FlightType: flightType, FlightDetails: Flight });

  const FlightFare = flightFares(flightDetails.FlightFares);
  const adultPrice = FlightFare?.adult.adultBaseFare * FlightFare?.passengers.adultQuantity;
  const childPrice = FlightFare?.child.childBaseFare * FlightFare?.passengers.childQuantity;
  const infantPrice = FlightFare?.infant.infantBaseFare * FlightFare?.passengers.infantQuantity;
  const totalBaseFare = adultPrice + childPrice + infantPrice;
  const adultTax = FlightFare?.adult.adultTax * FlightFare?.passengers.adultQuantity;
  const childTax = FlightFare?.child.childTax * FlightFare?.passengers.childQuantity;
  const infantTax = FlightFare?.infant.infantTax * FlightFare?.passengers.infantQuantity;
  const totalTax = adultTax + childTax + infantTax;

  const cabinClassAlt = (CabinClassCode: string) => {
    switch (CabinClassCode) {
      case 'Y':
        return 'Economy';
      case 'C':
        return 'Business';
      case 'F':
        return 'First';
      case 'S':
        return 'Premium Economy';
      default:
        return 'Undefined Class';
    }
  };

  const departureCabinClass = cabinClassAlt(departureItinerary?.CabinClassCode);
  const returnCabinClass = cabinClassAlt(returnItinerary?.CabinClassCode);
  //For Toggling Total Base Fare
  const { isTotalBaseFareClicked, toggleTotalBaseFare } = useInternationalBooking();

  return (
    <>
      <BookFlightStepsProgressBar />
      <div className="web-details">
        <section className="">
          <div style={{ paddingBottom: '32px' }}>
            <div className="booking-summary-title d-flex flex-column gap-2" style={{ padding: '10px 0' }}>
              <b style={{ fontSize: 34, fontWeight: '700', color: '#333' }}>Booking Summary</b>
              <p className="m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                Review your booking before proceeding to payment
              </p>
            </div>
          </div>
          <div>
            <div className="">
              <div className="bookingCard">
                <div className="card-body">
                  {/* departure flight details*/}
                  <div className="">
                    <div className="">
                      <p className="tealtext m-0 py-1 generalsans-medium">Departure</p>
                    </div>
                    <div className="">
                      <p className="pb-1 m-0" style={{ fontSize: 20, fontWeight: 600 }}>
                        {departureFlightSegmentDetails?.departureCode} - {departureFlightSegmentDetails?.arrivalCode}
                      </p>

                      <div className="d-flex align-items-center gap-2  py-0">
                        <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                          {moment(departureFlightSegmentDetails?.departureDateTime).format('MMM DD, YYYY')}
                        </div>
                        <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                          -
                        </div>
                        <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                          {moment(departureFlightSegmentDetails?.arrivalDateTime).format('MMM DD, YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="flightContainer" style={flightType === 'round-trip' ? { padding: '32px 0 20px 0' } : { padding: '32px 0' }}>
                      <div className="d-flex justify-between align-items-center">
                        <div className="d-flex gap-3 align-items-center">
                          <div className="d-flex gap-2 flex-row align-items-center">
                            <img className=" w-[24px] h-[24px] object-cover rounded-full" alt="" loading="lazy" src={departureAirline?.logo} />
                            <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 14, color: '#333' }}>
                              {departureAirline?.name}
                            </p>
                          </div>

                          <p className="generalsans-medium text-center" style={{ fontSize: 16, color: '#ADADAD' }}>
                            {departureFlightSegmentDetails?.MarketingCarriercode} <span className="px-0.5">·</span>{' '}
                            {departureFlightSegmentDetails?.MarketingFlightNumber ? `${departureFlightSegmentDetails?.MarketingFlightNumber}` : ''}
                            <span className="px-1">·</span>
                            {departureFlightSegmentDetails?.equipment ? `${departureFlightSegmentDetails?.equipment}` : ''}
                            {departureCabinClass ? `${departureCabinClass}` : ''}
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-between py-6 border-b-[1px] border-solid border-color-shades-grey-700">
                        <div className="d-flex flex-col justify-center gap-0.5">
                          <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 20, fontWeight: 600, color: '#333' }}>
                            {departureFlightSegmentDetails?.departureTime}
                          </p>
                          <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                            {departureFlightSegmentDetails?.departureCode}
                          </p>
                        </div>

                        <div className="d-flex flex-column gap-0.5 text-center">
                          <p className="generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                            {departureFlightSegmentDetails?.flightDuration}
                          </p>

                          <StoparrowSVG />

                          <p style={{ fontSize: 16, fontWeight: 500, color: '#ADADAD' }}>{departureFlightSegmentDetails?.stops}</p>
                        </div>

                        <div className="d-flex flex-col justify-center gap-0.5 align-items-end">
                          <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 20, fontWeight: 600, color: '#333' }}>
                            {departureFlightSegmentDetails?.arrivalTime}
                          </p>
                          <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                            {departureFlightSegmentDetails?.arrivalCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* return flight details */}
                  {flightType === 'round-trip' ? (
                    <div className="">
                      <div className="">
                        <p className="tealtext m-0 py-1 generalsans-regular">Return</p>
                      </div>
                      <div className="">
                        <p className="pb-1 m-0" style={{ fontSize: 20, fontWeight: 600 }}>
                          {returnFlightSegmentDetails?.departureCode} - {returnFlightSegmentDetails?.arrivalCode}
                        </p>

                        <div className="d-flex align-items-center gap-2  py-0">
                          <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                            {moment(returnFlightSegmentDetails?.departureDateTime).format('MMM DD, YYYY')}
                          </div>
                          <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                            -
                          </div>
                          <div className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#333' }}>
                            {moment(returnFlightSegmentDetails?.arrivalDateTime).format('MMMM DD, YYYY')}
                          </div>
                        </div>
                      </div>
                      <div className="flightContainer" style={flightType === 'round-trip' ? { padding: '32px 0 20px 0' } : { padding: '32px 0' }}>
                        <div className="d-flex justify-between align-items-center">
                          <div className="d-flex gap-3 align-items-center">
                            <div className="d-flex gap-2 flex-row align-items-center">
                              <img className=" w-[24px] h-[24px] object-cover rounded-full" alt="" loading="lazy" src={returnAirline?.logo} />
                              <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 14, color: '#333' }}>
                                {returnAirline?.name}
                              </p>
                            </div>

                            <p className="generalsans-medium text-center" style={{ fontSize: 16, color: '#ADADAD' }}>
                              {returnFlightSegmentDetails?.MarketingCarriercode} <span className="px-0.5">·</span>{' '}
                              {returnFlightSegmentDetails?.MarketingFlightNumber ? `${returnFlightSegmentDetails?.MarketingFlightNumber}` : ''}
                              <span className="px-1">·</span>
                              {returnFlightSegmentDetails?.equipment ? `${returnFlightSegmentDetails?.equipment}` : ''}
                              {returnCabinClass ? `${returnCabinClass}` : ''}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex justify-between py-6 border-b-[1px] border-solid border-color-shades-grey-700">
                          <div className="d-flex flex-col justify-center gap-0.5">
                            <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 20, fontWeight: 600, color: '#333' }}>
                              {returnFlightSegmentDetails?.departureTime}
                            </p>
                            <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                              {returnFlightSegmentDetails?.departureCode}
                            </p>
                          </div>

                          <div className="d-flex flex-column gap-0.5 text-center">
                            <p className="generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                              {returnFlightSegmentDetails?.flightDuration}
                            </p>

                            <StoparrowSVG />

                            <p style={{ fontSize: 16, fontWeight: 500, color: '#ADADAD' }}>{returnFlightSegmentDetails?.stops}</p>
                          </div>

                          <div className="d-flex flex-col justify-center gap-0.5">
                            <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 20, fontWeight: 600, color: '#333' }}>
                              {returnFlightSegmentDetails?.arrivalTime}
                            </p>
                            <p className="p-0 m-0 generalsans-medium" style={{ fontSize: 16, color: '#ADADAD' }}>
                              {returnFlightSegmentDetails?.arrivalCode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* price overview */}
                  <div className="price-overview d-flex flex-column gap-3">
                    <p className="generalsans-medium text-primary py-1">Price Overview</p>

                    <div className="d-flex flex-row justify-between" onClick={toggleTotalBaseFare}>
                      <div className="d-flex flex-column">
                        <div className="basefare-container flex flex-row gap-[8px] items-start">
                          <span className="textColor generalsans-medium text-base">Total Base Fare</span>
                          <span className={`${isTotalBaseFareClicked && 'rotate-180'}`}>
                            <ChevronDownSvg _color="#ADADAD" _height={14} _width={14} />
                          </span>
                        </div>
                        <div className="text-start textColor generalsans-regular text-base">
                          ({' '}
                          <span className="generalsans-medium text-sm">
                            {PassengerCount} Passenger{+PassengerCount > 1 ? 's' : null} ){' '}
                          </span>
                        </div>
                      </div>

                      <div className="relative leading-[1.31rem] font-medium font-footnote textColor generalsans-medium text-base">
                        {FlightFare?.currency} {formatPriceNoRound(totalBaseFare)}
                      </div>
                    </div>

                    {isTotalBaseFareClicked && (
                      <div className="flex flex-column gap-1 text-start text-secondary generalsans-medium text-sm">
                        {+AdultCount > 0 && (
                          <div className="flex flex-row justify-between">
                            <span>{+AdultCount > 1 ? `Adults x ${AdultCount}` : `Adult x ${AdultCount}`}</span>
                            <span>
                              {FlightFare?.currency} {formatPriceNoRound(adultPrice)}
                            </span>
                          </div>
                        )}

                        {+ChildrenCount > 0 && (
                          <div className="flex flex-row justify-between">
                            <span>{+ChildrenCount > 1 ? `Children x ${ChildrenCount}` : `Child x ${ChildrenCount}`}</span>
                            <span>
                              {FlightFare?.currency} {formatPriceNoRound(childPrice)}
                            </span>
                          </div>
                        )}

                        {+InfantCount > 0 && (
                          <div className="flex flex-row justify-between">
                            <span>{+InfantCount > 1 ? `Infants x ${InfantCount}` : `Infant x ${InfantCount}`}</span>
                            <span>
                              {FlightFare?.currency} {formatPriceNoRound(infantPrice)}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="flex flex-row justify-between items-center self-stretch">
                      <span className="textColor generalsans-medium text-base">Taxes and Fees</span>
                      <div className="relative leading-[1.31rem] font-footnote textColor generalsans-medium text-base">
                        {FlightFare?.currency} {formatPriceNoRound(totalTax)}
                      </div>
                    </div>
                  </div>

                  {/* OLD CODE */}
                  {/* <div className="d-none">
                    <div className="">
                      <div className="">
                        <div className="d-flex justify-between" onClick={() => setShowDepartureSummary((prev) => !prev)}>
                          <p className="tealtext" style={{ fontSize: 17, fontWeight: 500 }}>
                            Price Breakdown
                          </p>
                          <div className="d-flex gap-2">
                            <p style={{ fontSize: 17, fontWeight: 500 }}>
                              {FlightFare?.currency} {formatPriceNoRound(FlightFare?.flightFareGrandTotal)}
                            </p>

                            <span className={`${showDepartureSummary && 'rotate-180'}`}>
                              <ChevronTriangleDownSvg _color="#016E7F" />
                            </span>
                          </div>
                        </div>

                        <div className="" style={{ fontSize: 17, fontWeight: 500, color: '#ADADAD' }}>
                          <div className="d-flex justify-between  py-1">
                            <p className="">Baggage: No Additional Baggage</p>
                            <p className="">PHP 0.00</p>
                          </div>
                          <div className="d-flex justify-between  py-1">
                            <p className="">Seat: Random</p>
                            <p className="">PHP 0.00</p>
                          </div>
                          <div className="d-flex justify-between  py-1">
                            <p className="">Meal: Not Included</p>
                            <p className="">PHP 0.00</p>
                          </div>
                          <div className="d-flex justify-between  py-1">
                            <p className="">Taxes and Fees</p>
                            <p className="">PHP 192.00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* {showDepartureSummary && (
                    <div className="" style={{ fontSize: 17, fontWeight: 500, color: '#ADADAD' }}>
                      <div className="d-flex justify-between py-1">
                        <p className="">Base Fare</p>
                        <p className="">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.baseFareGrandTotal)}
                        </p>
                      </div>

                      <div className="d-flex justify-between  py-1">
                        <p className="">Taxes</p>
                        <p className="">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.taxesGrandTotal + FlightFare?.markedPriceGrandTotal)}
                        </p>
                      </div>

                      <div className="d-flex justify-between  py-1">
                        <p className="">Total Taxes and Fees</p>
                        <p className="">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.flightFareGrandTotal)}
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>

                <div className="d-flex justify-between bookingPrice ">
                  <p className="" style={{ fontSize: 16, fontWeight: 500, color: '#fff' }}>
                    Total Price
                  </p>
                  <p className="" style={{ fontSize: 20, fontWeight: 600, color: '#fff' }}>
                    {FlightFare.currency} {formatPriceNoRound(totalBaseFare + totalTax)}
                  </p>
                </div>
              </div>
            </div>

            {/* <BtnGrp /> */}
            <div className="galagoButton d-flex gap-2 flex-sm-col flex-lg-row">
              <GalagoButton
                onClick={() => {
                  window.history.back();
                }}
                size="xl"
                btn="light"
                label="Back"
                style={{ width: '100%' }}
              />

              <GalagoButton
                onClick={() => {
                  // saveBookingFlight(payload);
                  paymentProceed();
                  onOpen();
                }}
                // disabled={disablePaymentButton}
                size="xl"
                // _className={disablePaymentButton ? 'btn-secondary' : `btn-primary`}
                btn={'primary'}
                label={'Proceed to Payment'}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          {disablePaymentButton ? <style>{`body{overflow: hidden;}`}</style> : null} {disablePaymentButton ? <BookingVerification /> : null}
          {/* <BtnGrp /> */}
          {/* <div className="self-stretch flex flex-row items-start justify-start gap-[0.88rem] text-center text-[1rem] text-background-colors-surface-secondary font-callout">
            <GalagoButton
              onClick={() => {
                window.history.back();
              }}
              _className="btn-light btn-xl"
              label="Back"
            />

            <GalagoButton
              onClick={() => {
                // saveBookingFlight(payload);
                paymentProceed();
                onOpen();
              }}
              disabled={disablePaymentButton}
              _className={disablePaymentButton ? 'btn-secondary' : `btn-primary`}
              label={disablePaymentButton ? 'Verifying Flight' : 'Proceed to Payment'}
            ></GalagoButton>
          </div> */}
        </section>

        {bookingPayment && (
          <ModalTemplate isOpen={isOpen} onOpenChange={onOpenChange} optionalClassName="h-[25.63rem] w-[25.63rem]">
            <BookingModal
              onClose={onClose}
              totalAmount={totalBaseFare + totalTax}
              preBookDetails={preBookDetails?.data}
              SelectedFlight={SelectedFlight}
              TransactionId={transactionId}
              Passengers={Passengers}
              Contacts={Contacts}
            />
          </ModalTemplate>
        )}
      </div>
    </>
  );
};

export default BookingSummaryContainer;
