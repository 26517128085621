import { create } from 'zustand';

export const FlightDates = create((set: any) => ({
  departingDate: '',
  setDepartingDate: (string: string) => {
    set((state: { departingDate: string }) => ({
        departingDate: string,
    }));
  },
  returningDate: '',
  setReturningDate: (string: string) => {
    set((state: { returningDate: string }) => ({
        returningDate: string,
    }));
  },

}));
