import { SvgProps } from './SvgProps';

export const CloseCircle = ({ _width = 32, _height = 32, _color = '#fff' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0003 29.3327C23.3337 29.3327 29.3337 23.3327 29.3337 15.9993C29.3337 8.66602 23.3337 2.66602 16.0003 2.66602C8.66699 2.66602 2.66699 8.66602 2.66699 15.9993C2.66699 23.3327 8.66699 29.3327 16.0003 29.3327Z"
        stroke={_color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.2266 19.7732L19.7732 12.2266" stroke={_color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.7732 19.7732L12.2266 12.2266" stroke={_color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const CloseSvg = ({ _width = 32, _height = 32, _color = '#fff' }: SvgProps) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.625 10.375L10.375 1.625" stroke="#016E7F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.375 10.375L1.625 1.625" stroke="#016E7F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
