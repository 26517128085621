import React from 'react';
import { ASSETS_API } from '../../../utils/apiRoutes';
import { ArrowRightSvg } from '../../../resources/svg/ArrowSvg';
import { StoparrowSVG } from '../../../resources/svg/StoparrowSvg';
import { AirplaneAscendSvg } from '../../../resources/svg/AirplanesSvg';

type BookingDetailsProps = {
  FlightType: 'one-way' | 'round-trip';
  Flight: { [index: string]: any };
  FromAirportCode?: string;
  ToAirportCode?: string;
  DepartureFlightSegmentDetails: { [index: string]: any } | undefined;
  ReturnFlightSegmentDetails: { [index: string]: any } | undefined;
  DepartureAirline: { [index: string]: any } | undefined;
  ReturnAirline: { [index: string]: any } | undefined;
  departureItinerary?: { [index: string]: any } | undefined;
  returnItinerary?: { [index: string]: any } | undefined;
};

const BookingDetails = ({
  FlightType,
  Flight,
  FromAirportCode,
  ToAirportCode,
  DepartureFlightSegmentDetails,
  ReturnFlightSegmentDetails,
  DepartureAirline,
  ReturnAirline,
  departureItinerary,
  returnItinerary,
}: BookingDetailsProps) => {
  const imageApi = ASSETS_API().public.images;
  // const { isOneWay, isRoundTrip, isDomestic } = useInternationalBooking();

  const flightType = FlightType;
  // const flightDetails = Flight;

  // const { segments } = MystiflyFlightSegmentHook();
  // const { departureFlightSegmentDetails, departureAirline, returnFlightSegmentDetails, returnAirline } = segments({ FlightType: flightType, FlightDetails: flightDetails });

  const departureFlightSegmentDetails = DepartureFlightSegmentDetails;
  const departureAirline = DepartureAirline;
  const returnFlightSegmentDetails = ReturnFlightSegmentDetails;
  const returnAirline = ReturnAirline;
  const deptTime = DepartureFlightSegmentDetails?.departureDate;
  const retTime = ReturnFlightSegmentDetails?.arrivalDate;

  // const deptItinerary = departureItinerary;
  // const retItinerary = returnItinerary;

  // slice
  const departureDateParts = deptTime.split(',');
  const day = departureDateParts[0]; // "Sat"
  const month = departureDateParts[1]; // "Mar"
  const date = departureDateParts[2]; // "23"

  let rday, rmonth, rdate;
  if (retTime !== undefined) {
    const returnDateParts = retTime.split(',');
    rday = returnDateParts[0]; // "Sat"
    rmonth = returnDateParts[1]; // "Mar"
    rdate = returnDateParts[2]; // "23"
  }

  //stops
  // const stopsText =
  //   departureFlightSegmentDetails?.stops === 'Direct' ||
  //   departureFlightSegmentDetails?.stops === '1 Stops' ||
  //   departureFlightSegmentDetails?.stops === '2 Stops' ||
  //   departureFlightSegmentDetails?.stops === '3 Stops' ||
  //   departureFlightSegmentDetails?.stops === '4 Stops' ||
  //   departureFlightSegmentDetails?.stops === '5 Stops'
  //     ? `:${departureFlightSegmentDetails?.stops}`
  //     : null;

  //cabinClass
  const cabinClassAlt = (CabinClassCode: string) => {
    switch (CabinClassCode) {
      case 'Y':
        return 'Economy';
      case 'C':
        return 'Business';
      case 'F':
        return 'First';
      case 'S':
        return 'Premium Economy';
      default:
        return 'Undefined Class';
    }
  };

  const departureCabinClass = cabinClassAlt(departureItinerary?.CabinClassCode);
  const returnCabinClass = cabinClassAlt(returnItinerary?.CabinClassCode);
  // console.log(departureCabinClass, returnCabinClass);

  return (
    <div className="h-auto flex flex-col items-start justify-between text-center text-[1rem] text-text-colors-text-secondary font-footnote">
      {/* header */}
      <div className="self-stretch flex flex-col py-2.5 px-0 items-start justify-center gap-2.5 mb-4">
        <span className="self-stretch relative leading-[2.56rem] text-[2.12rem] align-self-start textColor font-satoshi font-bold">Booking Details</span>
        <div className="relative text-[1rem] leading-[1.31rem] font-footnote textColor font-medium">Confirm the details below to finalize your booking!</div>
      </div>

      {/* departure details */}
      <div className="text-[1rem] font-footnote rounded-xl bg-color-shades-white-800 shadow-[0px_8px_24px_rgba(149,_157,_165,_0.2)] box-border w-[37.38rem] flex flex-col items-start justify-start border-[1px] border-solid border-border-colors-border-primary">
        <div className="text-[2.13rem] font-heading-1  self-stretch flex flex-col pt-8 px-8 pb-3 items-start justify-start">
          <div className="flex flex-row items-center self-stretch justify-between">
            <div className="flex flex-row items-center justify-start gap-[0.75rem]">
              <div className="flex flex-row items-center justify-center px-0 py-2">
                <h3 className="relative leading-[2.56rem] font-satoshi text-[2.12rem] font-bold m-0 textColor">{departureFlightSegmentDetails?.departure?.cityName}</h3>
              </div>

              <ArrowRightSvg _width={25} _height={25} />

              <div className="flex flex-row items-center justify-start px-0 py-2">
                <h3 className="relative leading-[2.56rem] font-satoshi text-[2.12rem] font-bold m-0 textColor">{departureFlightSegmentDetails?.arrival?.cityName}</h3>
              </div>
            </div>

            {/* <div className="text-[0.81rem] text-background-colors-surface-secondary font-footnote flex flex-row p-1 items-center justify-center">
              <a className="relative leading-[1.13rem] font-medium font-footnote no-underline" href="/">
                Change Flight
              </a>
            </div> */}
          </div>

          <div className="font-footnote self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[0.63rem]">
            <div className="relative leading-[1.31rem] text-[1rem] font-medium font-footnote flex flex-row gap-2.5">
              <span>{departureFlightSegmentDetails?.departure?.airportName}</span> - <span>{departureFlightSegmentDetails?.arrival?.airportName}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start self-stretch justify-start px-8 py-3 text-background-colors-surface-secondary">
          <div className="self-stretch flex flex-row py-2 px-0 items-center justify-between border-b-[1px] border-solid border-border-colors-border-primary/50">
            <div className="flex flex-row items-center justify-start gap-[0.3rem]">
              <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center">
                {/* <img className="relative w-[1.25rem] h-[0.89rem]" alt="" src="/svg/fly.svg" /> */}
                <AirplaneAscendSvg _width={20} _height={20} _color="#016E7F" />
              </div>
              <div className="flex flex-row items-start justify-start">
                <div className="ml-1 relative leading-[1.31rem] font-medium font-footnote">Departure</div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center text-text-colors-text-secondary">
              <div className="relative leading-[1.31rem] font-regular font-footnote">
                {day},{month}
                {date}
              </div>
            </div>
          </div>
        </div>

        <div className="text-[1rem] self-stretch flex flex-col pt-3 px-8 pb-8 items-start justify-start gap-[2rem]">
          <div className="flex flex-row items-center self-stretch justify-between">
            <div className="flex flex-row items-center justify-center gap-[0.65rem]">
              <div className="flex flex-row items-center justify-center gap-[0.65rem]">
                <img className="relative w-[2rem] h-[2rem] rounded-full object-cover" alt="" loading="lazy" src={departureAirline?.logo} />
                <div className="relative leading-[1.31rem] font-medium font-footnote textColor">{departureAirline?.name}</div>
              </div>

              <div className="text-text-colors-text-secondary relative leading-[1.31rem] font-medium font-footnote flex flex-row gap-[0.25rem]">
                <span>
                  {departureFlightSegmentDetails?.MarketingCarriercode} {departureFlightSegmentDetails?.MarketingFlightNumber ? `${departureFlightSegmentDetails?.MarketingFlightNumber}` : ''}
                </span>
                <span>{departureFlightSegmentDetails?.equipment ? ` · ${departureFlightSegmentDetails?.equipment}` : ''}</span>
                <span>{departureCabinClass ? `· ${departureCabinClass}` : ''}</span>
              </div>
            </div>
          </div>

          <div className="text-[1.25rem] self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-col items-start justify-center gap-[0.13rem]">
              <div className="relative leading-[131.5%] font-semibold font-footnote textColor">{departureFlightSegmentDetails?.departureTime}</div>
              <div className="relative text-[1rem] leading-[1.31rem] font-medium text-text-colors-text-secondary font-footnote">{departureFlightSegmentDetails?.departureCode}</div>
            </div>

            <div className="text-center text-[1rem] text-text-colors-text-secondary flex flex-col items-center justify-center gap-[0.5rem]">
              <div className="relative leading-[1.31rem] font-regular font-footnote">{departureFlightSegmentDetails?.flightDuration}</div>

              <StoparrowSVG />
              <div className="relative leading-[1.31rem] font-regular font-footnote">{departureFlightSegmentDetails?.stops}</div>
            </div>

            <div className="flex flex-col items-start justify-center gap-[0.13rem]">
              <div className="relative leading-[131.5%] font-semibold font-footnote textColor">{departureFlightSegmentDetails?.arrivalTime}</div>
              <div className="relative text-[1rem] leading-[1.31rem] font-medium text-text-colors-text-secondary font-footnote">{departureFlightSegmentDetails?.arrivalCode}</div>
            </div>
          </div>
        </div>

        {flightType === 'round-trip' ? (
          <>
            <div className="flex justify-center w-full">
              <hr className="w-[90%]" />
            </div>
            <div className="text-[2.13rem] font-heading-1  self-stretch flex flex-col pt-8 px-8 pb-3 items-start justify-start">
              <div className="flex flex-row items-center self-stretch justify-between">
                <div className="flex flex-row items-center justify-start gap-[0.75rem]">
                  <div className="flex flex-row items-center justify-center px-0 py-1">
                    <div className="relative leading-[2.56rem] text-black font-satoshi text-[2.12rem] font-bold">{returnFlightSegmentDetails?.departure?.cityName ?? null}</div>
                  </div>

                  <ArrowRightSvg _width={25} _height={25} />

                  <div className="flex flex-row items-center justify-start px-0 py-1">
                    <div className="relative leading-[2.56rem] text-black font-satoshi text-[2.12rem] font-bold">{returnFlightSegmentDetails?.arrival?.cityName ?? null}</div>
                  </div>
                </div>
                {/* 
                <div className="text-[0.81rem] text-background-colors-surface-secondary font-footnote flex flex-row p-1 items-center justify-center">
                  <a className="relative leading-[1.13rem] font-medium font-footnote no-underline" href="/">
                    Change Flight
                  </a>
                </div> */}
              </div>

              <div className="font-footnote self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[0.63rem]">
                <div className="relative leading-[1.31rem] text-[1rem] font-medium font-footnote">
                  {returnFlightSegmentDetails?.departure?.airportName ?? null} - {returnFlightSegmentDetails?.arrival?.airportName ?? null}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start px-8 py-3 text-background-colors-surface-secondary">
              <div className="self-stretch flex flex-row py-2 px-0 items-center justify-between border-b-[1px] border-solid border-border-colors-border-primary">
                <div className="flex flex-row items-center justify-start gap-[0.63rem]">
                  <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center">
                    <img className="relative w-[1.25rem] h-[0.89rem]" alt="" src={`${imageApi}/return.png`} />
                  </div>
                  <div className="flex flex-row items-start justify-start">
                    <div className="relative leading-[1.31rem] font-medium font-footnote">Return</div>
                  </div>
                </div>

                <div className="flex flex-row items-center justify-center text-text-colors-text-secondary">
                  <div className="relative leading-[1.31rem] font-regular font-footnote">
                    {rday},{rmonth}
                    {rdate}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[1rem] self-stretch flex flex-col pt-3 px-8 pb-8 items-start justify-start gap-[2rem]">
              <div className="flex flex-row items-center self-stretch justify-between">
                <div className="flex flex-row items-center justify-center gap-[0.5rem]">
                  <div className="flex flex-row items-center justify-center gap-[0.25rem]">
                    <img className="relative w-[1.5rem] h-[1.5rem] object-cover" alt="" loading="lazy" src={returnAirline?.logo} />
                    <div className="relative leading-[1.31rem] font-medium font-footnote text-black">{returnAirline?.name ?? null}</div>
                  </div>

                  <div className="text-text-colors-text-secondary relative leading-[1.31rem] font-medium font-footnote">
                    <span>
                      {returnFlightSegmentDetails?.MarketingCarriercode} {returnFlightSegmentDetails?.MarketingFlightNumber ? ` ${returnFlightSegmentDetails?.MarketingFlightNumber}` : ''}
                    </span>
                    <span>{returnFlightSegmentDetails?.equipment ? ` · ${returnFlightSegmentDetails?.equipment}` : ''}</span>
                    <span>{returnCabinClass ? ` · ${returnCabinClass}` : ''}</span>
                  </div>
                </div>
              </div>

              <div className="text-[1.25rem] self-stretch flex flex-row items-center justify-between">
                <div className="flex flex-col items-start justify-center gap-[0.13rem]">
                  <div className="relative leading-[131.5%] font-semibold font-footnote text-black">{returnFlightSegmentDetails?.departureTime ?? null}</div>
                  <div className="relative text-[1rem] leading-[1.31rem] font-medium text-text-colors-text-secondary font-footnote">{returnFlightSegmentDetails?.departureCode ?? null}</div>
                </div>

                <div className="text-center text-[1rem] text-text-colors-text-secondary flex flex-col items-center justify-center gap-[0.5rem]">
                  <div className="relative leading-[1.31rem] font-medium font-footnote">{returnFlightSegmentDetails?.flightDuration ?? null}</div>
                  <StoparrowSVG />
                  <div className="relative leading-[1.31rem] font-medium font-footnote">{returnFlightSegmentDetails?.stops ?? null}</div>
                </div>

                <div className="flex flex-col items-start justify-center gap-[0.13rem]">
                  <div className="relative leading-[131.5%] font-semibold font-footnote text-black">{returnFlightSegmentDetails?.arrivalTime ?? null}</div>
                  <div className="relative text-[1rem] leading-[1.31rem] font-medium text-text-colors-text-secondary font-footnote">{returnFlightSegmentDetails?.arrivalCode ?? null}</div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default BookingDetails;
