import React from 'react';
import { hotelStore } from '../../../store/HotelStore';
import { GallerySvg } from '../../../resources/svg/Gallery';

const AllHotelPhotosModalNavbar = () => {
  const { toggleGallery, slideShow } = hotelStore();

  const linkData: string[] = ['All Photos', 'Rooms', 'Properties', 'Property Views', 'Facilities', 'Dining', 'Nearby Attractions', 'Others'];

  // eslint-disable-next-line
  const links: JSX.Element[] = linkData.map((text, index) => (
    <a
      href="/"
      key={index}
      className="flex flex-col no-underline pt-2 px-0 pb-0 items-center justify-start gap-[0.5rem] text-black-black-100 hover:border-b-2 active:border-b-2 hover:text-primary-primary-100 active:text-primary-primary-100 border-primary-primary-100 leading-[1.25rem] font-footnote font-normal mx-3"
    >
      {text}
    </a>
  ));

  return (
    <section
      className="absolute rounded-t-lg top-[0rem] left-[0rem] w-[77.25rem] h-[4.64rem] text-center text-[0.94rem] text-text-colors-text-primary font-callout bg-white shadow-[0px_16px_20px_rgba(51,_51,_51,_0.05)]
    flex items-center"
    >
      <nav className="flex flex-row items-start justify-start gap-[0.5rem]">
        {/* toggle slideshow and gallery */}

        <button
          onClick={toggleGallery}
          className="rounded-xl flex w-[150px] items-center justify-center py-2 px-4 ml-10 gap-[0.38rem] text-left text-[0.81rem] textColor font-callout border-[1px] border-solid border-primary-primary-100"
        >
          {slideShow ? (
            <>
              <GallerySvg />
              <div className="relative leading-[1.13rem] font-medium">Slide Show</div>
            </>
          ) : (
            <>
              <GallerySvg />
              <div className="relative leading-[1.13rem] font-medium">Gallery</div>
            </>
          )}
        </button>

        {/* {links} */}
      </nav>
    </section>
  );
};

export default AllHotelPhotosModalNavbar;
