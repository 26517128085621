import React from 'react';
import { FlightResultsHook } from '../../../hooks/FlightResultsHook';
import moment from 'moment';
import StickyBox from 'react-sticky-box';
import { useInternationalBooking } from '../../../store/InternationalStore';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { ASSETS_API } from '../../../utils/apiRoutes';
import { ChevronDownSvg, ChevronTriangleDownSvg } from '../../../resources/svg/ChevronDownSvg';

type BookingSummaryProps = {
  FlightType: 'one-way' | 'round-trip';
  Flight: { [index: string]: any };
  DepartureFlightSegmentDetails: { [index: string]: any } | undefined;
  ReturnFlightSegmentDetails: { [index: string]: any } | undefined;
  DepartureAirline: { [index: string]: any } | undefined;
  ReturnAirline: { [index: string]: any } | undefined;
  PassengerCount: number | string;
  AdultCount: number | string;
  ChildrenCount: number | string;
  InfantCount: number | string;
  MinorCount: number | string;
};

const BookingSummary = ({
  FlightType,
  Flight,
  DepartureFlightSegmentDetails,
  ReturnFlightSegmentDetails,
  DepartureAirline,
  ReturnAirline,
  PassengerCount,
  AdultCount,
  ChildrenCount,
  InfantCount,
  MinorCount,
}: BookingSummaryProps) => {
  const imageApi = ASSETS_API().public.images;
  const { isDepartureSummaryClicked, isReturnSummaryClicked, toggleDepartureSummary, toggleReturnSummary } = useInternationalBooking();
  const { isTotalBaseFareClicked, toggleTotalBaseFare } = useInternationalBooking();
  const { flightFares } = FlightResultsHook();

  // const urlState = useLocation();

  const flightType = FlightType; // one-way | round-trip
  const flightDetails = Flight;

  // const { segments } = MystiflyFlightSegmentHook();
  // const { departureFlightSegmentDetails, departureAirline, returnFlightSegmentDetails, returnAirline } = segments({ FlightType: flightType, FlightDetails: flightDetails });

  const departureFlightSegmentDetails = DepartureFlightSegmentDetails;
  const departureAirline = DepartureAirline;
  const returnFlightSegmentDetails = ReturnFlightSegmentDetails;
  const returnAirline = ReturnAirline;

  const FlightFare = flightFares(flightDetails.FlightFares);
  // console.log(FlightFare);

  const adultPrice = FlightFare?.adult.adultBaseFare * Number(AdultCount);
  const minorPrice = FlightFare?.adult.adultBaseFare * Number(MinorCount); // minor is same as adult
  const childPrice = FlightFare?.child.childBaseFare * Number(ChildrenCount);
  const infantPrice = FlightFare?.infant.infantBaseFare * Number(InfantCount);
  const totalBaseFare = adultPrice + minorPrice + childPrice + infantPrice; // summation of all base fares for all passengers

  const adultTax = FlightFare?.adult.adultTax * FlightFare?.passengers.adultQuantity;
  const childTax = FlightFare?.child.childTax * FlightFare?.passengers.childQuantity;
  const infantTax = FlightFare?.infant.infantTax * FlightFare?.passengers.infantQuantity;
  const totalTax = adultTax + childTax + infantTax;

  return (
    <StickyBox offsetTop={30} offsetBottom={20}>
      <div className="w-[33.75rem] flex flex-row items-start justify-between text-[1.38rem] font-footnote font-heading-1 text-text-colors-text-secondary">
        <div className="flex-1 rounded-xl bg-color-shades-white-800 shadow-[0px_4px_40px_rgba(51,_51,_51,_0.1)] flex flex-col items-start justify-start border-[1px] border-solid border-background-colors-surface-secondary">
          <div className="self-stretch rounded-t-xl rounded-b-none bg-color-shades-white-900 flex flex-row py-6 px-4 items-center justify-center font-heading-1 border-b-[1px] border-solid border-color-shades-grey-700">
            <b className="relative leading-[1.75rem] font-satoshi text-black">Price Summary</b>
          </div>

          {/* Total Base Fare */}
          <div className="self-stretch flex flex-col  py-[2rem] items-start justify-start gap-[1.25rem] text-[1.25rem] font-footnote transition-all">
            <div className="flex flex-col gap-[0.75rem] px-[1.5rem] justify-between w-100">
              <div className="flex flex-row justify-between items-start self-stretch justify-start">
                <div onClick={toggleTotalBaseFare} className="cursor-pointer self-stretch flex flex-col items-start justify-start gap-[14px] transition-all">
                  <div className="flex flex-column gap-[2px] ">
                    <div className="basefare-container flex flex-row gap-[8px] items-end">
                      <span className="textColor generalsans-medium text-lg">Total Base Fare</span>
                      <span className={`${isTotalBaseFareClicked && 'rotate-180'}`}>
                        <ChevronDownSvg _color="#ADADAD" _height={14} _width={14} />
                      </span>
                    </div>
                    <div className="text-start textColor generalsans-regular text-base">
                      ( <span>{PassengerCount}</span> Passenger{+PassengerCount > 1 ? 's' : null} )
                    </div>
                  </div>
                </div>
                <div className="relative leading-[1.31rem] font-medium font-footnote textColor generalsans-medium text-lg">
                  {FlightFare?.currency} {formatPriceNoRound(totalBaseFare)}
                </div>
              </div>

              {isTotalBaseFareClicked && (
                <div className="flex flex-column gap-[0.375rem] text-start text-secondary generalsans-regular text-base">
                  {+AdultCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{+AdultCount > 1 ? `Adults x ${AdultCount}` : `Adult x ${AdultCount}`}</span>
                      <span>
                        {FlightFare?.currency} {formatPriceNoRound(adultPrice)}
                      </span>
                    </div>
                  )}

                  {+MinorCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{+MinorCount > 1 ? `Minors x ${MinorCount}` : `Minor x ${MinorCount}`}</span>
                      <span>
                        {FlightFare?.currency} {formatPriceNoRound(adultPrice)}
                      </span>
                    </div>
                  )}

                  {+ChildrenCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{+ChildrenCount > 1 ? `Children x ${ChildrenCount}` : `Child x ${ChildrenCount}`}</span>
                      <span>
                        {FlightFare?.currency} {formatPriceNoRound(childPrice)}
                      </span>
                    </div>
                  )}

                  {+InfantCount > 0 && (
                    <div className="flex flex-row justify-between">
                      <span>{+InfantCount > 1 ? `Infants x ${InfantCount}` : `Infant x ${InfantCount}`}</span>
                      <span>
                        {FlightFare?.currency} {formatPriceNoRound(infantPrice)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex flex-row justify-between items-center self-stretch justify-start px-[1.5rem]">
              <span className="generalsans-medium text-lg textColor">Taxes and Fees</span>
              <div className="relative leading-[1.31rem] font-footnote textColor generalsans-medium text-lg">
                {FlightFare?.currency} {formatPriceNoRound(totalTax)}
              </div>
            </div>
          </div>

          {/* EXCESS CODE FOR FUTURE USE: departure summary */}
          <div className="self-stretch flex flex-col pt-6 px-0 items-start justify-start gap-[0.94rem] text-[1.25rem] font-footnote transition-all d-none ">
            <div className="flex flex-col items-start self-stretch justify-start">
              <div className="self-stretch flex flex-col py-0 px-[18px] items-start justify-start">
                <div onClick={toggleDepartureSummary} className="cursor-pointer self-stretch flex flex-col items-start justify-start gap-[0.25rem] transition-all">
                  <div className="flex flex-row items-start self-stretch justify-between px-0 py-3">
                    <div className="flex flex-col items-start justify-start gap-[0.5rem] ">
                      <div className="flex flex-row items-center justify-center gap-[0.63rem]">
                        <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center ">
                          <img className="relative w-[1.25rem] h-[0.89rem]" alt="" src="/svg/fly.svg" />
                        </div>
                        <div className="flex flex-row items-center justify-center gap-[0.63rem]">
                          <div className="relative leading-[131.5%] font-semibold text-black">
                            {departureFlightSegmentDetails?.departureCode} - {departureFlightSegmentDetails?.arrivalCode}
                          </div>
                        </div>
                        <div className="relative text-[1rem] leading-[1.31rem] font-medium  font-footnote text-text-colors-text-secondary">{departureFlightSegmentDetails?.flightDuration}</div>
                      </div>

                      <div className="flex flex-col items-start justify-start text-[0.81rem] text-text-colors-text-secondary ">
                        <div className="self-stretch flex flex-row py-0.5 px-0 items-start justify-start ">
                          <div className="flex flex-row items-start justify-start gap-[0.5rem] ">
                            <div className="relative leading-[1.13rem] font-medium font-footnote">{moment(departureFlightSegmentDetails?.departureDateTime).format('DD MMM YYYY • hh:mm A')}</div>
                            <div className="relative leading-[1.13rem] font-medium">-</div>
                            <div className="relative leading-[1.13rem] font-medium font-footnote">{moment(departureFlightSegmentDetails?.arrivalDateTime).format('DD MMM YYYY • hh:mm A')}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-[1rem] h-[1rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center">
                      <img className={`relative w-[0.63rem] h-[0.36rem] cursor-pointer ${isDepartureSummaryClicked && 'rotate-180'}`} alt="" src="/svg/accordion-arrow.svg" />
                      <span className={`${isDepartureSummaryClicked && 'rotate-180'}`}>
                        <ChevronTriangleDownSvg _color="#016E7F" />
                      </span>
                    </div>
                  </div>

                  {isDepartureSummaryClicked && (
                    <div className="self-stretch flex flex-row py-2.5 px-0 items-center justify-between text-[1rem] ">
                      <div className="flex flex-row items-center justify-center">
                        <div className="flex flex-row items-center justify-center gap-[0.25rem] ">
                          <img className="relative w-[1.25rem] h-[1.25rem] object-cover " alt="" loading="lazy" src={departureAirline?.logo} />
                          <div className="relative leading-[1.31rem] font-medium text-black text-[1rem] font-footnote">{departureAirline?.name}</div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-start gap-[0.38rem] text-text-colors-text-secondary ">
                        <div className="relative leading-[1.31rem] font-medium font-footnote capitalize">
                          {flightType} • {departureFlightSegmentDetails?.stops}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isDepartureSummaryClicked && (
              <div className="flex flex-col items-start self-stretch justify-start text-background-colors-surface-secondary font-heading-1">
                <div className="flex flex-col items-start self-stretch justify-start">
                  <div className="self-stretch flex flex-row py-0 px-[18px] items-start justify-between ">
                    <div className="flex flex-col items-start justify-start flex-1">
                      <div className="flex flex-col items-start self-stretch justify-center">
                        <div className="relative leading-[1.56rem] font-medium font-footnote">Price Breakdown</div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col py-3 px-[18px] items-start justify-center gap-[0.38rem] text-[1rem] text-text-colors-text-secondary font-footnote ">
                    <div className="flex flex-row items-center self-stretch justify-between">
                      <div className="relative leading-[1.31rem] font-medium font-footnote">Base Fare</div>
                      <div className="relative leading-[1.31rem] font-medium font-footnote">
                        {FlightFare?.currency} {formatPriceNoRound(FlightFare?.baseFareGrandTotal)}
                      </div>
                    </div>

                    <div className="flex flex-row items-center self-stretch justify-between">
                      <div className="relative leading-[1.31rem] font-medium font-footnote">Taxes</div>
                      <div className=" relative leading-[1.31rem] font-medium font-footnote">
                        {FlightFare?.currency} {formatPriceNoRound(FlightFare?.taxesGrandTotal + FlightFare?.markedPriceGrandTotal)}
                      </div>
                    </div>
                    <div className="flex flex-row items-center self-stretch justify-between">
                      <div className="flex flex-row items-center justify-center">
                        <div className="relative leading-[1.31rem] font-medium font-footnote">Total Taxes and Fees</div>
                      </div>
                      <div className=" relative leading-[1.31rem] font-medium font-footnote">
                        {FlightFare?.currency} {formatPriceNoRound(FlightFare?.flightFareGrandTotal)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* EXCESS CODE FOR FUTURE USE: return summary */}
          {flightType === 'round-trip' ? (
            <div className="self-stretch flex flex-col pb-6 px-0 items-start justify-start gap-[0.94rem] text-[1.25rem] font-footnote transition-all d-none">
              <div className="flex flex-col items-start self-stretch justify-start">
                <div className="self-stretch flex flex-col py-0 px-[18px] items-start justify-start">
                  <div onClick={toggleReturnSummary} className="cursor-pointer self-stretch flex flex-col items-start justify-start gap-[0.25rem]">
                    <div className="flex flex-row items-start self-stretch justify-between px-0 py-3">
                      <div className="flex flex-col items-start justify-start gap-[0.5rem] ">
                        <div className="flex flex-row items-center justify-center gap-[0.63rem]">
                          <div className="w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center ">
                            <img className="relative w-[1.25rem] h-[0.89rem]" alt="" src={`${imageApi}/return.png`} />
                          </div>
                          <div className="flex flex-row items-center justify-center gap-[0.63rem]">
                            <div className="relative leading-[131.5%] font-semibold text-black">
                              {returnFlightSegmentDetails?.departureCode} - {returnFlightSegmentDetails?.arrivalCode}
                            </div>
                          </div>
                          <div className="relative text-[1rem] leading-[1.31rem] font-medium  font-footnote text-text-colors-text-secondary">{returnFlightSegmentDetails?.flightDuration}</div>
                        </div>

                        <div className="flex flex-col items-start justify-start text-[0.81rem] text-text-colors-text-secondary ">
                          <div className="self-stretch flex flex-row py-0.5 px-0 items-start justify-start ">
                            <div className="flex flex-row items-start justify-start gap-[0.5rem] ">
                              <div className="relative leading-[1.13rem] font-medium font-footnote">{moment(returnFlightSegmentDetails?.departureDateTime).format('DD MMM YYYY • hh:mm A')}</div>
                              <div className="relative leading-[1.13rem] font-medium">-</div>
                              <div className="relative leading-[1.13rem] font-medium font-footnote">{moment(returnFlightSegmentDetails?.arrivalDateTime).format('DD MMM YYYY • hh:mm A')}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-[1rem] h-[1rem] overflow-hidden shrink-0 flex flex-col p-0.5 box-border items-center justify-center">
                        <img className={`relative w-[0.63rem] h-[0.36rem] cursor-pointer ${isReturnSummaryClicked && 'rotate-180'}`} alt="" src="/svg/accordion-arrow.svg" />
                        <span className={`${isReturnSummaryClicked && 'rotate-180'}`}>
                          <ChevronTriangleDownSvg _color="#016E7F" />
                        </span>
                      </div>
                    </div>

                    {isReturnSummaryClicked && (
                      <div className="self-stretch flex flex-row py-2.5 px-0 items-center justify-between text-[1rem] ">
                        <div className="flex flex-row items-center justify-center">
                          <div className="flex flex-row items-center justify-center gap-[0.25rem] ">
                            <img className="relative w-[1.25rem] h-[1.25rem] object-cover " alt="" loading="lazy" src={returnAirline?.logo} />
                            <div className="relative leading-[1.31rem] font-medium text-black text-[1rem] font-footnote">{returnAirline?.name}</div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-start gap-[0.38rem] text-text-colors-text-secondary ">
                          <div className="relative leading-[1.31rem] font-medium font-footnote capitalize">
                            {flightType} • {returnFlightSegmentDetails?.stops}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isReturnSummaryClicked && (
                <div className="flex flex-col items-start self-stretch justify-start text-background-colors-surface-secondary font-heading-1">
                  <div className="flex flex-col items-start self-stretch justify-start">
                    <div className="self-stretch flex flex-row py-0 px-[18px] items-start justify-between ">
                      <div className="flex flex-col items-start justify-start flex-1">
                        <div className="flex flex-col items-start self-stretch justify-center">
                          <div className="relative leading-[1.56rem] font-medium font-footnote">Price Breakdown</div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col py-3 px-[18px] items-start justify-center gap-[0.38rem] text-[1rem] text-text-colors-text-secondary font-footnote ">
                      <div className="flex flex-row items-center self-stretch justify-between">
                        <div className="relative leading-[1.31rem] font-medium font-footnote">Base Fare</div>
                        <div className="relative leading-[1.31rem] font-medium font-footnote">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.baseFareGrandTotal)}
                        </div>
                      </div>

                      <div className="flex flex-row items-center self-stretch justify-between">
                        <div className="relative leading-[1.31rem] font-medium font-footnote">Taxes</div>
                        <div className=" relative leading-[1.31rem] font-medium font-footnote">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.taxesGrandTotal + FlightFare?.markedPriceGrandTotal)}
                        </div>
                      </div>

                      <div className="flex flex-row items-center self-stretch justify-between">
                        <div className="flex flex-row items-center justify-center">
                          <div className="relative leading-[1.31rem] font-medium font-footnote">Total Taxes and Fees</div>
                        </div>
                        <div className=" relative leading-[1.31rem] font-medium font-footnote">
                          {FlightFare?.currency} {formatPriceNoRound(FlightFare?.flightFareGrandTotal)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : null}

          {/* total */}
          <div className="self-stretch rounded-t-none rounded-b-xl bg-background-colors-surface-secondary flex flex-row py-6 px-4 items-end justify-between text-[1.25rem] text-color-shades-white-800">
            <div className="relative leading-[1.31rem] font-medium font-footnote">Total Price</div>
            <div className="relative text-[1.25rem] leading-[131.5%] font-semibold font-footnote">
              {FlightFare.currency} {formatPriceNoRound(totalTax + totalBaseFare)}
            </div>
          </div>
        </div>
      </div>
    </StickyBox>
  );
};

export default BookingSummary;
