import React from 'react';
import { GallyError } from '../../resources/svg/gally-error-svg';

export const SomethingWentWrong = () => {
  return <div className='flex flex-col items-center justify-center gap-10'>
      <GallyError _height={160} _width={200}/>
      <div className='flex flex-col gap-y-1.5 items-center'>
        <span className='text-2xl satoshi-bold textColor'>Our bad! Something went wrong!</span>
        <p className='m-0 p-0 text-secondary text-base generalsans-medium'>Sit tight, we're working on it! Try refreshing this page – or contact our Travel Desk for help.</p>
      </div>
    </div>;
};
