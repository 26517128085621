import React from 'react';
import { useNavigate } from 'react-router';
import { HotelBookingReferenceHook } from '../../../hooks/HotelBookingReferenceHook';
import { AlertBox } from '../../../components/AlertBox';
import moment from 'moment';
import '../../../resources/css/payment-confirmation.scss';
import { CheckFilledSvg } from '../../../resources/svg/CheckSvg';
import { GalagoButton } from '../../../components/Button';

type HotelBookingConfirmationContainerTypes = {
  platform?: string | null;
  paymentReference?: string | null;
};

const HotelBookingConfirmationContainer = ({ platform, paymentReference }: HotelBookingConfirmationContainerTypes) => {
  // const publicImageApi = ASSETS_API().public.images;
  const navigate = useNavigate();

  const { bookingDetails } = HotelBookingReferenceHook(paymentReference);
  const [showBreakdown, setShowBreakdown] = React.useState(false);

  const hotelFirstImage = bookingDetails?.images !== undefined ? bookingDetails?.images[0] : '/img/missing-img.png';

  const checkInDate = moment(bookingDetails?.checkInDate).format('MMMM DD');
  const checkOutDate =
    moment(bookingDetails?.checkInDate).format('MMMM') === moment(bookingDetails?.checkOutDate).format('MMMM')
      ? moment(bookingDetails?.checkOutDate).format('DD, YYYY')
      : moment(bookingDetails?.checkOutDate).format('MMMM DD, YYYY');

  // count the nights of checkin and checkout
  const nights = moment(bookingDetails?.checkOutDate).diff(moment(bookingDetails?.checkInDate), 'days');

  // sample links
  // http://localhost:3000/#/payment-confirmation?paymentReference=QFZBnOUR2s50TyYhoqVfpPk&product=hotels
  // http://localhost:3000/#/payment-confirmation?paymentReference=QFZBnOUR2s50TyAipKZVr_Q&product=hotels

  React.useEffect(() => {
    if (platform === 'mobile') {
      setTimeout(() => {
        // window.open(`myapp://book/hotel/HotelCallbackScreen?paymentReference=${paymentReference ?? ''}`);
        window.location.assign(`myapp://book/hotel/HotelCallbackScreen?paymentReference=${paymentReference ?? ''}`);
      }, 2000);
    }
    if (platform === 'pwa') {
      window.open(`${process.env.REACT_APP_PWA_URL}/book/hotel/HotelCallbackScreen?paymentReference=${paymentReference ?? ''}`);
    }
    // eslint-disable-next-line
  }, []);

  // console.log(bookingDetails);

  return (
    <>
      <section className="container">
        <div className="row justify-content-evenly my-10">
          {/* Left Component  */}
          <div className="col-5 d-flex flex-column justify-content-center gap-2">
            <div className="flex flex-column align-items-center gap-2.5">
              <CheckFilledSvg _width={50} _height={50} _color="#428D41" />
              <h1 className="text-center textColor font-bold text-[34px]">Your booking is Confirmed!</h1>
            </div>
            <div className="flex justify-content-center textColor generalsans-medium text-base">
              <span className="text-center">
                Payment for your hotel booking is successful. <br /> Thanks for booking with GalaGO!
              </span>
            </div>
            <div className="mt-8">
              <GalagoButton label="Back to Home" btn="primary" onClick={() => navigate('/')} className="generalsans-regular" />
            </div>
          </div>

          {/* Right Component */}
          <div className="col-4 border border-primary rounded-lg shadow-md" id="hotel-book-information">
            <div className="flex flex-column gap-2">
              <div className="textColor generalsans-medium">You've booked a stay at:</div>
              <h3 className="font-semibold text-2xl mb-0 text-text-colors-text-brand">{bookingDetails?.hotelName}</h3>
            </div>

            <img className="rounded-xl h-60 w-100 mt-6" alt="" loading="lazy" src={hotelFirstImage} onError={(e) => (e.currentTarget.src = '/img/missing-img.png')} />

            <div className="mt-8">
              <div className="flex flex-col items-start justify-start gap-6">
                <div className="w-100 gap-[1.5rem] border-bottom pb-2.5">
                  <div className="w-100 flex justify-content-between textColor generalsans-medium text-base">
                    <div>{bookingDetails ? `${checkInDate} - ${checkOutDate}` : null}</div>
                    <div>{bookingDetails ? `(${nights} nights)` : null}</div>
                  </div>
                </div>

                <div className="w-100 flex flex-col items-start ">
                  <div className="w-100 flex flex-col items-start justify-center">
                    <div className="relative leading-[1.38rem] font-medium whitespace-pre-wrap flex items-center w-100">
                      <div className="flex flex-column gap-1 w-full">
                        <p className="flex justify-content-between m-0 textColor">
                          <span className="font-medium font-footnote">Paid Today</span>
                          <span className="font-medium font-footnote">
                            {bookingDetails?.currency} {bookingDetails?.totalRoomPriceFormatted}
                          </span>
                        </p>

                        <GalagoButton onClick={() => setShowBreakdown(true)} className="m-0 text-sm text-primary generalsans-regular text-xs !p-0 w-max" label="View Price Overview" btn="link" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start border-top pt-4 w-100">
                  <div className="w-100 flex flex-col items-start justify-start">
                    <div className="w-100 leading-[1.38rem] font-medium flex items-center justify-content-between">
                      <span className="generalsans-medium textColor text-base">Booking Reference</span>
                      <span className="generalsans-medium textColor text-base">{bookingDetails?.paymentReferenceNumber}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AlertBox
        show={showBreakdown}
        onHide={() => setShowBreakdown(false)}
        title="&nbsp;"
        children={
          <div className="p-4">
            <h4 className="mb-4 generalsans-regular font-semibold textColor">Price Overview</h4>
            <div className="flex justify-content-between mb-3 textColor">
              <span className="generalsans-regular">Total Room Price</span>
              <span className="generalsans-medium">
                {' '}
                {bookingDetails?.currency} {bookingDetails?.totalRoomsPriceFormatted}
              </span>
            </div>
            <div className="flex justify-content-between mb-4 textColor">
              <span className="generalsans-regular">Taxes and Fees</span>
              <span className="generalsans-medium">
                {bookingDetails?.currency} {bookingDetails?.totalTaxFormatted}
              </span>
            </div>

            {/* Special Request: Hidden Currently Unavailable */}
            <div className="d-none flex justify-content-between mb-3 textColor">
              <span className="generalsans-regular">Special Request</span>
              <span className="generalsans-medium">{bookingDetails?.currency} 0.00</span>
            </div>

            {/* GalaGO! Discount: Hidden Currently Unavailable */}
            <div className="d-none flex justify-content-between mb-3 text-danger generalsans-medium">
              <span className="generalsans-regular">GalaGO! Discount</span>
              <span className="generalsans-medium">{bookingDetails?.currency} 0.00</span>
            </div>

            <div className="flex flex-column align-items-end justify-content-between border-top pt-3">
              <span className="text-secondary text-sm generalsans-medium">Total Price</span>
              <span className="generalsans-semibold text-xl text-primary">
                {bookingDetails?.currency} {bookingDetails?.totalRoomPriceFormatted}
              </span>
            </div>
          </div>
        }
      />
    </>
  );
};

export default HotelBookingConfirmationContainer;
