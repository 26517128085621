import { SvgProps } from './SvgProps';
export const SearchSvg = ({ _width = 15, _height = 15, _color = '#333' }: SvgProps) => {
  return (
    <svg width={_width} height={_height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M11.2175 9.4284C10.9227 9.14595 10.6357 8.85539 10.3569 8.55705C10.1227 8.31907 9.98164 8.14593 9.98164 8.14593L8.21878 7.30417C8.92455 6.50373 9.31406 5.47331 9.31427 4.40616C9.31427 1.97657 7.33736 -0.000976562 4.90714 -0.000976562C2.47692 -0.000976562 0.5 1.97657 0.5 4.40616C0.5 6.83575 2.47692 8.81329 4.90714 8.81329C6.0171 8.81329 7.02886 8.39776 7.80514 7.71844L8.6469 9.48129C8.6469 9.48129 8.82004 9.62232 9.05803 9.85653C9.30168 10.0851 9.62214 10.3942 9.92938 10.7172L10.7844 11.5936L11.1646 12.0003L12.5 10.6649L12.0933 10.2846C11.8547 10.0504 11.5361 9.73942 11.2175 9.4284ZM4.90714 7.55411C3.17135 7.55411 1.75918 6.14194 1.75918 4.40616C1.75918 2.67038 3.17135 1.25821 4.90714 1.25821C6.64292 1.25821 8.05509 2.67038 8.05509 4.40616C8.05509 6.14194 6.64292 7.55411 4.90714 7.55411Z"
        fill={_color}
      />
    </svg>
  );
};
